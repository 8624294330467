/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "កិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់",
  privacyPolicy: "គោលការណ៍​ភាព​ឯកជន",
  termsOfService: "លក្ខខណ្ឌ​នៃ​សេវាកម្ម",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "មិនទាន់បានចាប់ផ្តើមនៅឡើយទេ",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "ចាក់ផ្សាយរួចហើយ",
  watch: "ទៅមើល",
  follow: "ផ្ដោត​ទៅលើ",
  following: "តាមរួចហើយ",
  subscribe: "ការជាវ",
  streamerSchedule: "កាលវិភាគយុថ្កា",
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
  unfollowPrompt: "តើអ្នកប្រាកដក្នុងការឈប់តាមដានទេ?",
  yes: "បាទ",
  no: "ទេ",
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  hours: "ម៉ោង",
  mins: "នាទី",
};

const AnchorInformation = {
  recommendedLiveStreams: "ការផ្សាយបន្តផ្ទាល់ត្រូវបានណែនាំ",
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
};

const BasketballScoreIndex = {
  details: "ព័ត៌មានលម្អិត",
  over: "ជាង",
  pointsDifference: "ពិន្ទុខុសគ្នា",
  totalScore: "ពិន្ទុ​សរុប",
  live: "LIVE",
  unSubscribePrompt: "តើអ្នកប្រាកដក្នុងការលុបចោលការជាវរបស់អ្នកមែនទេ?",
  yes: "បាទ",
  no: "ទេ",
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
};

const BasketballSmallBig = {
  details: "ព័ត៌មានលម្អិត",
  over: "ជាង",
  pointsDifference: "ពិន្ទុខុសគ្នា",
  totalScore: "ពិន្ទុ​សរុប",
  live: "LIVE",
  unSubscribePrompt: "តើអ្នកប្រាកដក្នុងការលុបចោលការជាវរបស់អ្នកមែនទេ?",
  yes: "បាទ",
  no: "ទេ",
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
  overIndex: "ធំ",
  underIndex: "តូច",
};

const BindPhoneDialog = {
  bindPhonePrompt: "ដើម្បីសុវត្ថិភាពគណនីរបស់អ្នក សូមចងលេខទូរស័ព្ទរបស់អ្នក ដើម្បីឱ្យយើងអាចផ្តល់ជូនអ្នកនូវសេវាកម្មកាន់តែប្រសើរ។",
  goBind: "ចូលទៅកាន់ការចង",
};

const BottomNavigationBar = {
  home: "ទំព័រ​មុខ",
  matches: "ការប្រកួតប្រជែង",
  recommend: "ណែនាំ",
  chat: "ជជែក",
  personal: "ផ្ទាល់ខ្លួន",
  discovery: "ស្វែងយល់",
  money: "រកលុយ",
};

const ChatInterface = {
  noCsPrompt: "មិនអាចជជែកបានទេនៅពេលនេះ",
  uploadRulePrompt: "សូមបង្ហោះរូបភាពជាទម្រង់ jpg, jpeg, png",
  uploadSizePrompt: "ទំហំរូបភាពមិនអាចលើសពី 10M",
  sendError: "ការ​ផ្ញើ​រ​ចេញ​មិនបាន​សំរេច",
  picSendError: "ការផ្ញើរូបភាពបានបរាជ័យ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
  bannedSendPrompt: "អ្នក​ត្រូវ​បាន​ហាមឃាត់",
};

const ChatList = {
  noNewPrompt: "មិនទាន់មានព័ត៌មាននៅឡើយទេ",
  logInPrompt: "ចូល​ដើម្បី​មើល​មាតិកា សូម​ចូល​ជា​មុន​សិន",
  logIn: "ចូល",
  group: "ក្រុម",
};

const ChatListItem = {
  withdrawMessage: "ដក​សារ",
  bannedPrompt: "បានហាមឃាត់",
  unBanPrompt: "មិនត្រូវបានហាមឃាត់",
  joinGroupPrompt: "បានចូលរួមក្រុម",
  leftGroupPrompt: "បានចាកចេញពីក្រុម",
  picture: "រូបភាព",
  link: "តំណភ្ជាប់",
  emoji: "សញ្ញាអារម្មណ៍មានចលនា",
  addedFriendPrompt: "បានបន្ថែមថាជាមិត្ត",
};

const CompetitionList = {
  logInPrompt: "ចូល​ដើម្បី​មើល​មាតិកា សូម​ចូល​ជា​មុន​សិន",
  logIn: "ចូល",
  today: "ថ្ងៃនេះ",
  Monday: "ថ្ងៃច័ន្ទ",
  Tuesday: "ថ្ងៃអង្គារ",
  Wednesday: "ថ្ងៃពុធ",
  Thursday: "ថ្ងៃព្រហស្បតិ៍",
  Friday: "ថ្ងៃសុក្រ",
  Saturday: "ថ្ងៃសៅរ៍",
  Sunday: "ថ្ងៃអាទិត្យ",
  noGames: "មិនទាន់មានហ្គេមទេ។",
  toCollapse: "ចុចដើម្បីបង្រួម",
  toExpand: "ចុចដើម្បីពង្រីក"
};

const CompetitionListItem = {
  details: "ព័ត៌មានលម្អិត",
  unSubscribePrompt: "តើអ្នកប្រាកដក្នុងការលុបចោលការជាវរបស់អ្នកមែនទេ?",
  yes: "បាទ",
  no: "ទេ",
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
};

const CompetitionMode = {
  matchMode: "របៀបប្រកួតប្រជែង",
  confirm: "បញ្ជាក់",
  cancel: "បោះបង់",
};

const EmojiCom = {
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  yes: "បាទ",
  no: "ទេ",
};

const ExponentCom = {
  company: "ក្រុមហ៊ុន",
  initial: "ដំបូង",
  ongoing: "ភ្លាមៗ",
  preMatch: "មុនការប្រកួត",
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
  handicap: "ពិការ",
  WinDrawLose: "ឈ្នះ ស្មើ ចាញ់",
  totalGoals: "គោលដៅសរុប",
  cornerKick: "ស្នែង",
  pleaseSelect: "សូម​ជ្រើសរើស",
  confirm: "បញ្ជាក់",
  cancel: "បោះបង់",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "បានខ្ទាស់ទៅ 'Stream chat'",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "ផ្ដោត​ទៅលើ",
  following: "តាមរួចហើយ",
  chat: "ជជែក",
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
  unfollowPrompt: "តើអ្នកប្រាកដក្នុងការឈប់តាមដានទេ?",
  yes: "បាទ",
  no: "ទេ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
};

const FootballCornerIndex = {
  details: "ព័ត៌មានលម្អិត",
  over: "ជាង",
  FH: "HT",
  cornerKick: "ស្នែង",
  unSubscribePrompt: "តើអ្នកប្រាកដក្នុងការលុបចោលការជាវរបស់អ្នកមែនទេ?",
  yes: "បាទ",
  no: "ទេ",
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
};

const FootballScoreIndex = {
  details: "ព័ត៌មានលម្អិត",
  over: "ជាង",
  FH: "HT",
  cornerKick: "ស្នែង",
  live: "LIVE",
  unSubscribePrompt: "តើអ្នកប្រាកដក្នុងការលុបចោលការជាវរបស់អ្នកមែនទេ?",
  yes: "បាទ",
  no: "ទេ",
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
};

const FootballScoreItem = {
  details: "ព័ត៌មានលម្អិត",
  over: "ជាង",
  FH: "HT",
  cornerKick: "ស្នែង",
  live: "LIVE",
  unSubscribePrompt: "តើអ្នកប្រាកដក្នុងការលុបចោលការជាវរបស់អ្នកមែនទេ?",
  yes: "បាទ",
  no: "ទេ",
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
};

const GoalToast = {
  goal: "គោលដៅ",
};

const LiveRecommend = {
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "ជ័យជំនះឬបរាជ័យ",
};

const MatchCardList = {
  details: "ព័ត៌មានលម្អិត",
};

const MatchStatus = {
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
};

const MoreStreams = {
  switchLines: "ប្តូរបន្ទាត់",
  cancel: "បោះបង់",
  currentStreamer: "យុថ្កាបច្ចុប្បន្ន",
  live: "LIVE",
  offLine: "មិនទាន់បានចាប់ផ្តើមនៅឡើយទេ",
  followersCount: "ផ្ដោត​ទៅលើ",
  otherStreamersPrompt: "មានយុថ្កាផ្សេងទៀតដែលកំពុងចាក់ផ្សាយហ្គេមនេះ។",
  watch: "ទៅមើល",
};

const NewMatchStatus = {
  team: "ក្រុម",
  halfTimeScore: "ពិន្ទុពាក់កណ្តាលម៉ោង",
  shotOnTarget: "បាញ់ចំគោលដៅ",
  missedShot: "ខកខានគោលដៅ",
  attack: "ការវាយប្រហារ",
  dangerousAttack: "ការវាយប្រហារដ៏គ្រោះថ្នាក់",
  number3PointGoals: "ចំនួនគ្រាប់បាល់ស៊ុតបញ្ចូលទី 3 ពិន្ទុ",
  number2PointGoals: "ចំនួនគ្រាប់បាល់ស៊ុតបញ្ចូលទី 2 ពិន្ទុ",
  penaltyKickGoals: "ការទាត់បាល់ប៉េណាល់ទី",
  freeThrowPercentage: "ភាគរយនៃការបោះចោលដោយឥតគិតថ្លៃ",
  numberOfPausesRemaining: "ចំនួននៃការផ្អាកដែលនៅសល់",
  numberOfFouls: "ចំនួននៃកំហុស",
  totalNumberOfPauses: "ការផ្អាកសរុប",
  threePointShot: "ការបាញ់ប្រហារ ៣ ពិន្ទុ",
  twoPointShot: "បាញ់ 2 ពិន្ទុ",
  shoot: "បាញ់",
  penalty: "ពិន័យ",
  firstHalf: "ពាក់កណ្តាលទីមួយ",
  secondHalf: "ពាក់កណ្ដាល​ទីពីរ",
  redCard: "កាតក្រហម",
  yellowCard: "កាតលឿង",
};

const NoData = {
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
};

const NoticeMessage = {
  addedFriendPrompt: "បានបន្ថែមថាជាមិត្ត",
  withdrawMessage: "ដក​សារ",
  withdrawMessageByAdmin: "សារមួយត្រូវបានដកចេញដោយអ្នកគ្រប់គ្រង",
  joinGroupPrompt: "បានចូលរួមក្រុម",
  leftGroupPrompt: "បានចាកចេញពីក្រុម",
  removeGroupPrompt: "បានលុបចេញពីក្រុមដោយអ្នកគ្រប់គ្រង",
  bannedPrompt: "បានហាមឃាត់",
  unBanPrompt: "មិនត្រូវបានហាមឃាត់",
};

const OddsStatisticsList = {
  initial: "ដំបូង",
  ongoing: "ភ្លាមៗ",
  preMatch: "មុនការប្រកួត",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "តម្លៃខ្ពស់បំផុត",
  min: "តម្លៃទាបបំផុត។",
  average: "តម្លៃមធ្យម",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
};

const RecommendCompetitions = {
  today: "ថ្ងៃនេះ",
  winsInARow: "សូម្បីតែនៅកណ្តាល",
  noLivePrompt: "មិនទាន់មានការផ្សាយផ្ទាល់នៅឡើយទេ",
};

const RecommendStreamerList = {
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "បុកប្រចាំសប្តាហ៍",
  victoryOrDefeat: "ជ័យជំនះឬបរាជ័យ",
  check: "ពិនិត្យ",
  chat: "ជជែក",
  quarterlyProfit: "ប្រាក់ចំណេញប្រចាំត្រីមាស",
};

const RecommendStreamers = {
  recommendedByExperts: "ត្រូវបានណែនាំដោយអ្នកជំនាញ",
  winsInARow: "សូម្បីតែនៅកណ្តាល",
  changeBatch: "ផ្លាស់ប្តូរមួយបាច់",
  follow: "ផ្ដោត​ទៅលើ",
  following: "តាមរួចហើយ",
  unFollowing: "ដោះសោ",
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  yes: "បាទ",
  no: "ទេ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
  unfollowPrompt: "តើអ្នកប្រាកដក្នុងការឈប់តាមដានទេ?",
};

const RecordChart = {
  record: "កត់ត្រា",
  allWaysToPlay: "វិធីទាំងអស់ក្នុងការលេង",
  fromFarToNear: "ពីឆ្ងាយទៅជិត",
  lastTenGames: "១០ ប្រកួតចុងក្រោយ",
  longestConsecutiveHit: "បុកជាប់គ្នាយូរជាងគេ",
  hitRate: "អត្រាបុក",
  currentPropmt: "ហ្គេមថ្មីៗ",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "បរាជ័យក្នុងការទទួលបានព័ត៌មានអ្នកប្រើប្រាស់",
  getAreaAndLanguageErrorPrompt: "បរាជ័យក្នុងការទទួលបានភាសាក្នុងតំបន់",
};

const ScheduleCom = {
  startTime: "ពេលវេលាចាប់ផ្តើម",
};

const SchemeCard = {
  planList: "បញ្ជីផែនការ",
  release: "ដោះលែង",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "ធានាសំណង",
  free: "ឥតគិតថ្លៃ",
  toll: "ទូរស័ព្ទ",
  victoryOrDefeat: "ជ័យជំនះឬបរាជ័យ",
};

const SquareCom = {
  watchPrompt: "មនុស្សកំពុងមើល",
  startedPrompt: "បានចាប់ផ្តើមប៉ុន្មាននាទីមុន។",
  chat: "ជជែក",
  sendMessageNeedLoginPrompt: "សូមចូលដើម្បីផ្ញើសារ",
  assistant: "អ្នកគ្រប់គ្រង",
  audience: "អ្នក​ប្រើ",
  newMessage: "ព័ត៌មានថ្មី។",
  confirmSend: "បញ្ជាក់ការផ្ញើ",
  cancelSend: "បោះបង់",
};

const StatisticalChart = {
  hitRate: "អត្រាបុក",
};

const SwitchLines = {
  switchLines: "ប្តូរបន្ទាត់",
  cancel: "បោះបង់",
  currentStreamer: "យុថ្កាបច្ចុប្បន្ន",
  live: "LIVE",
  offLine: "មិនទាន់បានចាប់ផ្តើមនៅឡើយទេ",
  followersCount: "ផ្ដោត​ទៅលើ",
  otherStreamersPrompt: "មានយុថ្កាផ្សេងទៀតដែលកំពុងចាក់ផ្សាយហ្គេមនេះ។",
  watch: "ទៅមើល",
  LINE: "បន្ទាត់",
  VIP: "វីអាយភី",
  OFFICIAL: "ផ្លូវការ",
};

const VideoCom = {
  videoErrorPrompt: "សញ្ញាមិនសូវល្អទេ សូមព្យាយាមម្តងទៀតពេលក្រោយ។",
  clickRetryPrompt: "ចុចដើម្បីព្យាយាមម្តងទៀត",
};

const WelcomeCard = {
  join: "ចូល​រួម​ជា​ក្រុម",
};

//utils
const tools = {
  yesterday: "ម្សិលមិញ",
  addedFriendPrompt: "បានបន្ថែមថាជាមិត្ត",
  withdrawMessage: "ដក​សារ",
  joinGroupPrompt: "បានចូលរួមក្រុម",
  leftGroupPrompt: "បានចាកចេញពីក្រុម",
  removeGroupPrompt: "បានលុបចេញពីក្រុមដោយអ្នកគ្រប់គ្រង",
  bannedPrompt: "បានហាមឃាត់",
  unBanPrompt: "មិនត្រូវបានហាមឃាត់",
};

//页面
const AddressBook = {
  search: "ស្វែងរក",
  addFriend: "បន្ថែម​មិត្ត",
  myGroupChat: "ការជជែកជាក្រុមរបស់ខ្ញុំ",
  friends: "មិត្តភក្តិ",
  delete: "លុប",
  noFriendsPrompt: "មិនទាន់មានមិត្តភ័ក្តិទេ។",
  nameAndIdSearch: "បញ្ចូលឈ្មោះ លេខសម្គាល់",
  searchResults: "ស្វែងរកលទ្ធផល",
  notFoundPrompt: "រកមិនឃើញអ្នកប្រើប្រាស់ទេ។",
  add: "បន្ថែម​លើ",
  deleteFriendPrompt: "តើ​អ្នក​ប្រាកដ​ក្នុង​ចិត្ត​លុប​មិត្តភ័ក្ដិ​នោះ​ឬ?",
  yes: "បាទ",
  no: "ទេ",
  addFriendSuccessPrompt: "បន្ថែមមិត្តដោយជោគជ័យ",
  deleteFriendSuccessPrompt: "លុបមិត្តដោយជោគជ័យ",
  InputSearchPrompt: "សូមបញ្ចូលខ្លឹមសារ",
};

const GroupChat = {
  groupChat: "ការជជែកជាក្រុម",
  groupSearch: "បញ្ចូលឈ្មោះជជែកជាក្រុម",
  noGroupPrompt: "មិនទាន់មានការជជែកជាក្រុមនៅឡើយទេ",
  chat: "ជជែក",
  InputSearchPrompt: "សូមបញ្ចូលខ្លឹមសារ",
};

const GroupAdministrator = {
  administrator: "អ្នកគ្រប់គ្រង",
  groupOwner: "ព្រះអម្ចាស់",
};

const GroupAnnouncement = {
  groupAnnouncement: "ប្រកាសក្រុម",
  groupOwner: "ព្រះអម្ចាស់",
  administrator: "អ្នកគ្រប់គ្រង",
};

const GroupDetail = {
  groupDetail: "ព័ត៌មានលម្អិតក្រុម",
  groupMembers: "សមាជិក​ក្រុម",
  groupName: "ឈ្មោះ​ក្រុម",
  groupDescription: "ការពិពណ៌នាក្រុម",
  groupOwner: "ព្រះអម្ចាស់",
  administrator: "អ្នកគ្រប់គ្រង",
  administratorList: "បញ្ជីអ្នកគ្រប់គ្រង",
  groupAnnouncement: "ប្រកាសក្រុម",
  groupFiles: "ឯកសារក្រុម",
  exitGroup: "ចេញពីក្រុម",
  exitGroupPrompt: "តើអ្នកប្រាកដក្នុងការចាកចេញពីក្រុមទេ?",
  yes: "បាទ",
  no: "ទេ",
  leftGroupSuccessPrompt: "បានចាកចេញពីក្រុមដោយជោគជ័យ",
};

const GroupFile = {
  groupFiles: "ឯកសារក្រុម",
  fileSearch: "បញ្ចូលឈ្មោះឯកសារ",
  today: "ថ្ងៃនេះ",
  multimedia: "ពហុព័ត៌មាន",
  file: "ឯកសារ",
  noFilesPrompt: "មិនទាន់មានឯកសារនៅឡើយ",
};

const GroupMembers = {
  groupMembers: "សមាជិក​ក្រុម",
  nameAndIdSearch: "បញ្ចូលឈ្មោះ លេខសម្គាល់",
};

const Chat = {
  chat: "ជជែក",
  nameAndIdSearch: "បញ្ចូលឈ្មោះ លេខសម្គាល់",
  contacts: "សៀវភៅ​អាស័យ​ដ្ឋាន",
  InputSearchPrompt: "សូមបញ្ចូលខ្លឹមសារ",
  deleteContactPrompt: "តើអ្នកប្រាកដក្នុងការលុបការជជែកទេ?",
};

const Competition = {
  all: "All",
  ongoing: "ភ្លាមៗ",
  popular: "ពេញនិយម",
  liveCompetition: "ការផ្សាយបន្តផ្ទាល់",
  schedule: "កាលវិភាគ",
  results: "លទ្ធផល",
  subscribed: "បានជាវ",
  goal: "គោលដៅ",
  today: "ថ្ងៃនេះ",
  Monday: "ថ្ងៃច័ន្ទ",
  Tuesday: "ថ្ងៃអង្គារ",
  Wednesday: "ថ្ងៃពុធ",
  Thursday: "ថ្ងៃព្រហស្បតិ៍",
  Friday: "ថ្ងៃសុក្រ",
  Saturday: "ថ្ងៃសៅរ៍",
  Sunday: "ថ្ងៃអាទិត្យ",
};

const CompetitionDetails = {
  subscribe: "ការជាវ",
  subscribed: "បានជាវ",
  matchStreamerPrompt: "យុថ្កាហ្គេមបន្តផ្ទាល់",
  live: "LIVE",
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
  noStreamersPrompt: "បច្ចុប្បន្នមិនមានម្ចាស់កម្មវិធីផ្សាយហ្គេមនេះទេ។",
  alreadyStreamerPrompt: "ពិធីករ​បាន​ចាប់​ផ្តើ​ម​ការ​ផ្សាយ​ការ​ប្រកួត​នេះ​តើ​អ្នក​ចង់​ទៅ​មើល​វា​?",
  yes: "បាទ",
  no: "ទេ",
  liveStream: "ការផ្សាយបន្តផ្ទាល់",
  matchStatus: "ស្ថានភាពការប្រកួត",
  odds: "Odds",
  unSubscribePrompt: "តើអ្នកប្រាកដក្នុងការលុបចោលការជាវរបស់អ្នកមែនទេ?",
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
};

const OddsDetails = {
  company: "ក្រុមហ៊ុន",
  handicap: "ពិការ",
  WinDrawLose: "ឈ្នះ ស្មើ ចាញ់",
  totalGoals: "គោលដៅសរុប",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "ជាច្រើន",
  Un: "តិចតួច",
};

const Home = {
  logIn: "ចូល",
  signUp: "ចុះឈ្មោះ",
  popularMatches: "ការប្រកួតប្រជែងពេញនិយម",
  more: "ច្រើនទៀត",
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
  currentlyLive: "ការផ្សាយបន្តផ្ទាល់",
  popularStreamers: "យុថ្កាពេញនិយម",
  thinkYouLike: "អ្នកក៏អាចចូលចិត្តដែរ។",
  loadMore: "ផ្ទុក​បន្ថែម",
  noMoreDataPrompt: "មិនមានទិន្នន័យទៀតទេ",
  addDeskPrompt: "បន្ថែមទៅផ្ទៃតុ",
  clikToolPrompt: "ចុចលើរបារឧបករណ៍ខាងក្រោម",
  andSeletcPrompt: "ហើយជ្រើសរើស",
  addToScreenPrompt: "យើងចង់បន្ថែមទៅអេក្រង់ដើមរបស់អ្នក ដើម្បីផ្តល់សេវាកម្មកាន់តែប្រសើរ",
  addToScreen: "បន្ថែមទៅអេក្រង់ដើម",
};

const MoreLives = {
  noDataPrompt: "គ្មាន​ទិន្នន័យ",
};

const MoreStreamers = {
  streamer: "យុថ្កា",
  streamerSearch: "បញ្ចូលឈ្មោះយុថ្កា និងលេខសម្គាល់",
  all: "All",
  notFollowing: "មិនធ្វើតាម",
  following: "តាមរួចហើយ",
  live: "LIVE",
  offLine: "មិនទាន់បានចាប់ផ្តើមនៅឡើយទេ",
  followersCount: "ផ្ដោត​ទៅលើ",
  unfollowPrompt: "តើអ្នកប្រាកដក្នុងការឈប់តាមដានទេ?",
  yes: "បាទ",
  no: "ទេ",
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
  InputSearchPrompt: "សូមបញ្ចូលខ្លឹមសារ",
  dataRequestErrorPrompt: "បរាជ័យក្នុងការទទួលបានទិន្នន័យ",
};

const LiveRoom = {
  switchLines: "ប្តូរបន្ទាត់",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "យុថ្កាជជែកឯកជន",
  chat: "ជជែក",
  odds: "Odds",
  streamerInformation: "ព័ត៌មានយុថ្កា",
  matchStatus: "ស្ថានភាពការប្រកួត",
};

const AnchorIntroduction = {
  streamerInformation: "ព័ត៌មានយុថ្កា",
  photosWall: "ជញ្ជាំងរូបថត",
  seeMore: "មើល​បន្ថែម​ទៀត",
  streamerPlan: "ផែនការយុថ្កា",
};

const AnchorPhoto = {
  photosWall: "ជញ្ជាំងរូបថត",
  noPhotosPrompt: "មិនមានរូបថតទៀតទេ",
};

const LogInRegister = {
  logIn: "ចូល",
  signUp: "ចុះឈ្មោះ",
  getAreaAndLanguageErrorPrompt: "បរាជ័យក្នុងការទទួលបានភាសាក្នុងតំបន់",
  quickRegisterFrequentPrompt: "សំណើញឹកញាប់ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ",
};

const LoginFn = {
  phone: "លេខទូរសព្ទ",
  email: "សំបុត្រ",
  logInPassword: "ពាក្យសម្ងាត់ចូល",
  rememberPassword: "ចងចាំ​ពាក្យ​សម្ងាត់",
  forgotPassword: "ភ្លេចពាក្យសម្ងាត់",
  logIn: "ចូល",
  logInAgreement: "ដោយការចូល អ្នកយល់ព្រម",
  userAgreement: "កិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់",
  and: "  និង",
  privacyPolicy: "គោលការណ៍​ភាព​ឯកជន",
  passwordRulePrompt: "ពាក្យសម្ងាត់ត្រូវតែមានអក្សរធំ និងអក្សរតូច និងលេខ ហើយត្រូវតែធំជាង 8 តួអក្សរ",
  passwordEmptyPrompt: "ពាក្យសម្ងាត់មិនអាចទទេបានទេ។",
  emailRulePrompt: "សូមបញ្ចូលទម្រង់ត្រឹមត្រូវនៃអាសយដ្ឋានអ៊ីមែលរបស់អ្នក។",
  emailEmptyPrompt: "អ៊ីមែលមិនអាចទទេបានទេ។",
  phoneRulePrompt: "សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នកក្នុងទម្រង់ត្រឹមត្រូវ។",
  phoneEmptyPrompt: "លេខទូរស័ព្ទចល័តមិនអាចទទេបានទេ។",
  phoneNum8: "សូមបញ្ចូលលេខទូរស័ព្ទ 8 ខ្ទង់របស់អ្នក។",
  phoneNum9To10: "សូមបញ្ចូលលេខទូរស័ព្ទដៃពី 9 ទៅ 10 ខ្ទង់",
  phoneNum9: "សូមបញ្ចូលលេខទូរស័ព្ទ 9 ខ្ទង់",
  enterErrorPrompt: "កំហុសក្នុងការបញ្ចូល ឬភាពមិនប្រក្រតីនៃបណ្តាញ",
  loginNoAgreePrompt: "អ្នកត្រូវយល់ព្រមចំពោះកិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់ និងគោលការណ៍ឯកជនភាព ដើម្បីចូល",
  accountAndPassword: "គណនី",
  account: "គណនី",
  accountEmptyPrompt: "គណនីមិនអាចទទេបានទេ។",
  accountOrPasswordError: "ឈ្មោះអ្នកប្រើ ឬពាក្យសម្ងាត់មិនត្រឹមត្រូវ",
};

const Register = {
  phone: "លេខទូរសព្ទ",
  email: "សំបុត្រ",
  code: "កូដ​ផ្ទៀង​ផ្ទាត់",
  getCode: "ទទួលបានលេខកូដផ្ទៀងផ្ទាត់",
  logInPassword: "ពាក្យសម្ងាត់ចូល",
  CS: "សេវាកម្ម​អតិថិជន",
  signUp: "ចុះឈ្មោះ",
  signUpAgreement: "តាមរយៈការចុះឈ្មោះ អ្នកយល់ព្រម",
  userAgreement: "កិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់",
  and: "និង",
  privacyPolicy: "គោលការណ៍​ភាព​ឯកជន",
  contactCSPrompt: "តើអ្នកមានបញ្ហាក្នុងការចុះឈ្មោះមែនទេ? ទាក់ទងផ្នែកបម្រើអតិថិជន ដើម្បីដោះស្រាយជូនអ្នក។",
  contactCS: "ទាក់ទងសេវាកម្មអតិថិជន",
  passwordRulePrompt: "ពាក្យសម្ងាត់ត្រូវតែមានអក្សរធំ និងអក្សរតូច និងលេខ ហើយត្រូវតែធំជាង 8 តួអក្សរ",
  passwordEmptyPrompt: "ពាក្យសម្ងាត់មិនអាចទទេបានទេ។",
  emailRulePrompt: "សូមបញ្ចូលទម្រង់ត្រឹមត្រូវនៃអាសយដ្ឋានអ៊ីមែលរបស់អ្នក។",
  emailEmptyPrompt: "អ៊ីមែលមិនអាចទទេបានទេ។",
  phoneRulePrompt: "សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នកក្នុងទម្រង់ត្រឹមត្រូវ។",
  phoneEmptyPrompt: "លេខទូរស័ព្ទចល័តមិនអាចទទេបានទេ។",
  codeRulePrompt: "លេខកូដផ្ទៀងផ្ទាត់គឺ 6 ខ្ទង់",
  codeEmptyPrompt: "លេខកូដផ្ទៀងផ្ទាត់ត្រូវតែបំពេញ",
  phoneNum8: "សូមបញ្ចូលលេខទូរស័ព្ទ 8 ខ្ទង់របស់អ្នក។",
  phoneNum9To10: "សូមបញ្ចូលលេខទូរស័ព្ទដៃពី 9 ទៅ 10 ខ្ទង់",
  phoneNum9: "សូមបញ្ចូលលេខទូរស័ព្ទ 9 ខ្ទង់",
  phoneCodeSendSuccessPrompt: "បានផ្ញើលេខកូដផ្ទៀងផ្ទាត់ដោយជោគជ័យ សូមពិនិត្យមើលសារនៅលើទូរស័ព្ទដៃរបស់អ្នក។",
  emailCodeSendSuccessPrompt: "បានផ្ញើលេខកូដផ្ទៀងផ្ទាត់ដោយជោគជ័យ សូមពិនិត្យមើលអ៊ីមែលរបស់អ្នក។",
  phoneCodeSendErrorPrompt: "លេខ​កូដ​ផ្ទៀងផ្ទាត់​មិន​អាច​ផ្ញើ​បាន​ទេ សូម​ពិនិត្យ​មើល​ថា​មាន​លេខ​ទូរសព្ទ​ឬ​អត់",
  emailCodeSendErrorPrompt: "មិនអាចផ្ញើលេខកូដផ្ទៀងផ្ទាត់បានទេ សូមពិនិត្យមើលថាតើអ៊ីមែលរបស់អ្នកមានឬអត់",
  codeSendToMuchPrompt: "ផ្ញើច្រើនដងពេកក្នុងពេលតែមួយ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ",
  phoneAlreadySignupPrompt: "លេខទូរស័ព្ទនេះត្រូវបានចុះឈ្មោះ សូមចូលដោយផ្ទាល់",
  emailAlreadySignupPrompt: "អាសយដ្ឋានអ៊ីមែលនេះត្រូវបានចុះឈ្មោះ សូមចូលដោយផ្ទាល់",
  signUpErrorPrompt: "ការចុះឈ្មោះបានបរាជ័យ សូមពិនិត្យមើលថាតើព័ត៌មានត្រឹមត្រូវឬអត់",
  enterErrorPrompt: "កំហុសក្នុងការបញ្ចូល ឬភាពមិនប្រក្រតីនៃបណ្តាញ",
  noAgreePrompt: "អ្នកត្រូវយល់ព្រមចំពោះកិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់ និងគោលការណ៍ឯកជនភាព ដើម្បីចុះឈ្មោះ",
  regionAndLanguagePrompt: "សូមជ្រើសរើសភាសាក្នុងតំបន់ពិតប្រាកដរបស់អ្នក បើមិនដូច្នេះទេវានឹងប៉ះពាល់ដល់ការប្រើប្រាស់មុខងារ",
  accountAndPassword: "គណនី",
  quickSignup: "ការចុះឈ្មោះដោយចុចតែម្តង",
  accountEmptyPrompt: "គណនីមិនអាចទទេបានទេ។",
  accountRulePrompt: "លេខគណនីត្រូវតែមាន 8-16 អក្សរ និងលេខ",
  accountAlreadySignupPrompt: "គណនីនេះត្រូវបានចុះឈ្មោះ សូមចូលដោយផ្ទាល់",
  tryAgain: "ផ្ញើឡើងវិញ",
};

const PersonalCenter = {
  logAndSign: "ចូល/ចុះឈ្មោះឥឡូវនេះ",
  logAndSignPrompt: "ដោះសោមុខងារផ្តាច់មុខ និងអត្ថប្រយោជន៍ផ្តាច់មុខឥឡូវនេះ",
  logOut: "ចាកចេញ",
  myFollow: "ការផ្តោតអារម្មណ៍របស់ខ្ញុំ",
  mySubscription: "ការជាវរបស់ខ្ញុំ",
  accountSecurity: "សុវត្ថិភាពគណនី",
  feedback: "មតិកែលម្អ",
  deviceInformation: "ឧបករណ៍",
  aboutUs: "អំពី​ពួក​យើង",
  coin: "មាស",
  moreServices: "សេវាកម្មច្រើនទៀត",
  following: "តាមរួចហើយ",
  subscribed: "បានជាវ",
  browser: "កម្មវិធីរុករក",
  user: "អ្នក​ប្រើ",
  profile: "ព័ត៌មាន​ផ្ទាល់ខ្លួន",
  nickname: "ឈ្មោះនីក",
  save: "រក្សាទុក",
  change: "ផ្លាស់ប្តូរ",
  nicknamePrompt: "សូមបញ្ចូលឈ្មោះហៅក្រៅ",
  avatar: "រូបតំណាង",
  changeSuccessPrompt: "ការផ្លាស់ប្តូរជោគជ័យ",
  ballAnimation: "ចលនាគោលដៅ",
  ballVoice: "ផលប៉ះពាល់សំឡេងគោលដៅ",
  setting: "រៀបចំ",
};

const IntegralSystem = {
  Coin: "មាស",
  goldCoinTask: "កិច្ចការកាក់មាស",
  PointsDetails: "ព័ត៌មានលម្អិតអំពីកាក់មាស",
  myCoins: "កាក់មាសរបស់ខ្ញុំ",
  income: "ប្រាក់ចំណូល",
  expenditure: "ការចំណាយ",
  signInPrompt: "ចូលជាបន្តបន្ទាប់ និងទទួលបានរង្វាន់ជាបន្តបន្ទាប់",
  toComplete: "ដើម្បី​បញ្ចប់",
  toShare: "ចែករំលែក",
  shareTasks: "ចែករំលែកកិច្ចការ",
  exclusiveLink: "តំណផ្តាច់មុខ",
  copyLink: "ចម្លងតំណ",
  highestWin: "ឈ្នះខ្ពស់បំផុត",
  day1: "ថ្ងៃទី 1",
  day2: "ថ្ងៃទី 2",
  day3: "ថ្ងៃទី 3",
  day4: "ថ្ងៃទី 4",
  day5: "ថ្ងៃទី 5",
  day6: "ថ្ងៃទី 6",
  day7: "ថ្ងៃទី 7",
  invitePrompt: "ភ្ញាក់ផ្អើល! ចុះឈ្មោះឥឡូវនេះ ហើយទាញយក APP របស់យើងដើម្បីទទួលបានអត្ថប្រយោជន៍ផ្តាច់មុខសម្រាប់អ្នកប្រើប្រាស់ថ្មី! ការផ្សាយបន្តផ្ទាល់ព្រឹត្តិការណ៍ដោយឥតគិតថ្លៃ ការវិភាគទិន្នន័យតាមពេលវេលាជាក់ស្តែង និងការព្យាករណ៍យុថ្កាប្រកបដោយវិជ្ជាជីវៈ ទាំងអស់នៅចុងម្រាមដៃរបស់អ្នក។ កុំរង់ចាំយូរ សូមចុច Link ដើម្បីចូលរួម!"
};

const Money = {
  activeCenter: "មជ្ឈមណ្ឌលសកម្មភាព",
  missionCenter: "មជ្ឈមណ្ឌលបេសកកម្ម",
};

const ActivityCenter = {
  toParticipate: "ចូលរួម",
  sportActivity: "សកម្មភាពកីឡា",
};

const ActivityDetails = {
  eventDetails: "ព័ត៌មានលម្អិតអំពីព្រឹត្តិការណ៍",
  dailyBetting: "ការភ្នាល់ប្រចាំថ្ងៃ",
  matchTime: "ពេលវេលាប្រកួត",
  popularForEvent: "ជម្រើសពេញនិយមសម្រាប់ព្រឹត្តិការណ៍នេះ។",
  clickToParticipate: "ចុចដើម្បីចូលរួមឥឡូវនេះ",
  activityRules: "ច្បាប់សកម្មភាព",
  clickToContactParticipate: "ចុច​ដើម្បី​ទាក់ទង​សេវា​អតិថិជន​ដើម្បី​ចូលរួម",
  activityObj: "វត្ថុសកម្ម",
  activityPlatform: "វេទិកាព្រឹត្តិការណ៍",
  activityDuration: "រយៈពេលសកម្មភាព",
  activityDemand: "តម្រូវការសកម្មភាព",
  activityTime: "ពេលវេលាសកម្មភាព",
};

const AboutUs = {
  aboutUs: "អំពី​ពួក​យើង",
  platformIntroduction: "ការណែនាំអំពីវេទិកា",
  privacyPolicy: "គោលការណ៍​ភាព​ឯកជន",
  userAgreement: "កិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់",
};

const DeviceInfo = {
  deviceInformation: "ឧបករណ៍",
  currentTime: "ពេលបច្ចុប្បន្ន",
  port: "ច្រកចូល",
  ip: "ចូល IP",
  version: "កំណែបច្ចុប្បន្ន",
  ipAndPort: "ព័ត៌មានចូល",
};

const FeedBack = {
  feedback: "មតិកែលម្អ",
  feedbackType: "ប្រភេទមតិត្រឡប់",
  productSuggestion: "ការណែនាំផលិតផល",
  functionalFault: "ការបរាជ័យមុខងារ",
  interfaceSuggestion: "ការណែនាំចំណុចប្រទាក់",
  accountIssue: "បញ្ហាគណនី",
  operationExperience: "បទពិសោធន៍ប្រតិបត្តិការ",
  otherFeedback: "មតិកែលម្អផ្សេងទៀត។",
  problemDescription: "សេចក្តីថ្លែងការណ៍បញ្ហា",
  submit: "ដាក់ស្នើ",
  addPhoto: "បន្ថែមរូបថត",
  uploadPrompt: "សូមអភ័យទោស រូបថត 3 សន្លឹកអាចបង្ហោះបាន។",
  uploadRulePrompt: "សូមបង្ហោះរូបភាពជាទម្រង់ jpg, jpeg, png",
  uploadSizePrompt: "ទំហំរូបភាពមិនអាចលើសពី 10M",
  chooseFeedback: "សូមជ្រើសរើសប្រភេទមតិកែលម្អ",
  inputQuestionPrompt: "សូមបញ្ចូលសំណួរ",
  submitSuccessPrompt: "បានដាក់ស្នើដោយជោគជ័យ",
};

const MySubscription = {
  myFollow: "ការផ្តោតអារម្មណ៍របស់ខ្ញុំ",
  mySubscription: "ការជាវរបស់ខ្ញុំ",
  streamer: "យុថ្កា",
  matches: "ការប្រកួតប្រជែង",
};

const PrivacyAgreement = {
  privacyPolicy: "គោលការណ៍​ភាព​ឯកជន",
};

const TermsofService = {
  termsOfService: "លក្ខខណ្ឌ​នៃ​សេវាកម្ម",
};

const UserAgreement = {
  userAgreement: "កិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់",
};

const AccountSecurity = {
  accountSecurity: "សុវត្ថិភាពគណនី",
  phone: "លេខទូរសព្ទ",
  email: "សំបុត្រ",
  bind: "ការចង",
  notBound: "មិនបានចង",
  change: "ផ្លាស់ប្តូរ",
  logInPassword: "ពាក្យសម្ងាត់ចូល",
  accountChangePassword: "សូមចងលេខទូរស័ព្ទ/អាសយដ្ឋានអ៊ីមែលរបស់អ្នកជាមុនសិន",
};

const BindEmail = {
  bindEmail: "ភ្ជាប់អ៊ីមែល",
  email: "សំបុត្រ",
  code: "កូដ​ផ្ទៀង​ផ្ទាត់",
  getCode: "ទទួលបានលេខកូដផ្ទៀងផ្ទាត់",
  confirm: "បញ្ជាក់",
  emailRulePrompt: "សូមបញ្ចូលទម្រង់ត្រឹមត្រូវនៃអាសយដ្ឋានអ៊ីមែលរបស់អ្នក។",
  emailEmptyPrompt: "អ៊ីមែលមិនអាចទទេបានទេ។",
  codeRulePrompt: "លេខកូដផ្ទៀងផ្ទាត់គឺ 6 ខ្ទង់",
  codeEmptyPrompt: "លេខកូដផ្ទៀងផ្ទាត់ត្រូវតែបំពេញ",
  changeSuccessPrompt: "ការផ្លាស់ប្តូរជោគជ័យ",
  changeErrorPrompt: "ការផ្លាស់ប្តូរបានបរាជ័យ",
  bindSuccessPrompt: "ការចងបានជោគជ័យ",
  bindErrorPrompt: "ការចងបានបរាជ័យ",
  emailCodeSendSuccessPrompt: "បានផ្ញើលេខកូដផ្ទៀងផ្ទាត់ដោយជោគជ័យ សូមពិនិត្យមើលអ៊ីមែលរបស់អ្នក។",
  emailCodeSendErrorPrompt: "មិនអាចផ្ញើលេខកូដផ្ទៀងផ្ទាត់បានទេ សូមពិនិត្យមើលថាតើអ៊ីមែលរបស់អ្នកមានឬអត់",
  codeSendToMuchPrompt: "ផ្ញើច្រើនដងពេកក្នុងពេលតែមួយ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ",
  emailAlreadySignupPrompt: "អាសយដ្ឋានអ៊ីមែលនេះត្រូវបានចុះឈ្មោះ សូមចូលដោយផ្ទាល់",
  tryAgain: "ផ្ញើឡើងវិញ",
};

const BindPhone = {
  bindPhone: "ចងទូរស័ព្ទចល័ត",
  phone: "លេខទូរសព្ទ",
  code: "កូដ​ផ្ទៀង​ផ្ទាត់",
  getCode: "ទទួលបានលេខកូដផ្ទៀងផ្ទាត់",
  confirm: "បញ្ជាក់",
  cancel: "បោះបង់",
  phoneRulePrompt: "សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នកក្នុងទម្រង់ត្រឹមត្រូវ។",
  phoneEmptyPrompt: "លេខទូរស័ព្ទចល័តមិនអាចទទេបានទេ។",
  codeRulePrompt: "លេខកូដផ្ទៀងផ្ទាត់គឺ 6 ខ្ទង់",
  codeEmptyPrompt: "លេខកូដផ្ទៀងផ្ទាត់ត្រូវតែបំពេញ",
  changeSuccessPrompt: "ការផ្លាស់ប្តូរជោគជ័យ",
  changeErrorPrompt: "ការផ្លាស់ប្តូរបានបរាជ័យ",
  bindSuccessPrompt: "ការចងបានជោគជ័យ",
  bindErrorPrompt: "ការចងបានបរាជ័យ",
  phoneNum8: "សូមបញ្ចូលលេខទូរស័ព្ទ 8 ខ្ទង់របស់អ្នក។",
  phoneNum9To10: "សូមបញ្ចូលលេខទូរស័ព្ទដៃពី 9 ទៅ 10 ខ្ទង់",
  phoneNum9: "សូមបញ្ចូលលេខទូរស័ព្ទ 9 ខ្ទង់",
  phoneCodeSendSuccessPrompt: "បានផ្ញើលេខកូដផ្ទៀងផ្ទាត់ដោយជោគជ័យ សូមពិនិត្យមើលសារនៅលើទូរស័ព្ទដៃរបស់អ្នក។",
  phoneCodeSendErrorPrompt: "លេខ​កូដ​ផ្ទៀងផ្ទាត់​មិន​អាច​ផ្ញើ​បាន​ទេ សូម​ពិនិត្យ​មើល​ថា​មាន​លេខ​ទូរសព្ទ​ឬ​អត់",
  codeSendToMuchPrompt: "ផ្ញើច្រើនដងពេកក្នុងពេលតែមួយ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ",
  phoneAlreadySignupPrompt: "លេខទូរស័ព្ទនេះត្រូវបានចុះឈ្មោះ សូមចូលដោយផ្ទាល់",
  checkSignup: "សូមពិនិត្យមើលថាតើលេខត្រូវបានចុះឈ្មោះឬអត់",
  tryAgain: "ផ្ញើឡើងវិញ",
};

const ChangePassword = {
  resetPassword: "កំណត់ពាក្យសម្ងាត់ឡើងវិញ",
  phone: "លេខទូរសព្ទ",
  email: "សំបុត្រ",
  code: "កូដ​ផ្ទៀង​ផ្ទាត់",
  getCode: "ទទួលបានលេខកូដផ្ទៀងផ្ទាត់",
  logInPassword: "ពាក្យសម្ងាត់ចូល",
  confirm: "បញ្ជាក់",
  cancel: "បោះបង់",
  passwordRulePrompt: "ពាក្យសម្ងាត់ត្រូវតែមានអក្សរធំ និងអក្សរតូច និងលេខ ហើយត្រូវតែធំជាង 8 តួអក្សរ",
  passwordEmptyPrompt: "ពាក្យសម្ងាត់មិនអាចទទេបានទេ។",
  emailRulePrompt: "សូមបញ្ចូលទម្រង់ត្រឹមត្រូវនៃអាសយដ្ឋានអ៊ីមែលរបស់អ្នក។",
  emailEmptyPrompt: "អ៊ីមែលមិនអាចទទេបានទេ។",
  phoneRulePrompt: "សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នកក្នុងទម្រង់ត្រឹមត្រូវ។",
  phoneEmptyPrompt: "លេខទូរស័ព្ទចល័តមិនអាចទទេបានទេ។",
  codeRulePrompt: "លេខកូដផ្ទៀងផ្ទាត់គឺ 6 ខ្ទង់",
  codeEmptyPrompt: "លេខកូដផ្ទៀងផ្ទាត់ត្រូវតែបំពេញ",
  phoneNum8: "សូមបញ្ចូលលេខទូរស័ព្ទ 8 ខ្ទង់របស់អ្នក។",
  phoneNum9To10: "សូមបញ្ចូលលេខទូរស័ព្ទដៃពី 9 ទៅ 10 ខ្ទង់",
  phoneNum9: "សូមបញ្ចូលលេខទូរស័ព្ទ 9 ខ្ទង់",
  resetSuccess: "កំណត់ពាក្យសម្ងាត់ឡើងវិញបានជោគជ័យ",
  resetError: "ការកំណត់ពាក្យសម្ងាត់ឡើងវិញបានបរាជ័យ សូមពិនិត្យមើលថាតើព័ត៌មាននេះត្រឹមត្រូវឬអត់",
  checkSignup: "សូមពិនិត្យមើលថាតើលេខត្រូវបានចុះឈ្មោះឬអត់",
  phoneCodeSendSuccessPrompt: "បានផ្ញើលេខកូដផ្ទៀងផ្ទាត់ដោយជោគជ័យ សូមពិនិត្យមើលសារនៅលើទូរស័ព្ទដៃរបស់អ្នក។",
  codeSendToMuchPrompt: "ផ្ញើច្រើនដងពេកក្នុងពេលតែមួយ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ",
  phoneAlreadySignupPrompt: "លេខទូរស័ព្ទនេះត្រូវបានចុះឈ្មោះ សូមចូលដោយផ្ទាល់",
  phoneCodeSendErrorPrompt: "លេខ​កូដ​ផ្ទៀងផ្ទាត់​មិន​អាច​ផ្ញើ​បាន​ទេ សូម​ពិនិត្យ​មើល​ថា​មាន​លេខ​ទូរសព្ទ​ឬ​អត់",
  emailCodeSendSuccessPrompt: "បានផ្ញើលេខកូដផ្ទៀងផ្ទាត់ដោយជោគជ័យ សូមពិនិត្យមើលអ៊ីមែលរបស់អ្នក។",
  emailCodeSendErrorPrompt: "មិនអាចផ្ញើលេខកូដផ្ទៀងផ្ទាត់បានទេ សូមពិនិត្យមើលថាតើអ៊ីមែលរបស់អ្នកមានឬអត់",
  emailAlreadySignupPrompt: "អាសយដ្ឋានអ៊ីមែលនេះត្រូវបានចុះឈ្មោះ សូមចូលដោយផ្ទាល់",
  bindPhoneOrEmail: "សូមចងលេខទូរស័ព្ទ ឬអាសយដ្ឋានអ៊ីមែលរបស់អ្នកជាមុនសិន",
  tryAgain: "ផ្ញើឡើងវិញ",
};

const QuickRegister = {
  downloadScreenshot: "ទាញយករូបថតអេក្រង់",
  saveAccountAndPassword: "សូមរក្សាគណនី និងពាក្យសម្ងាត់របស់អ្នកឱ្យមានសុវត្ថិភាព",
  suggestSave: "វាត្រូវបានណែនាំឱ្យអ្នកថតអេក្រង់ ហើយរក្សាទុកវា ឬចម្លងវាទៅក្នុងអនុស្សរណៈ។",
  account: "គណនី",
  password: "ពាក្យសម្ងាត់",
  copyAccountAndPassword: "ចម្លងពាក្យសម្ងាត់គណនី",
  prompt: "ព័ត៌មានជំនួយ",
  sureSave: "តើអ្នកបានរក្សាទុកពាក្យសម្ងាត់គណនីរបស់អ្នកហើយឬនៅ?",
  confirm: "បញ្ជាក់",
  cancel: "បោះបង់",
  copySuccess: "ចម្លងដោយជោគជ័យ",
  copyFailure: "ការចម្លងបានបរាជ័យ",
  manualRegister: "ចំនួននៃការចុះឈ្មោះបានដល់កម្រិតខាងលើ សូមចុះឈ្មោះដោយដៃ",
};

const Recommend = {
  recommend: "ណែនាំ",
  InternetErrorPrompt: "មាន​បញ្ហា​ខាង​បណ្ដាញ",
  hitOfTheWeek: "បុកប្រចាំសប្តាហ៍",
  victoryOrDefeat: "ជ័យជំនះឬបរាជ័យ",
  check: "ពិនិត្យ",
  lastTenGames: "១០ ប្រកួតចុងក្រោយ",
  recommendedByExperts: "ត្រូវបានណែនាំដោយអ្នកជំនាញ",
  changeBatch: "ផ្លាស់ប្តូរមួយបាច់",
  follow: "ផ្ដោត​ទៅលើ",
  following: "តាមរួចហើយ",
  unFollowing: "ដោះសោ",
  all: "All",
  guaranteedCompensation: "ធានាសំណង",
  free: "ឥតគិតថ្លៃ",
  toll: "ទូរស័ព្ទ",
  HOT: "លក់",
  HIT: "បុក",
  STREAK: "សូម្បីតែនៅកណ្តាល",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "ទំព័រដើម",
  chat: "ជជែក",
  follow: "ផ្ដោត​ទៅលើ",
  following: "តាមរួចហើយ",
  unFollowing: "ដោះសោ",
  followersCount: "ផ្ដោត​ទៅលើ",
  planCount: "ផែនការ",
  viewCount: "មើល",
  statistics: "ស្ថិតិ",
  plan: "ផែនការ",
};

const SchemeDetails = {
  planDetails: "ផែនការលម្អិត",
  release: "ដោះលែង",
  chat: "ជជែក",
  follow: "ផ្ដោត​ទៅលើ",
  following: "តាមរួចហើយ",
  unFollowing: "ដោះសោ",
  details: "ព័ត៌មានលម្អិត",
  analyze: "វិភាគ",
  title: "ចំណងជើង",
  privateChatStreamerJoinsGroup: "ម៉ាស៊ីនជជែកឯកជនចូលរួមក្រុម",
  upcoming: "មិនបានចាប់ផ្តើម",
  othersPlan: "ជម្រើសផ្សេងទៀត។",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "ការតម្រៀបការប្រកួត",
  byTime: "តាមពេលវេលា",
  byLeague: "តាមលីក",
  confirm: "បញ្ជាក់",
  cancel: "បោះបង់",
};

const RecommendPublish = {
  enterTeam: "ចូលក្រុម",
  planRelease: "ការចេញផ្សាយផែនការ",
  predictionTitle: "ចំណងជើង (អាចមើលឃើញដោយអ្នកប្រើប្រាស់ទាំងអស់ សូមកុំបង្ហាញលទ្ធផលព្យាករណ៍)",
  titleEmptyPrompt: "ចំណងជើង និងខ្លឹមសារនៃការវិភាគមិនអាចទទេបានទេ។",
  pleaseSelectAtLeastOneGameToPredictResults: "សូមជ្រើសរើសយ៉ាងហោចណាស់វិធីមួយដើម្បីទស្សន៍ទាយលទ្ធផល",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "បាល់ទាត់",
  basketball: "បាល់បោះ",
  mixed: "ទូលំទូលាយ",
  setLogInPassword: "ការកំណត់ពាក្យសម្ងាត់ចូល",
  OCS: "សេវាកម្មអនឡាញ",
  OCSPrompt: "នៅ​ក្នុង​ការ​បម្រើ​របស់​អ្នក",
  phoneInCorrectPrompt: "លេខ​ទូរសព្ទ​ចល័ត​បាន​បញ្ចូល​មិន​ត្រឹមត្រូវ។",
  codeInCorrectPrompt: "លេខកូដផ្ទៀងផ្ទាត់មិនត្រឹមត្រូវ",
  emailInCorrectPrompt: "កំហុសក្នុងការបញ្ចូលអ៊ីមែល",
  passwordPrompt: "សូមបញ្ចូលការរួមបញ្ចូលគ្នានៃលេខ 8-16 នៃភាសាអង់គ្លេស និងលេខ",
  newPassword: "បញ្ចូលពាក្យសម្ងាត់ថ្មី។",
  resetPasswordPrompt: "តើអ្នកប្រាកដក្នុងការកំណត់ពាក្យសម្ងាត់របស់អ្នកឡើងវិញទេ?",
  startChatPrompt: "ឥឡូវនេះអ្នកអាចចាប់ផ្តើមជជែក",
  send: "ផ្ញើ",
  noFollowPrompt: "មិនទាន់មានការយកចិត្តទុកដាក់នៅឡើយ",
  feedbackPrompt: "សូមទុកមតិយោបល់ដ៏មានតម្លៃរបស់អ្នក ហើយយើងនឹងខិតខំកែលម្អ!",
  bindPhoneByOtherPrompt: "លេខទូរសព្ទនេះត្រូវបានចងភ្ជាប់ដោយអ្នកប្រើប្រាស់ផ្សេងទៀត។",
  bindEmailByOtherPrompt: "អាសយដ្ឋានអ៊ីមែលនេះត្រូវបានចងដោយអ្នកប្រើប្រាស់ផ្សេងទៀត។",
  super_manager: "អ្នកគ្រប់គ្រងជាន់ខ្ពស់",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "ចុងបញ្ចប់នៃត្រីមាសទីមួយ",
  endOfSecondQuarter: "ចុងបញ្ចប់នៃត្រីមាសទីពីរ",
  endOfThirdQuarter: "ចុងបញ្ចប់នៃត្រីមាសទីបី",
  endOfFourthQuarter: "ចុងបញ្ចប់នៃត្រីមាសទីបួន",
  pullUpToLoadMore: "ទាញឡើងដើម្បីផ្ទុកបន្ថែមទៀត",
  pullDownToRefresh: "ទាញចុះក្រោមដើម្បីធ្វើឱ្យស្រស់",
  releaseToRefreshImmediately: "បញ្ចេញ​ដើម្បី​ផ្ទុក​ឡើងវិញ​ភ្លាមៗ",
  refreshPrompt: "ស្រស់ស្រាយ...",
  download: "ទាញយក",
  appDownload: "ការទាញយក APP",
  brandName: "OV88",
  saveToDesktop: "បន្ថែមទៅអេក្រង់ដើម",
  downloadTitle: "ការទាញយក APP",
  normalDownload: "ការទាញយកធម្មតា។",
  scanningPrompt: "ឈាក់ដោយប្រើកូដ QR ហើយទទួលការកាត់តម្លៃពិសេស ហើយមានល្បិចហ៊ូតថ្មីផ្សេងទៀតរង់កាយរបស់អ្នក!",
  downloadFailToWeb: "ប្រសិនបើអ្នកមិនអាចទាញយក APP បានទេ សូមចុចទីនេះដើម្បីប្រើកំណែគេហទំព័រ",
  scanQrCode: "ស្កែនកូដ QR ជាមួយទូរសព្ទដៃ",
  usingMobileDevices: "ប្រើទូរស័ព្ទចល័ត",
  installTutorial: "ចុចដើម្បីមើលមេរៀនដំឡើង",
  whyNameDifferent: "ឈ្មោះរបស់ APP ដែលបានដំឡើង និង APP ដែលបានទាញយកគឺមិនស៊ីសង្វាក់គ្នាទេ សូមរីករាយក្នុងការទាញយក ដំឡើង និងប្រើប្រាស់!",
  clickDownload: "ចុចដើម្បីទាញយក",
  installTutorialPrompt: "ការណែនាំអំពីការដំឡើង",
  brandNativeName: "UFOScore កម្មវិធីដើម",
  uploadingPrompt: "កំពុងបង្ហោះ...",
  bHandicap: "គ្រាប់ត",
  different: "ភាពខុសគ្នា",
  total: "សរុប",
  off: "មិនបានចាប់ផ្តើម",
  video: "វីដេអូ",
  animation: "ចលនា",
  install: "ដំឡើង",
  startDownloading: "ចាប់ផ្តើមទាញយក",
  cancelGoal: "គោលដៅត្រូវបានលុបចោល",
  appUpdatePrompt: "មានកំណែថ្មីនៃ APP សូមធ្វើបច្ចុប្បន្នភាព",
};
