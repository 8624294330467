/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "ユーザー規約",
  privacyPolicy: "プライバシーポリシー",
  termsOfService: "利用規約",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "まだ始まっていない",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "すでに放送済み",
  watch: "見に行く",
  follow: "焦点を当てる",
  following: "すでにフォローしています",
  subscribe: "サブスクリプション",
  streamerSchedule: "アンカースケジュール",
  noDataPrompt: "データなし",
  unfollowPrompt: "フォローを解除してもよろしいですか？",
  yes: "はい",
  no: "いいえ",
  logAndSign: "ログイン/今すぐ登録",
  hours: "時間",
  mins: "分",
};

const AnchorInformation = {
  recommendedLiveStreams: "おすすめの生放送",
  noDataPrompt: "データなし",
};

const BasketballScoreIndex = {
  details: "詳細",
  over: "以上",
  pointsDifference: "点差",
  totalScore: "合計スコア",
  live: "LIVE",
  unSubscribePrompt: "サブスクリプションをキャンセルしてもよろしいですか?",
  yes: "はい",
  no: "いいえ",
  logAndSign: "ログイン/今すぐ登録",
  InternetErrorPrompt: "ネットワークエラー",
};

const BasketballSmallBig = {
  details: "詳細",
  over: "以上",
  pointsDifference: "点差",
  totalScore: "合計スコア",
  live: "LIVE",
  unSubscribePrompt: "サブスクリプションをキャンセルしてもよろしいですか?",
  yes: "はい",
  no: "いいえ",
  logAndSign: "ログイン/今すぐ登録",
  InternetErrorPrompt: "ネットワークエラー",
  overIndex: "大きい",
  underIndex: "小さい",
};

const BindPhoneDialog = {
  bindPhonePrompt: "アカウントのセキュリティを確保するため、より良いサービスを提供できるよう、携帯電話番号をバインドしてください。",
  goBind: "バインディングに移動",
};

const BottomNavigationBar = {
  home: "表紙",
  matches: "コンテスト",
  recommend: "推薦する",
  chat: "チャット",
  personal: "個人的",
  discovery: "発見する",
  money: "お金を稼ぐ",
};

const ChatInterface = {
  noCsPrompt: "現在チャットできません",
  uploadRulePrompt: "画像はjpg、jpeg、png形式でアップロードしてください",
  uploadSizePrompt: "画像サイズは 10M を超えることはできません",
  sendError: "送信に失敗しました",
  picSendError: "画像の送信に失敗しました",
  InternetErrorPrompt: "ネットワークエラー",
  bannedSendPrompt: "あなたは禁止されました",
};

const ChatList = {
  noNewPrompt: "まだニュースはありません",
  logInPrompt: "コンテンツを表示するにはログインしてください。最初にログインしてください",
  logIn: "ログイン",
  group: "グループ",
};

const ChatListItem = {
  withdrawMessage: "メッセージを取り下げました",
  bannedPrompt: "禁止された",
  unBanPrompt: "禁止されていない",
  joinGroupPrompt: "グループに参加しました",
  leftGroupPrompt: "グループを脱退",
  picture: "写真",
  link: "リンク",
  emoji: "アニメーション顔文字",
  addedFriendPrompt: "友達として追加されました",
};

const CompetitionList = {
  logInPrompt: "コンテンツを表示するにはログインしてください。最初にログインしてください",
  logIn: "ログイン",
  today: "今日",
  Monday: "月曜日",
  Tuesday: "火曜日",
  Wednesday: "水曜日",
  Thursday: "木曜日",
  Friday: "金曜日",
  Saturday: "土曜日",
  Sunday: "日曜日",
  noGames: "まだゲームはありません",
  toCollapse: "クリックして折りたたむ",
  toExpand: "クリックして展開します"
};

const CompetitionListItem = {
  details: "詳細",
  unSubscribePrompt: "サブスクリプションをキャンセルしてもよろしいですか?",
  yes: "はい",
  no: "いいえ",
  logAndSign: "ログイン/今すぐ登録",
  InternetErrorPrompt: "ネットワークエラー",
};

const CompetitionMode = {
  matchMode: "競技モード",
  confirm: "確認する",
  cancel: "キャンセル",
};

const EmojiCom = {
  logAndSign: "ログイン/今すぐ登録",
  yes: "はい",
  no: "いいえ",
};

const ExponentCom = {
  company: "会社",
  initial: "イニシャル",
  ongoing: "すぐに",
  preMatch: "試合前",
  noDataPrompt: "データなし",
  handicap: "ハンディキャップ",
  WinDrawLose: "勝ち、引き分け、負け",
  totalGoals: "合計目標",
  cornerKick: "ホーン",
  pleaseSelect: "選んでください",
  confirm: "確認する",
  cancel: "キャンセル",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "「Stream chat」に固定されました",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "焦点を当てる",
  following: "すでにフォローしています",
  chat: "チャット",
  noDataPrompt: "データなし",
  unfollowPrompt: "フォローを解除してもよろしいですか？",
  yes: "はい",
  no: "いいえ",
  InternetErrorPrompt: "ネットワークエラー",
};

const FootballCornerIndex = {
  details: "詳細",
  over: "以上",
  FH: "HT",
  cornerKick: "ホーン",
  unSubscribePrompt: "サブスクリプションをキャンセルしてもよろしいですか?",
  yes: "はい",
  no: "いいえ",
  logAndSign: "ログイン/今すぐ登録",
  InternetErrorPrompt: "ネットワークエラー",
};

const FootballScoreIndex = {
  details: "詳細",
  over: "以上",
  FH: "HT",
  cornerKick: "ホーン",
  live: "LIVE",
  unSubscribePrompt: "サブスクリプションをキャンセルしてもよろしいですか?",
  yes: "はい",
  no: "いいえ",
  logAndSign: "ログイン/今すぐ登録",
  InternetErrorPrompt: "ネットワークエラー",
};

const FootballScoreItem = {
  details: "詳細",
  over: "以上",
  FH: "HT",
  cornerKick: "ホーン",
  live: "LIVE",
  unSubscribePrompt: "サブスクリプションをキャンセルしてもよろしいですか?",
  yes: "はい",
  no: "いいえ",
  logAndSign: "ログイン/今すぐ登録",
  InternetErrorPrompt: "ネットワークエラー",
};

const GoalToast = {
  goal: "ゴール",
};

const LiveRecommend = {
  noDataPrompt: "データなし",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "勝利か敗北か",
};

const MatchCardList = {
  details: "詳細",
};

const MatchStatus = {
  noDataPrompt: "データなし",
};

const MoreStreams = {
  switchLines: "回線を切り替える",
  cancel: "キャンセル",
  currentStreamer: "現在のアンカー",
  live: "LIVE",
  offLine: "まだ始まっていない",
  followersCount: "焦点を当てる",
  otherStreamersPrompt: "現在ゲームを放送している他のアンカーがいます",
  watch: "見に行く",
};

const NewMatchStatus = {
  team: "チーム",
  halfTimeScore: "ハーフタイムスコア",
  shotOnTarget: "ゴール上でシュート",
  missedShot: "ゴールを外した",
  attack: "攻撃",
  dangerousAttack: "危険な攻撃",
  number3PointGoals: "3ポイントゴール数",
  number2PointGoals: "2点ゴール数",
  penaltyKickGoals: "ペナルティキックゴール",
  freeThrowPercentage: "フリースローの成功率",
  numberOfPausesRemaining: "残りの一時停止数",
  numberOfFouls: "ファウルの数",
  totalNumberOfPauses: "合計一時停止数",
  threePointShot: "3ポイントシュート",
  twoPointShot: "2ポイントシュート",
  shoot: "シュート",
  penalty: "ペナルティ",
  firstHalf: "前半",
  secondHalf: "後半",
  redCard: "レッドカード",
  yellowCard: "イエローカード",
};

const NoData = {
  noDataPrompt: "データなし",
};

const NoticeMessage = {
  addedFriendPrompt: "友達として追加されました",
  withdrawMessage: "メッセージを取り下げました",
  withdrawMessageByAdmin: "メッセージは管理者によって取り消されました",
  joinGroupPrompt: "グループに参加しました",
  leftGroupPrompt: "グループを脱退",
  removeGroupPrompt: "管理者によってグループから削除されました",
  bannedPrompt: "禁止された",
  unBanPrompt: "禁止されていない",
};

const OddsStatisticsList = {
  initial: "イニシャル",
  ongoing: "すぐに",
  preMatch: "試合前",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "最高値",
  min: "最低値",
  average: "平均値",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "データなし",
};

const RecommendCompetitions = {
  today: "今日",
  winsInARow: "真ん中でも",
  noLivePrompt: "まだ生放送はありません",
};

const RecommendStreamerList = {
  noDataPrompt: "データなし",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "今週のヒット",
  victoryOrDefeat: "勝利か敗北か",
  check: "チェック",
  chat: "チャット",
  quarterlyProfit: "四半期利益",
};

const RecommendStreamers = {
  recommendedByExperts: "専門家が推奨する",
  winsInARow: "真ん中でも",
  changeBatch: "バッチを変更する",
  follow: "焦点を当てる",
  following: "すでにフォローしています",
  unFollowing: "ロックを解除する",
  noDataPrompt: "データなし",
  logAndSign: "ログイン/今すぐ登録",
  yes: "はい",
  no: "いいえ",
  InternetErrorPrompt: "ネットワークエラー",
  unfollowPrompt: "フォローを解除してもよろしいですか？",
};

const RecordChart = {
  record: "記録",
  allWaysToPlay: "あらゆる遊び方",
  fromFarToNear: "遠くから近くまで",
  lastTenGames: "過去10試合",
  longestConsecutiveHit: "最長連続ヒット",
  hitRate: "ヒット率",
  currentPropmt: "最近のゲーム",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "ユーザー情報の取得に失敗しました",
  getAreaAndLanguageErrorPrompt: "地域言語の取得に失敗しました",
};

const ScheduleCom = {
  startTime: "開始時間",
};

const SchemeCard = {
  planList: "プラン一覧",
  release: "リリース",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "保証された補償",
  free: "無料",
  toll: "通行料金",
  victoryOrDefeat: "勝利か敗北か",
};

const SquareCom = {
  watchPrompt: "人々は見ています",
  startedPrompt: "数分前に開始",
  chat: "チャット",
  sendMessageNeedLoginPrompt: "メッセージを送信するにはログインしてください",
  assistant: "管理者",
  audience: "ユーザー",
  newMessage: "新しいニュース",
  confirmSend: "送信を確認する",
  cancelSend: "キャンセル",
};

const StatisticalChart = {
  hitRate: "ヒット率",
};

const SwitchLines = {
  switchLines: "回線を切り替える",
  cancel: "キャンセル",
  currentStreamer: "現在のアンカー",
  live: "LIVE",
  offLine: "まだ始まっていない",
  followersCount: "焦点を当てる",
  otherStreamersPrompt: "現在ゲームを放送している他のアンカーがいます",
  watch: "見に行く",
  LINE: "ライン",
  VIP: "VIP",
  OFFICIAL: "正式",
};

const VideoCom = {
  videoErrorPrompt: "信号があまり良くありません。しばらくしてからもう一度お試しください。",
  clickRetryPrompt: "クリックして再試行してください",
};

const WelcomeCard = {
  join: "グループに参加",
};

//utils
const tools = {
  yesterday: "昨日",
  addedFriendPrompt: "友達として追加されました",
  withdrawMessage: "メッセージを取り下げました",
  joinGroupPrompt: "グループに参加しました",
  leftGroupPrompt: "グループを脱退",
  removeGroupPrompt: "管理者によってグループから削除されました",
  bannedPrompt: "禁止された",
  unBanPrompt: "禁止されていない",
};

//页面
const AddressBook = {
  search: "探す",
  addFriend: "友人を追加する",
  myGroupChat: "私のグループチャット",
  friends: "友達",
  delete: "消去",
  noFriendsPrompt: "まだ友達がいません",
  nameAndIdSearch: "名前、IDを入力してください",
  searchResults: "結果の検索",
  notFoundPrompt: "ユーザーが見つかりません",
  add: "に追加",
  deleteFriendPrompt: "友達を削除してもよろしいですか?",
  yes: "はい",
  no: "いいえ",
  addFriendSuccessPrompt: "友達追加に成功しました",
  deleteFriendSuccessPrompt: "友達を正常に削除しました",
  InputSearchPrompt: "内容を入力してください",
};

const GroupChat = {
  groupChat: "グループチャット",
  groupSearch: "グループチャット名を入力してください",
  noGroupPrompt: "グループチャットはまだありません",
  chat: "チャット",
  InputSearchPrompt: "内容を入力してください",
};

const GroupAdministrator = {
  administrator: "管理者",
  groupOwner: "Home",
};

const GroupAnnouncement = {
  groupAnnouncement: "グループ発表",
  groupOwner: "Home",
  administrator: "管理者",
};

const GroupDetail = {
  groupDetail: "グループ詳細",
  groupMembers: "グループの人（仲間）たち",
  groupName: "グループ名",
  groupDescription: "グループの説明",
  groupOwner: "Home",
  administrator: "管理者",
  administratorList: "管理者リスト",
  groupAnnouncement: "グループ発表",
  groupFiles: "グループファイル",
  exitGroup: "グループから抜け出す",
  exitGroupPrompt: "本当にグループを退会しますか?",
  yes: "はい",
  no: "いいえ",
  leftGroupSuccessPrompt: "グループから正常に退出しました",
};

const GroupFile = {
  groupFiles: "グループファイル",
  fileSearch: "ファイル名を入力してください",
  today: "今日",
  multimedia: "マルチメディア",
  file: "書類",
  noFilesPrompt: "まだファイルがありません",
};

const GroupMembers = {
  groupMembers: "グループの人（仲間）たち",
  nameAndIdSearch: "名前、IDを入力してください",
};

const Chat = {
  chat: "チャット",
  nameAndIdSearch: "名前、IDを入力してください",
  contacts: "住所録",
  InputSearchPrompt: "内容を入力してください",
  deleteContactPrompt: "チャットを削除してもよろしいですか?",
};

const Competition = {
  all: "All",
  ongoing: "すぐに",
  popular: "人気のある",
  liveCompetition: "生放送",
  schedule: "スケジュール",
  results: "結果",
  subscribed: "購読しました",
  goal: "ゴール",
  today: "今日",
  Monday: "月曜日",
  Tuesday: "火曜日",
  Wednesday: "水曜日",
  Thursday: "木曜日",
  Friday: "金曜日",
  Saturday: "土曜日",
  Sunday: "日曜日",
};

const CompetitionDetails = {
  subscribe: "サブスクリプション",
  subscribed: "購読しました",
  matchStreamerPrompt: "ライブゲームアンカー",
  live: "LIVE",
  noDataPrompt: "データなし",
  noStreamersPrompt: "現在、このゲームをブロードキャストしているホストはいません",
  alreadyStreamerPrompt: "ホストが試合の中継を開始しました。見に行きますか?",
  yes: "はい",
  no: "いいえ",
  liveStream: "生放送",
  matchStatus: "試合状況",
  odds: "Odds",
  unSubscribePrompt: "サブスクリプションをキャンセルしてもよろしいですか?",
  logAndSign: "ログイン/今すぐ登録",
  InternetErrorPrompt: "ネットワークエラー",
};

const OddsDetails = {
  company: "会社",
  handicap: "ハンディキャップ",
  WinDrawLose: "勝ち、引き分け、負け",
  totalGoals: "合計目標",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "多くの",
  Un: "少し",
};

const Home = {
  logIn: "ログイン",
  signUp: "登録する",
  popularMatches: "人気のコンテスト",
  more: "もっと",
  noDataPrompt: "データなし",
  currentlyLive: "ライブブロードキャスト",
  popularStreamers: "人気のアンカー",
  thinkYouLike: "あなたも好きかも",
  loadMore: "もっと読み込む",
  noMoreDataPrompt: "これ以上のデータはまだありません",
  addDeskPrompt: "デスクトップに追加",
  clikToolPrompt: "下のツールバーをクリックします",
  andSeletcPrompt: "そして選択します",
  addToScreenPrompt: "より良いサービスを提供するためにホーム画面に追加したい",
  addToScreen: "ホーム画面に追加します",
};

const MoreLives = {
  noDataPrompt: "データなし",
};

const MoreStreamers = {
  streamer: "アンカー",
  streamerSearch: "アンカー名とIDを入力します",
  all: "All",
  notFollowing: "フォローしていません",
  following: "すでにフォローしています",
  live: "LIVE",
  offLine: "まだ始まっていない",
  followersCount: "焦点を当てる",
  unfollowPrompt: "フォローを解除してもよろしいですか？",
  yes: "はい",
  no: "いいえ",
  logAndSign: "ログイン/今すぐ登録",
  InternetErrorPrompt: "ネットワークエラー",
  InputSearchPrompt: "内容を入力してください",
  dataRequestErrorPrompt: "データの取得に失敗しました",
};

const LiveRoom = {
  switchLines: "回線を切り替える",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "アンカープライベートチャット",
  chat: "チャット",
  odds: "Odds",
  streamerInformation: "アンカー情報",
  matchStatus: "試合状況",
};

const AnchorIntroduction = {
  streamerInformation: "アンカー情報",
  photosWall: "フォトウォール",
  seeMore: "続きを見る",
  streamerPlan: "アンカープラン",
};

const AnchorPhoto = {
  photosWall: "フォトウォール",
  noPhotosPrompt: "これ以上の写真はまだありません",
};

const LogInRegister = {
  logIn: "ログイン",
  signUp: "登録する",
  getAreaAndLanguageErrorPrompt: "地域言語の取得に失敗しました",
  quickRegisterFrequentPrompt: "よくあるリクエストです。しばらくしてからもう一度お試しください",
};

const LoginFn = {
  phone: "電話番号",
  email: "郵便",
  logInPassword: "ログインパスワード",
  rememberPassword: "パスワードを覚える",
  forgotPassword: "パスワードを忘れる",
  logIn: "ログイン",
  logInAgreement: "ログインすると同意したことになります",
  userAgreement: "ユーザー規約",
  and: "  そして ",
  privacyPolicy: "プライバシーポリシー",
  passwordRulePrompt: "パスワードは大文字、小文字、数字で構成され、8 文字以上でなければなりません",
  passwordEmptyPrompt: "パスワードを空白にすることはできません",
  emailRulePrompt: "メールアドレスの正しい形式を入力してください",
  emailEmptyPrompt: "電子メールを空にすることはできません",
  phoneRulePrompt: "携帯電話番号を正しい形式で入力してください",
  phoneEmptyPrompt: "携帯電話番号を空白にすることはできません",
  phoneNum8: "8桁の携帯電話番号を入力してください",
  phoneNum9To10: "9～10桁の携帯電話番号を入力してください",
  phoneNum9: "9桁の携帯電話番号を入力してください",
  enterErrorPrompt: "入力エラーまたはネットワーク異常",
  loginNoAgreePrompt: "ログインするには、ユーザー契約とプライバシー ポリシーに同意する必要があります",
  accountAndPassword: "アカウント",
  account: "アカウント",
  accountEmptyPrompt: "アカウントを空にすることはできません",
  accountOrPasswordError: "ユーザーネームまたはパスワードが違います",
};

const Register = {
  phone: "電話番号",
  email: "郵便",
  code: "検証コード",
  getCode: "確認コードを取得する",
  logInPassword: "ログインパスワード",
  CS: "顧客サービス",
  signUp: "登録する",
  signUpAgreement: "登録すると同意したことになります",
  userAgreement: "ユーザー規約",
  and: "そして",
  privacyPolicy: "プライバシーポリシー",
  contactCSPrompt: "登録に問題がありますか? カスタマーサービスに問い合わせて解決してください",
  contactCS: "カスタマーサービスに連絡する",
  passwordRulePrompt: "パスワードは大文字、小文字、数字で構成され、8 文字以上でなければなりません",
  passwordEmptyPrompt: "パスワードを空白にすることはできません",
  emailRulePrompt: "メールアドレスの正しい形式を入力してください",
  emailEmptyPrompt: "電子メールを空にすることはできません",
  phoneRulePrompt: "携帯電話番号を正しい形式で入力してください",
  phoneEmptyPrompt: "携帯電話番号を空白にすることはできません",
  codeRulePrompt: "認証コードは6桁です",
  codeEmptyPrompt: "確認コードを入力する必要があります",
  phoneNum8: "8桁の携帯電話番号を入力してください",
  phoneNum9To10: "9～10桁の携帯電話番号を入力してください",
  phoneNum9: "9桁の携帯電話番号を入力してください",
  phoneCodeSendSuccessPrompt: "確認コードは正常に送信されました。携帯電話のテキスト メッセージを確認してください。",
  emailCodeSendSuccessPrompt: "確認コードは正常に送信されました。メールを確認してください",
  phoneCodeSendErrorPrompt: "認証コードを送信できませんでした。携帯電話番号が利用可能かどうかを確認してください。",
  emailCodeSendErrorPrompt: "確認コードを送信できませんでした。メールが利用可能かどうかを確認してください",
  codeSendToMuchPrompt: "同時に送信しすぎました。後でもう一度お試しください。",
  phoneAlreadySignupPrompt: "この携帯電話番号は登録されています。直接ログインしてください",
  emailAlreadySignupPrompt: "このメールアドレスは登録されていますので、直接ログインしてください",
  signUpErrorPrompt: "登録に失敗しました。情報が正しいか確認してください",
  enterErrorPrompt: "入力エラーまたはネットワーク異常",
  noAgreePrompt: "登録するにはユーザー契約とプライバシーポリシーに同意する必要があります",
  regionAndLanguagePrompt: "実際の地域言語を選択してください。選択しないと、機能の使用に影響します。",
  accountAndPassword: "アカウント",
  quickSignup: "ワンクリック登録",
  accountEmptyPrompt: "アカウントを空にすることはできません",
  accountRulePrompt: "口座番号は 8 ～ 16 文字の文字と数字で構成する必要があります",
  accountAlreadySignupPrompt: "このアカウントは登録されています。直接ログインしてください",
  tryAgain: "再送信",
};

const PersonalCenter = {
  logAndSign: "ログイン/今すぐ登録",
  logAndSignPrompt: "今すぐ限定機能と限定特典をロック解除しましょう",
  logOut: "サインアウト",
  myFollow: "私の焦点",
  mySubscription: "私のサブスクリプション",
  accountSecurity: "アカウントのセキュリティ",
  feedback: "フィードバック",
  deviceInformation: "装置",
  aboutUs: "私たちについて",
  coin: "金",
  moreServices: "その他のサービス",
  following: "すでにフォローしています",
  subscribed: "購読しました",
  browser: "ブラウザ",
  user: "ユーザー",
  profile: "個人情報",
  nickname: "ニックネーム",
  save: "保存",
  change: "変化",
  nicknamePrompt: "ニックネームを入力してください",
  avatar: "アバター",
  changeSuccessPrompt: "変更が成功しました",
  ballAnimation: "ゴールアニメーション",
  ballVoice: "ゴール効果音",
  setting: "設定",
};

const IntegralSystem = {
  Coin: "金",
  goldCoinTask: "金貨タスク",
  PointsDetails: "金貨の詳細",
  myCoins: "私の金貨",
  income: "所得",
  expenditure: "支出",
  signInPrompt: "継続的にサインインして継続的に特典を受け取りましょう",
  toComplete: "完了する",
  toShare: "共有する",
  shareTasks: "タスクを共有する",
  exclusiveLink: "専用リンク",
  copyLink: "リンクをコピーする",
  highestWin: "最高の勝利",
  day1: "1日目",
  day2: "2日目",
  day3: "3日目",
  day4: "4日目",
  day5: "5日目",
  day6: "6日目",
  day7: "7日目",
  invitePrompt: "驚き！ 今すぐ登録してアプリをダウンロードして、新規ユーザー限定の特典をお楽しみください! 無料のライブ イベント ブロードキャスト、リアルタイムのデータ分析、プロのアンカーの予測をすべて簡単に行えます。 もう待つ必要はありません。リンクをクリックして参加してください。"
};

const Money = {
  activeCenter: "アクティビティセンター",
  missionCenter: "ミッションセンター",
};

const ActivityCenter = {
  toParticipate: "参加する",
  sportActivity: "スポーツ活動",
};

const ActivityDetails = {
  eventDetails: "イベントの詳細",
  dailyBetting: "毎日の賭け",
  matchTime: "試合時間",
  popularForEvent: "このイベントの人気のオプション",
  clickToParticipate: "クリックして今すぐ参加",
  activityRules: "活動ルール",
  clickToContactParticipate: "参加するにはクリックしてカスタマーサービスにお問い合わせください",
  activityObj: "アクティブなオブジェクト",
  activityPlatform: "イベントプラットフォーム",
  activityDuration: "活動時間",
  activityDemand: "活動要件",
  activityTime: "活動時間",
};

const AboutUs = {
  aboutUs: "私たちについて",
  platformIntroduction: "プラットフォームの紹介",
  privacyPolicy: "プライバシーポリシー",
  userAgreement: "ユーザー規約",
};

const DeviceInfo = {
  deviceInformation: "装置",
  currentTime: "現在の時刻",
  port: "ログインポート",
  ip: "ログインIP",
  version: "現行版",
  ipAndPort: "ログイン情報",
};

const FeedBack = {
  feedback: "フィードバック",
  feedbackType: "フィードバックタイプ",
  productSuggestion: "製品のご提案",
  functionalFault: "機能障害",
  interfaceSuggestion: "インターフェースの提案",
  accountIssue: "アカウントの問題",
  operationExperience: "操作経験",
  otherFeedback: "その他のフィードバック",
  problemDescription: "問題文",
  submit: "提出する",
  addPhoto: "写真を追加",
  uploadPrompt: "申し訳ありませんが、アップロードできる写真は 3 枚までです",
  uploadRulePrompt: "画像はjpg、jpeg、png形式でアップロードしてください",
  uploadSizePrompt: "画像サイズは 10M を超えることはできません",
  chooseFeedback: "フィードバックの種類を選択してください",
  inputQuestionPrompt: "質問を入力してください",
  submitSuccessPrompt: "正常に送信されました",
};

const MySubscription = {
  myFollow: "私の焦点",
  mySubscription: "私のサブスクリプション",
  streamer: "アンカー",
  matches: "コンテスト",
};

const PrivacyAgreement = {
  privacyPolicy: "プライバシーポリシー",
};

const TermsofService = {
  termsOfService: "利用規約",
};

const UserAgreement = {
  userAgreement: "ユーザー規約",
};

const AccountSecurity = {
  accountSecurity: "アカウントのセキュリティ",
  phone: "電話番号",
  email: "郵便",
  bind: "バインディング",
  notBound: "バインドされていません",
  change: "変化",
  logInPassword: "ログインパスワード",
  accountChangePassword: "まず携帯電話/メールアドレスをバインドしてください",
};

const BindEmail = {
  bindEmail: "電子メールをバインドする",
  email: "郵便",
  code: "検証コード",
  getCode: "確認コードを取得する",
  confirm: "確認する",
  emailRulePrompt: "メールアドレスの正しい形式を入力してください",
  emailEmptyPrompt: "電子メールを空にすることはできません",
  codeRulePrompt: "認証コードは6桁です",
  codeEmptyPrompt: "確認コードを入力する必要があります",
  changeSuccessPrompt: "変更が成功しました",
  changeErrorPrompt: "変更に失敗しました",
  bindSuccessPrompt: "バインド成功",
  bindErrorPrompt: "バインドに失敗しました",
  emailCodeSendSuccessPrompt: "確認コードは正常に送信されました。メールを確認してください",
  emailCodeSendErrorPrompt: "確認コードを送信できませんでした。メールが利用可能かどうかを確認してください",
  codeSendToMuchPrompt: "同時に送信しすぎました。後でもう一度お試しください。",
  emailAlreadySignupPrompt: "このメールアドレスは登録されていますので、直接ログインしてください",
  tryAgain: "再送信",
};

const BindPhone = {
  bindPhone: "携帯電話をバインドする",
  phone: "電話番号",
  code: "検証コード",
  getCode: "確認コードを取得する",
  confirm: "確認する",
  cancel: "キャンセル",
  phoneRulePrompt: "携帯電話番号を正しい形式で入力してください",
  phoneEmptyPrompt: "携帯電話番号を空白にすることはできません",
  codeRulePrompt: "認証コードは6桁です",
  codeEmptyPrompt: "確認コードを入力する必要があります",
  changeSuccessPrompt: "変更が成功しました",
  changeErrorPrompt: "変更に失敗しました",
  bindSuccessPrompt: "バインド成功",
  bindErrorPrompt: "バインドに失敗しました",
  phoneNum8: "8桁の携帯電話番号を入力してください",
  phoneNum9To10: "9～10桁の携帯電話番号を入力してください",
  phoneNum9: "9桁の携帯電話番号を入力してください",
  phoneCodeSendSuccessPrompt: "確認コードは正常に送信されました。携帯電話のテキスト メッセージを確認してください。",
  phoneCodeSendErrorPrompt: "認証コードを送信できませんでした。携帯電話番号が利用可能かどうかを確認してください。",
  codeSendToMuchPrompt: "同時に送信しすぎました。後でもう一度お試しください。",
  phoneAlreadySignupPrompt: "この携帯電話番号は登録されています。直接ログインしてください",
  checkSignup: "番号が登録されているか確認してください",
  tryAgain: "再送信",
};

const ChangePassword = {
  resetPassword: "パスワードを再設定する",
  phone: "電話番号",
  email: "郵便",
  code: "検証コード",
  getCode: "確認コードを取得する",
  logInPassword: "ログインパスワード",
  confirm: "確認する",
  cancel: "キャンセル",
  passwordRulePrompt: "パスワードは大文字、小文字、数字で構成され、8 文字以上でなければなりません",
  passwordEmptyPrompt: "パスワードを空白にすることはできません",
  emailRulePrompt: "メールアドレスの正しい形式を入力してください",
  emailEmptyPrompt: "電子メールを空にすることはできません",
  phoneRulePrompt: "携帯電話番号を正しい形式で入力してください",
  phoneEmptyPrompt: "携帯電話番号を空白にすることはできません",
  codeRulePrompt: "認証コードは6桁です",
  codeEmptyPrompt: "確認コードを入力する必要があります",
  phoneNum8: "8桁の携帯電話番号を入力してください",
  phoneNum9To10: "9～10桁の携帯電話番号を入力してください",
  phoneNum9: "9桁の携帯電話番号を入力してください",
  resetSuccess: "パスワードのリセットが成功しました",
  resetError: "パスワードのリセットに失敗しました。情報が正しいか確認してください",
  checkSignup: "番号が登録されているか確認してください",
  phoneCodeSendSuccessPrompt: "確認コードは正常に送信されました。携帯電話のテキスト メッセージを確認してください。",
  codeSendToMuchPrompt: "同時に送信しすぎました。後でもう一度お試しください。",
  phoneAlreadySignupPrompt: "この携帯電話番号は登録されています。直接ログインしてください",
  phoneCodeSendErrorPrompt: "認証コードを送信できませんでした。携帯電話番号が利用可能かどうかを確認してください。",
  emailCodeSendSuccessPrompt: "確認コードは正常に送信されました。メールを確認してください",
  emailCodeSendErrorPrompt: "確認コードを送信できませんでした。メールが利用可能かどうかを確認してください",
  emailAlreadySignupPrompt: "このメールアドレスは登録されていますので、直接ログインしてください",
  bindPhoneOrEmail: "最初に携帯電話番号またはメールアドレスをバインドしてください",
  tryAgain: "再送信",
};

const QuickRegister = {
  downloadScreenshot: "スクリーンショットをダウンロードする",
  saveAccountAndPassword: "アカウントとパスワードを安全に保管してください",
  suggestSave: "スクリーンショットを撮って保存するか、メモにコピーすることをお勧めします。",
  account: "アカウント",
  password: "パスワード",
  copyAccountAndPassword: "アカウントのパスワードをコピーする",
  prompt: "ヒント",
  sureSave: "アカウントのパスワードを保存しましたか?",
  confirm: "確認する",
  cancel: "キャンセル",
  copySuccess: "正常にコピーされました",
  copyFailure: "コピーに失敗しました",
  manualRegister: "登録数が上限に達していますので手動で登録してください",
};

const Recommend = {
  recommend: "推薦する",
  InternetErrorPrompt: "ネットワークエラー",
  hitOfTheWeek: "今週のヒット",
  victoryOrDefeat: "勝利か敗北か",
  check: "チェック",
  lastTenGames: "過去10試合",
  recommendedByExperts: "専門家が推奨する",
  changeBatch: "バッチを変更する",
  follow: "焦点を当てる",
  following: "すでにフォローしています",
  unFollowing: "ロックを解除する",
  all: "All",
  guaranteedCompensation: "保証された補償",
  free: "無料",
  toll: "通行料金",
  HOT: "販売",
  HIT: "打つ",
  STREAK: "真ん中でも",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "ホームページ",
  chat: "チャット",
  follow: "焦点を当てる",
  following: "すでにフォローしています",
  unFollowing: "ロックを解除する",
  followersCount: "焦点を当てる",
  planCount: "プラン",
  viewCount: "時計",
  statistics: "統計",
  plan: "プラン",
};

const SchemeDetails = {
  planDetails: "プラン詳細",
  release: "リリース",
  chat: "チャット",
  follow: "焦点を当てる",
  following: "すでにフォローしています",
  unFollowing: "ロックを解除する",
  details: "詳細",
  analyze: "分析する",
  title: "タイトル",
  privateChatStreamerJoinsGroup: "プライベートチャットホストがグループに参加",
  upcoming: "始まっていません",
  othersPlan: "その他のオプション",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "一致ソート",
  byTime: "時間までに",
  byLeague: "リーグ別",
  confirm: "確認する",
  cancel: "キャンセル",
};

const RecommendPublish = {
  enterTeam: "チームに入る",
  planRelease: "計画のリリース",
  predictionTitle: "タイトル (すべてのユーザーに表示されます。予測結果は公開しないでください)",
  titleEmptyPrompt: "分析のタイトルと内容を空にすることはできません",
  pleaseSelectAtLeastOneGameToPredictResults: "結果を予測する方法を少なくとも 1 つ選択してください",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "フットボール",
  basketball: "バスケットボール",
  mixed: "包括的な",
  setLogInPassword: "ログインパスワードの設定",
  OCS: "オンラインサービス",
  OCSPrompt: "どうぞ何なりとお申し付けください",
  phoneInCorrectPrompt: "携帯電話番号が間違って入力されました",
  codeInCorrectPrompt: "間違った確認コード",
  emailInCorrectPrompt: "メール入力エラー",
  passwordPrompt: "8～16桁の英語と数字を組み合わせて入力してください",
  newPassword: "新しいパスワードを入力",
  resetPasswordPrompt: "パスワードをリセットしてもよろしいですか?",
  startChatPrompt: "これでチャットを開始できます",
  send: "送信",
  noFollowPrompt: "まだ注目されていません",
  feedbackPrompt: "貴重なコメントを残してください。改善に努めます。",
  bindPhoneByOtherPrompt: "この携帯番号は他のユーザーによってバインドされています",
  bindEmailByOtherPrompt: "このメール アドレスは別のユーザーによってバインドされています",
  super_manager: "スーパー管理者",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "第 1 四半期の終わり",
  endOfSecondQuarter: "第2四半期の終わり",
  endOfThirdQuarter: "第3四半期の終わり",
  endOfFourthQuarter: "第 4 四半期の終わり",
  pullUpToLoadMore: "さらにロードするには引き上げます",
  pullDownToRefresh: "プルダウンして更新します",
  releaseToRefreshImmediately: "放すとすぐに更新されます",
  refreshPrompt: "爽やかな...",
  download: "ダウンロード",
  appDownload: "アプリのダウンロード",
  brandName: "OV88",
  saveToDesktop: "ホーム画面に追加します",
  downloadTitle: "アプリのダウンロード",
  normalDownload: "通常ダウンロード",
  scanningPrompt: "QRコードをスキャンしてインストールし、限定の大きな割引を受け取り、その他の豪華な特典もお楽しみいただけます！",
  downloadFailToWeb: "APPをダウンロードできない場合は、ここをクリックしてWebバージョンを使用してください",
  scanQrCode: "携帯電話でQRコードをスキャンしてください",
  usingMobileDevices: "モバイルを使用する",
  installTutorial: "クリックしてインストールチュートリアルを表示します",
  whyNameDifferent: "インストールしたAPPとダウンロードしたAPPの名前は一致しませんが、ご自由にダウンロード、インストールして使用してください。",
  clickDownload: "クリックしてダウンロード",
  installTutorialPrompt: "インストール手順ガイド",
  brandNativeName: "UFOScore ネイティブ APP",
  uploadingPrompt: "アップロード中...",
  bHandicap: "ハンディキャップ",
  different: "違い",
  total: "合計",
  off: "始まっていません",
  video: "ビデオ",
  animation: "アニメーション",
  install: "インストール",
  startDownloading: "ダウンロード開始",
  cancelGoal: "ゴールがキャンセルされました",
  appUpdatePrompt: "新しいバージョンのアプリがありますので、更新してください",
};
