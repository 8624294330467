import {
  getCarousels,
  getRecommendedLives,
  getLives,
  getStreamers,
} from "@/services/api/Home/index";
import { getUser } from "@/services/api/User";
import { getPopularCompetitions } from "@/services/api/Competition";

const state = {
  userInfo: {}, //用户信息
  carousels: [], // 轮播图
  recommendCarousels: [], //推荐轮播图
  popularCompetitions: [], // 热门比赛数据（不限体育类型）
  recommendedLive: [], // 推荐\猜你喜欢 直播
  recommendedItems: [], //
  popularStreamers: [], // 热门主播
  personalPopVisible: false, //个人中心卡片状态
  firstLanguage: localStorage.getItem("language_id"), //  当前语言: 首页热门比赛
  moreStreamersSelected: 0, // 更多主播选中的索引
  // 正在直播 数据
  BeBingLives: {
    lives: [], // 直播间
    getLivesParams: {
      // 请求API参数
      page: 1, // 页面序号
      size: 50, // 页面容量
    },
    maxLivePage: 1, // 最大页数
  },
  // 正在直播最新请求
  latestRequest: 0,
};

const mutations = {
  /**
   * 设置用户信息
   */
  SET_USERINFO(state, { data }) {
    state.userInfo = data;
  },
  /**
   * 设置当前语言
   */
  SET_FIRST_LANGUAGE(state, language_id) {
    state.firstLanguage = language_id;
  },
  /**
   * 设置热门比赛数据
   * @param {string} payload.popularCompetitions - 热门比赛数据（不限体育类型）
   */
  SET_POPULAR_COMPRTITIONS(state, { popularCompetitions }) {
    state.popularCompetitions = popularCompetitions;
  },

  /**
   * 设置轮播图
   * @param {string} payload.carousels - 轮播图
   */
  SET_CAROUSELS(state, { carousels }) {
    state.carousels = carousels;
  },

  /**
   * 设置推荐轮播图
   */
  SET_RECOMMEND_CAROUSELS(state, { recommendCarousels }) {
    state.recommendCarousels = recommendCarousels;
  },

  /**
   * 设置推荐\猜你喜欢 直播
   * @param {string} payload.recommendedLive - 推荐\猜你喜欢 直播
   */
  SET_RECOMMENDED_LIVE(state, { recommendedLive }) {
    state.recommendedLive = recommendedLive;
  },
  /**
   * 设置推荐\猜你喜欢 方案
   */
  SET_RECOMMENDED_ITEMS(state, { recommendedItems }) {
    state.recommendedItems = recommendedItems;
  },
  /**
   * 设置热门主播
   * @param {string} payload.recommendedLive - 热门主播
   */
  SET_POPULAR_STREAMERS(state, { popularStreamers }) {
    state.popularStreamers = popularStreamers;
  },

  /**
   * 设置 正在直播 的列表数据
   * @param {string} payload.BeBingLives - 正在直播 的列表数据
   */
  SET_BEBINF_LIVES(state, { BeBingLives, isLoadMore = false }) {
    if (!isLoadMore) {
      state.BeBingLives.lives.length = 0;
    }
    state.BeBingLives.lives.push(...BeBingLives.items);
    state.BeBingLives.getLivesParams.page = BeBingLives.page;
    state.BeBingLives.maxLivePage = BeBingLives.pages;
  },

  /**
   * 更新 正在直播 赛事比分
   */
  UPDATE_LIVING_SCORES(state, data) {
    const targetIndexObj = state.BeBingLives.lives.find(
      (item) => item.competition_id === Number(data.competition_id)
    );
    if (targetIndexObj) {
      const { home_scores, away_scores } = data;
      // console.group(`${competition_id}`);
      // home_scores 不为null且不是空数组
      if (home_scores && home_scores.length !== 0) {
        // console.log("home_scores", home_scores);
        targetIndexObj.match[0].score = home_scores[0];
      }
      // away_scores 不为null且不是空数组
      if (away_scores && away_scores.length !== 0) {
        // console.log("away_scores", away_scores);
        targetIndexObj.match[1].score = away_scores[0];
      }
      // console.groupEnd();
    }
  },

  /**
   * 切换更多直播主播选中的索引
   */
  TOGGLE_MORE_STREAMERS_SELECTED(state, index) {
    state.moreStreamersSelected = index;
  },

  /**
   * 设置个人中心弹窗状态
   */
  SET_PERSONAL_POPUP_VISIBLE(state, visible) {
    state.personalPopVisible = visible;
  }
};

const actions = {
  /**
   * 切换更多直播主播选中的索引
   */
  changeMoreStreamersSelected({ commit }, index) {
    commit("TOGGLE_MORE_STREAMERS_SELECTED", index);
  },
  /**
   * 设置当前语言
   */
  setFirstLanguage({ commit }, language_id) {
    commit("SET_FIRST_LANGUAGE", language_id);
  },

  /**
   * 异步获取用户信息
   */
  async fetchUserInfo({ commit }, { language_id }) {
    try {
      const response = await getUser({
        // country_id,
        language_id,
      });
      const { data, statusCode } = response;
      // console.log("异步获取用户信息", data);
      if (statusCode === 200) {
        // 提交数据到 state
        commit("SET_USERINFO", {
          data,
        });
      }
    } catch (error) {
      console.error(`异步获取用户信息`, error);
    }
  },

  // FIXME: 热门比赛数据需要重构 接新的接口
  /**
   * 异步获取热门比赛数据
   */
  async fetchPopularCompetitionData({ commit }, params) {
    try {
      const response = await getPopularCompetitions(params);
      const { statusCode, data } = response;
      if (statusCode === 200) {
        const rows = data.items;
        // 按时间重新排序
        rows.sort((a, b) => {
          return new Date(a.match_time) - new Date(b.match_time);
        });
        // 提交数据到 state
        commit("SET_POPULAR_COMPRTITIONS", {
          popularCompetitions: rows,
        });
      }

      return true;
    } catch (error) {
      console.error(`获取获取热门比赛数据出错`, error);
      return false;
    }
  },

  /**
   * 异步获取轮播图
   */
  async fetchCarousels({ commit }, { language_id, scene }) {
    try {
      const response = await getCarousels({
        // country_id,
        language_id,
        scene,
      });
      const { data, statusCode } = response;
      if (statusCode === 200) {
        // 提交数据到 state
        commit("SET_CAROUSELS", {
          carousels: data.items,
        });
      }
      return response;
    } catch (error) {
      console.error(`异步获取轮播图`, error);
      return false;
    }
  },

  /**
   * 异步获取推荐轮播图
   */
  async fetchRecommendCarousels(
    { commit },
    { language_id, scene }
  ) {
    try {
      const response = await getCarousels({
        // country_id,
        language_id,
        scene,
      });
      const { data, statusCode } = response;
      if (statusCode === 200) {
        // 提交数据到 state
        commit("SET_RECOMMEND_CAROUSELS", {
          recommendCarousels: data.items,
        });
      }
      return response;
    } catch (error) {
      console.error(`异步获取推荐轮播图`, error);
      return false;
    }
  },

  /**
   * 异步获取推荐\猜你喜欢 直播
   */
  async fetchRecommendedLive({ commit }, params) {
    try {
      const response = await getRecommendedLives(params);
      const { statusCode, data } = response;
      if (statusCode === 200) {
        const { lives, strategy_streamers } = data;
        commit("SET_RECOMMENDED_LIVE", {
          recommendedLive: lives,
        });
        commit("SET_RECOMMENDED_ITEMS", {
          recommendedItems: strategy_streamers,
        });
      }
    } catch (error) {
      console.error(`异步获取推荐直播`, error);
    }
  },

  /**
   * 异步获取热门主播
   */
  async fetchPopularStreamers({ commit }, params) {
    try {
      const response = await getStreamers(params);
      // 提交数据到 state
      commit("SET_POPULAR_STREAMERS", {
        popularStreamers: response.data.items,
      });
      return true;
    } catch (error) {
      console.error(`异步获取热门主播`, error);
      return false;
    }
  },

  /**
   * 异步获取 正在直播间 列表数据
   */
  async fetchBeBingLives(
    { commit, state },
    { isLoadMore = false, language_id, country_id }
  ) {
    try {
      const currentRequest = ++state.latestRequest;
      const response = await getLives({
        ...state.BeBingLives.getLivesParams,
        page: isLoadMore ? state.BeBingLives.getLivesParams.page + 1 : 1,
        country_id,
        language_id,
      });
      const { data, statusCode } = response;
      if (statusCode === 200) {
        if (currentRequest === state.latestRequest) {
          // 提交数据到 state
          commit("SET_BEBINF_LIVES", {
            BeBingLives: data,
            isLoadMore,
          });
          const { page, pages } = data;
          return { page, pages };
        }
      } else {
        return {};
      }
    } catch (error) {
      console.error(`异步获取 正在直播间 列表数据`, error);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
