import Vue from "vue";
import VueRouter from "vue-router";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import LiveRoom from "@/views/LiveRoom/Index";
import { checkSystemVersion, judegeArea } from "@/utils/tools";
import { checkClientTokenRole } from "@/utils/authCookie";
import { userStatistics } from "@/services/api/User";
Vue.use(VueRouter);
const routes = [
  {
    // 登录注册页
    path: "/LogInRegister",
    name: "LogInRegister",
    component: () => import("@/views/LogInRegister/Index"),
  },
  {
    // 快捷注册
    path: "/QuickRegister",
    name: "QuickRegister",
    component: () => import("@/views/QuickRegister/Index"),
  },
  {
    // 首页
    path: "/",
    name: "Home",
    component: () => import("@/views/Home/Index"),
  },
  {
    // 首页
    path: "/Home",
    redirect: "/",
  },
  {
    // 直播列表页
    path: "/MoreLives",
    name: "MoreLives",
    component: () => import("@/views/Home/MoreLives"),
  },
  {
    // 主播列表页
    path: "/MoreStreamers",
    name: "MoreStreamers",
    component: () => import("@/views/Home/MoreStreamers"),
  },
  {
    // 直播间
    path: "/LiveRoom",
    name: "LiveRoom",
    // 直播间不用路由懒加载 否则初次加载有明显延迟
    component: LiveRoom,
  },
  {
    // 主播简介页
    path: "/AnchorIntroduction",
    name: "AnchorIntroduction",
    component: () => import("@/views/LiveRoom/AnchorIntroduction"),
  },
  {
    // 主播照片墙页面
    path: "/AnchorPhotoWall",
    name: "AnchorPhotoWall",
    component: () => import("@/views/LiveRoom/AnchorPhotoWall"),
  },
  {
    // 比赛（列表）页
    path: "/Competition",
    name: "Competition",
    component: () => import("@/views/Competition/Index"),
    // component: () => import("@/views/Competition/newIndex"),
  },
  {
    // 比赛详情页面
    path: "/CompetitionDetails",
    name: "CompetitionDetails",
    component: () => import("@/views/Competition/CompetitionDetails"),
  },
  {
    // 指数详情页面
    path: "/OddsDetails/:selectedClassify",
    name: "OddsDetails",
    component: () => import("@/views/Competition/OddsDetails"),
  },
  {
    // 聊天（列表）页
    path: "/Chats",
    name: "Chats",
    // component: () => import("@/views/Chats/Index"),
    component: () => import("@/views/Chats/newIndex"),
    meta: {
      requiresAuth: true, // 需要登录才能访问的路由
    },
  },
  {
    // 聊天（列表）页
    path: "/Contacts",
    name: "Contacts",
    component: () => import("@/views/AddressBook/Index.vue"),
    meta: {
      requiresAuth: true, // 需要登录才能访问的路由
    },
  },
  {
    path: "/chatInterface",
    name: "chatInterface",
    component: () => import("@/components/ChatInterface/IndexNew.vue"),
    props: true,
  },
  // FIXME:推荐页
  {
    path: "/Recommend",
    name: "Recommend",
    component: () => import("@/views/Recommend/Index"),
  },
  // FIXME: 注释入口
  // {
  //   // 发布推荐列表
  //   path: "/RecommendPublish",
  //   name: "RecommendPublish",
  //   component: () => import("@/views/RecommendPublish/Index"),
  // },
  // {
  //   // 发布推荐详情
  //   path: "/RecommendPublishDetail",
  //   name: "RecommendPublishDetail",
  //   component: () => import("@/views/RecommendPublishDetail/Index"),
  // },
  // {
  //   // 我的推荐详情
  //   path: "/MyRecommendDetail",
  //   name: "MyRecommendDetail",
  //   component: () => import("@/views/MyRecommendDetail/Index"),
  // },
  {
    // 通讯录-群组页
    path: "/GroupChat",
    name: "GroupChat",
    component: () => import("@/views/AddressBook/GroupChat/Index"),
  },
  {
    // 群详情
    path: "/GroupDetails",
    name: "GroupDetails",
    component: () => import("@/views/AddressBook/GroupChat/GroupDetails"),
  },
  {
    // 群公告
    path: "/GroupIntroduction",
    name: "GroupIntroduction",
    component: () => import("@/views/AddressBook/GroupChat/GroupIntroduction"),
  },
  {
    // 群公告
    path: "/GroupAnnouncement",
    name: "GroupAnnouncement",
    component: () => import("@/views/AddressBook/GroupChat/GroupAnnouncement"),
  },

  {
    // 群成员
    path: "/GroupMembers",
    name: "GroupMembers",
    component: () => import("@/views/AddressBook/GroupChat/GroupMembers"),
  },
  {
    // 群管理
    path: "/GroupAdministrator",
    name: "GroupAdministrator",
    component: () => import("@/views/AddressBook/GroupChat/GroupAdministrator"),
  },
  {
    // 群文件
    path: "/GroupFile",
    name: "GroupFile",
    component: () => import("@/views/AddressBook/GroupChat/GroupFile"),
  },
  {
    // 个人中心 页面
    path: "/PersonalCenter",
    name: "PersonalCenter",
    // component: () => import('@/views/PersonalCenter/Index'),  // 原版面
    component: () => import("@/views/PersonalCenter/Index"), // 新版面
  },
  {
    // 个人中心-隐私协议
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PersonalCenter/PrivacyAgreement"),
  },
  // 服务条款
  {
    path: "/TermsofService",
    name: "TermsofService",
    component: () => import("@/views/PersonalCenter/TermsofService"),
  },
  {
    // 个人中心-用户协议
    path: "/UserPolicy",
    name: "UserPolicy",
    component: () => import("@/views/PersonalCenter/UserAgreement"),
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: () => import("@/views/PersonalCenter/MySubscription"),
  },
  {
    path: "/security",
    name: "security",
    component: () => import("@/views/PersonalCenter/AccountSecurity/Index"),
  },
  {
    path: "/Feedback",
    name: "Feedback",
    component: () => import("@/views/PersonalCenter/FeedBack"),
  },
  {
    path: "/DeviceInfo",
    name: "DeviceInfo",
    component: () => import("@/views/PersonalCenter/DeviceInfo"),
  },
  {
    path: "/AboutUs",
    name: "AboutUs",
    component: () => import("@/views/PersonalCenter/AboutUs"),
  },
  // 更改邮箱
  {
    path: "/BindEmail",
    name: "BindEmail",
    component: () => import("@/views/PersonalCenter/AccountSecurity/BindEmail"),
  },
  // 更改手机号
  {
    path: "/BindPhone",
    name: "BindPhone",
    component: () => import("@/views/PersonalCenter/AccountSecurity/BindPhone"),
  },
  // 更改密码
  {
    path: "/ChangePassword",
    name: "ChangePassword",
    component: () =>
      import("@/views/PersonalCenter/AccountSecurity/ChangePassword"),
  },
  {
    path: "/ChangeName",
    name: "ChangeName",
    component: () => import("@/views/PersonalCenter/EditName"),
  },
  // FIXME: 推荐详情
  // {
  //   path: "/RecommendDetail",
  //   name: "RecommendDetail",
  //   component: () => import("@/views/RecommendDetail/Index"),
  // },
  // 方案详情
  {
    path: "/SchemeDetails",
    name: "SchemeDetails",
    component: () => import("@/views/RecommendDetail/SchemeDetails/Index"),
  },
  // 下载页
  {
    path: "/Download",
    name: "Download",
    component: () => import("@/views/Download/Index"),
  },
  {
    path: "/PersonalInfo",
    name: "PersonalInfo",
    component: () => import("@/views/PersonalCenter/PersonalInfo"),
  },
  // // 测试页面
  // {
  //   path: "/TestView",
  //   name: "TestView",
  //   component: () => import("@/views/TestView"),
  // },

  // pc下载页
  {
    path: "/pcDownload",
    name: "pcDownload",
    component: () => import("@/views/Download/pcIndex"),
  },
  {
    path: "/Setting",
    name: "Setting",
    component: () => import("@/views/Setting/Index"),
  },
  // 赚钱
  {
    path: "/Money",
    name: "Money",
    component: () => import("@/views/Money/Index.vue"),
  },
  // 积分明细
  {
    path: "/PointsDetails",
    name: "PointsDetails",
    component: () =>
      import("@/views/Money/PointsDetails/PointsDetails.vue"),
  },
  // 活动详情
  {
    path: "/ActivityDetails",
    name: "ActivityDetails",
    component: () => import("@/views/Money/ActivityDetails/Index"),
  },
  // 积分中心
  // {
  //   path: "/IntegralSystem",
  //   name: "IntegralSystem",
  //   component: () =>
  //     import("@/views/PersonalCenter/IntegralSystem/IntegralSystem.vue"),
  // },
  // AI聊天
  {
    path: "/AIChatInterface",
    name: "AIChatInterface",
    component: () => import("@/components/AIChatInterface/Index"),
  },
  // 限制访问页面
  {
    path: "/LimitAccess",
    name: "LimitAccess",
    component: () => import("@/views/LimitAccess/Index"),
  },
];

let push = VueRouter.prototype.push;
let replace = VueRouter.prototype.replace;
//重写VueRouter.prototype原型对象的push|replace方法
VueRouter.prototype.push = function (location) {
  //调用push方法且篡改push方法内部this指向->vueRouter类的实例
  push.call(
    this,
    location,
    () => {},
    () => {}
  );
};
VueRouter.prototype.replace = function (location) {
  //调用push方法且篡改push方法内部this指向->vueRouter类的实例
  replace.apply(this, [location, () => {}, () => {}]);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// let refreshTimer = null; // 定时器引用

// 全局守卫
router.beforeEach(async (to, from, next) => {
  // 判断地区是否受限
  if (process.env.VUE_APP_ACCESSS_LINK === "true") {
    const toLimit = (data) => {
      if (data.loc === "CN") {
        next("/LimitAccess");
      }
    };
    judegeArea((data) => {
      toLimit(data);
    });
  }

  const isLoggedIn = checkLoginStatus(); // 检查用户是否已登录
  const requiresAuth = to.meta.requiresAuth; // 判断当前路由是否需要登录
  checkSystemVersion(); // 检查系统版本
  const browser_signature = localStorage.getItem("browser_signature");   //浏览器指纹
  if (!browser_signature) {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const signature = result.visitorId;
    if (signature) {
      const timestamp = new Date().getTime()
      try {
        const res = await userStatistics({ timestamp: Math.floor(timestamp / 1000), browser_signature: signature });
        if (res) {
          localStorage.setItem("browser_signature", signature);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
  // 清除之前的定时器
  // clearInterval(refreshTimer);
  if (requiresAuth && !isLoggedIn) {
    // 需要登录但用户未登录，则重定向到登录/注册路由
    next("/LogInRegister");
  } else {
    next();
  }
});

// 登录状态检查
function checkLoginStatus() {
  if (checkClientTokenRole() === "user") {
    // 判断已登录
    // 返回登录状态
    return true;
  } else {
    return false;
  }
}

export default router;
