/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "ຂໍ້ຕົກລົງຜູ້ໃຊ້",
  privacyPolicy: "ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ",
  termsOfService: "ເງື່ອນໄຂການໃຫ້ບໍລິການ",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "ຍັງບໍ່ໄດ້ເລີ່ມຕົ້ນເທື່ອ",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "ອອກອາກາດແລ້ວ",
  watch: "ໄປເບິ່ງ",
  follow: "ຈຸດ​ສຸມ",
  following: "ຕິດຕາມຢູ່ແລ້ວ",
  subscribe: "ການສະໝັກໃຊ້",
  streamerSchedule: "ຕາຕະລາງ Anchor",
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
  unfollowPrompt: "ທ່ານແນ່ໃຈບໍ່ທີ່ຈະເຊົາຕິດຕາມ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  hours: "ຊົ່ວໂມງ",
  mins: "ນາທີ",
};

const AnchorInformation = {
  recommendedLiveStreams: "ແນະນຳການຖ່າຍທອດສົດ",
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
};

const BasketballScoreIndex = {
  details: "ລາຍລະອຽດ",
  over: "ເກີນ",
  pointsDifference: "ຈຸດແຕກຕ່າງ",
  totalScore: "ຄະ​ແນນ​ທັງ​ຫມົດ​",
  live: "LIVE",
  unSubscribePrompt: "ທ່ານແນ່ໃຈບໍ່ວ່າຍົກເລີກການສະໝັກສະມາຊິກ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
};

const BasketballSmallBig = {
  details: "ລາຍລະອຽດ",
  over: "ເກີນ",
  pointsDifference: "ຈຸດແຕກຕ່າງ",
  totalScore: "ຄະ​ແນນ​ທັງ​ຫມົດ​",
  live: "LIVE",
  unSubscribePrompt: "ທ່ານແນ່ໃຈບໍ່ວ່າຍົກເລີກການສະໝັກສະມາຊິກ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
  overIndex: "ໃຫຍ່",
  underIndex: "ຂະຫນາດນ້ອຍ",
};

const BindPhoneDialog = {
  bindPhonePrompt: "ເພື່ອຄວາມປອດໄພຂອງບັນຊີຂອງທ່ານ, ກະລຸນາຜູກມັດເບີໂທລະສັບຂອງເຈົ້າເພື່ອໃຫ້ພວກເຮົາສາມາດໃຫ້ບໍລິການທີ່ດີກວ່າແກ່ເຈົ້າ.",
  goBind: "ໄປທີ່ການຜູກມັດ",
};

const BottomNavigationBar = {
  home: "ໜ້າ",
  matches: "ການແຂ່ງຂັນ",
  recommend: "ແນະນຳ",
  chat: "ສົນທະນາ",
  personal: "ສ່ວນຕົວ",
  discovery: "ຄົ້ນພົບ",
  money: "ຫາ​ເງິນ",
};

const ChatInterface = {
  noCsPrompt: "ບໍ່ສາມາດສົນທະນາໄດ້ໃນຂະນະນີ້",
  uploadRulePrompt: "ກະລຸນາອັບໂຫລດຮູບພາບໃນຮູບແບບ jpg, jpeg, png",
  uploadSizePrompt: "ຂະໜາດຮູບບໍ່ສາມາດເກີນ 10M",
  sendError: "ການ​ສົ່ງ​ລົ້ມ​ເຫຼວ",
  picSendError: "ການສົ່ງຮູບລົ້ມເຫລວ",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
  bannedSendPrompt: "ເຈົ້າໄດ້ຖືກຫ້າມ",
};

const ChatList = {
  noNewPrompt: "ຍັງບໍ່ມີຂ່າວເທື່ອ",
  logInPrompt: "ເຂົ້າສູ່ລະບົບເພື່ອເບິ່ງເນື້ອໃນ, ກະລຸນາເຂົ້າສູ່ລະບົບກ່ອນ",
  logIn: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
  group: "ກຸ່ມ",
};

const ChatListItem = {
  withdrawMessage: "ຖອນຂໍ້ຄວາມ",
  bannedPrompt: "ຫ້າມ",
  unBanPrompt: "ຍົກເລີກການຫ້າມ",
  joinGroupPrompt: "ເຂົ້າຮ່ວມກຸ່ມ",
  leftGroupPrompt: "ອອກຈາກກຸ່ມ",
  picture: "ຮູບ",
  link: "ເຊື່ອມຕໍ່",
  emoji: "emoticons ສັດ",
  addedFriendPrompt: "ເພີ່ມເປັນໝູ່ແລ້ວ",
};

const CompetitionList = {
  logInPrompt: "ເຂົ້າສູ່ລະບົບເພື່ອເບິ່ງເນື້ອໃນ, ກະລຸນາເຂົ້າສູ່ລະບົບກ່ອນ",
  logIn: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
  today: "ມື້​ນີ້",
  Monday: "ວັນຈັນ",
  Tuesday: "ວັນອັງຄານ",
  Wednesday: "ວັນພຸດ",
  Thursday: "ວັນພະຫັດ",
  Friday: "ວັນ​ສຸກ",
  Saturday: "ວັນເສົາ",
  Sunday: "ວັນອາທິດ",
  noGames: "ບໍ່ມີເກມເທື່ອ",
  toCollapse: "ຄລິກເພື່ອຫຍໍ້ລົງ",
  toExpand: "ຄລິກເພື່ອຂະຫຍາຍ"
};

const CompetitionListItem = {
  details: "ລາຍລະອຽດ",
  unSubscribePrompt: "ທ່ານແນ່ໃຈບໍ່ວ່າຍົກເລີກການສະໝັກສະມາຊິກ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
};

const CompetitionMode = {
  matchMode: "ຮູບແບບການແຂ່ງຂັນ",
  confirm: "ຢືນຢັນ",
  cancel: "ຍົກເລີກ",
};

const EmojiCom = {
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
};

const ExponentCom = {
  company: "ບໍລິສັດ",
  initial: "ເບື້ອງຕົ້ນ",
  ongoing: "ທັນທີ",
  preMatch: "ກ່ອນເກມ",
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
  handicap: "ພິການ",
  WinDrawLose: "ຊະນະ, ແຕ້ມ, ສູນເສຍ",
  totalGoals: "ເປົ້າ​ຫມາຍ​ທັງ​ຫມົດ​",
  cornerKick: "ເຂົາ",
  pleaseSelect: "ກະລຸນາເລືອກ",
  confirm: "ຢືນຢັນ",
  cancel: "ຍົກເລີກ",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "ປັກໝຸດໃສ່ 'Stream chat'",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "ຈຸດ​ສຸມ",
  following: "ຕິດຕາມຢູ່ແລ້ວ",
  chat: "ສົນທະນາ",
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
  unfollowPrompt: "ທ່ານແນ່ໃຈບໍ່ທີ່ຈະເຊົາຕິດຕາມ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
};

const FootballCornerIndex = {
  details: "ລາຍລະອຽດ",
  over: "ເກີນ",
  FH: "HT",
  cornerKick: "ເຂົາ",
  unSubscribePrompt: "ທ່ານແນ່ໃຈບໍ່ວ່າຍົກເລີກການສະໝັກສະມາຊິກ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
};

const FootballScoreIndex = {
  details: "ລາຍລະອຽດ",
  over: "ເກີນ",
  FH: "HT",
  cornerKick: "ເຂົາ",
  live: "LIVE",
  unSubscribePrompt: "ທ່ານແນ່ໃຈບໍ່ວ່າຍົກເລີກການສະໝັກສະມາຊິກ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
};

const FootballScoreItem = {
  details: "ລາຍລະອຽດ",
  over: "ເກີນ",
  FH: "HT",
  cornerKick: "ເຂົາ",
  live: "LIVE",
  unSubscribePrompt: "ທ່ານແນ່ໃຈບໍ່ວ່າຍົກເລີກການສະໝັກສະມາຊິກ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
};

const GoalToast = {
  goal: "ເປົ້າໝາຍ",
};

const LiveRecommend = {
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "ໄຊຊະນະ ຫຼື ໄຊຊະນະ",
};

const MatchCardList = {
  details: "ລາຍລະອຽດ",
};

const MatchStatus = {
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
};

const MoreStreams = {
  switchLines: "ສະຫຼັບສາຍ",
  cancel: "ຍົກເລີກ",
  currentStreamer: "ສະມໍປະຈຸບັນ",
  live: "LIVE",
  offLine: "ຍັງບໍ່ໄດ້ເລີ່ມຕົ້ນເທື່ອ",
  followersCount: "ຈຸດ​ສຸມ",
  otherStreamersPrompt: "ມີສະມໍອື່ນກຳລັງອອກອາກາດເກມ",
  watch: "ໄປເບິ່ງ",
};

const NewMatchStatus = {
  team: "ທີມງານ",
  halfTimeScore: "ຄະແນນເຄິ່ງເວລາ",
  shotOnTarget: "ຍິງໃສ່ເປົ້າໝາຍ",
  missedShot: "ພາດເປົ້າໝາຍ",
  attack: "ການໂຈມຕີ",
  dangerousAttack: "ການໂຈມຕີອັນຕະລາຍ",
  number3PointGoals: "ຈໍາ​ນວນ​ຂອງ​ເປົ້າ​ຫມາຍ 3 ຄະ​ແນນ​",
  number2PointGoals: "ຈໍາ​ນວນ​ຂອງ​ເປົ້າ​ຫມາຍ 2 ຄະ​ແນນ​",
  penaltyKickGoals: "ເຕະຈຸດໂທດ",
  freeThrowPercentage: "ເປີເຊັນຖິ້ມຟຣີ",
  numberOfPausesRemaining: "ຈຳນວນການຢຸດຊົ່ວຄາວທີ່ຍັງເຫຼືອ",
  numberOfFouls: "ຈໍານວນຂອງ fouls",
  totalNumberOfPauses: "ການຢຸດຊົ່ວຄາວທັງໝົດ",
  threePointShot: "ຍິງ 3 ຈຸດ",
  twoPointShot: "ຍິງ 2 ຈຸດ",
  shoot: "ຫນໍ່ໄມ້ສ່ວນຫຼາຍ",
  penalty: "ການລົງໂທດ",
  firstHalf: "ເຄິ່ງທໍາອິດ",
  secondHalf: "ເຄິ່ງທີ່ສອງ",
  redCard: "ບັດແດງ",
  yellowCard: "ບັດ​ເຫຼືອງ",
};

const NoData = {
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
};

const NoticeMessage = {
  addedFriendPrompt: "ເພີ່ມເປັນໝູ່ແລ້ວ",
  withdrawMessage: "ຖອນຂໍ້ຄວາມ",
  withdrawMessageByAdmin: "ຂໍ້ຄວາມຖືກຖອນອອກໂດຍຜູ້ເບິ່ງແຍງລະບົບ",
  joinGroupPrompt: "ເຂົ້າຮ່ວມກຸ່ມ",
  leftGroupPrompt: "ອອກຈາກກຸ່ມ",
  removeGroupPrompt: "ລຶບອອກຈາກກຸ່ມໂດຍຜູ້ເບິ່ງແຍງລະບົບ",
  bannedPrompt: "ຫ້າມ",
  unBanPrompt: "ຍົກເລີກການຫ້າມ",
};

const OddsStatisticsList = {
  initial: "ເບື້ອງຕົ້ນ",
  ongoing: "ທັນທີ",
  preMatch: "ກ່ອນເກມ",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "ມູນຄ່າສູງສຸດ",
  min: "ມູນຄ່າຕໍ່າສຸດ",
  average: "ຄ່າສະເລ່ຍ",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
};

const RecommendCompetitions = {
  today: "ມື້​ນີ້",
  winsInARow: "ເຖິງແມ່ນວ່າຢູ່ໃນກາງ",
  noLivePrompt: "ບໍ່ມີການຖ່າຍທອດສົດເທື່ອ",
};

const RecommendStreamerList = {
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "ຕີຂອງອາທິດ",
  victoryOrDefeat: "ໄຊຊະນະ ຫຼື ໄຊຊະນະ",
  check: "ກວດສອບ",
  chat: "ສົນທະນາ",
  quarterlyProfit: "ກໍາໄລປະຈໍາໄຕມາດ",
};

const RecommendStreamers = {
  recommendedByExperts: "ແນະນໍາໂດຍຜູ້ຊ່ຽວຊານ",
  winsInARow: "ເຖິງແມ່ນວ່າຢູ່ໃນກາງ",
  changeBatch: "ປ່ຽນຊຸດ",
  follow: "ຈຸດ​ສຸມ",
  following: "ຕິດຕາມຢູ່ແລ້ວ",
  unFollowing: "ປົດລັອກ",
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
  unfollowPrompt: "ທ່ານແນ່ໃຈບໍ່ທີ່ຈະເຊົາຕິດຕາມ?",
};

const RecordChart = {
  record: "ບັນທຶກ",
  allWaysToPlay: "ວິ​ທີ​ການ​ຫຼິ້ນ​ທັງ​ຫມົດ​",
  fromFarToNear: "ຈາກໄກຫາໃກ້",
  lastTenGames: "10 ເກມຫຼ້າສຸດ",
  longestConsecutiveHit: "ຕີຕິດຕໍ່ກັນດົນທີ່ສຸດ",
  hitRate: "ອັດຕາການຕີ",
  currentPropmt: "ເກມທີ່ຜ່ານມາ",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "ລົ້ມເຫລວໃນການໄດ້ຮັບຂໍ້ມູນຜູ້ໃຊ້",
  getAreaAndLanguageErrorPrompt: "ລົ້ມເຫລວໃນການໄດ້ຮັບພາສາພາກພື້ນ",
};

const ScheduleCom = {
  startTime: "ເວລາເລີ່ມຕົ້ນ",
};

const SchemeCard = {
  planList: "ບັນຊີລາຍຊື່ແຜນການ",
  release: "ປ່ອຍ",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "ຮັບປະກັນການຊົດເຊີຍ",
  free: "ຟຣີ",
  toll: "ໂທ",
  victoryOrDefeat: "ໄຊຊະນະ ຫຼື ໄຊຊະນະ",
};

const SquareCom = {
  watchPrompt: "ປະຊາຊົນກໍາລັງເບິ່ງ",
  startedPrompt: "ເລີ່ມຕົ້ນນາທີກ່ອນ",
  chat: "ສົນທະນາ",
  sendMessageNeedLoginPrompt: "ກະລຸນາເຂົ້າສູ່ລະບົບເພື່ອສົ່ງຂໍ້ຄວາມ",
  assistant: "ຜູ້​ບໍ​ລິ​ຫານ",
  audience: "ຜູ້ໃຊ້",
  newMessage: "ຂ່າວໃໝ່",
  confirmSend: "ຢືນຢັນການສົ່ງ",
  cancelSend: "ຍົກເລີກ",
};

const StatisticalChart = {
  hitRate: "ອັດຕາການຕີ",
};

const SwitchLines = {
  switchLines: "ສະຫຼັບສາຍ",
  cancel: "ຍົກເລີກ",
  currentStreamer: "ສະມໍປະຈຸບັນ",
  live: "LIVE",
  offLine: "ຍັງບໍ່ໄດ້ເລີ່ມຕົ້ນເທື່ອ",
  followersCount: "ຈຸດ​ສຸມ",
  otherStreamersPrompt: "ມີສະມໍອື່ນກຳລັງອອກອາກາດເກມ",
  watch: "ໄປເບິ່ງ",
  LINE: "ສາຍ",
  VIP: "ວີໄອພີ",
  OFFICIAL: "ທາງການ",
};

const VideoCom = {
  videoErrorPrompt: "ສັນຍານບໍ່ດີຫຼາຍ, ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ.",
  clickRetryPrompt: "ຄລິກເພື່ອລອງອີກຄັ້ງ",
};

const WelcomeCard = {
  join: "ເຂົ້າຮ່ວມກຸ່ມ",
};

//utils
const tools = {
  yesterday: "ມື້ວານນີ້",
  addedFriendPrompt: "ເພີ່ມເປັນໝູ່ແລ້ວ",
  withdrawMessage: "ຖອນຂໍ້ຄວາມ",
  joinGroupPrompt: "ເຂົ້າຮ່ວມກຸ່ມ",
  leftGroupPrompt: "ອອກຈາກກຸ່ມ",
  removeGroupPrompt: "ລຶບອອກຈາກກຸ່ມໂດຍຜູ້ເບິ່ງແຍງລະບົບ",
  bannedPrompt: "ຫ້າມ",
  unBanPrompt: "ຍົກເລີກການຫ້າມ",
};

//页面
const AddressBook = {
  search: "ຊອກຫາ",
  addFriend: "ເພີ່ມເພື່ອນ",
  myGroupChat: "ກຸ່ມສົນທະນາຂອງຂ້ອຍ",
  friends: "ເພື່ອນ",
  delete: "ລຶບ",
  noFriendsPrompt: "ບໍ່ມີໝູ່ເທື່ອ",
  nameAndIdSearch: "ໃສ່ຊື່, ID",
  searchResults: "ຊອກຫາຜົນໄດ້ຮັບ",
  notFoundPrompt: "ບໍ່ພົບຜູ້ໃຊ້",
  add: "ຕື່ມໃສ່",
  deleteFriendPrompt: "ເຈົ້າແນ່ໃຈບໍ່ວ່າລຶບໝູ່?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  addFriendSuccessPrompt: "ເພີ່ມໝູ່ສຳເລັດແລ້ວ",
  deleteFriendSuccessPrompt: "ລຶບໝູ່ສຳເລັດແລ້ວ",
  InputSearchPrompt: "ກະລຸນາໃສ່ເນື້ອໃນ",
};

const GroupChat = {
  groupChat: "ສົນທະນາກຸ່ມ",
  groupSearch: "ໃສ່ຊື່ກຸ່ມສົນທະນາ",
  noGroupPrompt: "ຍັງບໍ່ມີການສົນທະນາກຸ່ມເທື່ອ",
  chat: "ສົນທະນາ",
  InputSearchPrompt: "ກະລຸນາໃສ່ເນື້ອໃນ",
};

const GroupAdministrator = {
  administrator: "ຜູ້​ບໍ​ລິ​ຫານ",
  groupOwner: "ພຣະຜູ້ເປັນເຈົ້າ",
};

const GroupAnnouncement = {
  groupAnnouncement: "ປະກາດກຸ່ມ",
  groupOwner: "ພຣະຜູ້ເປັນເຈົ້າ",
  administrator: "ຜູ້​ບໍ​ລິ​ຫານ",
};

const GroupDetail = {
  groupDetail: "ລາຍລະອຽດກຸ່ມ",
  groupMembers: "ສະມາຊິກກຸ່ມ",
  groupName: "ຊື່ກຸ່ມ",
  groupDescription: "ລາຍລະອຽດກຸ່ມ",
  groupOwner: "ພຣະຜູ້ເປັນເຈົ້າ",
  administrator: "ຜູ້​ບໍ​ລິ​ຫານ",
  administratorList: "ລາຍຊື່ຜູ້ບໍລິຫານ",
  groupAnnouncement: "ປະກາດກຸ່ມ",
  groupFiles: "ໄຟລ໌ກຸ່ມ",
  exitGroup: "ອອກຈາກກຸ່ມ",
  exitGroupPrompt: "ເຈົ້າແນ່ໃຈບໍ່ວ່າອອກຈາກກຸ່ມ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  leftGroupSuccessPrompt: "ອອກຈາກກຸ່ມສຳເລັດແລ້ວ",
};

const GroupFile = {
  groupFiles: "ໄຟລ໌ກຸ່ມ",
  fileSearch: "ໃສ່ຊື່ໄຟລ໌",
  today: "ມື້​ນີ້",
  multimedia: "ມັນຕິມີເດຍ",
  file: "ເອກະສານ",
  noFilesPrompt: "ບໍ່ມີໄຟລ໌ເທື່ອ",
};

const GroupMembers = {
  groupMembers: "ສະມາຊິກກຸ່ມ",
  nameAndIdSearch: "ໃສ່ຊື່, ID",
};

const Chat = {
  chat: "ສົນທະນາ",
  nameAndIdSearch: "ໃສ່ຊື່, ID",
  contacts: "ປື້ມ​ທີ່​ຢູ່",
  InputSearchPrompt: "ກະລຸນາໃສ່ເນື້ອໃນ",
  deleteContactPrompt: "ທ່ານແນ່ໃຈບໍ່ວ່າລຶບການສົນທະນາ?",
};

const Competition = {
  all: "All",
  ongoing: "ທັນທີ",
  popular: "ນິຍົມ",
  liveCompetition: "ຖ່າຍທອດສົດ",
  schedule: "ຕາຕະລາງ",
  results: "ຜົນໄດ້ຮັບ",
  subscribed: "ສະໝັກແລ້ວ",
  goal: "ເປົ້າໝາຍ",
  today: "ມື້​ນີ້",
  Monday: "ວັນຈັນ",
  Tuesday: "ວັນອັງຄານ",
  Wednesday: "ວັນພຸດ",
  Thursday: "ວັນພະຫັດ",
  Friday: "ວັນ​ສຸກ",
  Saturday: "ວັນເສົາ",
  Sunday: "ວັນອາທິດ",
};

const CompetitionDetails = {
  subscribe: "ການສະໝັກໃຊ້",
  subscribed: "ສະໝັກແລ້ວ",
  matchStreamerPrompt: "ສະມໍເກມສົດ",
  live: "LIVE",
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
  noStreamersPrompt: "ປະຈຸບັນນີ້ຍັງບໍ່ທັນມີເຈົ້າພາບເຜີຍແຜ່ເກມນີ້ເທື່ອ",
  alreadyStreamerPrompt: "ເຈົ້າພາບໄດ້ເລີ່ມອອກອາກາດການແຂ່ງຂັນ ເຈົ້າຢາກໄປເບິ່ງມັນບໍ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  liveStream: "ຖ່າຍທອດສົດ",
  matchStatus: "ສະຖານະທີ່ກົງກັນ",
  odds: "Odds",
  unSubscribePrompt: "ທ່ານແນ່ໃຈບໍ່ວ່າຍົກເລີກການສະໝັກສະມາຊິກ?",
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
};

const OddsDetails = {
  company: "ບໍລິສັດ",
  handicap: "ພິການ",
  WinDrawLose: "ຊະນະ, ແຕ້ມ, ສູນເສຍ",
  totalGoals: "ເປົ້າ​ຫມາຍ​ທັງ​ຫມົດ​",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "ຫຼາຍ",
  Un: "ບໍ່ຫຼາຍປານໃດ",
};

const Home = {
  logIn: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
  signUp: "ລົງທະບຽນ",
  popularMatches: "ການແຂ່ງຂັນທີ່ນິຍົມ",
  more: "ເພີ່ມເຕີມ",
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
  currentlyLive: "ຖ່າຍທອດສົດ",
  popularStreamers: "ສະມໍຍອດນິຍົມ",
  thinkYouLike: "ເຈົ້າອາດຈະມັກ",
  loadMore: "ໂຫຼດເພີ່ມເຕີມ",
  noMoreDataPrompt: "ຍັງບໍ່ມີຂໍ້ມູນຕື່ມອີກ",
  addDeskPrompt: "ເພີ່ມໃສ່ desktop",
  clikToolPrompt: "ໃຫ້ຄລິກໃສ່ແຖບເຄື່ອງມືຂ້າງລຸ່ມນີ້",
  andSeletcPrompt: "ແລະເລືອກ",
  addToScreenPrompt: "ພວກເຮົາຕ້ອງການເພີ່ມໃສ່ໜ້າຈໍຫຼັກຂອງທ່ານເພື່ອໃຫ້ບໍລິການທີ່ດີກວ່າ",
  addToScreen: "ເພີ່ມໃສ່ໜ້າຈໍຫຼັກ",
};

const MoreLives = {
  noDataPrompt: "ບໍ່​ມີ​ຂໍ້​ມູນ",
};

const MoreStreamers = {
  streamer: "ສະມໍ",
  streamerSearch: "ໃສ່ຊື່ສະມໍ ແລະ ID",
  all: "All",
  notFollowing: "ບໍ່ໄດ້ຕິດຕາມ",
  following: "ຕິດຕາມຢູ່ແລ້ວ",
  live: "LIVE",
  offLine: "ຍັງບໍ່ໄດ້ເລີ່ມຕົ້ນເທື່ອ",
  followersCount: "ຈຸດ​ສຸມ",
  unfollowPrompt: "ທ່ານແນ່ໃຈບໍ່ທີ່ຈະເຊົາຕິດຕາມ?",
  yes: "ແມ່ນແລ້ວ",
  no: "ບໍ່",
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
  InputSearchPrompt: "ກະລຸນາໃສ່ເນື້ອໃນ",
  dataRequestErrorPrompt: "ຮັບຂໍ້ມູນບໍ່ສຳເລັດ",
};

const LiveRoom = {
  switchLines: "ສະຫຼັບສາຍ",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "Anchor ການສົນທະນາສ່ວນຕົວ",
  chat: "ສົນທະນາ",
  odds: "Odds",
  streamerInformation: "ຂໍ້ມູນສະມໍ",
  matchStatus: "ສະຖານະທີ່ກົງກັນ",
};

const AnchorIntroduction = {
  streamerInformation: "ຂໍ້ມູນສະມໍ",
  photosWall: "ຝາຮູບ",
  seeMore: "ເບິ່ງເພີ່ມເຕີມ",
  streamerPlan: "ແຜນການສະມໍ",
};

const AnchorPhoto = {
  photosWall: "ຝາຮູບ",
  noPhotosPrompt: "ຍັງບໍ່ມີຮູບອີກ",
};

const LogInRegister = {
  logIn: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
  signUp: "ລົງທະບຽນ",
  getAreaAndLanguageErrorPrompt: "ລົ້ມເຫລວໃນການໄດ້ຮັບພາສາພາກພື້ນ",
  quickRegisterFrequentPrompt: "ການຮ້ອງຂໍເລື້ອຍໆ, ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ",
};

const LoginFn = {
  phone: "ເບີ​ໂທລະ​ສັບ",
  email: "ຈົດໝາຍ",
  logInPassword: "ເຂົ້າສູ່ລະບົບລະຫັດຜ່ານ",
  rememberPassword: "ຈື່ລະຫັດຜ່ານ",
  forgotPassword: "ລືມລະຫັດຜ່ານ",
  logIn: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
  logInAgreement: "ໂດຍການເຂົ້າສູ່ລະບົບ, ທ່ານຕົກລົງເຫັນດີ",
  userAgreement: "ຂໍ້ຕົກລົງຜູ້ໃຊ້",
  and: "  ແລະ",
  privacyPolicy: "ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ",
  passwordRulePrompt: "ລະຫັດຜ່ານຕ້ອງປະກອບດ້ວຍຕົວພິມໃຫຍ່ ແລະຕົວພິມນ້ອຍ ແລະຕົວເລກ ແລະຕ້ອງມີຫຼາຍກວ່າ 8 ຕົວອັກສອນ",
  passwordEmptyPrompt: "ລະຫັດຜ່ານບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  emailRulePrompt: "ກະລຸນາໃສ່ຮູບແບບທີ່ຖືກຕ້ອງຂອງທີ່ຢູ່ອີເມວຂອງທ່ານ",
  emailEmptyPrompt: "ອີເມວບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  phoneRulePrompt: "ກະລຸນາໃສ່ເບີໂທລະສັບຂອງເຈົ້າໃນຮູບແບບທີ່ຖືກຕ້ອງ",
  phoneEmptyPrompt: "ເບີໂທລະສັບບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  phoneNum8: "ກະລຸນາໃສ່ເບີໂທລະສັບ 8 ຕົວເລກຂອງທ່ານ",
  phoneNum9To10: "ກະລຸນາໃສ່ເບີໂທລະສັບ 9 ຫາ 10 ຕົວເລກ",
  phoneNum9: "ກະລຸນາໃສ່ເບີໂທລະສັບ 9 ຕົວເລກ",
  enterErrorPrompt: "ການປ້ອນຂໍ້ມູນຜິດພາດ ຫຼືເຄືອຂ່າຍຜິດປົກກະຕິ",
  loginNoAgreePrompt: "ທ່ານຈໍາເປັນຕ້ອງຕົກລົງເຫັນດີກັບຂໍ້ຕົກລົງຜູ້ໃຊ້ແລະນະໂຍບາຍຄວາມເປັນສ່ວນຕົວເພື່ອເຂົ້າສູ່ລະບົບ",
  accountAndPassword: "ບັນຊີ",
  account: "ບັນຊີ",
  accountEmptyPrompt: "ບັນຊີບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  accountOrPasswordError: "ຊື່ຜູ້ໃຊ້ ຫຼືລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ",
};

const Register = {
  phone: "ເບີ​ໂທລະ​ສັບ",
  email: "ຈົດໝາຍ",
  code: "ລະຫັດຢືນຢັນ",
  getCode: "ໄດ້ຮັບລະຫັດຢືນຢັນ",
  logInPassword: "ເຂົ້າສູ່ລະບົບລະຫັດຜ່ານ",
  CS: "ບໍ​ລິ​ການ​ລູກ​ຄ້າ",
  signUp: "ລົງທະບຽນ",
  signUpAgreement: "ໂດຍການລົງທະບຽນ, ທ່ານຕົກລົງເຫັນດີ",
  userAgreement: "ຂໍ້ຕົກລົງຜູ້ໃຊ້",
  and: "ແລະ",
  privacyPolicy: "ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ",
  contactCSPrompt: "ເຈົ້າມີບັນຫາໃນການລົງທະບຽນບໍ? ຕິດຕໍ່ບໍລິການລູກຄ້າເພື່ອແກ້ໄຂບັນຫາສໍາລັບທ່ານ",
  contactCS: "ຕິດຕໍ່ບໍລິການລູກຄ້າ",
  passwordRulePrompt: "ລະຫັດຜ່ານຕ້ອງປະກອບດ້ວຍຕົວພິມໃຫຍ່ ແລະຕົວພິມນ້ອຍ ແລະຕົວເລກ ແລະຕ້ອງມີຫຼາຍກວ່າ 8 ຕົວອັກສອນ",
  passwordEmptyPrompt: "ລະຫັດຜ່ານບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  emailRulePrompt: "ກະລຸນາໃສ່ຮູບແບບທີ່ຖືກຕ້ອງຂອງທີ່ຢູ່ອີເມວຂອງທ່ານ",
  emailEmptyPrompt: "ອີເມວບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  phoneRulePrompt: "ກະລຸນາໃສ່ເບີໂທລະສັບຂອງເຈົ້າໃນຮູບແບບທີ່ຖືກຕ້ອງ",
  phoneEmptyPrompt: "ເບີໂທລະສັບບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  codeRulePrompt: "ລະຫັດຢືນຢັນແມ່ນ 6 ຕົວເລກ",
  codeEmptyPrompt: "ລະຫັດຢືນຢັນຕ້ອງຖືກຕື່ມໃສ່",
  phoneNum8: "ກະລຸນາໃສ່ເບີໂທລະສັບ 8 ຕົວເລກຂອງທ່ານ",
  phoneNum9To10: "ກະລຸນາໃສ່ເບີໂທລະສັບ 9 ຫາ 10 ຕົວເລກ",
  phoneNum9: "ກະລຸນາໃສ່ເບີໂທລະສັບ 9 ຕົວເລກ",
  phoneCodeSendSuccessPrompt: "ສົ່ງລະຫັດຢືນຢັນສຳເລັດແລ້ວ, ກະລຸນາກວດເບິ່ງຂໍ້ຄວາມໃນໂທລະສັບມືຖືຂອງທ່ານ",
  emailCodeSendSuccessPrompt: "ສົ່ງລະຫັດຢືນຢັນສຳເລັດແລ້ວ, ກະລຸນາກວດເບິ່ງອີເມວຂອງທ່ານ",
  phoneCodeSendErrorPrompt: "ລະຫັດຢືນຢັນບໍ່ຖືກສົ່ງ, ກະລຸນາກວດເບິ່ງວ່າມີເບີໂທລະສັບມືຖືຫຼືບໍ່",
  emailCodeSendErrorPrompt: "ລະຫັດຢືນຢັນບໍ່ຖືກສົ່ງ, ກະລຸນາກວດເບິ່ງວ່າອີເມວຂອງທ່ານມີຢູ່ຫຼືບໍ່",
  codeSendToMuchPrompt: "ສົ່ງຫຼາຍເທື່ອເກີນໄປໃນເວລາດຽວກັນ, ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ",
  phoneAlreadySignupPrompt: "ເບີໂທລະສັບນີ້ໄດ້ຖືກລົງທະບຽນ, ກະລຸນາເຂົ້າສູ່ລະບົບໂດຍກົງ",
  emailAlreadySignupPrompt: "ທີ່ຢູ່ອີເມວນີ້ໄດ້ຖືກລົງທະບຽນແລ້ວ, ກະລຸນາເຂົ້າສູ່ລະບົບໂດຍກົງ",
  signUpErrorPrompt: "ການລົງທະບຽນລົ້ມເຫລວ, ກະລຸນາກວດເບິ່ງວ່າຂໍ້ມູນຖືກຕ້ອງຫຼືບໍ່",
  enterErrorPrompt: "ການປ້ອນຂໍ້ມູນຜິດພາດ ຫຼືເຄືອຂ່າຍຜິດປົກກະຕິ",
  noAgreePrompt: "ທ່ານຈໍາເປັນຕ້ອງຕົກລົງເຫັນດີກັບຂໍ້ຕົກລົງຜູ້ໃຊ້ແລະນະໂຍບາຍຄວາມເປັນສ່ວນຕົວເພື່ອລົງທະບຽນ",
  regionAndLanguagePrompt: "ກະລຸນາເລືອກພາສາພາກພື້ນທີ່ແທ້ຈິງຂອງທ່ານ, ຖ້າບໍ່ດັ່ງນັ້ນມັນຈະສົ່ງຜົນກະທົບຕໍ່ການນໍາໃຊ້ຫນ້າທີ່",
  accountAndPassword: "ບັນຊີ",
  quickSignup: "ການລົງທະບຽນຄລິກດຽວ",
  accountEmptyPrompt: "ບັນຊີບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  accountRulePrompt: "ເລກບັນຊີຕ້ອງປະກອບດ້ວຍ 8-16 ຕົວອັກສອນ ແລະຕົວເລກ",
  accountAlreadySignupPrompt: "ບັນຊີນີ້ໄດ້ຖືກລົງທະບຽນແລ້ວ, ກະລຸນາເຂົ້າສູ່ລະບົບໂດຍກົງ",
  tryAgain: "ສົ່ງຄືນ",
};

const PersonalCenter = {
  logAndSign: "ເຂົ້າ​ສູ່​ລະ​ບົບ / ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​",
  logAndSignPrompt: "ປົດລັອກຄຸນສົມບັດສະເພາະ ແລະສິດທິປະໂຫຍດພິເສດສະເພາະດຽວນີ້",
  logOut: "ອອກ​ຈາກ​ລະ​ບົບ",
  myFollow: "ຈຸດສຸມຂອງຂ້ອຍ",
  mySubscription: "ການສະໝັກໃຊ້ຂອງຂ້ອຍ",
  accountSecurity: "ຄວາມປອດໄພບັນຊີ",
  feedback: "ຄໍາຕິຊົມ",
  deviceInformation: "ອຸປະກອນ",
  aboutUs: "ກ່ຽວ​ກັບ​ພວກ​ເຮົາ",
  coin: "ຄໍາ",
  moreServices: "ບໍລິການເພີ່ມເຕີມ",
  following: "ຕິດຕາມຢູ່ແລ້ວ",
  subscribed: "ສະໝັກແລ້ວ",
  browser: "ຕົວທ່ອງເວັບ",
  user: "ຜູ້ໃຊ້",
  profile: "ຂໍ້​ມູນ​ສ່ວນ​ຕົວ",
  nickname: "ຊື່ Nick",
  save: "ບັນທຶກ",
  change: "ປ່ຽນແປງ",
  nicknamePrompt: "ກະລຸນາໃສ່ຊື່ຫຼິ້ນ",
  avatar: "ຮູບແທນຕົວ",
  changeSuccessPrompt: "ການປ່ຽນແປງສຳເລັດແລ້ວ",
  ballAnimation: "ອະນິເມຊັນເປົ້າໝາຍ",
  ballVoice: "ຜົນ​ກະ​ທົບ​ສຽງ​ເປົ້າ​ຫມາຍ​",
  setting: "ຕັ້ງ​ຄ່າ",
};

const IntegralSystem = {
  Coin: "ຄໍາ",
  goldCoinTask: "ວຽກງານຫຼຽນຄໍາ",
  PointsDetails: "ລາຍລະອຽດຫຼຽນຄໍາ",
  myCoins: "ຫຼຽນຄໍາຂອງຂ້ອຍ",
  income: "ລາຍ​ໄດ້",
  expenditure: "ລາຍ​ຈ່າຍ",
  signInPrompt: "ເຂົ້າສູ່ລະບົບຢ່າງຕໍ່ເນື່ອງແລະໄດ້ຮັບລາງວັນຢ່າງຕໍ່ເນື່ອງ",
  toComplete: "ສໍາເລັດ",
  toShare: "ເພື່ອ​ແບ່ງ​ປັນ",
  shareTasks: "ແບ່ງ​ປັນ​ວຽກ​ງານ​",
  exclusiveLink: "ການເຊື່ອມຕໍ່ສະເພາະ",
  copyLink: "ສຳເນົາລິ້ງ",
  highestWin: "ຊະ​ນະ​ສູງ​ສຸດ​",
  day1: "ວັນທີ 1",
  day2: "ວັນທີ 2",
  day3: "ມື້ທີ 3",
  day4: "ວັນທີ 4",
  day5: "ວັນທີ 5",
  day6: "ວັນທີ 6",
  day7: "ວັນທີ 7",
  invitePrompt: "ແປກໃຈ! ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້​ແລະ​ດາວ​ນ​໌​ໂຫລດ APP ຂອງ​ພວກ​ເຮົາ​ເພື່ອ​ມີ​ຜົນ​ປະ​ໂຫຍດ​ສະ​ເພາະ​ສໍາ​ລັບ​ຜູ້​ໃຊ້​ໃຫມ່​! ການຖ່າຍທອດສົດການຖ່າຍທອດສົດ, ການວິເຄາະຂໍ້ມູນແບບສົດໆ, ແລະການຄາດຄະເນສະມໍແບບມືອາຊີບ, ທັງໝົດຢູ່ປາຍນິ້ວມືຂອງທ່ານ. ຢ່າລໍຖ້າອີກຕໍ່ໄປ, ຄລິກລິ້ງເພື່ອເຂົ້າຮ່ວມ!"
};

const Money = {
  activeCenter: "ສູນ​ກິດ​ຈະ​ກໍາ​",
  missionCenter: "ສູນພາລະກິດ",
};

const ActivityCenter = {
  toParticipate: "ເຂົ້າຮ່ວມ",
  sportActivity: "ກິດ​ຈະ​ກໍາ​ກິ​ລາ​",
};

const ActivityDetails = {
  eventDetails: "ລາຍລະອຽດເຫດການ",
  dailyBetting: "ການພະນັນປະຈໍາວັນ",
  matchTime: "ເວລາແຂ່ງຂັນ",
  popularForEvent: "ຕົວເລືອກທີ່ນິຍົມສຳລັບເຫດການນີ້",
  clickToParticipate: "ຄລິກເພື່ອເຂົ້າຮ່ວມດຽວນີ້",
  activityRules: "ກົດລະບຽບການເຄື່ອນໄຫວ",
  clickToContactParticipate: "ຄລິກເພື່ອຕິດຕໍ່ບໍລິການລູກຄ້າເພື່ອເຂົ້າຮ່ວມ",
  activityObj: "ວັດຖຸທີ່ໃຊ້ວຽກ",
  activityPlatform: "ເວທີເຫດການ",
  activityDuration: "ໄລຍະເວລາການເຄື່ອນໄຫວ",
  activityDemand: "ຄວາມຕ້ອງການກິດຈະກໍາ",
  activityTime: "ເວລາກິດຈະກໍາ",
};

const AboutUs = {
  aboutUs: "ກ່ຽວ​ກັບ​ພວກ​ເຮົາ",
  platformIntroduction: "ການແນະນຳເວທີ",
  privacyPolicy: "ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ",
  userAgreement: "ຂໍ້ຕົກລົງຜູ້ໃຊ້",
};

const DeviceInfo = {
  deviceInformation: "ອຸປະກອນ",
  currentTime: "ເວລາປະຈຸບັນ",
  port: "ຜອດເຂົ້າສູ່ລະບົບ",
  ip: "ເຂົ້າສູ່ລະບົບ IP",
  version: "ສະບັບປະຈຸບັນ",
  ipAndPort: "ຂໍ້ມູນການເຂົ້າສູ່ລະບົບ",
};

const FeedBack = {
  feedback: "ຄໍາຕິຊົມ",
  feedbackType: "ປະ​ເພດ​ຄໍາ​ຄຶດ​ຄໍາ​ເຫັນ​",
  productSuggestion: "ຄໍາແນະນໍາຜະລິດຕະພັນ",
  functionalFault: "ການເຮັດວຽກລົ້ມເຫລວ",
  interfaceSuggestion: "ການແນະນຳການໂຕ້ຕອບ",
  accountIssue: "ບັນຫາບັນຊີ",
  operationExperience: "ປະສົບການການດໍາເນີນງານ",
  otherFeedback: "ຄວາມຄິດເຫັນອື່ນໆ",
  problemDescription: "ຖະແຫຼງການບັນຫາ",
  submit: "ສົ່ງ",
  addPhoto: "ເພີ່ມຮູບ",
  uploadPrompt: "ຂໍອະໄພ, ສາມາດອັບໂຫລດໄດ້ພຽງແຕ່ 3 ຮູບເທົ່ານັ້ນ",
  uploadRulePrompt: "ກະລຸນາອັບໂຫລດຮູບພາບໃນຮູບແບບ jpg, jpeg, png",
  uploadSizePrompt: "ຂະໜາດຮູບບໍ່ສາມາດເກີນ 10M",
  chooseFeedback: "ກະລຸນາເລືອກປະເພດຄໍາຄິດເຫັນ",
  inputQuestionPrompt: "ກະລຸນາໃສ່ຄໍາຖາມ",
  submitSuccessPrompt: "ສົ່ງສຳເລັດແລ້ວ",
};

const MySubscription = {
  myFollow: "ຈຸດສຸມຂອງຂ້ອຍ",
  mySubscription: "ການສະໝັກໃຊ້ຂອງຂ້ອຍ",
  streamer: "ສະມໍ",
  matches: "ການແຂ່ງຂັນ",
};

const PrivacyAgreement = {
  privacyPolicy: "ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ",
};

const TermsofService = {
  termsOfService: "ເງື່ອນໄຂການໃຫ້ບໍລິການ",
};

const UserAgreement = {
  userAgreement: "ຂໍ້ຕົກລົງຜູ້ໃຊ້",
};

const AccountSecurity = {
  accountSecurity: "ຄວາມປອດໄພບັນຊີ",
  phone: "ເບີ​ໂທລະ​ສັບ",
  email: "ຈົດໝາຍ",
  bind: "ຜູກມັດ",
  notBound: "ບໍ່ຜູກມັດ",
  change: "ປ່ຽນແປງ",
  logInPassword: "ເຂົ້າສູ່ລະບົບລະຫັດຜ່ານ",
  accountChangePassword: "ກະລຸນາຜູກມັດໂທລະສັບມືຖື/ທີ່ຢູ່ອີເມວຂອງເຈົ້າກ່ອນ",
};

const BindEmail = {
  bindEmail: "ຜູກມັດອີເມວ",
  email: "ຈົດໝາຍ",
  code: "ລະຫັດຢືນຢັນ",
  getCode: "ໄດ້ຮັບລະຫັດຢືນຢັນ",
  confirm: "ຢືນຢັນ",
  emailRulePrompt: "ກະລຸນາໃສ່ຮູບແບບທີ່ຖືກຕ້ອງຂອງທີ່ຢູ່ອີເມວຂອງທ່ານ",
  emailEmptyPrompt: "ອີເມວບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  codeRulePrompt: "ລະຫັດຢືນຢັນແມ່ນ 6 ຕົວເລກ",
  codeEmptyPrompt: "ລະຫັດຢືນຢັນຕ້ອງຖືກຕື່ມໃສ່",
  changeSuccessPrompt: "ການປ່ຽນແປງສຳເລັດແລ້ວ",
  changeErrorPrompt: "ການປ່ຽນແປງລົ້ມເຫລວ",
  bindSuccessPrompt: "ຜູກມັດສຳເລັດແລ້ວ",
  bindErrorPrompt: "ການຜູກມັດລົ້ມເຫລວ",
  emailCodeSendSuccessPrompt: "ສົ່ງລະຫັດຢືນຢັນສຳເລັດແລ້ວ, ກະລຸນາກວດເບິ່ງອີເມວຂອງທ່ານ",
  emailCodeSendErrorPrompt: "ລະຫັດຢືນຢັນບໍ່ຖືກສົ່ງ, ກະລຸນາກວດເບິ່ງວ່າອີເມວຂອງທ່ານມີຢູ່ຫຼືບໍ່",
  codeSendToMuchPrompt: "ສົ່ງຫຼາຍເທື່ອເກີນໄປໃນເວລາດຽວກັນ, ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ",
  emailAlreadySignupPrompt: "ທີ່ຢູ່ອີເມວນີ້ໄດ້ຖືກລົງທະບຽນແລ້ວ, ກະລຸນາເຂົ້າສູ່ລະບົບໂດຍກົງ",
  tryAgain: "ສົ່ງຄືນ",
};

const BindPhone = {
  bindPhone: "ຜູກມັດໂທລະສັບມືຖື",
  phone: "ເບີ​ໂທລະ​ສັບ",
  code: "ລະຫັດຢືນຢັນ",
  getCode: "ໄດ້ຮັບລະຫັດຢືນຢັນ",
  confirm: "ຢືນຢັນ",
  cancel: "ຍົກເລີກ",
  phoneRulePrompt: "ກະລຸນາໃສ່ເບີໂທລະສັບຂອງເຈົ້າໃນຮູບແບບທີ່ຖືກຕ້ອງ",
  phoneEmptyPrompt: "ເບີໂທລະສັບບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  codeRulePrompt: "ລະຫັດຢືນຢັນແມ່ນ 6 ຕົວເລກ",
  codeEmptyPrompt: "ລະຫັດຢືນຢັນຕ້ອງຖືກຕື່ມໃສ່",
  changeSuccessPrompt: "ການປ່ຽນແປງສຳເລັດແລ້ວ",
  changeErrorPrompt: "ການປ່ຽນແປງລົ້ມເຫລວ",
  bindSuccessPrompt: "ຜູກມັດສຳເລັດແລ້ວ",
  bindErrorPrompt: "ການຜູກມັດລົ້ມເຫລວ",
  phoneNum8: "ກະລຸນາໃສ່ເບີໂທລະສັບ 8 ຕົວເລກຂອງທ່ານ",
  phoneNum9To10: "ກະລຸນາໃສ່ເບີໂທລະສັບ 9 ຫາ 10 ຕົວເລກ",
  phoneNum9: "ກະລຸນາໃສ່ເບີໂທລະສັບ 9 ຕົວເລກ",
  phoneCodeSendSuccessPrompt: "ສົ່ງລະຫັດຢືນຢັນສຳເລັດແລ້ວ, ກະລຸນາກວດເບິ່ງຂໍ້ຄວາມໃນໂທລະສັບມືຖືຂອງທ່ານ",
  phoneCodeSendErrorPrompt: "ລະຫັດຢືນຢັນບໍ່ຖືກສົ່ງ, ກະລຸນາກວດເບິ່ງວ່າມີເບີໂທລະສັບມືຖືຫຼືບໍ່",
  codeSendToMuchPrompt: "ສົ່ງຫຼາຍເທື່ອເກີນໄປໃນເວລາດຽວກັນ, ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ",
  phoneAlreadySignupPrompt: "ເບີໂທລະສັບນີ້ໄດ້ຖືກລົງທະບຽນ, ກະລຸນາເຂົ້າສູ່ລະບົບໂດຍກົງ",
  checkSignup: "ກະລຸນາກວດເບິ່ງວ່າໝາຍເລກດັ່ງກ່າວໄດ້ລົງທະບຽນຫຼືບໍ່",
  tryAgain: "ສົ່ງຄືນ",
};

const ChangePassword = {
  resetPassword: "ຣີເຊັດລະຫັດຜ່ານ",
  phone: "ເບີ​ໂທລະ​ສັບ",
  email: "ຈົດໝາຍ",
  code: "ລະຫັດຢືນຢັນ",
  getCode: "ໄດ້ຮັບລະຫັດຢືນຢັນ",
  logInPassword: "ເຂົ້າສູ່ລະບົບລະຫັດຜ່ານ",
  confirm: "ຢືນຢັນ",
  cancel: "ຍົກເລີກ",
  passwordRulePrompt: "ລະຫັດຜ່ານຕ້ອງປະກອບດ້ວຍຕົວພິມໃຫຍ່ ແລະຕົວພິມນ້ອຍ ແລະຕົວເລກ ແລະຕ້ອງມີຫຼາຍກວ່າ 8 ຕົວອັກສອນ",
  passwordEmptyPrompt: "ລະຫັດຜ່ານບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  emailRulePrompt: "ກະລຸນາໃສ່ຮູບແບບທີ່ຖືກຕ້ອງຂອງທີ່ຢູ່ອີເມວຂອງທ່ານ",
  emailEmptyPrompt: "ອີເມວບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  phoneRulePrompt: "ກະລຸນາໃສ່ເບີໂທລະສັບຂອງເຈົ້າໃນຮູບແບບທີ່ຖືກຕ້ອງ",
  phoneEmptyPrompt: "ເບີໂທລະສັບບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  codeRulePrompt: "ລະຫັດຢືນຢັນແມ່ນ 6 ຕົວເລກ",
  codeEmptyPrompt: "ລະຫັດຢືນຢັນຕ້ອງຖືກຕື່ມໃສ່",
  phoneNum8: "ກະລຸນາໃສ່ເບີໂທລະສັບ 8 ຕົວເລກຂອງທ່ານ",
  phoneNum9To10: "ກະລຸນາໃສ່ເບີໂທລະສັບ 9 ຫາ 10 ຕົວເລກ",
  phoneNum9: "ກະລຸນາໃສ່ເບີໂທລະສັບ 9 ຕົວເລກ",
  resetSuccess: "ຣີເຊັດລະຫັດຜ່ານສຳເລັດແລ້ວ",
  resetError: "ຣີເຊັດລະຫັດຜ່ານບໍ່ສຳເລັດ, ກະລຸນາກວດເບິ່ງວ່າຂໍ້ມູນຖືກຕ້ອງຫຼືບໍ່",
  checkSignup: "ກະລຸນາກວດເບິ່ງວ່າໝາຍເລກດັ່ງກ່າວໄດ້ລົງທະບຽນຫຼືບໍ່",
  phoneCodeSendSuccessPrompt: "ສົ່ງລະຫັດຢືນຢັນສຳເລັດແລ້ວ, ກະລຸນາກວດເບິ່ງຂໍ້ຄວາມໃນໂທລະສັບມືຖືຂອງທ່ານ",
  codeSendToMuchPrompt: "ສົ່ງຫຼາຍເທື່ອເກີນໄປໃນເວລາດຽວກັນ, ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ",
  phoneAlreadySignupPrompt: "ເບີໂທລະສັບນີ້ໄດ້ຖືກລົງທະບຽນ, ກະລຸນາເຂົ້າສູ່ລະບົບໂດຍກົງ",
  phoneCodeSendErrorPrompt: "ລະຫັດຢືນຢັນບໍ່ຖືກສົ່ງ, ກະລຸນາກວດເບິ່ງວ່າມີເບີໂທລະສັບມືຖືຫຼືບໍ່",
  emailCodeSendSuccessPrompt: "ສົ່ງລະຫັດຢືນຢັນສຳເລັດແລ້ວ, ກະລຸນາກວດເບິ່ງອີເມວຂອງທ່ານ",
  emailCodeSendErrorPrompt: "ລະຫັດຢືນຢັນບໍ່ຖືກສົ່ງ, ກະລຸນາກວດເບິ່ງວ່າອີເມວຂອງທ່ານມີຢູ່ຫຼືບໍ່",
  emailAlreadySignupPrompt: "ທີ່ຢູ່ອີເມວນີ້ໄດ້ຖືກລົງທະບຽນແລ້ວ, ກະລຸນາເຂົ້າສູ່ລະບົບໂດຍກົງ",
  bindPhoneOrEmail: "ກະລຸນາຜູກມັດເບີໂທລະສັບ ຫຼືທີ່ຢູ່ອີເມວຂອງທ່ານກ່ອນ",
  tryAgain: "ສົ່ງຄືນ",
};

const QuickRegister = {
  downloadScreenshot: "ດາວໂຫຼດພາບໜ້າຈໍ",
  saveAccountAndPassword: "ກະລຸນາຮັກສາບັນຊີ ແລະລະຫັດຜ່ານຂອງທ່ານໃຫ້ປອດໄພ",
  suggestSave: "ຂໍແນະນຳໃຫ້ທ່ານຖ່າຍຮູບໜ້າຈໍ ແລະບັນທຶກມັນໄວ້ ຫຼືສຳເນົາໄວ້ໃນບັນທຶກຊ່ວຍຈຳ.",
  account: "ບັນຊີ",
  password: "ລະຫັດຜ່ານ",
  copyAccountAndPassword: "ສຳເນົາລະຫັດຜ່ານບັນຊີ",
  prompt: "ຄຳໃບ້",
  sureSave: "ທ່ານໄດ້ບັນທຶກລະຫັດຜ່ານບັນຊີຂອງທ່ານບໍ?",
  confirm: "ຢືນຢັນ",
  cancel: "ຍົກເລີກ",
  copySuccess: "ສຳເນົາສຳເລັດແລ້ວ",
  copyFailure: "ສຳເນົາລົ້ມເຫລວ",
  manualRegister: "ຈໍາ​ນວນ​ຂອງ​ການ​ລົງ​ທະ​ບຽນ​ໄດ້​ເຖິງ​ກໍາ​ນົດ​ໄວ້​ເທິງ​, ກະ​ລຸ​ນາ​ລົງ​ທະ​ບຽນ​ດ້ວຍ​ຕົນ​ເອງ​",
};

const Recommend = {
  recommend: "ແນະນຳ",
  InternetErrorPrompt: "ເຄືອຂ່າຍຜິດພາດ",
  hitOfTheWeek: "ຕີຂອງອາທິດ",
  victoryOrDefeat: "ໄຊຊະນະ ຫຼື ໄຊຊະນະ",
  check: "ກວດສອບ",
  lastTenGames: "10 ເກມຫຼ້າສຸດ",
  recommendedByExperts: "ແນະນໍາໂດຍຜູ້ຊ່ຽວຊານ",
  changeBatch: "ປ່ຽນຊຸດ",
  follow: "ຈຸດ​ສຸມ",
  following: "ຕິດຕາມຢູ່ແລ້ວ",
  unFollowing: "ປົດລັອກ",
  all: "All",
  guaranteedCompensation: "ຮັບປະກັນການຊົດເຊີຍ",
  free: "ຟຣີ",
  toll: "ໂທ",
  HOT: "ຂາຍ",
  HIT: "ຕີ",
  STREAK: "ເຖິງແມ່ນວ່າຢູ່ໃນກາງ",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "ຫນ້າທໍາອິດ",
  chat: "ສົນທະນາ",
  follow: "ຈຸດ​ສຸມ",
  following: "ຕິດຕາມຢູ່ແລ້ວ",
  unFollowing: "ປົດລັອກ",
  followersCount: "ຈຸດ​ສຸມ",
  planCount: "ແຜນການ",
  viewCount: "ສັງເກດເບິ່ງ",
  statistics: "ສະຖິຕິ",
  plan: "ແຜນການ",
};

const SchemeDetails = {
  planDetails: "ລາຍລະອຽດແຜນການ",
  release: "ປ່ອຍ",
  chat: "ສົນທະນາ",
  follow: "ຈຸດ​ສຸມ",
  following: "ຕິດຕາມຢູ່ແລ້ວ",
  unFollowing: "ປົດລັອກ",
  details: "ລາຍລະອຽດ",
  analyze: "ວິເຄາະ",
  title: "ຫົວຂໍ້",
  privateChatStreamerJoinsGroup: "ເຈົ້າພາບສົນທະນາສ່ວນຕົວເຂົ້າຮ່ວມກຸ່ມ",
  upcoming: "ຍັງບໍ່ໄດ້ເລີ່ມຕົ້ນ",
  othersPlan: "ທາງເລືອກອື່ນ",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "ການຈັດຮຽງການແຂ່ງຂັນ",
  byTime: "ຕາມເວລາ",
  byLeague: "ໂດຍລີກ",
  confirm: "ຢືນຢັນ",
  cancel: "ຍົກເລີກ",
};

const RecommendPublish = {
  enterTeam: "ເຂົ້າທີມ",
  planRelease: "ວາງ​ແຜນ",
  predictionTitle: "ຫົວຂໍ້ (ເຫັນໄດ້ໂດຍຜູ້ໃຊ້ທຸກຄົນ, ກະລຸນາຢ່າເປີດເຜີຍຜົນການຄາດເດົາ)",
  titleEmptyPrompt: "ຫົວຂໍ້ ແລະເນື້ອໃນຂອງການວິເຄາະບໍ່ສາມາດຫວ່າງເປົ່າໄດ້",
  pleaseSelectAtLeastOneGameToPredictResults: "ກະລຸນາເລືອກຢ່າງໜ້ອຍໜຶ່ງວິທີໃນການຄາດເດົາຜົນໄດ້ຮັບ",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "ບານເຕະ",
  basketball: "ບ້ວງ",
  mixed: "ທີ່ສົມບູນແບບ",
  setLogInPassword: "ການ​ຕັ້ງ​ຄ່າ​ລະ​ຫັດ​ຜ່ານ​ເຂົ້າ​ສູ່​ລະ​ບົບ​",
  OCS: "ບໍລິການອອນໄລນ໌",
  OCSPrompt: "ໃນ​ການ​ບໍ​ລິ​ການ​ຂອງ​ທ່ານ​",
  phoneInCorrectPrompt: "ເບີໂທລະສັບມືຖືໃສ່ບໍ່ຖືກຕ້ອງ",
  codeInCorrectPrompt: "ລະຫັດຢືນຢັນບໍ່ຖືກຕ້ອງ",
  emailInCorrectPrompt: "ການປ້ອນອີເມວຜິດພາດ",
  passwordPrompt: "ກະລຸນາໃສ່ການປະສົມຂອງ 8-16 ຕົວເລກຂອງພາສາອັງກິດ ແລະຕົວເລກ",
  newPassword: "ໃສ່ລະຫັດຜ່ານໃໝ່",
  resetPasswordPrompt: "ທ່ານແນ່ໃຈບໍ່ວ່າຈະຣີເຊັດລະຫັດຜ່ານຂອງທ່ານ?",
  startChatPrompt: "ໃນປັດຈຸບັນທ່ານສາມາດເລີ່ມຕົ້ນການສົນທະນາ",
  send: "ສົ່ງ",
  noFollowPrompt: "ບໍ່ມີຄວາມສົນໃຈເທື່ອ",
  feedbackPrompt: "ກະລຸນາອອກຄໍາເຫັນທີ່ມີຄຸນຄ່າຂອງທ່ານແລະພວກເຮົາຈະເຮັດວຽກຫນັກເພື່ອປັບປຸງ!",
  bindPhoneByOtherPrompt: "ເບີໂທລະສັບມືຖືນີ້ໄດ້ຖືກຜູກມັດໂດຍຜູ້ໃຊ້ອື່ນໆ",
  bindEmailByOtherPrompt: "ທີ່ຢູ່ອີເມວນີ້ຖືກຜູກມັດໂດຍຜູ້ໃຊ້ອື່ນ",
  super_manager: "ຜູ້​ບໍ​ລິ​ຫານ​ສູງ​ສຸດ​",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "ທ້າຍໄຕມາດທໍາອິດ",
  endOfSecondQuarter: "ທ້າຍໄຕມາດທີສອງ",
  endOfThirdQuarter: "ທ້າຍໄຕມາດທີສາມ",
  endOfFourthQuarter: "ທ້າຍໄຕມາດທີ່ສີ່",
  pullUpToLoadMore: "ດຶງຂຶ້ນເພື່ອໂຫຼດເພີ່ມເຕີມ",
  pullDownToRefresh: "ດຶງລົງເພື່ອໂຫຼດຂໍ້ມູນຄືນໃໝ່",
  releaseToRefreshImmediately: "ປ່ອຍເພື່ອໂຫຼດຫນ້າຈໍຄືນທັນທີ",
  refreshPrompt: "ກຳລັງສົດຊື່ນ...",
  download: "ດາວໂຫຼດ",
  appDownload: "ແອັບດາວໂຫຼດ",
  brandName: "OV88",
  saveToDesktop: "ເພີ່ມໃສ່ໜ້າຈໍຫຼັກ",
  downloadTitle: "ແອັບດາວໂຫຼດ",
  normalDownload: "ການດາວໂຫຼດປົກກະຕິ",
  scanningPrompt: "ຕິດຕັ້ງດ້ວຍລະຫັດ QR ແລະຮວມຮັບຄຸນນະທົບໃຫຍ່ທີ່ມີພິເສດ, ແລະມີຄຸນນະທົບສຽງອື່ນທີ່ມີຄວາມງາມໃຫຍ່!",
  downloadFailToWeb: "ຖ້າ​ຫາກ​ວ່າ​ທ່ານ​ບໍ່​ສາ​ມາດ​ດາວ​ໂຫລດ APP ໄດ້​, ຄລິກ​ທີ່​ນີ້​ເພື່ອ​ນໍາ​ໃຊ້​ສະ​ບັບ​ເວັບ​ໄຊ​ຕ​໌​",
  scanQrCode: "ສະແກນລະຫັດ QR ດ້ວຍໂທລະສັບມືຖື",
  usingMobileDevices: "ໃຊ້ມືຖື",
  installTutorial: "ຄລິກເພື່ອເບິ່ງການສອນການຕິດຕັ້ງ",
  whyNameDifferent: "ຊື່​ຂອງ APP ທີ່​ຕິດ​ຕັ້ງ​ແລະ APP ທີ່​ດາວ​ໂຫຼດ​ບໍ່​ສອດ​ຄ່ອງ​, ກະ​ລຸ​ນາ​ດາວ​ນ​໌​ໂຫລດ​, ຕິດ​ຕັ້ງ​ແລະ​ນໍາ​ໃຊ້​!",
  clickDownload: "ຄລິກເພື່ອດາວໂຫລດ",
  installTutorialPrompt: "ຄູ່ມືການຕິດຕັ້ງ",
  brandNativeName: "UFOScore APP ພື້ນເມືອງ",
  uploadingPrompt: "ກຳລັງອັບໂຫລດ...",
  bHandicap: "Handicap",
  different: "ຄວາມແຕກຕ່າງ",
  total: "ທັງໝົດ",
  off: "ຍັງບໍ່ໄດ້ເລີ່ມຕົ້ນ",
  video: "ວິດີໂອ",
  animation: "ພາບເຄື່ອນໄຫວ",
  install: "ຕິດຕັ້ງ",
  startDownloading: "ເລີ່ມການດາວໂຫຼດ",
  cancelGoal: "ເປົ້າໝາຍຖືກຍົກເລີກ",
  appUpdatePrompt: "ມີ APP ລຸ້ນໃໝ່, ກະລຸນາອັບເດດ",
};
