/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "사용자 계약",
  privacyPolicy: "개인 정보 정책",
  termsOfService: "서비스 약관",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "아직 시작되지 않음",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "이미 방송 중",
  watch: "보러 가다",
  follow: "집중하다",
  following: "이미 팔로우 중",
  subscribe: "신청",
  streamerSchedule: "앵커 일정",
  noDataPrompt: "데이터 없음",
  unfollowPrompt: "팔로우를 취소하시겠습니까?",
  yes: "예",
  no: "아니요",
  logAndSign: "지금 로그인/등록",
  hours: "시간",
  mins: "분",
};

const AnchorInformation = {
  recommendedLiveStreams: "추천 생방송",
  noDataPrompt: "데이터 없음",
};

const BasketballScoreIndex = {
  details: "세부",
  over: "~ 위에",
  pointsDifference: "포인트 차이",
  totalScore: "총 점수",
  live: "LIVE",
  unSubscribePrompt: "구독을 취소하시겠습니까?",
  yes: "예",
  no: "아니요",
  logAndSign: "지금 로그인/등록",
  InternetErrorPrompt: "네트워크 오류",
};

const BasketballSmallBig = {
  details: "세부",
  over: "~ 위에",
  pointsDifference: "포인트 차이",
  totalScore: "총 점수",
  live: "LIVE",
  unSubscribePrompt: "구독을 취소하시겠습니까?",
  yes: "예",
  no: "아니요",
  logAndSign: "지금 로그인/등록",
  InternetErrorPrompt: "네트워크 오류",
  overIndex: "큰",
  underIndex: "작은",
};

const BindPhoneDialog = {
  bindPhonePrompt: "계정 보안을 위해 더 나은 서비스를 제공할 수 있도록 휴대폰 번호를 바인딩해 주시기 바랍니다.",
  goBind: "바인딩으로 이동",
};

const BottomNavigationBar = {
  home: "첫 장",
  matches: "대회",
  recommend: "추천하다",
  chat: "채팅",
  personal: "개인의",
  discovery: "발견하다",
  money: "돈을 벌다",
};

const ChatInterface = {
  noCsPrompt: "지금은 채팅할 수 없습니다.",
  uploadRulePrompt: "사진은 jpg, jpeg, png 형식으로 업로드해주세요.",
  uploadSizePrompt: "이미지 크기는 10M를 초과할 수 없습니다.",
  sendError: "보내지 못했습니다.",
  picSendError: "사진 전송 실패",
  InternetErrorPrompt: "네트워크 오류",
  bannedSendPrompt: "당신은 금지되었습니다",
};

const ChatList = {
  noNewPrompt: "아직 소식이 없습니다",
  logInPrompt: "콘텐츠를 보려면 로그인하세요. 먼저 로그인하세요.",
  logIn: "로그인",
  group: "그룹",
};

const ChatListItem = {
  withdrawMessage: "메시지를 철회했습니다",
  bannedPrompt: "금지된",
  unBanPrompt: "금지 해제됨",
  joinGroupPrompt: "그룹에 가입했습니다",
  leftGroupPrompt: "그룹을 탈퇴했습니다",
  picture: "그림",
  link: "링크",
  emoji: "애니메이션 이모티콘",
  addedFriendPrompt: "친구로 추가됨",
};

const CompetitionList = {
  logInPrompt: "콘텐츠를 보려면 로그인하세요. 먼저 로그인하세요.",
  logIn: "로그인",
  today: "오늘",
  Monday: "월요일",
  Tuesday: "화요일",
  Wednesday: "수요일",
  Thursday: "목요일",
  Friday: "금요일",
  Saturday: "토요일",
  Sunday: "일요일",
  noGames: "아직 게임 없음",
  toCollapse: "접으려면 클릭하세요.",
  toExpand: "펼치려면 클릭하세요."
};

const CompetitionListItem = {
  details: "세부",
  unSubscribePrompt: "구독을 취소하시겠습니까?",
  yes: "예",
  no: "아니요",
  logAndSign: "지금 로그인/등록",
  InternetErrorPrompt: "네트워크 오류",
};

const CompetitionMode = {
  matchMode: "경쟁 모드",
  confirm: "확인하다",
  cancel: "취소",
};

const EmojiCom = {
  logAndSign: "지금 로그인/등록",
  yes: "예",
  no: "아니요",
};

const ExponentCom = {
  company: "회사",
  initial: "초기의",
  ongoing: "즉각적인",
  preMatch: "경기 전",
  noDataPrompt: "데이터 없음",
  handicap: "핸디캡",
  WinDrawLose: "승리, 무승부, 패배",
  totalGoals: "총 목표",
  cornerKick: "뿔",
  pleaseSelect: "선택해주세요",
  confirm: "확인하다",
  cancel: "취소",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "Stream chat'에 고정됨",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "집중하다",
  following: "이미 팔로우 중",
  chat: "채팅",
  noDataPrompt: "데이터 없음",
  unfollowPrompt: "팔로우를 취소하시겠습니까?",
  yes: "예",
  no: "아니요",
  InternetErrorPrompt: "네트워크 오류",
};

const FootballCornerIndex = {
  details: "세부",
  over: "~ 위에",
  FH: "HT",
  cornerKick: "뿔",
  unSubscribePrompt: "구독을 취소하시겠습니까?",
  yes: "예",
  no: "아니요",
  logAndSign: "지금 로그인/등록",
  InternetErrorPrompt: "네트워크 오류",
};

const FootballScoreIndex = {
  details: "세부",
  over: "~ 위에",
  FH: "HT",
  cornerKick: "뿔",
  live: "LIVE",
  unSubscribePrompt: "구독을 취소하시겠습니까?",
  yes: "예",
  no: "아니요",
  logAndSign: "지금 로그인/등록",
  InternetErrorPrompt: "네트워크 오류",
};

const FootballScoreItem = {
  details: "세부",
  over: "~ 위에",
  FH: "HT",
  cornerKick: "뿔",
  live: "LIVE",
  unSubscribePrompt: "구독을 취소하시겠습니까?",
  yes: "예",
  no: "아니요",
  logAndSign: "지금 로그인/등록",
  InternetErrorPrompt: "네트워크 오류",
};

const GoalToast = {
  goal: "목표",
};

const LiveRecommend = {
  noDataPrompt: "데이터 없음",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "승리 또는 패배",
};

const MatchCardList = {
  details: "세부",
};

const MatchStatus = {
  noDataPrompt: "데이터 없음",
};

const MoreStreams = {
  switchLines: "스위치 라인",
  cancel: "취소",
  currentStreamer: "현재 앵커",
  live: "LIVE",
  offLine: "아직 시작되지 않음",
  followersCount: "집중하다",
  otherStreamersPrompt: "현재 게임을 방송하는 다른 앵커가 있습니다.",
  watch: "보러 가다",
};

const NewMatchStatus = {
  team: "팀",
  halfTimeScore: "전반전 점수",
  shotOnTarget: "골대에 슛",
  missedShot: "목표를 놓쳤습니다",
  attack: "공격",
  dangerousAttack: "위험한 공격",
  number3PointGoals: "3점슛 득점 수",
  number2PointGoals: "2점 득점 수",
  penaltyKickGoals: "페널티킥 골",
  freeThrowPercentage: "자유투 비율",
  numberOfPausesRemaining: "남은 일시중지 횟수",
  numberOfFouls: "파울 수",
  totalNumberOfPauses: "총 일시중지",
  threePointShot: "3점슛",
  twoPointShot: "2점슛",
  shoot: "사격",
  penalty: "패널티",
  firstHalf: "전반",
  secondHalf: "후반",
  redCard: "레드 카드",
  yellowCard: "경고 카드",
};

const NoData = {
  noDataPrompt: "데이터 없음",
};

const NoticeMessage = {
  addedFriendPrompt: "친구로 추가됨",
  withdrawMessage: "메시지를 철회했습니다",
  withdrawMessageByAdmin: "관리자가 메시지를 철회했습니다",
  joinGroupPrompt: "그룹에 가입했습니다",
  leftGroupPrompt: "그룹을 탈퇴했습니다",
  removeGroupPrompt: "관리자가 그룹에서 제거했습니다.",
  bannedPrompt: "금지된",
  unBanPrompt: "금지 해제됨",
};

const OddsStatisticsList = {
  initial: "초기의",
  ongoing: "즉각적인",
  preMatch: "경기 전",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "가장 높은 가치",
  min: "가장 낮은 값",
  average: "평균값",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "데이터 없음",
};

const RecommendCompetitions = {
  today: "오늘",
  winsInARow: "중간에도",
  noLivePrompt: "아직 라이브 방송이 없습니다",
};

const RecommendStreamerList = {
  noDataPrompt: "데이터 없음",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "금주의 히트작",
  victoryOrDefeat: "승리 또는 패배",
  check: "확인하다",
  chat: "채팅",
  quarterlyProfit: "분기별 이익",
};

const RecommendStreamers = {
  recommendedByExperts: "전문가가 추천하는",
  winsInARow: "중간에도",
  changeBatch: "배치 변경",
  follow: "집중하다",
  following: "이미 팔로우 중",
  unFollowing: "터놓다",
  noDataPrompt: "데이터 없음",
  logAndSign: "지금 로그인/등록",
  yes: "예",
  no: "아니요",
  InternetErrorPrompt: "네트워크 오류",
  unfollowPrompt: "팔로우를 취소하시겠습니까?",
};

const RecordChart = {
  record: "기록",
  allWaysToPlay: "모든 플레이 방법",
  fromFarToNear: "먼 곳에서 가까운 곳으로",
  lastTenGames: "최근 10경기",
  longestConsecutiveHit: "최장 연속 안타",
  hitRate: "타율",
  currentPropmt: "최근 게임",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "사용자 정보를 가져오지 못했습니다.",
  getAreaAndLanguageErrorPrompt: "지역 언어를 가져오지 못했습니다.",
};

const ScheduleCom = {
  startTime: "시작 시간",
};

const SchemeCard = {
  planList: "플랜 목록",
  release: "풀어 주다",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "보상 보장",
  free: "무료",
  toll: "통행료",
  victoryOrDefeat: "승리 또는 패배",
};

const SquareCom = {
  watchPrompt: "사람들이 지켜보고 있어요",
  startedPrompt: "몇 분 전에 시작되었습니다.",
  chat: "채팅",
  sendMessageNeedLoginPrompt: "메시지를 보내려면 로그인하세요.",
  assistant: "관리자",
  audience: "사용자",
  newMessage: "새로운 뉴스",
  confirmSend: "전송 확인",
  cancelSend: "취소",
};

const StatisticalChart = {
  hitRate: "타율",
};

const SwitchLines = {
  switchLines: "스위치 라인",
  cancel: "취소",
  currentStreamer: "현재 앵커",
  live: "LIVE",
  offLine: "아직 시작되지 않음",
  followersCount: "집중하다",
  otherStreamersPrompt: "현재 게임을 방송하는 다른 앵커가 있습니다.",
  watch: "보러 가다",
  LINE: "선",
  VIP: "요인",
  OFFICIAL: "공식적인",
};

const VideoCom = {
  videoErrorPrompt: "신호가 좋지 않습니다. 나중에 다시 시도해 주세요.",
  clickRetryPrompt: "다시 시도하려면 클릭하세요.",
};

const WelcomeCard = {
  join: "그룹에 가입",
};

//utils
const tools = {
  yesterday: "어제",
  addedFriendPrompt: "친구로 추가됨",
  withdrawMessage: "메시지를 철회했습니다",
  joinGroupPrompt: "그룹에 가입했습니다",
  leftGroupPrompt: "그룹을 탈퇴했습니다",
  removeGroupPrompt: "관리자가 그룹에서 제거했습니다.",
  bannedPrompt: "금지된",
  unBanPrompt: "금지 해제됨",
};

//页面
const AddressBook = {
  search: "찾다",
  addFriend: "친구 추가",
  myGroupChat: "내 그룹 채팅",
  friends: "친구",
  delete: "삭제",
  noFriendsPrompt: "아직 친구가 없습니다",
  nameAndIdSearch: "이름, 아이디 입력",
  searchResults: "결과 찾기",
  notFoundPrompt: "사용자를 찾을 수 없습니다",
  add: "다음에 추가",
  deleteFriendPrompt: "친구를 삭제하시겠습니까?",
  yes: "예",
  no: "아니요",
  addFriendSuccessPrompt: "친구 추가 성공",
  deleteFriendSuccessPrompt: "친구를 삭제했습니다.",
  InputSearchPrompt: "내용을 입력해주세요",
};

const GroupChat = {
  groupChat: "그룹 채팅",
  groupSearch: "그룹 채팅 이름을 입력하세요",
  noGroupPrompt: "아직 그룹 채팅이 없습니다.",
  chat: "채팅",
  InputSearchPrompt: "내용을 입력해주세요",
};

const GroupAdministrator = {
  administrator: "관리자",
  groupOwner: "주님",
};

const GroupAnnouncement = {
  groupAnnouncement: "그룹 공지",
  groupOwner: "주님",
  administrator: "관리자",
};

const GroupDetail = {
  groupDetail: "그룹 세부정보",
  groupMembers: "그룹 구성원",
  groupName: "그룹 이름",
  groupDescription: "그룹 설명",
  groupOwner: "주님",
  administrator: "관리자",
  administratorList: "관리자 목록",
  groupAnnouncement: "그룹 공지",
  groupFiles: "그룹 파일",
  exitGroup: "그룹에서 나가기",
  exitGroupPrompt: "그룹에서 탈퇴하시겠습니까?",
  yes: "예",
  no: "아니요",
  leftGroupSuccessPrompt: "그룹을 성공적으로 종료했습니다.",
};

const GroupFile = {
  groupFiles: "그룹 파일",
  fileSearch: "파일 이름을 입력하세요",
  today: "오늘",
  multimedia: "멀티미디어",
  file: "문서",
  noFilesPrompt: "아직 파일이 없습니다",
};

const GroupMembers = {
  groupMembers: "그룹 구성원",
  nameAndIdSearch: "이름, 아이디 입력",
};

const Chat = {
  chat: "채팅",
  nameAndIdSearch: "이름, 아이디 입력",
  contacts: "주소록",
  InputSearchPrompt: "내용을 입력해주세요",
  deleteContactPrompt: "채팅을 삭제하시겠습니까?",
};

const Competition = {
  all: "All",
  ongoing: "즉각적인",
  popular: "인기 있는",
  liveCompetition: "라이브 스트리밍",
  schedule: "일정",
  results: "결과",
  subscribed: "구독함",
  goal: "목표",
  today: "오늘",
  Monday: "월요일",
  Tuesday: "화요일",
  Wednesday: "수요일",
  Thursday: "목요일",
  Friday: "금요일",
  Saturday: "토요일",
  Sunday: "일요일",
};

const CompetitionDetails = {
  subscribe: "신청",
  subscribed: "구독함",
  matchStreamerPrompt: "라이브 게임 앵커",
  live: "LIVE",
  noDataPrompt: "데이터 없음",
  noStreamersPrompt: "현재 이 게임을 방송하는 호스트가 없습니다.",
  alreadyStreamerPrompt: "진행자가 경기를 방송하기 시작했습니다. 가서 시청하시겠습니까?",
  yes: "예",
  no: "아니요",
  liveStream: "라이브 스트리밍",
  matchStatus: "경기 상태",
  odds: "Odds",
  unSubscribePrompt: "구독을 취소하시겠습니까?",
  logAndSign: "지금 로그인/등록",
  InternetErrorPrompt: "네트워크 오류",
};

const OddsDetails = {
  company: "회사",
  handicap: "핸디캡",
  WinDrawLose: "승리, 무승부, 패배",
  totalGoals: "총 목표",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "많은",
  Un: "약간의",
};

const Home = {
  logIn: "로그인",
  signUp: "등록하다",
  popularMatches: "인기 대회",
  more: "더",
  noDataPrompt: "데이터 없음",
  currentlyLive: "생방송",
  popularStreamers: "인기 앵커",
  thinkYouLike: "너도 좋아할 거야",
  loadMore: "더 로드하세요",
  noMoreDataPrompt: "아직 데이터가 더 이상 없습니다.",
  addDeskPrompt: "데스크탑에 추가",
  clikToolPrompt: "아래 툴바를 클릭하세요",
  andSeletcPrompt: "그리고 선택",
  addToScreenPrompt: "더 나은 서비스 제공을 위해 홈 화면에 추가하고 싶습니다.",
  addToScreen: "홈 화면에 추가",
};

const MoreLives = {
  noDataPrompt: "데이터 없음",
};

const MoreStreamers = {
  streamer: "닻",
  streamerSearch: "앵커 이름과 ID를 입력하세요.",
  all: "All",
  notFollowing: "팔로우하지 않음",
  following: "이미 팔로우 중",
  live: "LIVE",
  offLine: "아직 시작되지 않음",
  followersCount: "집중하다",
  unfollowPrompt: "팔로우를 취소하시겠습니까?",
  yes: "예",
  no: "아니요",
  logAndSign: "지금 로그인/등록",
  InternetErrorPrompt: "네트워크 오류",
  InputSearchPrompt: "내용을 입력해주세요",
  dataRequestErrorPrompt: "데이터를 가져오지 못했습니다.",
};

const LiveRoom = {
  switchLines: "스위치 라인",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "앵커 비공개 채팅",
  chat: "채팅",
  odds: "Odds",
  streamerInformation: "앵커정보",
  matchStatus: "경기 상태",
};

const AnchorIntroduction = {
  streamerInformation: "앵커정보",
  photosWall: "포토월",
  seeMore: "더보기",
  streamerPlan: "앵커 계획",
};

const AnchorPhoto = {
  photosWall: "포토월",
  noPhotosPrompt: "아직 사진이 더 이상 없습니다.",
};

const LogInRegister = {
  logIn: "로그인",
  signUp: "등록하다",
  getAreaAndLanguageErrorPrompt: "지역 언어를 가져오지 못했습니다.",
  quickRegisterFrequentPrompt: "자주 요청합니다. 나중에 다시 시도해 주세요.",
};

const LoginFn = {
  phone: "전화 번호",
  email: "우편",
  logInPassword: "로그인 비밀번호",
  rememberPassword: "비밀번호 기억하기",
  forgotPassword: "비밀번호를 잊어버려",
  logIn: "로그인",
  logInAgreement: "로그인하시면 동의하신 것으로 간주됩니다",
  userAgreement: "사용자 계약",
  and: "  그리고 ",
  privacyPolicy: "개인 정보 정책",
  passwordRulePrompt: "비밀번호는 대문자, 소문자, 숫자로 구성되어야 하며 8자 이상이어야 합니다.",
  passwordEmptyPrompt: "비밀번호는 비워둘 수 없습니다.",
  emailRulePrompt: "이메일 주소의 올바른 형식을 입력하세요.",
  emailEmptyPrompt: "이메일은 비워둘 수 없습니다.",
  phoneRulePrompt: "휴대폰 번호를 올바른 형식으로 입력해주세요.",
  phoneEmptyPrompt: "휴대폰 번호는 비워둘 수 없습니다.",
  phoneNum8: "휴대폰번호 8자리를 입력해주세요",
  phoneNum9To10: "9~10자리 휴대폰번호를 입력해주세요.",
  phoneNum9: "휴대전화번호 9자리를 입력해주세요.",
  enterErrorPrompt: "입력 오류 또는 네트워크 이상",
  loginNoAgreePrompt: "로그인하려면 사용자 계약 및 개인정보 보호정책에 동의해야 합니다.",
  accountAndPassword: "계정",
  account: "계정",
  accountEmptyPrompt: "계정은 비워둘 수 없습니다.",
  accountOrPasswordError: "잘못된 사용자 이름 또는 비밀번호",
};

const Register = {
  phone: "전화 번호",
  email: "우편",
  code: "확인 코드",
  getCode: "인증 코드 받기",
  logInPassword: "로그인 비밀번호",
  CS: "고객 서비스",
  signUp: "등록하다",
  signUpAgreement: "등록함으로써 귀하는 동의합니다",
  userAgreement: "사용자 계약",
  and: "그리고",
  privacyPolicy: "개인 정보 정책",
  contactCSPrompt: "등록하는 데 문제가 있나요? 문제를 해결하려면 고객 서비스에 문의하세요.",
  contactCS: "고객 서비스에 문의하세요",
  passwordRulePrompt: "비밀번호는 대문자, 소문자, 숫자로 구성되어야 하며 8자 이상이어야 합니다.",
  passwordEmptyPrompt: "비밀번호는 비워둘 수 없습니다.",
  emailRulePrompt: "이메일 주소의 올바른 형식을 입력하세요.",
  emailEmptyPrompt: "이메일은 비워둘 수 없습니다.",
  phoneRulePrompt: "휴대폰 번호를 올바른 형식으로 입력해주세요.",
  phoneEmptyPrompt: "휴대폰 번호는 비워둘 수 없습니다.",
  codeRulePrompt: "인증번호는 6자리입니다",
  codeEmptyPrompt: "인증 코드를 입력해야 합니다",
  phoneNum8: "휴대폰번호 8자리를 입력해주세요",
  phoneNum9To10: "9~10자리 휴대폰번호를 입력해주세요.",
  phoneNum9: "휴대전화번호 9자리를 입력해주세요.",
  phoneCodeSendSuccessPrompt: "인증번호가 성공적으로 발송되었습니다. 휴대폰 문자 메시지를 확인해주세요.",
  emailCodeSendSuccessPrompt: "인증코드가 성공적으로 전송되었습니다. 이메일을 확인해 주세요.",
  phoneCodeSendErrorPrompt: "인증번호가 전송되지 않았습니다. 휴대폰 번호가 사용 가능한지 확인하세요.",
  emailCodeSendErrorPrompt: "인증 코드가 전송되지 않았습니다. 이메일이 가능한지 확인하세요.",
  codeSendToMuchPrompt: "동시에 너무 많이 보냈습니다. 나중에 다시 시도해 주세요.",
  phoneAlreadySignupPrompt: "등록된 휴대폰번호입니다. 직접 로그인해주세요.",
  emailAlreadySignupPrompt: "이 이메일 주소는 등록되었습니다. 직접 로그인하세요.",
  signUpErrorPrompt: "등록에 실패했습니다. 정보가 올바른지 확인하세요.",
  enterErrorPrompt: "입력 오류 또는 네트워크 이상",
  noAgreePrompt: "등록하려면 사용자 계약 및 개인정보 보호정책에 동의해야 합니다.",
  regionAndLanguagePrompt: "실제 지역 언어를 선택하십시오. 그렇지 않으면 기능 사용에 영향을 미칩니다.",
  accountAndPassword: "계정",
  quickSignup: "원클릭 등록",
  accountEmptyPrompt: "계정은 비워둘 수 없습니다.",
  accountRulePrompt: "계좌번호는 8~16자리의 문자와 숫자로 구성되어야 합니다.",
  accountAlreadySignupPrompt: "이 계정이 등록되었습니다. 직접 로그인하세요.",
  tryAgain: "재전송",
};

const PersonalCenter = {
  logAndSign: "지금 로그인/등록",
  logAndSignPrompt: "지금 독점 기능과 독점 혜택을 잠금 해제하세요",
  logOut: "로그아웃",
  myFollow: "내 초점",
  mySubscription: "내 구독",
  accountSecurity: "계정 보안",
  feedback: "피드백",
  deviceInformation: "장비",
  aboutUs: "회사 소개",
  coin: "금",
  moreServices: "더 많은 서비스",
  following: "이미 팔로우 중",
  subscribed: "구독함",
  browser: "브라우저",
  user: "사용자",
  profile: "개인 정보",
  nickname: "닉네임",
  save: "구하다",
  change: "변화",
  nicknamePrompt: "닉네임을 입력해주세요",
  avatar: "화신",
  changeSuccessPrompt: "변경 성공",
  ballAnimation: "목표 애니메이션",
  ballVoice: "목표 음향 효과",
  setting: "설정",
};

const IntegralSystem = {
  Coin: "금",
  goldCoinTask: "금화 작업",
  PointsDetails: "금화 세부정보",
  myCoins: "내 금화",
  income: "소득",
  expenditure: "지출",
  signInPrompt: "지속적으로 로그인하고 지속적으로 보상을 받으세요",
  toComplete: "완료하다",
  toShare: "공유하다",
  shareTasks: "작업 공유",
  exclusiveLink: "독점 링크",
  copyLink: "링크 복사",
  highestWin: "최고 승리",
  day1: "1일차",
  day2: "2일차",
  day3: "3일째",
  day4: "4일차",
  day5: "5일차",
  day6: "6일차",
  day7: "7일차",
  invitePrompt: "놀라다! 지금 등록하고 앱을 다운로드하여 신규 사용자를 위한 독점 혜택을 누리세요! 무료 라이브 이벤트 방송, 실시간 데이터 분석, 전문 앵커 예측을 모두 손쉽게 이용할 수 있습니다. 더 이상 기다리지 말고 링크를 클릭하여 참여하세요!"
};

const Money = {
  activeCenter: "활동 센터",
  missionCenter: "선교 센터",
};

const ActivityCenter = {
  toParticipate: "참여",
  sportActivity: "스포츠 활동",
};

const ActivityDetails = {
  eventDetails: "이벤트 내용",
  dailyBetting: "일일 베팅",
  matchTime: "경기 시간",
  popularForEvent: "이 이벤트의 인기 옵션",
  clickToParticipate: "지금 참여하려면 클릭하세요",
  activityRules: "활동 규칙",
  clickToContactParticipate: "참여하려면 고객 서비스에 문의하려면 클릭하세요.",
  activityObj: "활성 개체",
  activityPlatform: "이벤트 플랫폼",
  activityDuration: "활동 기간",
  activityDemand: "활동 요구 사항",
  activityTime: "활동 시간",
};

const AboutUs = {
  aboutUs: "회사 소개",
  platformIntroduction: "플랫폼 소개",
  privacyPolicy: "개인 정보 정책",
  userAgreement: "사용자 계약",
};

const DeviceInfo = {
  deviceInformation: "장비",
  currentTime: "현재 시간",
  port: "로그인 포트",
  ip: "로그인 IP",
  version: "현재 버전",
  ipAndPort: "로그인 정보",
};

const FeedBack = {
  feedback: "피드백",
  feedbackType: "피드백 유형",
  productSuggestion: "제품 제안",
  functionalFault: "기능적 실패",
  interfaceSuggestion: "인터페이스 제안",
  accountIssue: "계정 문제",
  operationExperience: "운영 경험",
  otherFeedback: "기타 피드백",
  problemDescription: "문제 설명",
  submit: "제출하다",
  addPhoto: "사진 추가",
  uploadPrompt: "죄송합니다. 사진은 3장만 업로드할 수 있습니다.",
  uploadRulePrompt: "사진은 jpg, jpeg, png 형식으로 업로드해주세요.",
  uploadSizePrompt: "이미지 크기는 10M를 초과할 수 없습니다.",
  chooseFeedback: "피드백 유형을 선택하세요.",
  inputQuestionPrompt: "질문을 입력하세요",
  submitSuccessPrompt: "성공적으로 제출되었습니다",
};

const MySubscription = {
  myFollow: "내 초점",
  mySubscription: "내 구독",
  streamer: "닻",
  matches: "대회",
};

const PrivacyAgreement = {
  privacyPolicy: "개인 정보 정책",
};

const TermsofService = {
  termsOfService: "서비스 약관",
};

const UserAgreement = {
  userAgreement: "사용자 계약",
};

const AccountSecurity = {
  accountSecurity: "계정 보안",
  phone: "전화 번호",
  email: "우편",
  bind: "제본",
  notBound: "구속되지 않음",
  change: "변화",
  logInPassword: "로그인 비밀번호",
  accountChangePassword: "먼저 휴대폰/이메일 주소를 연결해 주세요.",
};

const BindEmail = {
  bindEmail: "이메일 바인딩",
  email: "우편",
  code: "확인 코드",
  getCode: "인증 코드 받기",
  confirm: "확인하다",
  emailRulePrompt: "이메일 주소의 올바른 형식을 입력하세요.",
  emailEmptyPrompt: "이메일은 비워둘 수 없습니다.",
  codeRulePrompt: "인증번호는 6자리입니다",
  codeEmptyPrompt: "인증 코드를 입력해야 합니다",
  changeSuccessPrompt: "변경 성공",
  changeErrorPrompt: "변경 실패",
  bindSuccessPrompt: "바인딩 성공",
  bindErrorPrompt: "바인딩 실패",
  emailCodeSendSuccessPrompt: "인증코드가 성공적으로 전송되었습니다. 이메일을 확인해 주세요.",
  emailCodeSendErrorPrompt: "인증 코드가 전송되지 않았습니다. 이메일이 가능한지 확인하세요.",
  codeSendToMuchPrompt: "동시에 너무 많이 보냈습니다. 나중에 다시 시도해 주세요.",
  emailAlreadySignupPrompt: "이 이메일 주소는 등록되었습니다. 직접 로그인하세요.",
  tryAgain: "재전송",
};

const BindPhone = {
  bindPhone: "휴대폰 바인딩",
  phone: "전화 번호",
  code: "확인 코드",
  getCode: "인증 코드 받기",
  confirm: "확인하다",
  cancel: "취소",
  phoneRulePrompt: "휴대폰 번호를 올바른 형식으로 입력해주세요.",
  phoneEmptyPrompt: "휴대폰 번호는 비워둘 수 없습니다.",
  codeRulePrompt: "인증번호는 6자리입니다",
  codeEmptyPrompt: "인증 코드를 입력해야 합니다",
  changeSuccessPrompt: "변경 성공",
  changeErrorPrompt: "변경 실패",
  bindSuccessPrompt: "바인딩 성공",
  bindErrorPrompt: "바인딩 실패",
  phoneNum8: "휴대폰번호 8자리를 입력해주세요",
  phoneNum9To10: "9~10자리 휴대폰번호를 입력해주세요.",
  phoneNum9: "휴대전화번호 9자리를 입력해주세요.",
  phoneCodeSendSuccessPrompt: "인증번호가 성공적으로 발송되었습니다. 휴대폰 문자 메시지를 확인해주세요.",
  phoneCodeSendErrorPrompt: "인증번호가 전송되지 않았습니다. 휴대폰 번호가 사용 가능한지 확인하세요.",
  codeSendToMuchPrompt: "동시에 너무 많이 보냈습니다. 나중에 다시 시도해 주세요.",
  phoneAlreadySignupPrompt: "등록된 휴대폰번호입니다. 직접 로그인해주세요.",
  checkSignup: "등록된 번호인지 확인해주세요",
  tryAgain: "재전송",
};

const ChangePassword = {
  resetPassword: "암호를 재설정",
  phone: "전화 번호",
  email: "우편",
  code: "확인 코드",
  getCode: "인증 코드 받기",
  logInPassword: "로그인 비밀번호",
  confirm: "확인하다",
  cancel: "취소",
  passwordRulePrompt: "비밀번호는 대문자, 소문자, 숫자로 구성되어야 하며 8자 이상이어야 합니다.",
  passwordEmptyPrompt: "비밀번호는 비워둘 수 없습니다.",
  emailRulePrompt: "이메일 주소의 올바른 형식을 입력하세요.",
  emailEmptyPrompt: "이메일은 비워둘 수 없습니다.",
  phoneRulePrompt: "휴대폰 번호를 올바른 형식으로 입력해주세요.",
  phoneEmptyPrompt: "휴대폰 번호는 비워둘 수 없습니다.",
  codeRulePrompt: "인증번호는 6자리입니다",
  codeEmptyPrompt: "인증 코드를 입력해야 합니다",
  phoneNum8: "휴대폰번호 8자리를 입력해주세요",
  phoneNum9To10: "9~10자리 휴대폰번호를 입력해주세요.",
  phoneNum9: "휴대전화번호 9자리를 입력해주세요.",
  resetSuccess: "비밀번호 재설정 성공",
  resetError: "비밀번호 재설정에 실패했습니다. 정보가 올바른지 확인하세요.",
  checkSignup: "등록된 번호인지 확인해주세요",
  phoneCodeSendSuccessPrompt: "인증번호가 성공적으로 발송되었습니다. 휴대폰 문자 메시지를 확인해주세요.",
  codeSendToMuchPrompt: "동시에 너무 많이 보냈습니다. 나중에 다시 시도해 주세요.",
  phoneAlreadySignupPrompt: "등록된 휴대폰번호입니다. 직접 로그인해주세요.",
  phoneCodeSendErrorPrompt: "인증번호가 전송되지 않았습니다. 휴대폰 번호가 사용 가능한지 확인하세요.",
  emailCodeSendSuccessPrompt: "인증코드가 성공적으로 전송되었습니다. 이메일을 확인해 주세요.",
  emailCodeSendErrorPrompt: "인증 코드가 전송되지 않았습니다. 이메일이 가능한지 확인하세요.",
  emailAlreadySignupPrompt: "이 이메일 주소는 등록되었습니다. 직접 로그인하세요.",
  bindPhoneOrEmail: "먼저 휴대폰 번호나 이메일 주소를 연결해 주세요",
  tryAgain: "재전송",
};

const QuickRegister = {
  downloadScreenshot: "스크린샷 다운로드",
  saveAccountAndPassword: "귀하의 계정과 비밀번호를 안전하게 보관하십시오",
  suggestSave: "스크린샷을 찍어 저장하거나 메모에 복사해 두시는 것을 권장합니다.",
  account: "계정",
  password: "비밀번호",
  copyAccountAndPassword: "계정 비밀번호 복사",
  prompt: "힌트",
  sureSave: "계정 비밀번호를 저장하셨나요?",
  confirm: "확인하다",
  cancel: "취소",
  copySuccess: "성공적으로 복사되었습니다",
  copyFailure: "복사 실패",
  manualRegister: "등록횟수가 한도에 도달했습니다. 수동으로 등록해 주세요.",
};

const Recommend = {
  recommend: "추천하다",
  InternetErrorPrompt: "네트워크 오류",
  hitOfTheWeek: "금주의 히트작",
  victoryOrDefeat: "승리 또는 패배",
  check: "확인하다",
  lastTenGames: "최근 10경기",
  recommendedByExperts: "전문가가 추천하는",
  changeBatch: "배치 변경",
  follow: "집중하다",
  following: "이미 팔로우 중",
  unFollowing: "터놓다",
  all: "All",
  guaranteedCompensation: "보상 보장",
  free: "무료",
  toll: "통행료",
  HOT: "판매",
  HIT: "때리다",
  STREAK: "중간에도",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "홈페이지",
  chat: "채팅",
  follow: "집중하다",
  following: "이미 팔로우 중",
  unFollowing: "터놓다",
  followersCount: "집중하다",
  planCount: "계획",
  viewCount: "보다",
  statistics: "통계",
  plan: "계획",
};

const SchemeDetails = {
  planDetails: "요금제 세부정보",
  release: "풀어 주다",
  chat: "채팅",
  follow: "집중하다",
  following: "이미 팔로우 중",
  unFollowing: "터놓다",
  details: "세부",
  analyze: "분석하다",
  title: "제목",
  privateChatStreamerJoinsGroup: "비공개 채팅 호스트가 그룹에 합류",
  upcoming: "아직 시작되지 않았습니다",
  othersPlan: "다른 옵션",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "일치 정렬",
  byTime: "시간에 따라",
  byLeague: "리그별",
  confirm: "확인하다",
  cancel: "취소",
};

const RecommendPublish = {
  enterTeam: "팀 입력",
  planRelease: "기획발표",
  predictionTitle: "제목(모든 사용자에게 표시됩니다. 예측 결과를 공개하지 마세요.)",
  titleEmptyPrompt: "분석의 제목과 내용은 비워둘 수 없습니다.",
  pleaseSelectAtLeastOneGameToPredictResults: "결과를 예측하는 방법을 하나 이상 선택하세요.",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "축구",
  basketball: "농구",
  mixed: "포괄적인",
  setLogInPassword: "로그인 비밀번호 설정",
  OCS: "온라인 서비스",
  OCSPrompt: "당신의 서비스에",
  phoneInCorrectPrompt: "휴대폰번호가 잘못 입력되었습니다.",
  codeInCorrectPrompt: "잘못된 인증 코드",
  emailInCorrectPrompt: "이메일 입력 오류",
  passwordPrompt: "영문 8~16자리와 숫자를 조합하여 입력해주세요.",
  newPassword: "새 비밀번호를 입력하세요",
  resetPasswordPrompt: "비밀번호를 재설정하시겠습니까?",
  startChatPrompt: "이제 채팅을 시작할 수 있습니다",
  send: "보내다",
  noFollowPrompt: "아직 관심 없음",
  feedbackPrompt: "소중한 의견을 남겨주시면 개선하도록 노력하겠습니다!",
  bindPhoneByOtherPrompt: "이 휴대폰 번호는 다른 사용자에 의해 연결되었습니다.",
  bindEmailByOtherPrompt: "이 이메일 주소는 다른 사용자에 의해 연결되었습니다.",
  super_manager: "최고 관리자",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "1분기말",
  endOfSecondQuarter: "2분기말",
  endOfThirdQuarter: "3분기말",
  endOfFourthQuarter: "4분기말",
  pullUpToLoadMore: "더 많은 것을 로드하려면 위로 당기세요.",
  pullDownToRefresh: "아래로 당겨 새로고침하세요.",
  releaseToRefreshImmediately: "즉시 새로고침하려면 버튼을 놓으세요.",
  refreshPrompt: "상쾌하다...",
  download: "다운로드",
  appDownload: "앱 다운로드",
  brandName: "OV88",
  saveToDesktop: "홈 화면에 추가",
  downloadTitle: "앱 다운로드",
  normalDownload: "일반 다운로드",
  scanningPrompt: "QR 코드로 설치하고 독점 대규모 할인을 받으세요. 또한 다른 고급 혜택도 즐길 수 있습니다!",
  downloadFailToWeb: "앱을 다운로드할 수 없는 경우 여기를 클릭하여 웹 버전을 사용하세요.",
  scanQrCode: "휴대폰으로 QR 코드를 스캔하세요",
  usingMobileDevices: "모바일을 이용하세요",
  installTutorial: "설치 튜토리얼을 보려면 클릭하세요.",
  whyNameDifferent: "설치된 앱과 다운로드한 앱의 이름이 일치하지 않습니다. 자유롭게 다운로드하여 설치하고 사용하세요!",
  clickDownload: "다운로드하려면 클릭하세요",
  installTutorialPrompt: "설치 지침 가이드",
  brandNativeName: "UFOScore 기본 앱",
  uploadingPrompt: "업로드 중...",
  bHandicap: "핸디캡",
  different: "차이점",
  total: "총",
  off: "아직 시작되지 않았습니다",
  video: "동영상",
  animation: "생기",
  install: "설치하다",
  startDownloading: "다운로드를 시작하다",
  cancelGoal: "목표가 취소됨",
  appUpdatePrompt: "새로운 버전의 APP이 있습니다. 업데이트해주세요.",
};
