import { languageGet, regionGet } from "@/services/api/User";
import { getUser } from "@/services/api/User";

const state = { 
  translateData: [], 
  translateUserData: [],
  regionsData: []
};

const mutations = {
  SET_TRANSLATEDATA(state, data) {
    state.translateData = data;
  },
  CLEAR_TRANSLATEDATA(state) {
    state.translateData = [];
  },
  SET_USERTRANSLATEDATA(state, data) {
    state.translateUserData = data;
  },
  CLEAR_USERTRANSLATEDATA(state) {
    state.translateUserData = [];
  },
  SET_REGIONSDATA(state, data) {
    state.regionsData = data;
  },
  CLEAR_REGIONSDATA(state) {
    state.regionsData = [];
  },
};
const getters = {
  gettersTranslateData: (state) => state.translateData,
};
const actions = {
  async translateDataNull({ commit }) {
    commit("CLEAR_TRANSLATEDATA");
  },
  async getTranslateData({ commit }) {
    try {
      const res = await languageGet();
      const { data, statusCode } = res || {};
      if (statusCode === 200) {
        commit("SET_TRANSLATEDATA", data.items);
      }
    } catch (error) {
      console.error(error);
    }
    // console.log(state.translateData);
  },
  async getUserTranslateData({ commit }, callback) {
    try {
      const res = await getUser({
        language_id: Number(localStorage.getItem("language_id")),
      });
      const { data, statusCode } = res || {};
      if (statusCode === 200) {
        const userInfor = data;
        // const regions = state.translateData.find(
        //   (item) => item.country_id === data.country_id
        // );
        // console.log("regions", regions);
        const language_id = data.language_id;
        const country_id = data.country_id;
        // console.log("state.translateData", state.translateData);
        // const language = state.translateUserData?.[0].language.find(
        //   (item) => item.language_id === data.language_id
        // );
        const language = { language_abbreviation: userInfor.language_abbreviation, language_id: userInfor.language_id }
        commit("SET_USERTRANSLATEDATA", [language]);
        // console.log(language);
        // 存储地区id
        localStorage.setItem("country_id", data.country_id);
        // 存储语言id
        localStorage.setItem("language_id", data.language_id);
        console.log('getUserTranslateData', language.abbreviation, data.language_id);
        callback &&
          callback({
            userInfor,
            language_id,
            country_id,
          });
      }
    } catch (error) {
      console.error(error);
    }
  },

  async getRegionsData({ commit }) {
    try {
      const res = await regionGet();
      const { data, statusCode } = res || {};
      if (statusCode === 200) {
        commit("SET_REGIONSDATA", data.items);
      }
    } catch (error) {
      console.error(error);
    }
    // console.log(state.translateData);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
