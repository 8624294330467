import {
  getFootballAppreciation,
  getBasketballAppreciation,
} from "@/services/api/appreciation";
const state = {
  recommendPageStatus: {
    sportType: "football",
    category: "FREE",
  },

  // 直播间鉴球数据
  liveRecommendData: [],
  // 直播间鉴球 页码相关参数
  liveRecommendParams: {
    page: 1,
    size: 50,
    pages: 0,
  },

  // 方法发布页面的页面状态
  recommendPublishPageStatus: {
    sportType: "football",
  },

  // 个人方案页面的页面状态
  myRecommendPageStatus: {
    sportType: "football",
  },
};

const mutations = {
  // 设置推荐页面状态
  SET_RECOMMENDPAGESTATUS(state, { sportType, category }) {
    if (sportType !== undefined) {
      state.recommendPageStatus.sportType = sportType;
    }
    if (category !== undefined) {
      state.recommendPageStatus.category = category;
    }
  },

  // 直播间鉴球数据
  SET_LIVERECOMMEND_DATA(state, { data }) {
    state.liveRecommendData = data;
  },

  // 直播间鉴球 页码相关参数
  SET_LIVERECOMMEND_PARAMS(state, { data }) {
    state.liveRecommendParams = data;
  },

  // 设置推荐发布页面状态
  SET_RECOMMEND_PUBLISH_PAGESTATUS(state, { sportType }) {
    if (sportType !== undefined) {
      state.recommendPublishPageStatus.sportType = sportType;
    }
  },

  // 设置个人方案页面状态
  SET_MY_RECOMMEND_PAGESTATUS(state, { sportType }) {
    if (sportType !== undefined) {
      state.myRecommendPageStatus.sportType = sportType;
    }
  },
};

const actions = {
  async getLiveRecommendsData(
    { state, commit },
    { isRefresh, streamer_category = "HOT", sportTypeId, competitionId }
  ) {
    const fetchFn =
      sportTypeId === 1 ? getFootballAppreciation : getBasketballAppreciation;
    try {
      const res = await fetchFn({
        streamer_category: streamer_category,
        language_id: localStorage.getItem("language_id"),
        // country_id: localStorage.getItem("country_id"),
        page: !isRefresh ? state.liveRecommendParams.page + 1 : 1,
        size: state.liveRecommendParams.size,
        match_id: competitionId,
      });
      const { data, statusCode } = res;
      if (statusCode === 200) {
        const { items, page, pages, size } = data || [];
        commit("SET_LIVERECOMMEND_DATA", { data: items });
        commit("SET_LIVERECOMMEND_PARAMS", { data: { page, size, pages } });
        return { statusCode, isEmpty: !items.length };
      }
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
