<template>
  <!-- 移动端 -->

  <div class="liveRoomMain">
    <!-- 视频板块 -->
    <div class="videoContent">
      <div class="header" :class="{ showBlackBar: showComponent }">
        <div class="headerT">
          <div class="iconArea" @click="goBack">
            <van-icon name="arrow-left" color="#fff" size="22px" />
          </div>
          <div
            class="StreamName"
            v-if="showComponent || liveMode === 'competition'"
          >
            <div class="text">
              {{ competition?.name || liveInformation.live_name }}
            </div>
            <div class="homeArea" @click="goHome">
              <van-icon name="wap-home-o" />
            </div>
          </div>
        </div>
      </div>
      <div class="video">
        <VideoCom
          :currentVideo="currentVideo"
          :message="messages"
          :liveLines="liveLines"
          v-if="roomLiveMode === 'video'"
          @changeCurrentVideo="changeCurrentVideo"
          @bangsStatus="controlComponentF"
          @showMoreStreams="showMoreStreams"
        ></VideoCom>
        <MatchInfo
          :competitionId="competitionId"
          :sportTypeId="sportTypeId"
          :noLiveId="
            liveId === undefined || !liveInformation?.streamer_username
          "
          :currentVideo="currentVideo"
          @changeLiveMode="changeLiveMode"
          v-show="roomLiveMode === 'competition'"
        ></MatchInfo>
        <AnimateCom
          v-if="roomLiveMode === 'animation'"
          :url="animate_url"
        ></AnimateCom>
      </div>
    </div>
    <div class="content">
      <!-- 功能栏 v-if="liveInformation?.streamer_username" -->
      <div class="area">
        <div class="options" v-if="liveMode === 'video'">
          <!-- 本直播间主播 -->
          <div class="streams" v-if="liveInformation?.streamer_name">
            <div class="liveIcon"></div>
            <!-- <div class="streamName">
              {{
                liveInformation?.streamer_name +
                `(${liveInformation?.streamer_username})`
              }}
            </div> -->
            <div class="streamName">
              {{ liveInformation?.streamer_name }}
            </div>
          </div>
          <!-- 切换线路/主播 -->
          <div
            class="moreStreams"
            @click="moreStreams"
            v-if="liveInformation?.streamer_name"
          >
            <div class="ellipsis" v-if="diversionStreamers.length !== 0">
              ···
            </div>
            <!-- 可选切换的主播 -->
            <div class="streamImg">
              <div
                class="avatarWrap"
                v-for="(item, index) in diversionStreamers"
                :key="index"
              >
                <div
                  class="Anchor"
                  :style="{
                    backgroundImage: 'url(' + getAvatar(item) + ')',
                  }"
                ></div>
              </div>
            </div>
            <div class="title">{{ $t("switchLines") }}</div>
            <div class="icon" :class="{ rotate: MoreStreamsDrawer }"></div>
          </div>
        </div>
        <!-- 标签页 -->
        <div class="tabs">
          <van-tabs
            v-model="TMoptionActivity"
            :ellipsis="false"
            color="var(--theme-gradient-start)"
            background="var(--theme-box-1)"
            title-active-color="#ffffff"
            title-inactive-color="#ffffff"
          >
            <!-- 广场消息 -->
            <van-tab
              v-if="liveId !== undefined && !disableSquare"
              :title="$t('streamChat')"
              name="streamChat"
              class="van-tab"
            >
              <SquareCom
                :TMoptionActivity="TMoptionActivity"
                :messages="messages"
                :userInfo="userInfo"
                :squareData="{ viewsNumber, startTime }"
                @changeMessages="changeMessages"
                @changeTabToStreamerPrivateChat="changeTabToStreamerPrivateChat"
              >
              </SquareCom>
            </van-tab>

            <!-- 主播私聊 -->
            <van-tab
              v-if="liveId !== undefined && chatAssistant"
              :title="$t('streamerPrivateChat')"
              name="streamerPrivateChat"
              class="van-tab"
            >
              <LiveRoomChatInterface
                mode="privateChatMode"
                :liveId="liveId"
                :TMoptionActivity="TMoptionActivity"
                :targetName="chatTargetName"
                :chatData="{
                  category: 'private',
                  targetName: liveInformation?.streamer_name,
                  streamer_username: liveInformation?.streamer_username,
                  targetId: chatAssistant?.username,
                  targetAvatar: chatAssistant?.avatar,
                }"
              ></LiveRoomChatInterface>
            </van-tab>

            <!-- 聊天记录列表 -->
            <van-tab :title="$t('chat')" name="chat" class="van-tab chatTab">
              <van-image
                class="pullDownIcon"
                v-if="showLoading"
                :src="logoUrl"
              ></van-image>
              <ChatList
                v-if="!showChatInterface"
                @enterChat="enterChat"
                @changeLoading="changeLoading"
                :targetFromRecommend="targetFromRecommend"
                mode="liveRoom"
                :TMoptionActivity="TMoptionActivity"
              ></ChatList>
              <LiveRoomChatInterface
                v-else
                mode="chatInterfaceMode"
                @goBack="showChatInterface = false"
                :targetName="chatTargetName"
                :TMoptionActivity="TMoptionActivity"
                :chatData="chatInterfaceData"
              ></LiveRoomChatInterface>
            </van-tab>

            <!-- 推荐板块 -->
            <van-tab
              :title="$t('recommend')"
              name="recommend"
              class="van-tab"
              v-if="competitionId && liveRecommendDataLength"
            >
              <LiveRecommend
                @changeTabToChat="changeTabToChat"
                :competitionId="competitionId"
                :sportTypeId="sportTypeId"
                :userInfo="userInfo"
              ></LiveRecommend>
            </van-tab>

            <!-- 指数板块 -->
            <van-tab
              :title="$t('odds')"
              name="odds"
              class="van-tab"
              v-if="competitionId"
            >
              <FootballExponentCom
                v-if="sportTypeId === 1"
                parentComponent="LiveRoom"
                :competition_id="competitionId"
                :source_id="sourceId"
                :sport_type_id="sportTypeId"
                :TMoptionActivity="TMoptionActivity"
              ></FootballExponentCom>
              <BasketballExponentCom
                v-if="sportTypeId === 2"
                parentComponent="LiveRoom"
                :competition_id="competitionId"
                :source_id="sourceId"
                :sport_type_id="sportTypeId"
                :TMoptionActivity="TMoptionActivity"
              ></BasketballExponentCom>
            </van-tab>

            <!-- 主播信息 -->
            <van-tab
              v-if="liveInformation?.streamer_username"
              :title="$t('streamerInformation')"
              name="streamerInformation"
              class="van-tab"
            >
              <AnchorInformation
                :streamerUsername="streamer_username"
                :liveId="Number(liveId)"
                @refreshGrandParent="clickLiveCard"
                @changeTabToChat="changeTabToChat"
              ></AnchorInformation>
            </van-tab>
            <!-- 赛况板块 -->
            <van-tab
              :title="$t('matchStatus')"
              name="matchStatus"
              class="van-tab"
              v-if="competitionId"
            >
              <ScheduleCom
                :competitionId="competitionId"
                :TMoptionActivity="TMoptionActivity"
                :sportTypeId="sportTypeId"
              ></ScheduleCom>
            </van-tab>
          </van-tabs>

          <!-- 切换线路弹出层 -->
          <div
            class="switchLinesPopup"
            :class="{ showPopup: MoreStreamsDrawer }"
          >
            <SwitchLines
              v-if="MoreStreamsDrawer"
              :liveInformation="liveInformation"
              :liveId="liveId"
              @cancel="cancel"
              @changeCurrentVideo="changeCurrentVideo"
              @refreshLiveRoom="clickWatchOtherLive"
            ></SwitchLines>
          </div>
        </div>
      </div>
    </div>
    <BindPhoneDialog :isLogin="role === 'user'"></BindPhoneDialog>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus";
/* eslint-disable */
import SquareCom from "@/components/SquareCom/Index.vue";
import ChatList from "@/components/ChatList/Index.vue";
import LiveRoomChatInterface from "@/components/LiveRoomChatInterface/Index.vue";
import VideoCom from "@/components/VideoCom/Index.vue";
import SwitchLines from "@/components/SwitchLines/Index.vue";
import FootballExponentCom from "@/components/ExponentCom/FootballIndex.vue";
import BasketballExponentCom from "@/components/ExponentCom/BasketballIndex.vue";
import ScheduleCom from "@/components/ScheduleCom/Index.vue";
import AnchorInformation from "@/components/AnchorInformation/Index.vue";
import BindPhoneDialog from "@/components/BindPhoneDialog/Index.vue";
import LiveRecommend from "@/components/LiveRecommend/Index.vue";
import AnimateCom from "@/components/AnimateCom/Index.vue";
import MatchInfo from "@/components/MatchInfo/Index.vue";
import { liveQuery, getPrivateChatInfo } from "@/services/api/Live";
import { getLiveChatChannel } from "@/services/api/IM";
import { getLiveChatHistoryMessage } from "@/services/api/LiveChat";
import { getUser } from "@/services/api/User";
import { mapState, mapActions, mapMutations } from "vuex";
import { checkClientTokenRole } from "@/utils/authCookie";
// import toast from "@/utils/toast.js";
const MANUAL_CLOSE = 3007; //

export default {
  components: {
    SquareCom,
    ChatList,
    VideoCom,
    SwitchLines,
    FootballExponentCom,
    BasketballExponentCom,
    ScheduleCom,
    AnchorInformation,
    LiveRoomChatInterface,
    BindPhoneDialog,
    LiveRecommend,
    AnimateCom,
    MatchInfo,
  },
  data() {
    return {
      TMoptionActivity: "streamChat",
      Moptions: ["liveChat", "privateChat", "recentContact", "SwitchingLine"],
      liveId: "", //直播间号
      competitionId: "", //比赛id
      sourceId: "",
      competition: null, //比赛信息
      sportTypeId: null, // 体育id
      streamer: {}, //主播信息
      startTime: null, //开始时间
      currentVideo: null, //当前视频
      messages: [], //消息列表
      streamer_username: null, //主播ID
      userInfo: {},
      viewsNumber: "", //观看人数
      role: "", //是否登录
      MoreStreamsDrawer: false, //更多主播列表
      //当前直播间信息
      liveInformation: {},
      // 当前直播间主播私聊信息
      chatAssistant: null,
      showChatInterface: false, // 是否显示聊天界面
      chatInterfaceData: null, // 直播间进入聊天界面详细页面所需的参数
      chatTargetName: "", // 聊天对象名称
      diversionStreamers: [], // 可切换的主播头像列表
      targetFromRecommend: null, // 由推荐tab的聊天跳转而来的主播username
      // FIXME: mock liveMode为video， 初始值为competition
      liveMode: "competition", // 直播模式: 比赛/视频/动画
      showComponent: true, //直播界面刘海是否显示
      // timer: null,
      showLoading: false, // 是否展示加载中
      // disableSquare: false, // 是否禁用广场
      liveLines: [], // 视频线路
      disableLoading: false, // 隐藏loading
      logoUrl: require("@/assets/client-logos/com-logo@3x.webp"),
      animate_url: "",
      imageBaseUrl: "", // 图片基础路径
    };
  },
  computed: {
    /**
     * 获取头像
     */
    getAvatar() {
      return (streamer) => {
        return streamer.isLoaded
          ? this.imageBaseUrl + streamer?.avatar
          : streamer.placeholderAvatar;
      };
    },
    roomLiveMode() {
      return this.liveMode;
    },

    disableSquare() {
      const statusId = this.liveCurrentMatch?.competition_status?.id;
      if (statusId === 1 || statusId === 3) {
        return true;
      } else {
        return false;
      }
    },

    ...mapState({
      liveRecommendDataLength: (state) =>
        state.recommend.liveRecommendData.length,

      liveCurrentMatch: (state) => state.competitions.liveCurrentMatch,
    }),
  },
  async created() {
    EventBus.$on("changeFollowed", this.changeFollowed);
    this.getLiveRecommendsData({
      isRefresh: true,
      streamer_category: "HOT",
      sportTypeId: Number(this.$route.query?.sportTypeId),
      competitionId: this.$route.query?.competitionId,
    });
    const basePathStr = localStorage.getItem("basePathObj");
    const basePathObj = JSON.parse(basePathStr) || {};
    this.imageBaseUrl = basePathObj?.fileServer || "";
    await this.initCreated();
  },

  async mounted() {
    await this.initMounted();
  },
  watch: {
    "$route.query.liveId": {
      async handler(newVal) {
        this.SET_CURRENT_LIVE_ID({id: newVal});
        this.SET_CURRENT_LIVE_LINE_KEY({ key: 0 });
        await this.initCreated();
        await this.initMounted();
      },
    },

    liveMode(newVal) {
      this.SET_TASKBEACON_VALUE({
        key: "TASK_LiveRoom_liveMode",
        value: newVal,
      });
    },
  },
  beforeDestroy() {
    this.SET_CURRENT_LIVE_ID({id: null});
    this.disconnectLiveWS({
      code: MANUAL_CLOSE,
      reason: "channel主动关闭",
    });
    this.setLiveCurrentMatch({});
  },
  methods: {
    ...mapActions("competitions", [
      "setLiveCurrentMatch",
    ]),
    ...mapActions("liveWS", [
      "createLiveWS",
      "handleLiveMessge",
      "disconnectLiveWS",
    ]),
    ...mapActions("recommend", ["getLiveRecommendsData"]),
    ...mapMutations("taskBeacon", ["SET_TASKBEACON_VALUE"]),
    ...mapMutations("lives", ["SET_CURRENT_LIVE_ID", "SET_CURRENT_LIVE_LINE_KEY"]),
    changeFollowed(type) {
      this.liveInformation.follower_count =
        type == 1
          ? this.liveInformation.follower_count - 1
          : this.liveInformation.follower_count + 1;
      console.log(type);
    },
    async initData() {
      this.role = checkClientTokenRole();
      this.liveId = this.$route.query?.liveId;
      this.SET_CURRENT_LIVE_ID({id: this.liveId});
      await this.getAssisant();
      await this.getLiveQuery();
      this.competitionId = this.$route.query?.competitionId;
      this.sourceId = this.$route.query?.sourceId;
      this.sportTypeId = Number(this.$route.query?.sportTypeId);
      // this.disableSquare = this.$route.query?.disableSquare === true;
      if (this.$route.query?.liveRoomTabActivity) {
        this.TMoptionActivity = this.$route.query.liveRoomTabActivity;
      } else {
        // 判断是否有tab激活状态记录
        const tabActivity = sessionStorage.getItem("liveRoomTabActivity");
        if (tabActivity) {
          this.TMoptionActivity = tabActivity;
          this.disableLoading = true;
          // 清除保存的路径
          sessionStorage.removeItem("liveRoomTabActivity");
        }
      }
      if (this.disableSquare) {
        this.TMoptionActivity = "streamerPrivateChat";
      }
    },
    changeLiveMode(value) {
      this.liveMode = value;
      // 关闭切换线路
      this.MoreStreamsDrawer = false;
      if (this.liveMode === "video" || this.liveMode === "animation") {
        this.controlComponentF();
      } else if (this.liveMode === "competition") {
        clearTimeout(this.timer);
        this.showComponent = true;
      }
    },
    // 获取直播广场websocket地址
    async initLiveChatWS() {
      try {
        if (this.liveId === undefined) {
          return;
        }
        const res = await getLiveChatChannel({
          live_id: this.liveId,
        });
        const { data, statusCode } = res;
        if (statusCode === 200) {
          const {
            link,
            heartbeat_interval: heartbeatInterval,
            ack_nbits: ackNbits,
            heartbeat_tolerance: heartbeatTolerance,
            max_reconnect_times: maxReconnectTimes,
            ack_interval: ackInterval,
          } = data || {};
          this.createLiveWS({
            url: link,
            options: {
              heartbeatInterval,
              ackNbits,
              heartbeatTolerance,
              maxReconnectTimes,
              ackInterval,
            },
          });
          this.handleLiveMessge({
            type: "live_chat_message_receive",
            callback: (message) => {
              const { data } = message;
              const cur = this.messages.find(
                (item) => item.message_id === data.message_id
              );
              if (cur) return;
              this.messages.push(data);
            },
            id: "liveChatList",
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    changeMessages(data) {
      this.messages = data;
    },
    // 跳转至聊天
    enterChat(data) {
      const { category, target_id, name, avatar } = data;
      this.chatInterfaceData = {
        category,
        targetName: name,
        targetId: target_id,
        targetAvatar: avatar,
      };
      this.chatTargetName = name;
      this.showChatInterface = true;
      this.targetFromRecommend = null;
    },
    // 关闭更多主播列表
    cancel() {
      this.MoreStreamsDrawer = false;
    },
    // 跳转登录页
    toLoginRouter() {
      this.$router.push("/LogInRegister");
    },
    //  获取用户信息
    async getUserInfo() {
      try {
        const res = await getUser({
          // country_id: Number(localStorage.getItem("country_id")),
          language_id: Number(localStorage.getItem("language_id")),
        });
        if (res.statusCode === 200) {
          const { data } = res;
          this.userInfo = data;
        } else {
          this.userInfo = {};
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    /**
     * 接收来自SquareCom的事件
     */
    changeTabToStreamerPrivateChat() {
      this.TMoptionActivity = "streamerPrivateChat";
    },

    /**
     * 接收来自LiveRecommend/AnchorInformation的事件
     */
    changeTabToChat(target) {
      this.TMoptionActivity = "chat";
      this.targetFromRecommend = target;
    },

    moreStreams() {
      this.MoreStreamsDrawer = !this.MoreStreamsDrawer;
    },
    showMoreStreams() {
      this.MoreStreamsDrawer = true;
    },
    // 获取广场历史消息
    async historyLiveroomMessages() {
      if (!this.liveId) {
        return;
      }
      const params = {
        page: 1,
        size: 40,
        live_id: this.liveId,
      };
      try {
        const res = await getLiveChatHistoryMessage(params);
        const { data, statusCode } = res;
        if (statusCode === 200) {
          const rows = data.items;
          rows.sort((a, b) => a.send_at - b.send_at);
          this.messages = rows;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getAssisant() {
      if (!this.liveId) return;
      try {
        const res = await getPrivateChatInfo(this.liveId);
        const { data, statusCode } = res;
        if (statusCode === 200) {
          this.chatAssistant = data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 获取直播间信息
    async getLiveQuery() {
      if (this.liveId === undefined) {
        return;
      }
      const param = {
        live_id: this.liveId,
        country_id: Number(localStorage.getItem("country_id")),
        language_id: Number(localStorage.getItem("language_id")),
      };
      try {
        const res = await liveQuery(param);
        const { statusCode, data } = res;
        if (statusCode === 200) {
          const {
            live_lines,
            streamer_username,
            streamer_name,
            view_count,
            live_duration,
            competition,
            animate_url,
          } = data || {};
          if (!competition) this.changeLiveMode("video");
          this.liveInformation = data;
          this.chatTargetName = streamer_name;
          this.competition = competition;
          this.sportTypeId = competition?.sport_type_id;
          this.streamer_username = streamer_username;
          this.viewsNumber = view_count;
          this.startTime = live_duration;
          this.currentVideo = live_lines?.[0];
          this.animate_url = animate_url;
          this.liveLines = live_lines;
          this.diversionStreamers = this.getDiversionStreamers(
            data.diversion_streamers
          );
          this.diversionStreamers.forEach((streamer) =>
            this.loadFullAvatar(streamer)
          );
        } else {
          console.error(res.statusText);
        }
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * 获取 可切换的主播 列表
     * 1.限制最大长度 2.增加属性:占位图 头像加载状态
     * @param { Array } res.data.diversion_streamers
     */
    getDiversionStreamers(original) {
      let diversionStreamers =
        original.length > 3 ? original.slice(0, 3) : original; // 限制主播头像列表长度为3
      return diversionStreamers.map((item) => {
        return {
          ...item,
          placeholderAvatar: require("@/assets/client-logos/nodata.webp"),
          isLoaded: false,
        };
      });
    },

    /**
     * 加载主播头像
     * @param { Object } streamer 主播
     */
    loadFullAvatar(streamer) {
      const img = new Image();
      img.src = this.imageBaseUrl + streamer?.avatar;
      img.onload = () => {
        streamer.isLoaded = true; // 图像加载完成后更新状态
      };
    },

    // 切换视频线路
    changeCurrentVideo(data) {
      this.currentVideo = data;
    },
    goBack() {
      if (
        (this.roomLiveMode === "video" || this.roomLiveMode === "animation") &&
        this.competitionId
      ) {
        this.changeLiveMode("competition");
        return;
      }
      this.$router.go(-1);
    },

    /**
     * 来自子组件AnchorInformation的事件
     * 更新路由参数 刷新页面
     */
    async clickLiveCard({ competition_id, live_id, sport_type_id, source_id }) {
      this.$router.push({
        name: "LiveRoom",
        query: {
          ...this.$route.query,
          liveId: live_id,
          competitionId: competition_id,
          sportTypeId: sport_type_id,
          sourceId: source_id,
        },
      });
      // this.$router.go(0);
    },

    /**
     * 来自子组件SwitchLines的事件
     * 更新路由参数 刷新页面
     */
    clickWatchOtherLive({ live_id }) {
      this.$router.push({
        name: "LiveRoom",
        query: {
          ...this.$route.query,
          liveId: live_id,
        },
      });
      this.$router.go(0);
    },

    /**
     * 更新 主播 直播状态
     */
    updataStreamerStatus(data) {
      // 当前主播
      if (this.liveInformation?.streamer_username === data.username) {
        this.liveInformation.status = data.status;
        if (data.status === 0) {
          this.liveInformation.live_lines.length = 0;
        }
      }
      // '切换线路'栏的主播预览列表
      const targetIndex = this.diversionStreamers.findIndex(
        (item) => item.username === data.username && data.status === 0
      );
      if (targetIndex !== -1) {
        this.diversionStreamers.splice(targetIndex, 1);
      }
    },

    /**
     * 更新 直播热度/观看人数
     */
    updataViewCount(data) {
      if (this.liveId === Number(data.live_id)) {
        this.viewsNumber = data.view_count;
      }
    },

    // 设置刘海显隐
    controlComponentF() {
      this.showComponent = true;
      this.timer = setTimeout(() => {
        this.showComponent = false;
      }, 2300);
    },

    changeLoading(value) {
      this.showLoading = value;
    },

    // 初始化created
    async initCreated() {
      await this.initData();
      await this.initLiveChatWS();
    },
    // 初始化mounted
    async initMounted() {
      await this.getUserInfo();
      await this.historyLiveroomMessages();
    },
    goHome() {
      this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/LiveRoom/Index.scss";
</style>
<style lang="scss" scoped>
@import "@/styles/LiveRoom/Index.vant.scss";
</style>
<style lang="scss">
@import "@/styles/ConfirmDialog.vant.scss";
</style>
