import request from "@/services/axios/commonRequest";
import requestV2 from "@/services/axios/commonRequestV2/index";

// 查询备选语言映射表
export function getAlternativeLanguages() {
  return request({
    url: "/alternative-languages-map",
    method: "get",
  });
}

// 获取首页轮播图
export function getCarousels(params) {
  return requestV2({
    url: "/carousels",
    method: "get",
    params: params,
  });
}

// 查询用户是否已绑定手机
export function getIsBindPhoneAndEmail() {
  return requestV2({
    url: "/user-status/me",
    method: "get",
  });
}

// 获取直播间列表
export function getLives(params) {
  return requestV2({
    url: "/lives",
    method: "get",
    params: params,
  });
}
// 猜你喜欢的接口
export function getRecommendedLives(params) {
  return requestV2({
    url: "/recommend/lives-and-strategy-streamers",
    method: "get",
    params: params,
  });
}
// 获取主播列表
export function getStreamers(params) {
  return requestV2({
    url: "/streamers",
    method: "get",
    params: params,
  });
}
// 获取主播列表
export function getFollowStreamers(params) {
  return requestV2({
    url: "/streamers/me",
    method: "get",
    params: params,
  });
}


// 关注主播
export function followStreamer(streamer_username) {
  return requestV2({
    url: "/follow-streamer",
    method: "post",
    data: { usernames: [streamer_username] },
  });
}

// 取消关注主播
export function unFollowStreamer(streamer_username) {
  return requestV2({
    url: "/unfollow-streamer",
    method: "post",
    data: { usernames: [streamer_username] },
  });
}
