/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "उपयोगकर्ता का समझौता",
  privacyPolicy: "गोपनीयता नीति",
  termsOfService: "सेवा की शर्तें",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "अभी तक शुरू नहीं हुआ",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "पहले ही प्रसारित हो चुका है",
  watch: "जाओ देखो",
  follow: "ध्यान केंद्रित करना",
  following: "पहले से ही फ़ॉलो कर रहे हैं",
  subscribe: "अंशदान",
  streamerSchedule: "एंकर शेड्यूल",
  noDataPrompt: "कोई डेटा नहीं",
  unfollowPrompt: "क्या आप निश्चित रूप से अनफ़ॉलो करना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  hours: "घंटा",
  mins: "मिनट",
};

const AnchorInformation = {
  recommendedLiveStreams: "अनुशंसित लाइव प्रसारण",
  noDataPrompt: "कोई डेटा नहीं",
};

const BasketballScoreIndex = {
  details: "विवरण",
  over: "ऊपर",
  pointsDifference: "अंकों का अंतर",
  totalScore: "कुल स्कोर",
  live: "LIVE",
  unSubscribePrompt: "क्या आप निश्चित रूप से अपनी सदस्यता रद्द करना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
};

const BasketballSmallBig = {
  details: "विवरण",
  over: "ऊपर",
  pointsDifference: "अंकों का अंतर",
  totalScore: "कुल स्कोर",
  live: "LIVE",
  unSubscribePrompt: "क्या आप निश्चित रूप से अपनी सदस्यता रद्द करना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
  overIndex: "बड़ा",
  underIndex: "छोटा",
};

const BindPhoneDialog = {
  bindPhonePrompt: "अपने खाते की सुरक्षा के लिए, कृपया अपना मोबाइल फ़ोन नंबर बाध्य करें ताकि हम आपको बेहतर सेवाएँ प्रदान कर सकें।",
  goBind: "बाइंडिंग पर जाएं",
};

const BottomNavigationBar = {
  home: "मुखपृष्ठ",
  matches: "प्रतियोगिता",
  recommend: "अनुशंसा करना",
  chat: "बात करना",
  personal: "निजी",
  discovery: "खोज करना",
  money: "पैसा बनाएं",
};

const ChatInterface = {
  noCsPrompt: "फिलहाल चैट करने में असमर्थ",
  uploadRulePrompt: "कृपया चित्र जेपीजी, जेपीईजी, पीएनजी प्रारूप में अपलोड करें",
  uploadSizePrompt: "छवि का आकार 10M से अधिक नहीं हो सकता",
  sendError: "भेजने में विफल",
  picSendError: "चित्र भेजना विफल रहा",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
  bannedSendPrompt: "आप पर प्रतिबंध लगा दिया गया है",
};

const ChatList = {
  noNewPrompt: "अभी तक कोई खबर नहीं",
  logInPrompt: "सामग्री देखने के लिए लॉग इन करें, कृपया पहले लॉग इन करें",
  logIn: "लॉग इन करें",
  group: "समूह",
};

const ChatListItem = {
  withdrawMessage: "एक संदेश वापस ले लिया",
  bannedPrompt: "पर प्रतिबंध लगा दिया",
  unBanPrompt: "अप्रतिबंधित",
  joinGroupPrompt: "समूह में शामिल हो गए",
  leftGroupPrompt: "समूह छोड़ दिया",
  picture: "चित्र",
  link: "जोड़ना",
  emoji: "एनिमेटेड इमोटिकॉन्स",
  addedFriendPrompt: "मित्र के रूप में जोड़ा गया",
};

const CompetitionList = {
  logInPrompt: "सामग्री देखने के लिए लॉग इन करें, कृपया पहले लॉग इन करें",
  logIn: "लॉग इन करें",
  today: "आज",
  Monday: "सोमवार",
  Tuesday: "मंगलवार",
  Wednesday: "बुधवार",
  Thursday: "गुरुवार",
  Friday: "शुक्रवार",
  Saturday: "शनिवार",
  Sunday: "रविवार",
  noGames: "अभी तक कोई खेल नहीं",
  toCollapse: "संक्षिप्त करने के लिए क्लिक करें",
  toExpand: "विस्तार करने के लिए क्लिक करें"
};

const CompetitionListItem = {
  details: "विवरण",
  unSubscribePrompt: "क्या आप निश्चित रूप से अपनी सदस्यता रद्द करना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
};

const CompetitionMode = {
  matchMode: "प्रतियोगिता मोड",
  confirm: "पुष्टि करना",
  cancel: "रद्द करना",
};

const EmojiCom = {
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  yes: "हाँ",
  no: "नहीं",
};

const ExponentCom = {
  company: "कंपनी",
  initial: "प्रारंभिक",
  ongoing: "तुरंत",
  preMatch: "खेल से पहले",
  noDataPrompt: "कोई डेटा नहीं",
  handicap: "अपंगता",
  WinDrawLose: "जीतो, ड्रा करो, हारो",
  totalGoals: "कुल लक्ष्य",
  cornerKick: "सींग",
  pleaseSelect: "कृपया चुनें",
  confirm: "पुष्टि करना",
  cancel: "रद्द करना",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "लाइव चैट के लिए पिन करें",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "ध्यान केंद्रित करना",
  following: "पहले से ही फ़ॉलो कर रहे हैं",
  chat: "बात करना",
  noDataPrompt: "कोई डेटा नहीं",
  unfollowPrompt: "क्या आप निश्चित रूप से अनफ़ॉलो करना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
};

const FootballCornerIndex = {
  details: "विवरण",
  over: "ऊपर",
  FH: "HT",
  cornerKick: "सींग",
  unSubscribePrompt: "क्या आप निश्चित रूप से अपनी सदस्यता रद्द करना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
};

const FootballScoreIndex = {
  details: "विवरण",
  over: "ऊपर",
  FH: "HT",
  cornerKick: "सींग",
  live: "LIVE",
  unSubscribePrompt: "क्या आप निश्चित रूप से अपनी सदस्यता रद्द करना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
};

const FootballScoreItem = {
  details: "विवरण",
  over: "ऊपर",
  FH: "HT",
  cornerKick: "सींग",
  live: "LIVE",
  unSubscribePrompt: "क्या आप निश्चित रूप से अपनी सदस्यता रद्द करना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
};

const GoalToast = {
  goal: "लक्ष्य",
};

const LiveRecommend = {
  noDataPrompt: "कोई डेटा नहीं",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "जीत हो या हार",
};

const MatchCardList = {
  details: "विवरण",
};

const MatchStatus = {
  noDataPrompt: "कोई डेटा नहीं",
};

const MoreStreams = {
  switchLines: "स्विच लाइनें",
  cancel: "रद्द करना",
  currentStreamer: "वर्तमान एंकर",
  live: "LIVE",
  offLine: "अभी तक शुरू नहीं हुआ",
  followersCount: "ध्यान केंद्रित करना",
  otherStreamersPrompt: "वर्तमान में खेल का प्रसारण करने वाले अन्य एंकर भी हैं",
  watch: "जाओ देखो",
};

const NewMatchStatus = {
  team: "टीम",
  halfTimeScore: "आधे समय का स्कोर",
  shotOnTarget: "गोल पर शॉट",
  missedShot: "लक्ष्य चूक गया",
  attack: "आक्रमण करना",
  dangerousAttack: "खतरनाक हमला",
  number3PointGoals: "बनाए गए 3-पॉइंट गोलों की संख्या",
  number2PointGoals: "बनाए गए 2-पॉइंट गोलों की संख्या",
  penaltyKickGoals: "पेनल्टी किक गोल",
  freeThrowPercentage: "फ़्री थ्रो प्रतिशत",
  numberOfPausesRemaining: "शेष विरामों की संख्या",
  numberOfFouls: "बेईमानी की संख्या",
  totalNumberOfPauses: "पूर्ण विराम",
  threePointShot: "3 पॉइंट शॉट",
  twoPointShot: "2 पॉइंट शॉट",
  shoot: "गोली मार",
  penalty: "दंड",
  firstHalf: "पहली छमाही",
  secondHalf: "दूसरी छमाही",
  redCard: "लाल कार्ड",
  yellowCard: "येल्लो कार्ड",
};

const NoData = {
  noDataPrompt: "कोई डेटा नहीं",
};

const NoticeMessage = {
  addedFriendPrompt: "मित्र के रूप में जोड़ा गया",
  withdrawMessage: "एक संदेश वापस ले लिया",
  withdrawMessageByAdmin: "व्यवस्थापक द्वारा एक संदेश वापस ले लिया गया",
  joinGroupPrompt: "समूह में शामिल हो गए",
  leftGroupPrompt: "समूह छोड़ दिया",
  removeGroupPrompt: "एडमिनिस्ट्रेटर द्वारा ग्रुप से हटा दिया गया",
  bannedPrompt: "पर प्रतिबंध लगा दिया",
  unBanPrompt: "अप्रतिबंधित",
};

const OddsStatisticsList = {
  initial: "प्रारंभिक",
  ongoing: "तुरंत",
  preMatch: "खेल से पहले",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "उच्चतम मूल्य",
  min: "सबसे कम मूल्य",
  average: "औसत मूल्य",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "कोई डेटा नहीं",
};

const RecommendCompetitions = {
  today: "आज",
  winsInARow: "बीच में भी",
  noLivePrompt: "अभी तक कोई सीधा प्रसारण नहीं",
};

const RecommendStreamerList = {
  noDataPrompt: "कोई डेटा नहीं",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "सप्ताह का हिट",
  victoryOrDefeat: "जीत हो या हार",
  check: "जाँच करना",
  chat: "बात करना",
  quarterlyProfit: "त्रैमासिक लाभ",
};

const RecommendStreamers = {
  recommendedByExperts: "विशेषज्ञों द्वारा अनुशंसित",
  winsInARow: "बीच में भी",
  changeBatch: "एक बैच बदलें",
  follow: "ध्यान केंद्रित करना",
  following: "पहले से ही फ़ॉलो कर रहे हैं",
  unFollowing: "अनलॉक",
  noDataPrompt: "कोई डेटा नहीं",
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  yes: "हाँ",
  no: "नहीं",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
  unfollowPrompt: "क्या आप निश्चित रूप से अनफ़ॉलो करना चाहते हैं?",
};

const RecordChart = {
  record: "अभिलेख",
  allWaysToPlay: "खेलने के सभी तरीके",
  fromFarToNear: "दूर से निकट की ओर",
  lastTenGames: "पिछले 10 खेल",
  longestConsecutiveHit: "सबसे लंबे समय तक लगातार हिट",
  hitRate: "प्राप्ति दर",
  currentPropmt: "हाल के खेल",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "उपयोगकर्ता जानकारी प्राप्त करने में विफल",
  getAreaAndLanguageErrorPrompt: "क्षेत्रीय भाषा प्राप्त करने में विफल",
};

const ScheduleCom = {
  startTime: "प्रारम्भ का समय",
};

const SchemeCard = {
  planList: "योजना सूची",
  release: "मुक्त करना",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "मुआवज़े की गारंटी",
  free: "मुक्त",
  toll: "टोल",
  victoryOrDefeat: "जीत हो या हार",
};

const SquareCom = {
  watchPrompt: "लोग देख रहे हैं",
  startedPrompt: "कुछ मिनट पहले शुरू हुआ",
  chat: "बात करना",
  sendMessageNeedLoginPrompt: "संदेश भेजने के लिए कृपया लॉग इन करें",
  assistant: "प्रशासक",
  audience: "उपयोगकर्ता",
  newMessage: "नई खबर",
  confirmSend: "भेजने की पुष्टि करें",
  cancelSend: "रद्द करना",
};

const StatisticalChart = {
  hitRate: "प्राप्ति दर",
};

const SwitchLines = {
  switchLines: "स्विच लाइनें",
  cancel: "रद्द करना",
  currentStreamer: "वर्तमान एंकर",
  live: "LIVE",
  offLine: "अभी तक शुरू नहीं हुआ",
  followersCount: "ध्यान केंद्रित करना",
  otherStreamersPrompt: "वर्तमान में खेल का प्रसारण करने वाले अन्य एंकर भी हैं",
  watch: "जाओ देखो",
  LINE: "रेखा",
  VIP: "वीआईपी",
  OFFICIAL: "अधिकारी",
};

const VideoCom = {
  videoErrorPrompt: "सिग्नल बहुत अच्छा नहीं है, कृपया बाद में पुनः प्रयास करें।",
  clickRetryPrompt: "पुनः प्रयास करने के लिए क्लिक करें",
};

const WelcomeCard = {
  join: "समूह में शामिल",
};

//utils
const tools = {
  yesterday: "कल",
  addedFriendPrompt: "मित्र के रूप में जोड़ा गया",
  withdrawMessage: "एक संदेश वापस ले लिया",
  joinGroupPrompt: "समूह में शामिल हो गए",
  leftGroupPrompt: "समूह छोड़ दिया",
  removeGroupPrompt: "एडमिनिस्ट्रेटर द्वारा ग्रुप से हटा दिया गया",
  bannedPrompt: "पर प्रतिबंध लगा दिया",
  unBanPrompt: "अप्रतिबंधित",
};

//页面
const AddressBook = {
  search: "खोजो",
  addFriend: "दोस्त जोड़ें",
  myGroupChat: "मेरा समूह चैट",
  friends: "दोस्त",
  delete: "मिटाना",
  noFriendsPrompt: "अभी तक कोई दोस्त नहीं",
  nameAndIdSearch: "नाम, आईडी दर्ज करें",
  searchResults: "परिणाम खोजें",
  notFoundPrompt: "उपयोगकर्ता नहीं मिला",
  add: "इसमें जोड़ें",
  deleteFriendPrompt: "क्या आप निश्चित रूप से मित्र को हटाना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  addFriendSuccessPrompt: "मित्र को सफलतापूर्वक जोड़ें",
  deleteFriendSuccessPrompt: "मित्र को सफलतापूर्वक हटाएँ",
  InputSearchPrompt: "कृपया सामग्री दर्ज करें",
};

const GroupChat = {
  groupChat: "समूह बातचीत",
  groupSearch: "समूह चैट नाम दर्ज करें",
  noGroupPrompt: "अभी तक कोई समूह चैट नहीं",
  chat: "बात करना",
  InputSearchPrompt: "कृपया सामग्री दर्ज करें",
};

const GroupAdministrator = {
  administrator: "प्रशासक",
  groupOwner: "भगवान",
};

const GroupAnnouncement = {
  groupAnnouncement: "समूह घोषणा",
  groupOwner: "भगवान",
  administrator: "प्रशासक",
};

const GroupDetail = {
  groupDetail: "समूह विवरण",
  groupMembers: "समूह के सदस्यों को",
  groupName: "समूह नाम",
  groupDescription: "समूह विवरण",
  groupOwner: "भगवान",
  administrator: "प्रशासक",
  administratorList: "प्रशासक सूची",
  groupAnnouncement: "समूह घोषणा",
  groupFiles: "समूह फ़ाइल",
  exitGroup: "समूह से बाहर निकलें",
  exitGroupPrompt: "क्या आप निश्चित रूप से समूह छोड़ देंगे?",
  yes: "हाँ",
  no: "नहीं",
  leftGroupSuccessPrompt: "समूह से सफलतापूर्वक बाहर निकल गया",
};

const GroupFile = {
  groupFiles: "समूह फ़ाइल",
  fileSearch: "फ़ाइल नाम दर्ज करें",
  today: "आज",
  multimedia: "मल्टीमीडिया",
  file: "दस्तावेज़",
  noFilesPrompt: "अभी तक कोई फ़ाइल नहीं",
};

const GroupMembers = {
  groupMembers: "समूह के सदस्यों को",
  nameAndIdSearch: "नाम, आईडी दर्ज करें",
};

const Chat = {
  chat: "बात करना",
  nameAndIdSearch: "नाम, आईडी दर्ज करें",
  contacts: "पता पुस्तिका",
  InputSearchPrompt: "कृपया सामग्री दर्ज करें",
  deleteContactPrompt: "क्या आप वाकई चैट हटाना चाहते हैं?",
};

const Competition = {
  all: "All",
  ongoing: "तुरंत",
  popular: "लोकप्रिय",
  liveCompetition: "सीधा आ रहा है",
  schedule: "अनुसूची",
  results: "परिणाम",
  subscribed: "सदस्यता लिया",
  goal: "लक्ष्य",
  today: "आज",
  Monday: "सोमवार",
  Tuesday: "मंगलवार",
  Wednesday: "बुधवार",
  Thursday: "गुरुवार",
  Friday: "शुक्रवार",
  Saturday: "शनिवार",
  Sunday: "रविवार",
};

const CompetitionDetails = {
  subscribe: "अंशदान",
  subscribed: "सदस्यता लिया",
  matchStreamerPrompt: "लाइव गेम एंकर",
  live: "LIVE",
  noDataPrompt: "कोई डेटा नहीं",
  noStreamersPrompt: "वर्तमान में इस खेल का प्रसारण करने वाला कोई मेजबान नहीं है",
  alreadyStreamerPrompt: "एक मेज़बान ने मैच का प्रसारण शुरू कर दिया है क्या आप जाकर इसे देखना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  liveStream: "सीधा आ रहा है",
  matchStatus: "मैच की स्थिति",
  odds: "Odds",
  unSubscribePrompt: "क्या आप निश्चित रूप से अपनी सदस्यता रद्द करना चाहते हैं?",
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
};

const OddsDetails = {
  company: "कंपनी",
  handicap: "अपंगता",
  WinDrawLose: "जीतो, ड्रा करो, हारो",
  totalGoals: "कुल लक्ष्य",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "अनेक",
  Un: "कुछ",
};

const Home = {
  logIn: "लॉग इन करें",
  signUp: "पंजीकरण करवाना",
  popularMatches: "लोकप्रिय प्रतियोगिताएं",
  more: "अधिक",
  noDataPrompt: "कोई डेटा नहीं",
  currentlyLive: "सीधा प्रसारण",
  popularStreamers: "लोकप्रिय एंकर",
  thinkYouLike: "आपको यह भी पसंद आ सकता हैं",
  loadMore: "और लोड करें",
  noMoreDataPrompt: "अभी और कोई डेटा नहीं है",
  addDeskPrompt: "डेस्कटॉप पर जोड़ें",
  clikToolPrompt: "नीचे टूलबार पर क्लिक करें",
  andSeletcPrompt: "और चुनें",
  addToScreenPrompt: "हम बेहतर सेवा प्रदान करने के लिए आपकी होम स्क्रीन पर जोड़ना चाहते हैं",
  addToScreen: "होम स्क्रीन में शामिल करें",
};

const MoreLives = {
  noDataPrompt: "कोई डेटा नहीं",
};

const MoreStreamers = {
  streamer: "लंगर",
  streamerSearch: "एंकर का नाम और आईडी दर्ज करें",
  all: "All",
  notFollowing: "पालन ​​नहीं करते हुए",
  following: "पहले से ही फ़ॉलो कर रहे हैं",
  live: "LIVE",
  offLine: "अभी तक शुरू नहीं हुआ",
  followersCount: "ध्यान केंद्रित करना",
  unfollowPrompt: "क्या आप निश्चित रूप से अनफ़ॉलो करना चाहते हैं?",
  yes: "हाँ",
  no: "नहीं",
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
  InputSearchPrompt: "कृपया सामग्री दर्ज करें",
  dataRequestErrorPrompt: "डेटा प्राप्त करने में विफल",
};

const LiveRoom = {
  switchLines: "स्विच लाइनें",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "एंकर निजी चैट",
  chat: "बात करना",
  odds: "Odds",
  streamerInformation: "एंकर जानकारी",
  matchStatus: "मैच की स्थिति",
};

const AnchorIntroduction = {
  streamerInformation: "एंकर जानकारी",
  photosWall: "फोटो वॉल",
  seeMore: "और देखें",
  streamerPlan: "लंगर योजना",
};

const AnchorPhoto = {
  photosWall: "फोटो वॉल",
  noPhotosPrompt: "अभी तक कोई और फ़ोटो नहीं है",
};

const LogInRegister = {
  logIn: "लॉग इन करें",
  signUp: "पंजीकरण करवाना",
  getAreaAndLanguageErrorPrompt: "क्षेत्रीय भाषा प्राप्त करने में विफल",
  quickRegisterFrequentPrompt: "बार-बार अनुरोध, कृपया बाद में पुनः प्रयास करें",
};

const LoginFn = {
  phone: "फ़ोन नंबर",
  email: "मेल",
  logInPassword: "लॉगिन पासवर्ड",
  rememberPassword: "पासवर्ड याद",
  forgotPassword: "पासवर्ड भूल जाओ",
  logIn: "लॉग इन करें",
  logInAgreement: "लॉग इन करके आप सहमत हैं",
  userAgreement: "उपयोगकर्ता का समझौता",
  and: "  और ",
  privacyPolicy: "गोपनीयता नीति",
  passwordRulePrompt: "पासवर्ड में अपरकेस और लोअरकेस अक्षर और संख्याएं होनी चाहिए और 8 अक्षरों से अधिक होनी चाहिए",
  passwordEmptyPrompt: "पासवर्ड खाली नहीं हो सकता",
  emailRulePrompt: "कृपया अपने ईमेल पते का सही प्रारूप दर्ज करें",
  emailEmptyPrompt: "ई-मेल खाली नहीं रह सकता",
  phoneRulePrompt: "कृपया अपना मोबाइल फ़ोन नंबर सही प्रारूप में दर्ज करें",
  phoneEmptyPrompt: "मोबाइल फ़ोन नंबर खाली नहीं हो सकता",
  phoneNum8: "कृपया अपना 8 अंकीय मोबाइल फ़ोन नंबर दर्ज करें",
  phoneNum9To10: "कृपया 9 से 10 अंकों का मोबाइल फ़ोन नंबर दर्ज करें",
  phoneNum9: "कृपया 9 अंकों का मोबाइल फ़ोन नंबर दर्ज करें",
  enterErrorPrompt: "इनपुट त्रुटि या नेटवर्क असामान्यता",
  loginNoAgreePrompt: "लॉग इन करने के लिए आपको उपयोगकर्ता अनुबंध और गोपनीयता नीति से सहमत होना होगा",
  accountAndPassword: "खाता",
  account: "खाता",
  accountEmptyPrompt: "खाता खाली नहीं हो सकता",
  accountOrPasswordError: "गलत उपयोगकर्ता नाम या पासवर्ड",
};

const Register = {
  phone: "फ़ोन नंबर",
  email: "मेल",
  code: "सत्यापन कोड",
  getCode: "सत्यापन कोड प्राप्त करें",
  logInPassword: "लॉगिन पासवर्ड",
  CS: "ग्राहक सेवा",
  signUp: "पंजीकरण करवाना",
  signUpAgreement: "पंजीकरण करके आप सहमत हैं",
  userAgreement: "उपयोगकर्ता का समझौता",
  and: "और",
  privacyPolicy: "गोपनीयता नीति",
  contactCSPrompt: "क्या आपको पंजीकरण करने में परेशानी हो रही है? आपके लिए इसे हल करने के लिए ग्राहक सेवा से संपर्क करें",
  contactCS: "ग्राहक सेवा से सम्पर्क करें",
  passwordRulePrompt: "पासवर्ड में अपरकेस और लोअरकेस अक्षर और संख्याएं होनी चाहिए और 8 अक्षरों से अधिक होनी चाहिए",
  passwordEmptyPrompt: "पासवर्ड खाली नहीं हो सकता",
  emailRulePrompt: "कृपया अपने ईमेल पते का सही प्रारूप दर्ज करें",
  emailEmptyPrompt: "ई-मेल खाली नहीं रह सकता",
  phoneRulePrompt: "कृपया अपना मोबाइल फ़ोन नंबर सही प्रारूप में दर्ज करें",
  phoneEmptyPrompt: "मोबाइल फ़ोन नंबर खाली नहीं हो सकता",
  codeRulePrompt: "सत्यापन कोड 6 अंकों का है",
  codeEmptyPrompt: "सत्यापन कोड भरना होगा",
  phoneNum8: "कृपया अपना 8 अंकीय मोबाइल फ़ोन नंबर दर्ज करें",
  phoneNum9To10: "कृपया 9 से 10 अंकों का मोबाइल फ़ोन नंबर दर्ज करें",
  phoneNum9: "कृपया 9 अंकों का मोबाइल फ़ोन नंबर दर्ज करें",
  phoneCodeSendSuccessPrompt: "सत्यापन कोड सफलतापूर्वक भेजा गया, कृपया अपने मोबाइल फोन पर टेक्स्ट संदेश की जांच करें",
  emailCodeSendSuccessPrompt: "सत्यापन कोड सफलतापूर्वक भेजा गया, कृपया अपना ईमेल जांचें",
  phoneCodeSendErrorPrompt: "सत्यापन कोड भेजा जाना विफल रहा, कृपया जांचें कि मोबाइल फोन नंबर उपलब्ध है या नहीं",
  emailCodeSendErrorPrompt: "सत्यापन कोड भेजा जाने में विफल रहा, कृपया जांचें कि क्या आपका ईमेल उपलब्ध है",
  codeSendToMuchPrompt: "एक ही समय में बहुत अधिक बार भेजा गया, कृपया बाद में पुनः प्रयास करें",
  phoneAlreadySignupPrompt: "यह मोबाइल फ़ोन नंबर पंजीकृत हो गया है, कृपया सीधे लॉग इन करें",
  emailAlreadySignupPrompt: "यह ईमेल पता पंजीकृत कर दिया गया है, कृपया सीधे लॉग इन करें",
  signUpErrorPrompt: "पंजीकरण विफल रहा, कृपया जांचें कि जानकारी सही है या नहीं",
  enterErrorPrompt: "इनपुट त्रुटि या नेटवर्क असामान्यता",
  noAgreePrompt: "पंजीकरण करने के लिए आपको उपयोगकर्ता अनुबंध और गोपनीयता नीति से सहमत होना होगा",
  regionAndLanguagePrompt: "कृपया अपनी वास्तविक क्षेत्रीय भाषा का चयन करें, अन्यथा यह कार्यों के उपयोग को प्रभावित करेगा",
  accountAndPassword: "खाता",
  quickSignup: "एक-क्लिक पंजीकरण",
  accountEmptyPrompt: "खाता खाली नहीं हो सकता",
  accountRulePrompt: "खाता संख्या में 8-16 अक्षर और अंक होने चाहिए",
  accountAlreadySignupPrompt: "यह खाता पंजीकृत हो गया है, कृपया सीधे लॉग इन करें",
  tryAgain: "पुन: भेजें",
};

const PersonalCenter = {
  logAndSign: "अभी लॉगिन/रजिस्टर करें",
  logAndSignPrompt: "अभी विशेष सुविधाओं और विशेष लाभों को अनलॉक करें",
  logOut: "साइन आउट",
  myFollow: "मेरा ध्यान",
  mySubscription: "मेरी सदस्यता",
  accountSecurity: "खाते की सुरक्षा",
  feedback: "प्रतिक्रिया",
  deviceInformation: "उपकरण",
  aboutUs: "हमारे बारे में",
  coin: "सोना",
  moreServices: "अधिक सेवाएँ",
  following: "पहले से ही फ़ॉलो कर रहे हैं",
  subscribed: "सदस्यता लिया",
  browser: "ब्राउज़र",
  user: "उपयोगकर्ता",
  profile: "व्यक्तिगत जानकारी",
  nickname: "उपनाम",
  save: "बचाना",
  change: "परिवर्तन",
  nicknamePrompt: "कृपया एक निकनाम दर्ज करें",
  avatar: "अवतार",
  changeSuccessPrompt: "परिवर्तन सफल",
  ballAnimation: "लक्ष्य एनीमेशन",
  ballVoice: "लक्ष्य ध्वनि प्रभाव",
  setting: "स्थापित करना",
};

const IntegralSystem = {
  Coin: "सोना",
  goldCoinTask: "सोने का सिक्का कार्य",
  PointsDetails: "सोने के सिक्के का विवरण",
  myCoins: "मेरे सोने के सिक्के",
  income: "आय",
  expenditure: "व्यय",
  signInPrompt: "लगातार साइन इन करें और लगातार पुरस्कार प्राप्त करें",
  toComplete: "को पूरा करने के",
  toShare: "साझा करने के लिए",
  shareTasks: "कार्य साझा करें",
  exclusiveLink: "विशेष लिंक",
  copyLink: "लिंक की प्रतिलिपि करें",
  highestWin: "सर्वोच्च जीत",
  day1: "दिन 1",
  day2: "दूसरा दिन",
  day3: "तीसरा दिन",
  day4: "दिन 4",
  day5: "दिन 5",
  day6: "दिन 6",
  day7: "दिन 7",
  invitePrompt: "आश्चर्य! अभी पंजीकरण करें और नए उपयोगकर्ताओं के लिए विशेष लाभों का आनंद लेने के लिए हमारा एपीपी डाउनलोड करें! मुफ़्त लाइव इवेंट प्रसारण, वास्तविक समय डेटा विश्लेषण और पेशेवर एंकर भविष्यवाणियाँ, सब कुछ आपकी उंगलियों पर। अब और इंतजार न करें, शामिल होने के लिए लिंक पर क्लिक करें!"
};

const Money = {
  activeCenter: "गतिविधि केंद्र",
  missionCenter: "मिशन केंद्र",
};

const ActivityCenter = {
  toParticipate: "भाग लेने के लिए",
  sportActivity: "खेलकूद गतिविधियां",
};

const ActivityDetails = {
  eventDetails: "घटना की जानकारी",
  dailyBetting: "दैनिक सट्टेबाजी",
  matchTime: "मैच का समय",
  popularForEvent: "इस इवेंट के लिए लोकप्रिय विकल्प",
  clickToParticipate: "अभी भाग लेने के लिए क्लिक करें",
  activityRules: "गतिविधि नियम",
  clickToContactParticipate: "भाग लेने के लिए ग्राहक सेवा से संपर्क करने के लिए क्लिक करें",
  activityObj: "सक्रिय वस्तु",
  activityPlatform: "आयोजन मंच",
  activityDuration: "गतिविधि की अवधि",
  activityDemand: "गतिविधि आवश्यकताएँ",
  activityTime: "गतिविधि का समय",
};

const AboutUs = {
  aboutUs: "हमारे बारे में",
  platformIntroduction: "मंच परिचय",
  privacyPolicy: "गोपनीयता नीति",
  userAgreement: "उपयोगकर्ता का समझौता",
};

const DeviceInfo = {
  deviceInformation: "उपकरण",
  currentTime: "वर्तमान समय",
  port: "लॉगिन पोर्ट",
  ip: "आईपी ​​लॉगिन करें",
  version: "वर्तमान संस्करण",
  ipAndPort: "लॉगिन जानकारी",
};

const FeedBack = {
  feedback: "प्रतिक्रिया",
  feedbackType: "प्रतिक्रिया प्रकार",
  productSuggestion: "उत्पाद सुझाव",
  functionalFault: "कार्यात्मक विफलता",
  interfaceSuggestion: "इंटरफ़ेस सुझाव",
  accountIssue: "खाता समस्या",
  operationExperience: "ऑपरेशन का अनुभव",
  otherFeedback: "अन्य प्रतिक्रिया",
  problemDescription: "समस्या का विवरण",
  submit: "जमा करना",
  addPhoto: "तस्वीर जोड़ो",
  uploadPrompt: "क्षमा करें, केवल 3 फ़ोटो अपलोड की जा सकती हैं",
  uploadRulePrompt: "कृपया चित्र जेपीजी, जेपीईजी, पीएनजी प्रारूप में अपलोड करें",
  uploadSizePrompt: "छवि का आकार 10M से अधिक नहीं हो सकता",
  chooseFeedback: "कृपया फीडबैक प्रकार चुनें",
  inputQuestionPrompt: "कृपया एक प्रश्न दर्ज करें",
  submitSuccessPrompt: "सफलतापूर्वक जमा",
};

const MySubscription = {
  myFollow: "मेरा ध्यान",
  mySubscription: "मेरी सदस्यता",
  streamer: "लंगर",
  matches: "प्रतियोगिता",
};

const PrivacyAgreement = {
  privacyPolicy: "गोपनीयता नीति",
};

const TermsofService = {
  termsOfService: "सेवा की शर्तें",
};

const UserAgreement = {
  userAgreement: "उपयोगकर्ता का समझौता",
};

const AccountSecurity = {
  accountSecurity: "खाते की सुरक्षा",
  phone: "फ़ोन नंबर",
  email: "मेल",
  bind: "बंधन",
  notBound: "बँधा नहीं",
  change: "परिवर्तन",
  logInPassword: "लॉगिन पासवर्ड",
  accountChangePassword: "कृपया पहले अपना मोबाइल फ़ोन/ईमेल पता बाइंड करें",
};

const BindEmail = {
  bindEmail: "ईमेल बाइंड करें",
  email: "मेल",
  code: "सत्यापन कोड",
  getCode: "सत्यापन कोड प्राप्त करें",
  confirm: "पुष्टि करना",
  emailRulePrompt: "कृपया अपने ईमेल पते का सही प्रारूप दर्ज करें",
  emailEmptyPrompt: "ई-मेल खाली नहीं रह सकता",
  codeRulePrompt: "सत्यापन कोड 6 अंकों का है",
  codeEmptyPrompt: "सत्यापन कोड भरना होगा",
  changeSuccessPrompt: "परिवर्तन सफल",
  changeErrorPrompt: "परिवर्तन विफल रहा",
  bindSuccessPrompt: "बाइंडिंग सफल",
  bindErrorPrompt: "बाइंडिंग विफल",
  emailCodeSendSuccessPrompt: "सत्यापन कोड सफलतापूर्वक भेजा गया, कृपया अपना ईमेल जांचें",
  emailCodeSendErrorPrompt: "सत्यापन कोड भेजा जाने में विफल रहा, कृपया जांचें कि क्या आपका ईमेल उपलब्ध है",
  codeSendToMuchPrompt: "एक ही समय में बहुत अधिक बार भेजा गया, कृपया बाद में पुनः प्रयास करें",
  emailAlreadySignupPrompt: "यह ईमेल पता पंजीकृत कर दिया गया है, कृपया सीधे लॉग इन करें",
  tryAgain: "पुन: भेजें",
};

const BindPhone = {
  bindPhone: "मोबाइल फ़ोन बाइंड करें",
  phone: "फ़ोन नंबर",
  code: "सत्यापन कोड",
  getCode: "सत्यापन कोड प्राप्त करें",
  confirm: "पुष्टि करना",
  cancel: "रद्द करना",
  phoneRulePrompt: "कृपया अपना मोबाइल फ़ोन नंबर सही प्रारूप में दर्ज करें",
  phoneEmptyPrompt: "मोबाइल फ़ोन नंबर खाली नहीं हो सकता",
  codeRulePrompt: "सत्यापन कोड 6 अंकों का है",
  codeEmptyPrompt: "सत्यापन कोड भरना होगा",
  changeSuccessPrompt: "परिवर्तन सफल",
  changeErrorPrompt: "परिवर्तन विफल रहा",
  bindSuccessPrompt: "बाइंडिंग सफल",
  bindErrorPrompt: "बाइंडिंग विफल",
  phoneNum8: "कृपया अपना 8 अंकीय मोबाइल फ़ोन नंबर दर्ज करें",
  phoneNum9To10: "कृपया 9 से 10 अंकों का मोबाइल फ़ोन नंबर दर्ज करें",
  phoneNum9: "कृपया 9 अंकों का मोबाइल फ़ोन नंबर दर्ज करें",
  phoneCodeSendSuccessPrompt: "सत्यापन कोड सफलतापूर्वक भेजा गया, कृपया अपने मोबाइल फोन पर टेक्स्ट संदेश की जांच करें",
  phoneCodeSendErrorPrompt: "सत्यापन कोड भेजा जाना विफल रहा, कृपया जांचें कि मोबाइल फोन नंबर उपलब्ध है या नहीं",
  codeSendToMuchPrompt: "एक ही समय में बहुत अधिक बार भेजा गया, कृपया बाद में पुनः प्रयास करें",
  phoneAlreadySignupPrompt: "यह मोबाइल फ़ोन नंबर पंजीकृत हो गया है, कृपया सीधे लॉग इन करें",
  checkSignup: "कृपया जांचें कि नंबर पंजीकृत है या नहीं",
  tryAgain: "पुन: भेजें",
};

const ChangePassword = {
  resetPassword: "पासवर्ड रीसेट",
  phone: "फ़ोन नंबर",
  email: "मेल",
  code: "सत्यापन कोड",
  getCode: "सत्यापन कोड प्राप्त करें",
  logInPassword: "लॉगिन पासवर्ड",
  confirm: "पुष्टि करना",
  cancel: "रद्द करना",
  passwordRulePrompt: "पासवर्ड में अपरकेस और लोअरकेस अक्षर और संख्याएं होनी चाहिए और 8 अक्षरों से अधिक होनी चाहिए",
  passwordEmptyPrompt: "पासवर्ड खाली नहीं हो सकता",
  emailRulePrompt: "कृपया अपने ईमेल पते का सही प्रारूप दर्ज करें",
  emailEmptyPrompt: "ई-मेल खाली नहीं रह सकता",
  phoneRulePrompt: "कृपया अपना मोबाइल फ़ोन नंबर सही प्रारूप में दर्ज करें",
  phoneEmptyPrompt: "मोबाइल फ़ोन नंबर खाली नहीं हो सकता",
  codeRulePrompt: "सत्यापन कोड 6 अंकों का है",
  codeEmptyPrompt: "सत्यापन कोड भरना होगा",
  phoneNum8: "कृपया अपना 8 अंकीय मोबाइल फ़ोन नंबर दर्ज करें",
  phoneNum9To10: "कृपया 9 से 10 अंकों का मोबाइल फ़ोन नंबर दर्ज करें",
  phoneNum9: "कृपया 9 अंकों का मोबाइल फ़ोन नंबर दर्ज करें",
  resetSuccess: "पासवर्ड रीसेट सफल",
  resetError: "पासवर्ड रीसेट विफल रहा, कृपया जांचें कि जानकारी सही है या नहीं",
  checkSignup: "कृपया जांचें कि नंबर पंजीकृत है या नहीं",
  phoneCodeSendSuccessPrompt: "सत्यापन कोड सफलतापूर्वक भेजा गया, कृपया अपने मोबाइल फोन पर टेक्स्ट संदेश की जांच करें",
  codeSendToMuchPrompt: "एक ही समय में बहुत अधिक बार भेजा गया, कृपया बाद में पुनः प्रयास करें",
  phoneAlreadySignupPrompt: "यह मोबाइल फ़ोन नंबर पंजीकृत हो गया है, कृपया सीधे लॉग इन करें",
  phoneCodeSendErrorPrompt: "सत्यापन कोड भेजा जाना विफल रहा, कृपया जांचें कि मोबाइल फोन नंबर उपलब्ध है या नहीं",
  emailCodeSendSuccessPrompt: "सत्यापन कोड सफलतापूर्वक भेजा गया, कृपया अपना ईमेल जांचें",
  emailCodeSendErrorPrompt: "सत्यापन कोड भेजा जाने में विफल रहा, कृपया जांचें कि क्या आपका ईमेल उपलब्ध है",
  emailAlreadySignupPrompt: "यह ईमेल पता पंजीकृत कर दिया गया है, कृपया सीधे लॉग इन करें",
  bindPhoneOrEmail: "कृपया पहले अपना मोबाइल फ़ोन नंबर या ईमेल पता बाइंड करें",
  tryAgain: "पुन: भेजें",
};

const QuickRegister = {
  downloadScreenshot: "स्क्रीनशॉट डाउनलोड करें",
  saveAccountAndPassword: "कृपया अपना खाता और पासवर्ड सुरक्षित रखें",
  suggestSave: "यह अनुशंसा की जाती है कि आप एक स्क्रीनशॉट लें और इसे सहेजें या इसे मेमो में कॉपी करें।",
  account: "खाता",
  password: "पासवर्ड",
  copyAccountAndPassword: "अकाउंट पासवर्ड कॉपी करें",
  prompt: "संकेत देना",
  sureSave: "क्या आपने अपना खाता पासवर्ड सहेज लिया है?",
  confirm: "पुष्टि करना",
  cancel: "रद्द करना",
  copySuccess: "सफलतापूर्वक कॉपी किया गया",
  copyFailure: "प्रतिलिपि विफल",
  manualRegister: "पंजीकरण की संख्या ऊपरी सीमा तक पहुंच गई है, कृपया मैन्युअल रूप से पंजीकरण करें",
};

const Recommend = {
  recommend: "अनुशंसा करना",
  InternetErrorPrompt: "नेटवर्क त्रुटि",
  hitOfTheWeek: "सप्ताह का हिट",
  victoryOrDefeat: "जीत हो या हार",
  check: "जाँच करना",
  lastTenGames: "पिछले 10 खेल",
  recommendedByExperts: "विशेषज्ञों द्वारा अनुशंसित",
  changeBatch: "एक बैच बदलें",
  follow: "ध्यान केंद्रित करना",
  following: "पहले से ही फ़ॉलो कर रहे हैं",
  unFollowing: "अनलॉक",
  all: "All",
  guaranteedCompensation: "मुआवज़े की गारंटी",
  free: "मुक्त",
  toll: "टोल",
  HOT: "बेचना",
  HIT: "मार",
  STREAK: "बीच में भी",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "होम पेज",
  chat: "बात करना",
  follow: "ध्यान केंद्रित करना",
  following: "पहले से ही फ़ॉलो कर रहे हैं",
  unFollowing: "अनलॉक",
  followersCount: "ध्यान केंद्रित करना",
  planCount: "योजना",
  viewCount: "घड़ी",
  statistics: "आंकड़े",
  plan: "योजना",
};

const SchemeDetails = {
  planDetails: "योजना विवरण",
  release: "मुक्त करना",
  chat: "बात करना",
  follow: "ध्यान केंद्रित करना",
  following: "पहले से ही फ़ॉलो कर रहे हैं",
  unFollowing: "अनलॉक",
  details: "विवरण",
  analyze: "विश्लेषण",
  title: "शीर्षक",
  privateChatStreamerJoinsGroup: "निजी चैट होस्ट समूह में शामिल होता है",
  upcoming: "प्रारंभ नहीं हुआ है",
  othersPlan: "अन्य विकल्प",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "मिलान छँटाई",
  byTime: "समय तक",
  byLeague: "लीग द्वारा",
  confirm: "पुष्टि करना",
  cancel: "रद्द करना",
};

const RecommendPublish = {
  enterTeam: "टीम दर्ज करें",
  planRelease: "योजना विमोचन",
  predictionTitle: "शीर्षक (सभी उपयोगकर्ताओं के लिए दृश्यमान, कृपया पूर्वानुमान परिणामों का खुलासा न करें)",
  titleEmptyPrompt: "विश्लेषण का शीर्षक और सामग्री खाली नहीं हो सकती",
  pleaseSelectAtLeastOneGameToPredictResults: "कृपया परिणामों की भविष्यवाणी करने का कम से कम एक तरीका चुनें",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "फ़ुटबॉल",
  basketball: "बास्केटबाल",
  mixed: "विस्तृत",
  setLogInPassword: "लॉगिन पासवर्ड सेट करना",
  OCS: "ऑनलाइन सेवा",
  OCSPrompt: "आपकी सेवा में",
  phoneInCorrectPrompt: "मोबाइल फ़ोन नंबर ग़लत दर्ज किया गया",
  codeInCorrectPrompt: "ग़लत सत्यापन कोड",
  emailInCorrectPrompt: "ईमेल इनपुट त्रुटि",
  passwordPrompt: "कृपया अंग्रेजी के 8-16 अंकों और संख्याओं का संयोजन दर्ज करें",
  newPassword: "नया पारण शब्द भरे",
  resetPasswordPrompt: "क्या आप निश्चित रूप से अपना पासवर्ड रीसेट कर रहे हैं?",
  startChatPrompt: "अब आप चैट करना शुरू कर सकते हैं",
  send: "भेजना",
  noFollowPrompt: "अभी तक कोई ध्यान नहीं",
  feedbackPrompt: "कृपया अपनी बहुमूल्य टिप्पणियाँ छोड़ें और हम सुधार के लिए कड़ी मेहनत करेंगे!",
  bindPhoneByOtherPrompt: "यह मोबाइल नंबर अन्य उपयोगकर्ताओं द्वारा बाध्य किया गया है",
  bindEmailByOtherPrompt: "यह ईमेल पता किसी अन्य उपयोगकर्ता द्वारा बाध्य किया गया है",
  super_manager: "सुपर प्रशासक",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "पहली तिमाही का अंत",
  endOfSecondQuarter: "दूसरी तिमाही का अंत",
  endOfThirdQuarter: "तीसरी तिमाही का अंत",
  endOfFourthQuarter: "चौथी तिमाही का अंत",
  pullUpToLoadMore: "अधिक लोड करने के लिए ऊपर खींचें",
  pullDownToRefresh: "ताज़ा करने के लिए नीचे खींचें",
  releaseToRefreshImmediately: "तुरंत ताज़ा करने के लिए रिलीज़ करें",
  refreshPrompt: "ताज़ा...",
  download: "डाउनलोड करना",
  appDownload: "एपीपी डाउनलोड करें",
  brandName: "OV88",
  saveToDesktop: "होम स्क्रीन में शामिल करें",
  downloadTitle: "एपीपी डाउनलोड करें",
  normalDownload: "सामान्य डाउनलोड",
  scanningPrompt: "QR कोड के माध्यम से स्थापित करें और विशेष बड़ी छूट प्राप्त करें, और अन्य शानदार लाभ भी लें!",
  downloadFailToWeb: "यदि आप एपीपी डाउनलोड नहीं कर सकते, तो वेब संस्करण का उपयोग करने के लिए यहां क्लिक करें",
  scanQrCode: "मोबाइल फ़ोन से QR कोड स्कैन करें",
  usingMobileDevices: "मोबाइल का प्रयोग करें",
  installTutorial: "इंस्टॉलेशन ट्यूटोरियल देखने के लिए क्लिक करें",
  whyNameDifferent: "इंस्टॉल किए गए एपीपी और डाउनलोड किए गए एपीपी के नाम असंगत हैं, कृपया बेझिझक डाउनलोड, इंस्टॉल और उपयोग करें!",
  clickDownload: "डाउनलोड करने के लिए क्लिक करें",
  installTutorialPrompt: "इंस्टालेशन अनुदेश मार्गदर्शिका",
  brandNativeName: "यूएफओस्कोर देशी एपीपी",
  uploadingPrompt: "अपलोड हो रहा है...",
  bHandicap: "अपंगता",
  different: "अंतर",
  total: "कुल",
  off: "प्रारंभ नहीं हुआ है",
  video: "वीडियो",
  animation: "एनिमेशन",
  install: "स्थापित करना",
  startDownloading: "अधःभारण शुरू करें",
  cancelGoal: "लक्ष्य रद्द",
  appUpdatePrompt: "एपीपी का एक नया संस्करण है, कृपया अपडेट करें",
};
