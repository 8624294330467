/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "ข้อตกลงการใช้",
  privacyPolicy: "นโยบายความเป็นส่วนตัว",
  termsOfService: "เงื่อนไขการให้บริการ",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "ยังไม่ได้เริ่มเลย",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "ออกอากาศแล้ว",
  watch: "ไปดู",
  follow: "มุ่งเน้นไปที่",
  following: "ตามมาแล้วครับ",
  subscribe: "สมัครสมาชิก",
  streamerSchedule: "กำหนดการสมอ",
  noDataPrompt: "ไม่มีข้อมูล",
  unfollowPrompt: "คุณแน่ใจหรือว่าจะเลิกติดตาม?",
  yes: "ใช่",
  no: "เลขที่",
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  hours: "ชั่วโมง",
  mins: "นาที",
};

const AnchorInformation = {
  recommendedLiveStreams: "แนะนำการถ่ายทอดสด",
  noDataPrompt: "ไม่มีข้อมูล",
};

const BasketballScoreIndex = {
  details: "รายละเอียด",
  over: "เกิน",
  pointsDifference: "คะแนนต่างกัน",
  totalScore: "คะแนนรวม",
  live: "LIVE",
  unSubscribePrompt: "คุณแน่ใจหรือว่าจะยกเลิกการสมัครสมาชิกของคุณ?",
  yes: "ใช่",
  no: "เลขที่",
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
};

const BasketballSmallBig = {
  details: "รายละเอียด",
  over: "เกิน",
  pointsDifference: "คะแนนต่างกัน",
  totalScore: "คะแนนรวม",
  live: "LIVE",
  unSubscribePrompt: "คุณแน่ใจหรือว่าจะยกเลิกการสมัครสมาชิกของคุณ?",
  yes: "ใช่",
  no: "เลขที่",
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
  overIndex: "ใหญ่",
  underIndex: "เล็ก",
};

const BindPhoneDialog = {
  bindPhonePrompt: "เพื่อความปลอดภัยในบัญชีของคุณ โปรดผูกหมายเลขโทรศัพท์มือถือของคุณเพื่อให้เราสามารถให้บริการที่ดียิ่งขึ้นแก่คุณได้",
  goBind: "ไปที่การผูก",
};

const BottomNavigationBar = {
  home: "หน้าแรก",
  matches: "การประกวด",
  recommend: "แนะนำ",
  chat: "แชท",
  personal: "ส่วนตัว",
  discovery: "ค้นพบ",
  money: "หาเงิน",
};

const ChatInterface = {
  noCsPrompt: "ไม่สามารถสนทนาได้ในขณะนี้",
  uploadRulePrompt: "กรุณาอัพโหลดรูปภาพในรูปแบบ jpg, jpeg, PNG",
  uploadSizePrompt: "ขนาดภาพต้องไม่เกิน 10M",
  sendError: "การส่งล้มเหลว",
  picSendError: "การส่งรูปภาพล้มเหลว",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
  bannedSendPrompt: "คุณถูกแบน",
};

const ChatList = {
  noNewPrompt: "ยังไม่มีข่าว",
  logInPrompt: "เข้าสู่ระบบเพื่อดูเนื้อหา กรุณาเข้าสู่ระบบก่อน",
  logIn: "เข้าสู่ระบบ",
  group: "กลุ่ม",
};

const ChatListItem = {
  withdrawMessage: "ถอนข้อความแล้ว",
  bannedPrompt: "ห้าม",
  unBanPrompt: "ไม่ถูกแบน",
  joinGroupPrompt: "เข้าร่วมกลุ่มแล้ว",
  leftGroupPrompt: "ออกจากกลุ่มแล้ว",
  picture: "รูปภาพ",
  link: "ลิงค์",
  emoji: "อีโมติคอนแบบเคลื่อนไหว",
  addedFriendPrompt: "เพิ่มเป็นเพื่อนแล้ว",
};

const CompetitionList = {
  logInPrompt: "เข้าสู่ระบบเพื่อดูเนื้อหา กรุณาเข้าสู่ระบบก่อน",
  logIn: "เข้าสู่ระบบ",
  today: "วันนี้",
  Monday: "วันจันทร์",
  Tuesday: "วันอังคาร",
  Wednesday: "วันพุธ",
  Thursday: "วันพฤหัสบดี",
  Friday: "วันศุกร์",
  Saturday: "วันเสาร์",
  Sunday: "วันอาทิตย์",
  noGames: "ยังไม่มีเกม",
  toCollapse: "คลิกเพื่อยุบ",
  toExpand: "คลิกเพื่อขยาย"
};

const CompetitionListItem = {
  details: "รายละเอียด",
  unSubscribePrompt: "คุณแน่ใจหรือว่าจะยกเลิกการสมัครสมาชิกของคุณ?",
  yes: "ใช่",
  no: "เลขที่",
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
};

const CompetitionMode = {
  matchMode: "โหมดการแข่งขัน",
  confirm: "ยืนยัน",
  cancel: "ยกเลิก",
};

const EmojiCom = {
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  yes: "ใช่",
  no: "เลขที่",
};

const ExponentCom = {
  company: "บริษัท",
  initial: "อักษรย่อ",
  ongoing: "ทันที",
  preMatch: "ก่อนเกม",
  noDataPrompt: "ไม่มีข้อมูล",
  handicap: "แต้มต่อ",
  WinDrawLose: "ชนะ เสมอ แพ้",
  totalGoals: "เป้าหมายทั้งหมด",
  cornerKick: "แตร",
  pleaseSelect: "กรุณาเลือก",
  confirm: "ยืนยัน",
  cancel: "ยกเลิก",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "ปักหมุดไว้ที่ 'Stream chat'",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "มุ่งเน้นไปที่",
  following: "ตามมาแล้วครับ",
  chat: "แชท",
  noDataPrompt: "ไม่มีข้อมูล",
  unfollowPrompt: "คุณแน่ใจหรือว่าจะเลิกติดตาม?",
  yes: "ใช่",
  no: "เลขที่",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
};

const FootballCornerIndex = {
  details: "รายละเอียด",
  over: "เกิน",
  FH: "HT",
  cornerKick: "แตร",
  unSubscribePrompt: "คุณแน่ใจหรือว่าจะยกเลิกการสมัครสมาชิกของคุณ?",
  yes: "ใช่",
  no: "เลขที่",
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
};

const FootballScoreIndex = {
  details: "รายละเอียด",
  over: "เกิน",
  FH: "HT",
  cornerKick: "แตร",
  live: "LIVE",
  unSubscribePrompt: "คุณแน่ใจหรือว่าจะยกเลิกการสมัครสมาชิกของคุณ?",
  yes: "ใช่",
  no: "เลขที่",
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
};

const FootballScoreItem = {
  details: "รายละเอียด",
  over: "เกิน",
  FH: "HT",
  cornerKick: "แตร",
  live: "LIVE",
  unSubscribePrompt: "คุณแน่ใจหรือว่าจะยกเลิกการสมัครสมาชิกของคุณ?",
  yes: "ใช่",
  no: "เลขที่",
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
};

const GoalToast = {
  goal: "เป้าหมาย",
};

const LiveRecommend = {
  noDataPrompt: "ไม่มีข้อมูล",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "ชัยชนะหรือความพ่ายแพ้",
};

const MatchCardList = {
  details: "รายละเอียด",
};

const MatchStatus = {
  noDataPrompt: "ไม่มีข้อมูล",
};

const MoreStreams = {
  switchLines: "สลับสาย",
  cancel: "ยกเลิก",
  currentStreamer: "สมอปัจจุบัน",
  live: "LIVE",
  offLine: "ยังไม่ได้เริ่มเลย",
  followersCount: "มุ่งเน้นไปที่",
  otherStreamersPrompt: "มีผู้ประกาศข่าวรายอื่นที่กำลังออกอากาศเกมอยู่",
  watch: "ไปดู",
};

const NewMatchStatus = {
  team: "ทีม",
  halfTimeScore: "คะแนนครึ่งเวลา",
  shotOnTarget: "ยิงเข้าประตู",
  missedShot: "พลาดเป้าหมาย",
  attack: "จู่โจม",
  dangerousAttack: "การโจมตีที่เป็นอันตราย",
  number3PointGoals: "จำนวนประตู 3 แต้มที่ทำได้",
  number2PointGoals: "จำนวนประตู 2 แต้มที่ทำได้",
  penaltyKickGoals: "ประตูเตะจุดโทษ",
  freeThrowPercentage: "เปอร์เซ็นต์การโยนโทษ",
  numberOfPausesRemaining: "จำนวนการหยุดชั่วคราวที่เหลือ",
  numberOfFouls: "จำนวนครั้งของฟาวล์",
  totalNumberOfPauses: "การหยุดชั่วคราวทั้งหมด",
  threePointShot: "ยิง 3 แต้ม",
  twoPointShot: "ยิง 2 แต้ม",
  shoot: "ยิง",
  penalty: "การลงโทษ",
  firstHalf: "ครึ่งแรก",
  secondHalf: "ครึ่งหลัง",
  redCard: "ใบแดง",
  yellowCard: "ใบเหลือง",
};

const NoData = {
  noDataPrompt: "ไม่มีข้อมูล",
};

const NoticeMessage = {
  addedFriendPrompt: "เพิ่มเป็นเพื่อนแล้ว",
  withdrawMessage: "ถอนข้อความแล้ว",
  withdrawMessageByAdmin: "ข้อความถูกถอนออกโดยผู้ดูแลระบบ",
  joinGroupPrompt: "เข้าร่วมกลุ่มแล้ว",
  leftGroupPrompt: "ออกจากกลุ่มแล้ว",
  removeGroupPrompt: "ลบออกจากกลุ่มโดยผู้ดูแลระบบ",
  bannedPrompt: "ห้าม",
  unBanPrompt: "ไม่ถูกแบน",
};

const OddsStatisticsList = {
  initial: "อักษรย่อ",
  ongoing: "ทันที",
  preMatch: "ก่อนเกม",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "มูลค่าสูงสุด",
  min: "ค่าต่ำสุด",
  average: "ค่าเฉลี่ย",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "ไม่มีข้อมูล",
};

const RecommendCompetitions = {
  today: "วันนี้",
  winsInARow: "แม้จะอยู่ตรงกลางก็ตาม",
  noLivePrompt: "ยังไม่มีการถ่ายทอดสด",
};

const RecommendStreamerList = {
  noDataPrompt: "ไม่มีข้อมูล",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "ฮิตประจำสัปดาห์",
  victoryOrDefeat: "ชัยชนะหรือความพ่ายแพ้",
  check: "ตรวจสอบ",
  chat: "แชท",
  quarterlyProfit: "กำไรรายไตรมาส",
};

const RecommendStreamers = {
  recommendedByExperts: "แนะนำโดยผู้เชี่ยวชาญ",
  winsInARow: "แม้จะอยู่ตรงกลางก็ตาม",
  changeBatch: "เปลี่ยนชุด",
  follow: "มุ่งเน้นไปที่",
  following: "ตามมาแล้วครับ",
  unFollowing: "ปลดล็อค",
  noDataPrompt: "ไม่มีข้อมูล",
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  yes: "ใช่",
  no: "เลขที่",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
  unfollowPrompt: "คุณแน่ใจหรือว่าจะเลิกติดตาม?",
};

const RecordChart = {
  record: "บันทึก",
  allWaysToPlay: "ทุกช่องทางในการเล่น",
  fromFarToNear: "จากไกลไปใกล้",
  lastTenGames: "10เกมหลังสุด",
  longestConsecutiveHit: "ตีต่อเนื่องยาวนานที่สุด",
  hitRate: "อัตราการเข้าชม",
  currentPropmt: "เกมล่าสุด",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "ไม่สามารถรับข้อมูลผู้ใช้ได้",
  getAreaAndLanguageErrorPrompt: "ไม่สามารถรับภาษาประจำภูมิภาคได้",
};

const ScheduleCom = {
  startTime: "เวลาเริ่มต้น",
};

const SchemeCard = {
  planList: "รายการแผน",
  release: "ปล่อย",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "รับประกันการชดเชย",
  free: "ฟรี",
  toll: "ค่าผ่านทาง",
  victoryOrDefeat: "ชัยชนะหรือความพ่ายแพ้",
};

const SquareCom = {
  watchPrompt: "ผู้คนกำลังดูอยู่",
  startedPrompt: "เริ่มเมื่อไม่กี่นาทีที่แล้ว",
  chat: "แชท",
  sendMessageNeedLoginPrompt: "กรุณาเข้าสู่ระบบเพื่อส่งข้อความ",
  assistant: "ผู้ดูแลระบบ",
  audience: "ผู้ใช้",
  newMessage: "ข่าวใหม่",
  confirmSend: "ยืนยันการส่ง",
  cancelSend: "ยกเลิก",
};

const StatisticalChart = {
  hitRate: "อัตราการเข้าชม",
};

const SwitchLines = {
  switchLines: "สลับสาย",
  cancel: "ยกเลิก",
  currentStreamer: "สมอปัจจุบัน",
  live: "LIVE",
  offLine: "ยังไม่ได้เริ่มเลย",
  followersCount: "มุ่งเน้นไปที่",
  otherStreamersPrompt: "มีผู้ประกาศข่าวรายอื่นที่กำลังออกอากาศเกมอยู่",
  watch: "ไปดู",
  LINE: "เส้น",
  VIP: "วีไอพี",
  OFFICIAL: "เป็นทางการ",
};

const VideoCom = {
  videoErrorPrompt: "สัญญาณไม่ค่อยดี โปรดลองอีกครั้งในภายหลัง",
  clickRetryPrompt: "คลิกเพื่อลองอีกครั้ง",
};

const WelcomeCard = {
  join: "เข้าร่วมกลุ่ม",
};

//utils
const tools = {
  yesterday: "เมื่อวาน",
  addedFriendPrompt: "เพิ่มเป็นเพื่อนแล้ว",
  withdrawMessage: "ถอนข้อความแล้ว",
  joinGroupPrompt: "เข้าร่วมกลุ่มแล้ว",
  leftGroupPrompt: "ออกจากกลุ่มแล้ว",
  removeGroupPrompt: "ลบออกจากกลุ่มโดยผู้ดูแลระบบ",
  bannedPrompt: "ห้าม",
  unBanPrompt: "ไม่ถูกแบน",
};

//页面
const AddressBook = {
  search: "หา",
  addFriend: "เพิ่มเพื่อน",
  myGroupChat: "แชทกลุ่มของฉัน",
  friends: "เพื่อน",
  delete: "ลบ",
  noFriendsPrompt: "ยังไม่มีเพื่อน",
  nameAndIdSearch: "ใส่ชื่อ, ID",
  searchResults: "ค้นหาผลลัพธ์",
  notFoundPrompt: "ไม่พบชื่อผู้ใช้",
  add: "เพิ่ม",
  deleteFriendPrompt: "คุณแน่ใจหรือว่าจะลบเพื่อนออก?",
  yes: "ใช่",
  no: "เลขที่",
  addFriendSuccessPrompt: "เพิ่มเพื่อนสำเร็จ",
  deleteFriendSuccessPrompt: "ลบเพื่อนสำเร็จ",
  InputSearchPrompt: "กรุณากรอกเนื้อหา",
};

const GroupChat = {
  groupChat: "แชทเป็นกลุ่ม",
  groupSearch: "ป้อนชื่อแชทกลุ่ม",
  noGroupPrompt: "ยังไม่มีการแชทเป็นกลุ่ม",
  chat: "แชท",
  InputSearchPrompt: "กรุณากรอกเนื้อหา",
};

const GroupAdministrator = {
  administrator: "ผู้ดูแลระบบ",
  groupOwner: "พระเจ้า",
};

const GroupAnnouncement = {
  groupAnnouncement: "ประกาศกลุ่ม",
  groupOwner: "พระเจ้า",
  administrator: "ผู้ดูแลระบบ",
};

const GroupDetail = {
  groupDetail: "รายละเอียดกลุ่ม",
  groupMembers: "สมาชิกกลุ่ม",
  groupName: "ชื่อกลุ่ม",
  groupDescription: "รายละเอียดกลุ่ม",
  groupOwner: "พระเจ้า",
  administrator: "ผู้ดูแลระบบ",
  administratorList: "รายชื่อผู้ดูแลระบบ",
  groupAnnouncement: "ประกาศกลุ่ม",
  groupFiles: "ไฟล์กลุ่ม",
  exitGroup: "ออกจากกลุ่ม",
  exitGroupPrompt: "คุณแน่ใจหรือว่าจะออกจากกลุ่ม?",
  yes: "ใช่",
  no: "เลขที่",
  leftGroupSuccessPrompt: "ออกจากกลุ่มสำเร็จ",
};

const GroupFile = {
  groupFiles: "ไฟล์กลุ่ม",
  fileSearch: "ป้อนชื่อไฟล์",
  today: "วันนี้",
  multimedia: "มัลติมีเดีย",
  file: "เอกสาร",
  noFilesPrompt: "ยังไม่มีไฟล์",
};

const GroupMembers = {
  groupMembers: "สมาชิกกลุ่ม",
  nameAndIdSearch: "ใส่ชื่อ, ID",
};

const Chat = {
  chat: "แชท",
  nameAndIdSearch: "ใส่ชื่อ, ID",
  contacts: "สมุดที่อยู่",
  InputSearchPrompt: "กรุณากรอกเนื้อหา",
  deleteContactPrompt: "คุณแน่ใจหรือว่าจะลบแชท?",
};

const Competition = {
  all: "All",
  ongoing: "ทันที",
  popular: "เป็นที่นิยม",
  liveCompetition: "สตรีมมิ่งสด",
  schedule: "กำหนดการ",
  results: "ผลลัพธ์",
  subscribed: "สมัครสมาชิกแล้ว",
  goal: "เป้าหมาย",
  today: "วันนี้",
  Monday: "วันจันทร์",
  Tuesday: "วันอังคาร",
  Wednesday: "วันพุธ",
  Thursday: "วันพฤหัสบดี",
  Friday: "วันศุกร์",
  Saturday: "วันเสาร์",
  Sunday: "วันอาทิตย์",
};

const CompetitionDetails = {
  subscribe: "สมัครสมาชิก",
  subscribed: "สมัครสมาชิกแล้ว",
  matchStreamerPrompt: "ผู้ประกาศเกมถ่ายทอดสด",
  live: "LIVE",
  noDataPrompt: "ไม่มีข้อมูล",
  noStreamersPrompt: "ขณะนี้ไม่มีผู้ดำเนินรายการถ่ายทอดสดเกมนี้",
  alreadyStreamerPrompt: "พิธีกรเริ่มถ่ายทอดสดการแข่งขันแล้ว คุณต้องการไปดูหรือไม่?",
  yes: "ใช่",
  no: "เลขที่",
  liveStream: "สตรีมมิ่งสด",
  matchStatus: "สถานะการแข่งขัน",
  odds: "Odds",
  unSubscribePrompt: "คุณแน่ใจหรือว่าจะยกเลิกการสมัครสมาชิกของคุณ?",
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
};

const OddsDetails = {
  company: "บริษัท",
  handicap: "แต้มต่อ",
  WinDrawLose: "ชนะ เสมอ แพ้",
  totalGoals: "เป้าหมายทั้งหมด",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "มากมาย",
  Un: "น้อย",
};

const Home = {
  logIn: "เข้าสู่ระบบ",
  signUp: "ลงทะเบียน",
  popularMatches: "การแข่งขันยอดนิยม",
  more: "มากกว่า",
  noDataPrompt: "ไม่มีข้อมูล",
  currentlyLive: "ออกอากาศสด",
  popularStreamers: "สมอยอดนิยม",
  thinkYouLike: "คุณอาจจะชอบ",
  loadMore: "โหลดเพิ่มเติม",
  noMoreDataPrompt: "ยังไม่มีข้อมูลเพิ่มเติม",
  addDeskPrompt: "เพิ่มไปยังเดสก์ท็อป",
  clikToolPrompt: "คลิกที่แถบเครื่องมือด้านล่าง",
  andSeletcPrompt: "และเลือก",
  addToScreenPrompt: "เราต้องการเพิ่มลงในหน้าจอหลักของคุณเพื่อให้บริการที่ดียิ่งขึ้น",
  addToScreen: "เพิ่มไปที่หน้าจอหลัก",
};

const MoreLives = {
  noDataPrompt: "ไม่มีข้อมูล",
};

const MoreStreamers = {
  streamer: "สมอ",
  streamerSearch: "ป้อนชื่อจุดยึดและรหัส",
  all: "All",
  notFollowing: "ไม่ติดตาม",
  following: "ตามมาแล้วครับ",
  live: "LIVE",
  offLine: "ยังไม่ได้เริ่มเลย",
  followersCount: "มุ่งเน้นไปที่",
  unfollowPrompt: "คุณแน่ใจหรือว่าจะเลิกติดตาม?",
  yes: "ใช่",
  no: "เลขที่",
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
  InputSearchPrompt: "กรุณากรอกเนื้อหา",
  dataRequestErrorPrompt: "ไม่สามารถรับข้อมูลได้",
};

const LiveRoom = {
  switchLines: "สลับสาย",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "ยึดการแชทส่วนตัว",
  chat: "แชท",
  odds: "Odds",
  streamerInformation: "ข้อมูลจุดยึด",
  matchStatus: "สถานะการแข่งขัน",
};

const AnchorIntroduction = {
  streamerInformation: "ข้อมูลจุดยึด",
  photosWall: "ผนังภาพถ่าย",
  seeMore: "ดูเพิ่มเติม",
  streamerPlan: "แผนสมอ",
};

const AnchorPhoto = {
  photosWall: "ผนังภาพถ่าย",
  noPhotosPrompt: "ยังไม่มีรูปถ่ายเพิ่มเติม",
};

const LogInRegister = {
  logIn: "เข้าสู่ระบบ",
  signUp: "ลงทะเบียน",
  getAreaAndLanguageErrorPrompt: "ไม่สามารถรับภาษาประจำภูมิภาคได้",
  quickRegisterFrequentPrompt: "คำขอที่พบบ่อย โปรดลองอีกครั้งในภายหลัง",
};

const LoginFn = {
  phone: "หมายเลขโทรศัพท์",
  email: "จดหมาย",
  logInPassword: "รหัสผ่านการเข้าใช้งาน",
  rememberPassword: "จดจำรหัสผ่าน",
  forgotPassword: "ลืมรหัสผ่าน",
  logIn: "เข้าสู่ระบบ",
  logInAgreement: "โดยการเข้าสู่ระบบแสดงว่าคุณยอมรับ",
  userAgreement: "ข้อตกลงการใช้",
  and: "  และ ",
  privacyPolicy: "นโยบายความเป็นส่วนตัว",
  passwordRulePrompt: "รหัสผ่านจะต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่และพิมพ์เล็กและตัวเลขและต้องมากกว่า 8 ตัวอักษร",
  passwordEmptyPrompt: "รหัสผ่านต้องไม่เว้นว่าง",
  emailRulePrompt: "กรุณากรอกรูปแบบที่อยู่อีเมลของคุณที่ถูกต้อง",
  emailEmptyPrompt: "อีเมลไม่สามารถเว้นว่างได้",
  phoneRulePrompt: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณในรูปแบบที่ถูกต้อง",
  phoneEmptyPrompt: "หมายเลขโทรศัพท์มือถือต้องไม่เว้นว่าง",
  phoneNum8: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 8 หลักของคุณ",
  phoneNum9To10: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 9 ถึง 10 หลัก",
  phoneNum9: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 9 หลัก",
  enterErrorPrompt: "ข้อผิดพลาดอินพุตหรือความผิดปกติของเครือข่าย",
  loginNoAgreePrompt: "คุณต้องยอมรับข้อตกลงผู้ใช้และนโยบายความเป็นส่วนตัวเพื่อเข้าสู่ระบบ",
  accountAndPassword: "บัญชี",
  account: "บัญชี",
  accountEmptyPrompt: "บัญชีต้องไม่ว่างเปล่า",
  accountOrPasswordError: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
};

const Register = {
  phone: "หมายเลขโทรศัพท์",
  email: "จดหมาย",
  code: "รหัสยืนยัน",
  getCode: "รับรหัสยืนยัน",
  logInPassword: "รหัสผ่านการเข้าใช้งาน",
  CS: "บริการลูกค้า",
  signUp: "ลงทะเบียน",
  signUpAgreement: "โดยการลงทะเบียนคุณเห็นด้วย",
  userAgreement: "ข้อตกลงการใช้",
  and: "และ",
  privacyPolicy: "นโยบายความเป็นส่วนตัว",
  contactCSPrompt: "คุณมีปัญหาในการลงทะเบียนหรือไม่? ติดต่อฝ่ายบริการลูกค้าเพื่อแก้ไขปัญหาให้กับคุณ",
  contactCS: "ติดต่อฝ่ายบริการลูกค้า",
  passwordRulePrompt: "รหัสผ่านจะต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่และพิมพ์เล็กและตัวเลขและต้องมากกว่า 8 ตัวอักษร",
  passwordEmptyPrompt: "รหัสผ่านต้องไม่เว้นว่าง",
  emailRulePrompt: "กรุณากรอกรูปแบบที่อยู่อีเมลของคุณที่ถูกต้อง",
  emailEmptyPrompt: "อีเมลไม่สามารถเว้นว่างได้",
  phoneRulePrompt: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณในรูปแบบที่ถูกต้อง",
  phoneEmptyPrompt: "หมายเลขโทรศัพท์มือถือต้องไม่เว้นว่าง",
  codeRulePrompt: "รหัสยืนยันคือ 6 หลัก",
  codeEmptyPrompt: "ต้องกรอกรหัสยืนยัน",
  phoneNum8: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 8 หลักของคุณ",
  phoneNum9To10: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 9 ถึง 10 หลัก",
  phoneNum9: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 9 หลัก",
  phoneCodeSendSuccessPrompt: "ส่งรหัสยืนยันสำเร็จแล้ว โปรดตรวจสอบข้อความบนโทรศัพท์มือถือของคุณ",
  emailCodeSendSuccessPrompt: "ส่งรหัสยืนยันสำเร็จแล้ว โปรดตรวจสอบอีเมลของคุณ",
  phoneCodeSendErrorPrompt: "ไม่สามารถส่งรหัสยืนยันได้ โปรดตรวจสอบว่าหมายเลขโทรศัพท์มือถือสามารถใช้ได้หรือไม่",
  emailCodeSendErrorPrompt: "ไม่สามารถส่งรหัสยืนยันได้ โปรดตรวจสอบว่าอีเมลของคุณสามารถใช้ได้หรือไม่",
  codeSendToMuchPrompt: "ส่งหลายครั้งเกินไปในเวลาเดียวกัน โปรดลองอีกครั้งในภายหลัง",
  phoneAlreadySignupPrompt: "หมายเลขโทรศัพท์มือถือนี้ได้รับการลงทะเบียนแล้ว กรุณาเข้าสู่ระบบโดยตรง",
  emailAlreadySignupPrompt: "ที่อยู่อีเมลนี้ได้รับการลงทะเบียนแล้ว กรุณาเข้าสู่ระบบโดยตรง",
  signUpErrorPrompt: "การลงทะเบียนล้มเหลว โปรดตรวจสอบว่าข้อมูลถูกต้องหรือไม่",
  enterErrorPrompt: "ข้อผิดพลาดอินพุตหรือความผิดปกติของเครือข่าย",
  noAgreePrompt: "คุณต้องยอมรับข้อตกลงผู้ใช้และนโยบายความเป็นส่วนตัวเพื่อลงทะเบียน",
  regionAndLanguagePrompt: "โปรดเลือกภาษาตามภูมิภาคจริงของคุณ มิฉะนั้นจะส่งผลต่อการใช้งานฟังก์ชัน",
  accountAndPassword: "บัญชี",
  quickSignup: "การลงทะเบียนเพียงคลิกเดียว",
  accountEmptyPrompt: "บัญชีต้องไม่ว่างเปล่า",
  accountRulePrompt: "หมายเลขบัญชีต้องประกอบด้วยตัวอักษรและตัวเลข 8-16 ตัว",
  accountAlreadySignupPrompt: "บัญชีนี้ได้รับการลงทะเบียนแล้ว กรุณาเข้าสู่ระบบโดยตรง",
  tryAgain: "ส่งซ้ำ",
};

const PersonalCenter = {
  logAndSign: "เข้าสู่ระบบ/ลงทะเบียนตอนนี้",
  logAndSignPrompt: "ปลดล็อคคุณสมบัติพิเศษและสิทธิประโยชน์พิเศษทันที",
  logOut: "ออกจากระบบ",
  myFollow: "โฟกัสของฉัน",
  mySubscription: "การสมัครของฉัน",
  accountSecurity: "ความปลอดภัยของบัญชี",
  feedback: "ข้อเสนอแนะ",
  deviceInformation: "อุปกรณ์",
  aboutUs: "เกี่ยวกับเรา",
  coin: "ทอง",
  moreServices: "บริการเพิ่มเติม",
  following: "ตามมาแล้วครับ",
  subscribed: "สมัครสมาชิกแล้ว",
  browser: "เบราว์เซอร์",
  user: "ผู้ใช้",
  profile: "ข้อมูลส่วนบุคคล",
  nickname: "ชื่อนิค",
  save: "บันทึก",
  change: "เปลี่ยน",
  nicknamePrompt: "กรุณากรอกชื่อเล่น",
  avatar: "สัญลักษณ์",
  changeSuccessPrompt: "การเปลี่ยนแปลงประสบความสำเร็จ",
  ballAnimation: "แอนิเมชั่นเป้าหมาย",
  ballVoice: "เอฟเฟกต์เสียงเป้าหมาย",
  setting: "ติดตั้ง",
};

const IntegralSystem = {
  Coin: "ทอง",
  goldCoinTask: "งานเหรียญทอง",
  PointsDetails: "รายละเอียดเหรียญทอง",
  myCoins: "เหรียญทองของฉัน",
  income: "รายได้",
  expenditure: "ค่าใช้จ่าย",
  signInPrompt: "เข้าสู่ระบบอย่างต่อเนื่องและรับรางวัลอย่างต่อเนื่อง",
  toComplete: "ทำให้สมบูรณ์",
  toShare: "แบ่ง",
  shareTasks: "แบ่งปันงาน",
  exclusiveLink: "ลิงค์พิเศษ",
  copyLink: "คัดลอกลิงค์",
  highestWin: "ชัยชนะสูงสุด",
  day1: "วันที่ 1",
  day2: "วันที่ 2",
  day3: "วันที่ 3",
  day4: "วันที่ 4",
  day5: "วันที่ 5",
  day6: "วันที่ 6",
  day7: "วันที่ 7",
  invitePrompt: "เซอร์ไพรส์! ลงทะเบียนตอนนี้และดาวน์โหลดแอพของเราเพื่อรับสิทธิประโยชน์สุดพิเศษสำหรับผู้ใช้ใหม่! การถ่ายทอดสดรายการสดฟรี การวิเคราะห์ข้อมูลแบบเรียลไทม์ และการทำนายจุดยึดอย่างมืออาชีพ ทั้งหมดนี้ทำได้เพียงปลายนิ้วสัมผัส อย่ารอช้า คลิกลิงค์เพื่อเข้าร่วมได้เลย!"
};

const Money = {
  activeCenter: "ศูนย์กิจกรรม",
  missionCenter: "ศูนย์ภารกิจ",
};

const ActivityCenter = {
  toParticipate: "ที่จะเข้าร่วม",
  sportActivity: "กิจกรรมกีฬา",
};

const ActivityDetails = {
  eventDetails: "รายละเอียดกิจกรรม",
  dailyBetting: "การเดิมพันรายวัน",
  matchTime: "เวลาการแข่งขัน",
  popularForEvent: "ตัวเลือกยอดนิยมสำหรับงานนี้",
  clickToParticipate: "คลิกเพื่อเข้าร่วมทันที",
  activityRules: "กฎกิจกรรม",
  clickToContactParticipate: "คลิกเพื่อติดต่อฝ่ายบริการลูกค้าเพื่อเข้าร่วม",
  activityObj: "วัตถุที่ใช้งานอยู่",
  activityPlatform: "แพลตฟอร์มกิจกรรม",
  activityDuration: "ระยะเวลากิจกรรม",
  activityDemand: "ข้อกำหนดกิจกรรม",
  activityTime: "เวลากิจกรรม",
};

const AboutUs = {
  aboutUs: "เกี่ยวกับเรา",
  platformIntroduction: "การแนะนำแพลตฟอร์ม",
  privacyPolicy: "นโยบายความเป็นส่วนตัว",
  userAgreement: "ข้อตกลงการใช้",
};

const DeviceInfo = {
  deviceInformation: "อุปกรณ์",
  currentTime: "เวลาปัจจุบัน",
  port: "พอร์ตเข้าสู่ระบบ",
  ip: "เข้าสู่ระบบไอพี",
  version: "รุ่นปัจจุบัน",
  ipAndPort: "ข้อมูลการเข้าสู่ระบบ",
};

const FeedBack = {
  feedback: "ข้อเสนอแนะ",
  feedbackType: "ประเภทข้อเสนอแนะ",
  productSuggestion: "ข้อเสนอแนะผลิตภัณฑ์",
  functionalFault: "ความล้มเหลวในการทำงาน",
  interfaceSuggestion: "คำแนะนำอินเทอร์เฟซ",
  accountIssue: "ปัญหาบัญชี",
  operationExperience: "ประสบการณ์การดำเนินงาน",
  otherFeedback: "ข้อเสนอแนะอื่น ๆ",
  problemDescription: "คำชี้แจงปัญหา",
  submit: "ส่ง",
  addPhoto: "เพิ่มรูปภาพ",
  uploadPrompt: "ขออภัย สามารถอัปโหลดได้เพียง 3 รูปเท่านั้น",
  uploadRulePrompt: "กรุณาอัพโหลดรูปภาพในรูปแบบ jpg, jpeg, PNG",
  uploadSizePrompt: "ขนาดภาพต้องไม่เกิน 10M",
  chooseFeedback: "โปรดเลือกประเภทความคิดเห็น",
  inputQuestionPrompt: "กรุณากรอกคำถาม",
  submitSuccessPrompt: "ส่งเรียบร้อยแล้ว",
};

const MySubscription = {
  myFollow: "โฟกัสของฉัน",
  mySubscription: "การสมัครของฉัน",
  streamer: "สมอ",
  matches: "การประกวด",
};

const PrivacyAgreement = {
  privacyPolicy: "นโยบายความเป็นส่วนตัว",
};

const TermsofService = {
  termsOfService: "เงื่อนไขการให้บริการ",
};

const UserAgreement = {
  userAgreement: "ข้อตกลงการใช้",
};

const AccountSecurity = {
  accountSecurity: "ความปลอดภัยของบัญชี",
  phone: "หมายเลขโทรศัพท์",
  email: "จดหมาย",
  bind: "ผูกพัน",
  notBound: "ไม่ผูกมัด",
  change: "เปลี่ยน",
  logInPassword: "รหัสผ่านการเข้าใช้งาน",
  accountChangePassword: "กรุณาผูกโทรศัพท์มือถือ/ที่อยู่อีเมลของคุณก่อน",
};

const BindEmail = {
  bindEmail: "ผูกอีเมล",
  email: "จดหมาย",
  code: "รหัสยืนยัน",
  getCode: "รับรหัสยืนยัน",
  confirm: "ยืนยัน",
  emailRulePrompt: "กรุณากรอกรูปแบบที่อยู่อีเมลของคุณที่ถูกต้อง",
  emailEmptyPrompt: "อีเมลไม่สามารถเว้นว่างได้",
  codeRulePrompt: "รหัสยืนยันคือ 6 หลัก",
  codeEmptyPrompt: "ต้องกรอกรหัสยืนยัน",
  changeSuccessPrompt: "การเปลี่ยนแปลงประสบความสำเร็จ",
  changeErrorPrompt: "การเปลี่ยนแปลงล้มเหลว",
  bindSuccessPrompt: "การผูกสำเร็จ",
  bindErrorPrompt: "การเชื่อมโยงล้มเหลว",
  emailCodeSendSuccessPrompt: "ส่งรหัสยืนยันสำเร็จแล้ว โปรดตรวจสอบอีเมลของคุณ",
  emailCodeSendErrorPrompt: "ไม่สามารถส่งรหัสยืนยันได้ โปรดตรวจสอบว่าอีเมลของคุณสามารถใช้ได้หรือไม่",
  codeSendToMuchPrompt: "ส่งหลายครั้งเกินไปในเวลาเดียวกัน โปรดลองอีกครั้งในภายหลัง",
  emailAlreadySignupPrompt: "ที่อยู่อีเมลนี้ได้รับการลงทะเบียนแล้ว กรุณาเข้าสู่ระบบโดยตรง",
  tryAgain: "ส่งซ้ำ",
};

const BindPhone = {
  bindPhone: "ผูกโทรศัพท์มือถือ",
  phone: "หมายเลขโทรศัพท์",
  code: "รหัสยืนยัน",
  getCode: "รับรหัสยืนยัน",
  confirm: "ยืนยัน",
  cancel: "ยกเลิก",
  phoneRulePrompt: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณในรูปแบบที่ถูกต้อง",
  phoneEmptyPrompt: "หมายเลขโทรศัพท์มือถือต้องไม่เว้นว่าง",
  codeRulePrompt: "รหัสยืนยันคือ 6 หลัก",
  codeEmptyPrompt: "ต้องกรอกรหัสยืนยัน",
  changeSuccessPrompt: "การเปลี่ยนแปลงประสบความสำเร็จ",
  changeErrorPrompt: "การเปลี่ยนแปลงล้มเหลว",
  bindSuccessPrompt: "การผูกสำเร็จ",
  bindErrorPrompt: "การเชื่อมโยงล้มเหลว",
  phoneNum8: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 8 หลักของคุณ",
  phoneNum9To10: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 9 ถึง 10 หลัก",
  phoneNum9: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 9 หลัก",
  phoneCodeSendSuccessPrompt: "ส่งรหัสยืนยันสำเร็จแล้ว โปรดตรวจสอบข้อความบนโทรศัพท์มือถือของคุณ",
  phoneCodeSendErrorPrompt: "ไม่สามารถส่งรหัสยืนยันได้ โปรดตรวจสอบว่าหมายเลขโทรศัพท์มือถือสามารถใช้ได้หรือไม่",
  codeSendToMuchPrompt: "ส่งหลายครั้งเกินไปในเวลาเดียวกัน โปรดลองอีกครั้งในภายหลัง",
  phoneAlreadySignupPrompt: "หมายเลขโทรศัพท์มือถือนี้ได้รับการลงทะเบียนแล้ว กรุณาเข้าสู่ระบบโดยตรง",
  checkSignup: "โปรดตรวจสอบว่าหมายเลขดังกล่าวได้รับการลงทะเบียนแล้วหรือไม่",
  tryAgain: "ส่งซ้ำ",
};

const ChangePassword = {
  resetPassword: "รีเซ็ตรหัสผ่าน",
  phone: "หมายเลขโทรศัพท์",
  email: "จดหมาย",
  code: "รหัสยืนยัน",
  getCode: "รับรหัสยืนยัน",
  logInPassword: "รหัสผ่านการเข้าใช้งาน",
  confirm: "ยืนยัน",
  cancel: "ยกเลิก",
  passwordRulePrompt: "รหัสผ่านจะต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่และพิมพ์เล็กและตัวเลขและต้องมากกว่า 8 ตัวอักษร",
  passwordEmptyPrompt: "รหัสผ่านต้องไม่เว้นว่าง",
  emailRulePrompt: "กรุณากรอกรูปแบบที่อยู่อีเมลของคุณที่ถูกต้อง",
  emailEmptyPrompt: "อีเมลไม่สามารถเว้นว่างได้",
  phoneRulePrompt: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณในรูปแบบที่ถูกต้อง",
  phoneEmptyPrompt: "หมายเลขโทรศัพท์มือถือต้องไม่เว้นว่าง",
  codeRulePrompt: "รหัสยืนยันคือ 6 หลัก",
  codeEmptyPrompt: "ต้องกรอกรหัสยืนยัน",
  phoneNum8: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 8 หลักของคุณ",
  phoneNum9To10: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 9 ถึง 10 หลัก",
  phoneNum9: "กรุณากรอกหมายเลขโทรศัพท์มือถือ 9 หลัก",
  resetSuccess: "รีเซ็ตรหัสผ่านสำเร็จ",
  resetError: "การรีเซ็ตรหัสผ่านล้มเหลว โปรดตรวจสอบว่าข้อมูลถูกต้องหรือไม่",
  checkSignup: "โปรดตรวจสอบว่าหมายเลขดังกล่าวได้รับการลงทะเบียนแล้วหรือไม่",
  phoneCodeSendSuccessPrompt: "ส่งรหัสยืนยันสำเร็จแล้ว โปรดตรวจสอบข้อความบนโทรศัพท์มือถือของคุณ",
  codeSendToMuchPrompt: "ส่งหลายครั้งเกินไปในเวลาเดียวกัน โปรดลองอีกครั้งในภายหลัง",
  phoneAlreadySignupPrompt: "หมายเลขโทรศัพท์มือถือนี้ได้รับการลงทะเบียนแล้ว กรุณาเข้าสู่ระบบโดยตรง",
  phoneCodeSendErrorPrompt: "ไม่สามารถส่งรหัสยืนยันได้ โปรดตรวจสอบว่าหมายเลขโทรศัพท์มือถือสามารถใช้ได้หรือไม่",
  emailCodeSendSuccessPrompt: "ส่งรหัสยืนยันสำเร็จแล้ว โปรดตรวจสอบอีเมลของคุณ",
  emailCodeSendErrorPrompt: "ไม่สามารถส่งรหัสยืนยันได้ โปรดตรวจสอบว่าอีเมลของคุณสามารถใช้ได้หรือไม่",
  emailAlreadySignupPrompt: "ที่อยู่อีเมลนี้ได้รับการลงทะเบียนแล้ว กรุณาเข้าสู่ระบบโดยตรง",
  bindPhoneOrEmail: "กรุณาผูกหมายเลขโทรศัพท์มือถือหรือที่อยู่อีเมลของคุณก่อน",
  tryAgain: "ส่งซ้ำ",
};

const QuickRegister = {
  downloadScreenshot: "ดาวน์โหลดภาพหน้าจอ",
  saveAccountAndPassword: "โปรดรักษาบัญชีและรหัสผ่านของคุณให้ปลอดภัย",
  suggestSave: "ขอแนะนำให้คุณจับภาพหน้าจอและบันทึกหรือคัดลอกลงในบันทึกช่วยจำ",
  account: "บัญชี",
  password: "รหัสผ่าน",
  copyAccountAndPassword: "คัดลอกรหัสผ่านบัญชี",
  prompt: "คำใบ้",
  sureSave: "คุณได้บันทึกรหัสผ่านบัญชีของคุณแล้วหรือยัง?",
  confirm: "ยืนยัน",
  cancel: "ยกเลิก",
  copySuccess: "คัดลอกเรียบร้อยแล้ว",
  copyFailure: "การคัดลอกล้มเหลว",
  manualRegister: "จำนวนการลงทะเบียนถึงขีดจำกัดสูงสุดแล้ว โปรดลงทะเบียนด้วยตนเอง",
};

const Recommend = {
  recommend: "แนะนำ",
  InternetErrorPrompt: "ข้อผิดพลาดของเครือข่าย",
  hitOfTheWeek: "ฮิตประจำสัปดาห์",
  victoryOrDefeat: "ชัยชนะหรือความพ่ายแพ้",
  check: "ตรวจสอบ",
  lastTenGames: "10เกมหลังสุด",
  recommendedByExperts: "แนะนำโดยผู้เชี่ยวชาญ",
  changeBatch: "เปลี่ยนชุด",
  follow: "มุ่งเน้นไปที่",
  following: "ตามมาแล้วครับ",
  unFollowing: "ปลดล็อค",
  all: "All",
  guaranteedCompensation: "รับประกันการชดเชย",
  free: "ฟรี",
  toll: "ค่าผ่านทาง",
  HOT: "ขาย",
  HIT: "ตี",
  STREAK: "แม้จะอยู่ตรงกลางก็ตาม",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "หน้าแรก",
  chat: "แชท",
  follow: "มุ่งเน้นไปที่",
  following: "ตามมาแล้วครับ",
  unFollowing: "ปลดล็อค",
  followersCount: "มุ่งเน้นไปที่",
  planCount: "วางแผน",
  viewCount: "ดู",
  statistics: "สถิติ",
  plan: "วางแผน",
};

const SchemeDetails = {
  planDetails: "รายละเอียดแผน",
  release: "ปล่อย",
  chat: "แชท",
  follow: "มุ่งเน้นไปที่",
  following: "ตามมาแล้วครับ",
  unFollowing: "ปลดล็อค",
  details: "รายละเอียด",
  analyze: "วิเคราะห์",
  title: "ชื่อ",
  privateChatStreamerJoinsGroup: "โฮสต์แชทส่วนตัวเข้าร่วมกลุ่ม",
  upcoming: "ยังไม่ได้เริ่ม",
  othersPlan: "ตัวเลือกอื่น",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "การเรียงลำดับการจับคู่",
  byTime: "ตามเวลา",
  byLeague: "ตามลีก",
  confirm: "ยืนยัน",
  cancel: "ยกเลิก",
};

const RecommendPublish = {
  enterTeam: "เข้าทีม",
  planRelease: "ปล่อยแผน",
  predictionTitle: "ชื่อ (ปรากฏแก่ผู้ใช้ทุกคน โปรดอย่าเปิดเผยผลการทำนาย)",
  titleEmptyPrompt: "ชื่อและเนื้อหาของการวิเคราะห์ต้องไม่เว้นว่าง",
  pleaseSelectAtLeastOneGameToPredictResults: "โปรดเลือกอย่างน้อยหนึ่งวิธีในการทำนายผลลัพธ์",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "ฟุตบอล",
  basketball: "บาสเกตบอล",
  mixed: "ครอบคลุม",
  setLogInPassword: "การตั้งรหัสผ่านเข้าสู่ระบบ",
  OCS: "บริการออนไลน์",
  OCSPrompt: "ที่บริการของคุณ",
  phoneInCorrectPrompt: "หมายเลขโทรศัพท์มือถือที่ป้อนไม่ถูกต้อง",
  codeInCorrectPrompt: "รหัสยืนยันไม่ถูกต้อง",
  emailInCorrectPrompt: "ข้อผิดพลาดในการป้อนอีเมล",
  passwordPrompt: "กรุณากรอกภาษาอังกฤษและตัวเลขผสมกัน 8-16 หลัก",
  newPassword: "ป้อนรหัสผ่านใหม่",
  resetPasswordPrompt: "คุณแน่ใจหรือว่าจะรีเซ็ตรหัสผ่านของคุณ?",
  startChatPrompt: "ตอนนี้คุณสามารถเริ่มแชทได้แล้ว",
  send: "ส่ง",
  noFollowPrompt: "ยังไม่มีความสนใจ",
  feedbackPrompt: "กรุณาแสดงความคิดเห็นอันมีค่าของคุณและเราจะทำงานอย่างหนักเพื่อปรับปรุง!",
  bindPhoneByOtherPrompt: "หมายเลขโทรศัพท์มือถือนี้ถูกผูกมัดโดยผู้ใช้รายอื่น",
  bindEmailByOtherPrompt: "ที่อยู่อีเมลนี้ถูกผูกมัดโดยผู้ใช้รายอื่น",
  super_manager: "ผู้ดูแลระบบระดับสูง",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "สิ้นสุดไตรมาสแรก",
  endOfSecondQuarter: "สิ้นสุดไตรมาสที่สอง",
  endOfThirdQuarter: "สิ้นสุดไตรมาสที่สาม",
  endOfFourthQuarter: "สิ้นสุดไตรมาสที่สี่",
  pullUpToLoadMore: "ดึงขึ้นเพื่อโหลดเพิ่มเติม",
  pullDownToRefresh: "ดึงลงเพื่อรีเฟรช",
  releaseToRefreshImmediately: "ปล่อยเพื่อรีเฟรชทันที",
  refreshPrompt: "กำลังสดชื่น...",
  download: "ดาวน์โหลด",
  appDownload: "ดาวน์โหลดแอพ",
  brandName: "OV88",
  saveToDesktop: "เพิ่มไปที่หน้าจอหลัก",
  downloadTitle: "ดาวน์โหลดแอพ",
  normalDownload: "ดาวน์โหลดได้ปกติ",
  scanningPrompt: "ติดตั้งด้วยรหัส QR และรับส่วนลดพิเศษมาก ๆ และสิทธิประโยชน์อื่น ๆ ที่หรูหราให้กับคุณ!",
  downloadFailToWeb: "หากคุณไม่สามารถดาวน์โหลด APP ได้ คลิกที่นี่เพื่อใช้เวอร์ชันเว็บ",
  scanQrCode: "สแกนรหัส QR ด้วยโทรศัพท์มือถือ",
  usingMobileDevices: "ใช้มือถือ",
  installTutorial: "คลิกเพื่อดูบทช่วยสอนการติดตั้ง",
  whyNameDifferent: "ชื่อของแอปที่ติดตั้งและแอปที่ดาวน์โหลดไม่สอดคล้องกัน โปรดดาวน์โหลด ติดตั้ง และใช้งาน!",
  clickDownload: "คลิกเพื่อดาวน์โหลด",
  installTutorialPrompt: "คู่มือคำแนะนำในการติดตั้ง",
  brandNativeName: "แอพเนทีฟ UFOScore",
  uploadingPrompt: "กำลังอัปโหลด...",
  bHandicap: "แต้มต่อ",
  different: "ความแตกต่าง",
  total: "ทั้งหมด",
  off: "ยังไม่ได้เริ่ม",
  video: "วิดีโอ",
  animation: "แอนิเมชั่น",
  install: "ติดตั้ง",
  startDownloading: "เริ่มดาวน์โหลด",
  cancelGoal: "ยกเลิกเป้าหมายแล้ว",
  appUpdatePrompt: "มี APP เวอร์ชันใหม่ โปรดอัปเดต",
};
