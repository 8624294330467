/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "Пользовательское Соглашение",
  privacyPolicy: "политика конфиденциальности",
  termsOfService: "Условия использования",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "Еще не началось",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "Уже в эфире",
  watch: "иди смотреть",
  follow: "сосредоточиться на",
  following: "Уже подписаны",
  subscribe: "подписка",
  streamerSchedule: "Расписание якорей",
  noDataPrompt: "Нет данных",
  unfollowPrompt: "Вы уверены, что отпишитесь?",
  yes: "да",
  no: "нет",
  logAndSign: "Войти/Зарегистрироваться сейчас",
  hours: "Час",
  mins: "минута",
};

const AnchorInformation = {
  recommendedLiveStreams: "Рекомендуем прямой эфир",
  noDataPrompt: "Нет данных",
};

const BasketballScoreIndex = {
  details: "Подробности",
  over: "над",
  pointsDifference: "Разница в очках",
  totalScore: "общий счет",
  live: "LIVE",
  unSubscribePrompt: "Вы уверены, что отмените подписку?",
  yes: "да",
  no: "нет",
  logAndSign: "Войти/Зарегистрироваться сейчас",
  InternetErrorPrompt: "Ошибка сети",
};

const BasketballSmallBig = {
  details: "Подробности",
  over: "над",
  pointsDifference: "Разница в очках",
  totalScore: "общий счет",
  live: "LIVE",
  unSubscribePrompt: "Вы уверены, что отмените подписку?",
  yes: "да",
  no: "нет",
  logAndSign: "Войти/Зарегистрироваться сейчас",
  InternetErrorPrompt: "Ошибка сети",
  overIndex: "большой",
  underIndex: "Маленький",
};

const BindPhoneDialog = {
  bindPhonePrompt: "В целях безопасности вашей учетной записи привяжите номер своего мобильного телефона, чтобы мы могли предоставлять вам более качественные услуги.",
  goBind: "Перейти к привязке",
};

const BottomNavigationBar = {
  home: "титульная страница",
  matches: "Конкурс",
  recommend: "рекомендовать",
  chat: "чат",
  personal: "личный",
  discovery: "Обнаружить",
  money: "Зарабатывать",
};

const ChatInterface = {
  noCsPrompt: "Невозможно общаться в данный момент",
  uploadRulePrompt: "Пожалуйста, загружайте изображения в формате jpg, jpeg, png.",
  uploadSizePrompt: "Размер изображения не может превышать 10 МБ.",
  sendError: "Не удалось отправить",
  picSendError: "Отправка изображения не удалась",
  InternetErrorPrompt: "Ошибка сети",
  bannedSendPrompt: "Вас забанили",
};

const ChatList = {
  noNewPrompt: "Новостей пока нет",
  logInPrompt: "Войдите, чтобы просмотреть контент, пожалуйста, сначала войдите в систему",
  logIn: "Авторизоваться",
  group: "группа",
};

const ChatListItem = {
  withdrawMessage: "Отозвано сообщение",
  bannedPrompt: "запрещен",
  unBanPrompt: "Разбанен",
  joinGroupPrompt: "Присоединился к группе",
  leftGroupPrompt: "Покинул группу",
  picture: "картина",
  link: "Связь",
  emoji: "анимированные смайлы",
  addedFriendPrompt: "Добавлен в друзья",
};

const CompetitionList = {
  logInPrompt: "Войдите, чтобы просмотреть контент, пожалуйста, сначала войдите в систему",
  logIn: "Авторизоваться",
  today: "сегодня",
  Monday: "Понедельник",
  Tuesday: "Вторник",
  Wednesday: "Среда",
  Thursday: "Четверг",
  Friday: "Пятница",
  Saturday: "Суббота",
  Sunday: "Воскресенье",
  noGames: "Игр пока нет",
  toCollapse: "Нажмите, чтобы свернуть",
  toExpand: "Нажмите, чтобы развернуть"
};

const CompetitionListItem = {
  details: "Подробности",
  unSubscribePrompt: "Вы уверены, что отмените подписку?",
  yes: "да",
  no: "нет",
  logAndSign: "Войти/Зарегистрироваться сейчас",
  InternetErrorPrompt: "Ошибка сети",
};

const CompetitionMode = {
  matchMode: "Режим соревнований",
  confirm: "подтверждать",
  cancel: "Отмена",
};

const EmojiCom = {
  logAndSign: "Войти/Зарегистрироваться сейчас",
  yes: "да",
  no: "нет",
};

const ExponentCom = {
  company: "компания",
  initial: "исходный",
  ongoing: "немедленный",
  preMatch: "Перед игрой",
  noDataPrompt: "Нет данных",
  handicap: "инвалидность",
  WinDrawLose: "Выиграть, сыграть вничью, проиграть",
  totalGoals: "общее количество голов",
  cornerKick: "рог",
  pleaseSelect: "пожалуйста, выбери",
  confirm: "подтверждать",
  cancel: "Отмена",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "Прикреплено к «Stream chat».",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "сосредоточиться на",
  following: "Уже подписаны",
  chat: "чат",
  noDataPrompt: "Нет данных",
  unfollowPrompt: "Вы уверены, что отпишитесь?",
  yes: "да",
  no: "нет",
  InternetErrorPrompt: "Ошибка сети",
};

const FootballCornerIndex = {
  details: "Подробности",
  over: "над",
  FH: "HT",
  cornerKick: "рог",
  unSubscribePrompt: "Вы уверены, что отмените подписку?",
  yes: "да",
  no: "нет",
  logAndSign: "Войти/Зарегистрироваться сейчас",
  InternetErrorPrompt: "Ошибка сети",
};

const FootballScoreIndex = {
  details: "Подробности",
  over: "над",
  FH: "HT",
  cornerKick: "рог",
  live: "LIVE",
  unSubscribePrompt: "Вы уверены, что отмените подписку?",
  yes: "да",
  no: "нет",
  logAndSign: "Войти/Зарегистрироваться сейчас",
  InternetErrorPrompt: "Ошибка сети",
};

const FootballScoreItem = {
  details: "Подробности",
  over: "над",
  FH: "HT",
  cornerKick: "рог",
  live: "LIVE",
  unSubscribePrompt: "Вы уверены, что отмените подписку?",
  yes: "да",
  no: "нет",
  logAndSign: "Войти/Зарегистрироваться сейчас",
  InternetErrorPrompt: "Ошибка сети",
};

const GoalToast = {
  goal: "цель",
};

const LiveRecommend = {
  noDataPrompt: "Нет данных",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "Победа или поражение",
};

const MatchCardList = {
  details: "Подробности",
};

const MatchStatus = {
  noDataPrompt: "Нет данных",
};

const MoreStreams = {
  switchLines: "переключать линии",
  cancel: "Отмена",
  currentStreamer: "Текущий якорь",
  live: "LIVE",
  offLine: "Еще не началось",
  followersCount: "сосредоточиться на",
  otherStreamersPrompt: "В настоящее время игру транслируют другие ведущие.",
  watch: "иди смотреть",
};

const NewMatchStatus = {
  team: "команда",
  halfTimeScore: "счет в перерыве тайма",
  shotOnTarget: "Удар по воротам",
  missedShot: "Пропустил гол",
  attack: "атака",
  dangerousAttack: "Опасная атака",
  number3PointGoals: "Количество забитых трёхочковых голов",
  number2PointGoals: "Количество забитых двухочковых голов",
  penaltyKickGoals: "Голы с пенальти",
  freeThrowPercentage: "Процент штрафных бросков",
  numberOfPausesRemaining: "Количество оставшихся пауз",
  numberOfFouls: "Количество фолов",
  totalNumberOfPauses: "Всего пауз",
  threePointShot: "3-очковый бросок",
  twoPointShot: "2-очковый бросок",
  shoot: "Стрелять",
  penalty: "штраф",
  firstHalf: "первая половина",
  secondHalf: "Вторая половина",
  redCard: "Красная карточка",
  yellowCard: "желтая карточка",
};

const NoData = {
  noDataPrompt: "Нет данных",
};

const NoticeMessage = {
  addedFriendPrompt: "Добавлен в друзья",
  withdrawMessage: "Отозвано сообщение",
  withdrawMessageByAdmin: "Сообщение было отозвано администратором",
  joinGroupPrompt: "Присоединился к группе",
  leftGroupPrompt: "Покинул группу",
  removeGroupPrompt: "Удален из группы администратором",
  bannedPrompt: "запрещен",
  unBanPrompt: "Разбанен",
};

const OddsStatisticsList = {
  initial: "исходный",
  ongoing: "немедленный",
  preMatch: "Перед игрой",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "наивысшая ценность",
  min: "самое низкое значение",
  average: "Средняя стоимость",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "Нет данных",
};

const RecommendCompetitions = {
  today: "сегодня",
  winsInARow: "Даже в середине",
  noLivePrompt: "Прямого эфира пока нет",
};

const RecommendStreamerList = {
  noDataPrompt: "Нет данных",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "Хит недели",
  victoryOrDefeat: "Победа или поражение",
  check: "Проверять",
  chat: "чат",
  quarterlyProfit: "квартальная прибыль",
};

const RecommendStreamers = {
  recommendedByExperts: "Рекомендовано экспертами",
  winsInARow: "Даже в середине",
  changeBatch: "Изменить партию",
  follow: "сосредоточиться на",
  following: "Уже подписаны",
  unFollowing: "Разблокировать",
  noDataPrompt: "Нет данных",
  logAndSign: "Войти/Зарегистрироваться сейчас",
  yes: "да",
  no: "нет",
  InternetErrorPrompt: "Ошибка сети",
  unfollowPrompt: "Вы уверены, что отпишитесь?",
};

const RecordChart = {
  record: "записывать",
  allWaysToPlay: "Все способы игры",
  fromFarToNear: "издалека в ближний",
  lastTenGames: "Последние 10 игр",
  longestConsecutiveHit: "Самый длинный последовательный хит",
  hitRate: "процент попаданий",
  currentPropmt: "последние игры",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "Не удалось получить информацию о пользователе.",
  getAreaAndLanguageErrorPrompt: "Не удалось получить региональный язык",
};

const ScheduleCom = {
  startTime: "Время начала",
};

const SchemeCard = {
  planList: "Список планов",
  release: "выпускать",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "Гарантированная компенсация",
  free: "бесплатно",
  toll: "ПОТЕРИ",
  victoryOrDefeat: "Победа или поражение",
};

const SquareCom = {
  watchPrompt: "люди смотрят",
  startedPrompt: "Началось несколько минут назад",
  chat: "чат",
  sendMessageNeedLoginPrompt: "Пожалуйста, войдите, чтобы отправить сообщение",
  assistant: "администратор",
  audience: "пользователь",
  newMessage: "новые новости",
  confirmSend: "Подтвердить отправку",
  cancelSend: "Отмена",
};

const StatisticalChart = {
  hitRate: "процент попаданий",
};

const SwitchLines = {
  switchLines: "переключать линии",
  cancel: "Отмена",
  currentStreamer: "Текущий якорь",
  live: "LIVE",
  offLine: "Еще не началось",
  followersCount: "сосредоточиться на",
  otherStreamersPrompt: "В настоящее время игру транслируют другие ведущие.",
  watch: "иди смотреть",
  LINE: "линия",
  VIP: "VIP",
  OFFICIAL: "чиновник",
};

const VideoCom = {
  videoErrorPrompt: "Сигнал не очень хороший, повторите попытку позже.",
  clickRetryPrompt: "Нажмите, чтобы попробовать еще раз",
};

const WelcomeCard = {
  join: "Вступить в группу",
};

//utils
const tools = {
  yesterday: "вчера",
  addedFriendPrompt: "Добавлен в друзья",
  withdrawMessage: "Отозвано сообщение",
  joinGroupPrompt: "Присоединился к группе",
  leftGroupPrompt: "Покинул группу",
  removeGroupPrompt: "Удален из группы администратором",
  bannedPrompt: "запрещен",
  unBanPrompt: "Разбанен",
};

//页面
const AddressBook = {
  search: "Находить",
  addFriend: "добавить в друзья",
  myGroupChat: "мой групповой чат",
  friends: "друзья",
  delete: "удалить",
  noFriendsPrompt: "Друзей пока нет",
  nameAndIdSearch: "Введите имя, идентификатор",
  searchResults: "Найти результаты",
  notFoundPrompt: "Пользователь не найден",
  add: "добавить в",
  deleteFriendPrompt: "Вы уверены, что удалите друга?",
  yes: "да",
  no: "нет",
  addFriendSuccessPrompt: "Добавление друга успешно добавлено",
  deleteFriendSuccessPrompt: "Удалить друга успешно",
  InputSearchPrompt: "Пожалуйста, введите содержание",
};

const GroupChat = {
  groupChat: "Групповой чат",
  groupSearch: "Введите название группового чата",
  noGroupPrompt: "Группового чата пока нет",
  chat: "чат",
  InputSearchPrompt: "Пожалуйста, введите содержание",
};

const GroupAdministrator = {
  administrator: "администратор",
  groupOwner: "Господин",
};

const GroupAnnouncement = {
  groupAnnouncement: "Объявление группы",
  groupOwner: "Господин",
  administrator: "администратор",
};

const GroupDetail = {
  groupDetail: "Детали группы",
  groupMembers: "Участники группы",
  groupName: "Имя группы",
  groupDescription: "Описание группы",
  groupOwner: "Господин",
  administrator: "администратор",
  administratorList: "Список администраторов",
  groupAnnouncement: "Объявление группы",
  groupFiles: "групповой файл",
  exitGroup: "Выйти из группы",
  exitGroupPrompt: "Вы уверены, что покинете группу?",
  yes: "да",
  no: "нет",
  leftGroupSuccessPrompt: "Успешно вышел из группы",
};

const GroupFile = {
  groupFiles: "групповой файл",
  fileSearch: "Введите имя файла",
  today: "сегодня",
  multimedia: "мультимедиа",
  file: "документ",
  noFilesPrompt: "Файлов пока нет",
};

const GroupMembers = {
  groupMembers: "Участники группы",
  nameAndIdSearch: "Введите имя, идентификатор",
};

const Chat = {
  chat: "чат",
  nameAndIdSearch: "Введите имя, идентификатор",
  contacts: "Адресная книга",
  InputSearchPrompt: "Пожалуйста, введите содержание",
  deleteContactPrompt: "Вы уверены, что удалите чат?",
};

const Competition = {
  all: "All",
  ongoing: "немедленный",
  popular: "Популярный",
  liveCompetition: "прямая трансляция",
  schedule: "расписание",
  results: "результат",
  subscribed: "Подписан",
  goal: "цель",
  today: "сегодня",
  Monday: "Понедельник",
  Tuesday: "Вторник",
  Wednesday: "Среда",
  Thursday: "Четверг",
  Friday: "Пятница",
  Saturday: "Суббота",
  Sunday: "Воскресенье",
};

const CompetitionDetails = {
  subscribe: "подписка",
  subscribed: "Подписан",
  matchStreamerPrompt: "Ведущий прямой трансляции игры",
  live: "LIVE",
  noDataPrompt: "Нет данных",
  noStreamersPrompt: "В настоящее время нет хоста, транслирующего эту игру.",
  alreadyStreamerPrompt: "Ведущий начал трансляцию матча. Хотите пойти и посмотреть его?",
  yes: "да",
  no: "нет",
  liveStream: "прямая трансляция",
  matchStatus: "Статус матча",
  odds: "Odds",
  unSubscribePrompt: "Вы уверены, что отмените подписку?",
  logAndSign: "Войти/Зарегистрироваться сейчас",
  InternetErrorPrompt: "Ошибка сети",
};

const OddsDetails = {
  company: "компания",
  handicap: "инвалидность",
  WinDrawLose: "Выиграть, сыграть вничью, проиграть",
  totalGoals: "общее количество голов",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "много",
  Un: "немного",
};

const Home = {
  logIn: "Авторизоваться",
  signUp: "регистр",
  popularMatches: "Популярные соревнования",
  more: "Более",
  noDataPrompt: "Нет данных",
  currentlyLive: "Прямая трансляция",
  popularStreamers: "Популярный ведущий",
  thinkYouLike: "Вам также может понравиться",
  loadMore: "Загрузи больше",
  noMoreDataPrompt: "Больше данных пока нет",
  addDeskPrompt: "добавить на рабочий стол",
  clikToolPrompt: "Нажмите на панель инструментов ниже",
  andSeletcPrompt: "и выберите",
  addToScreenPrompt: "Мы хотим добавить на ваш главный экран, чтобы обеспечить лучший сервис",
  addToScreen: "Добавить на домашний экран",
};

const MoreLives = {
  noDataPrompt: "Нет данных",
};

const MoreStreamers = {
  streamer: "Якорь",
  streamerSearch: "Введите имя и идентификатор якоря",
  all: "All",
  notFollowing: "Не подписан",
  following: "Уже подписаны",
  live: "LIVE",
  offLine: "Еще не началось",
  followersCount: "сосредоточиться на",
  unfollowPrompt: "Вы уверены, что отпишитесь?",
  yes: "да",
  no: "нет",
  logAndSign: "Войти/Зарегистрироваться сейчас",
  InternetErrorPrompt: "Ошибка сети",
  InputSearchPrompt: "Пожалуйста, введите содержание",
  dataRequestErrorPrompt: "Не удалось получить данные",
};

const LiveRoom = {
  switchLines: "переключать линии",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "Привязать приватный чат",
  chat: "чат",
  odds: "Odds",
  streamerInformation: "Информация о якоре",
  matchStatus: "Статус матча",
};

const AnchorIntroduction = {
  streamerInformation: "Информация о якоре",
  photosWall: "Фото Стена",
  seeMore: "узнать больше",
  streamerPlan: "Якорный план",
};

const AnchorPhoto = {
  photosWall: "Фото Стена",
  noPhotosPrompt: "Больше фотографий пока нет",
};

const LogInRegister = {
  logIn: "Авторизоваться",
  signUp: "регистр",
  getAreaAndLanguageErrorPrompt: "Не удалось получить региональный язык",
  quickRegisterFrequentPrompt: "Частые запросы, повторите попытку позже",
};

const LoginFn = {
  phone: "Номер телефона",
  email: "Почта",
  logInPassword: "логин Пароль",
  rememberPassword: "Запомнить пароль",
  forgotPassword: "забыть пароль",
  logIn: "Авторизоваться",
  logInAgreement: "Авторизуясь, вы соглашаетесь",
  userAgreement: "Пользовательское Соглашение",
  and: "  и ",
  privacyPolicy: "политика конфиденциальности",
  passwordRulePrompt: "Пароль должен состоять из прописных и строчных букв и цифр и содержать более 8 символов.",
  passwordEmptyPrompt: "пароль не может быть пустым",
  emailRulePrompt: "Пожалуйста, введите правильный формат вашего адреса электронной почты",
  emailEmptyPrompt: "E-mail не может быть пустым",
  phoneRulePrompt: "Пожалуйста, введите номер вашего мобильного телефона в правильном формате",
  phoneEmptyPrompt: "Номер мобильного телефона не может быть пустым",
  phoneNum8: "Пожалуйста, введите свой 8-значный номер мобильного телефона",
  phoneNum9To10: "Пожалуйста, введите номер мобильного телефона длиной от 9 до 10 цифр.",
  phoneNum9: "Пожалуйста, введите 9-значный номер мобильного телефона",
  enterErrorPrompt: "Ошибка ввода или неисправность сети",
  loginNoAgreePrompt: "Для входа необходимо согласиться с Пользовательским соглашением и Политикой конфиденциальности.",
  accountAndPassword: "счет",
  account: "счет",
  accountEmptyPrompt: "Аккаунт не может быть пустым",
  accountOrPasswordError: "Неверное имя пользователя или пароль",
};

const Register = {
  phone: "Номер телефона",
  email: "Почта",
  code: "Проверочный код",
  getCode: "получить код подтверждения",
  logInPassword: "логин Пароль",
  CS: "обслуживание клиентов",
  signUp: "регистр",
  signUpAgreement: "Регистрируясь вы соглашаетесь",
  userAgreement: "Пользовательское Соглашение",
  and: "и",
  privacyPolicy: "политика конфиденциальности",
  contactCSPrompt: "У вас возникли проблемы с регистрацией? Свяжитесь со службой поддержки клиентов, чтобы решить эту проблему за вас.",
  contactCS: "Свяжитесь со службой поддержки клиентов",
  passwordRulePrompt: "Пароль должен состоять из прописных и строчных букв и цифр и содержать более 8 символов.",
  passwordEmptyPrompt: "пароль не может быть пустым",
  emailRulePrompt: "Пожалуйста, введите правильный формат вашего адреса электронной почты",
  emailEmptyPrompt: "E-mail не может быть пустым",
  phoneRulePrompt: "Пожалуйста, введите номер вашего мобильного телефона в правильном формате",
  phoneEmptyPrompt: "Номер мобильного телефона не может быть пустым",
  codeRulePrompt: "Код подтверждения состоит из 6 цифр.",
  codeEmptyPrompt: "код подтверждения должен быть заполнен",
  phoneNum8: "Пожалуйста, введите свой 8-значный номер мобильного телефона",
  phoneNum9To10: "Пожалуйста, введите номер мобильного телефона длиной от 9 до 10 цифр.",
  phoneNum9: "Пожалуйста, введите 9-значный номер мобильного телефона",
  phoneCodeSendSuccessPrompt: "Код подтверждения успешно отправлен. Проверьте текстовое сообщение на своем мобильном телефоне.",
  emailCodeSendSuccessPrompt: "Код подтверждения успешно отправлен, проверьте свою электронную почту",
  phoneCodeSendErrorPrompt: "Не удалось отправить код подтверждения. Проверьте, доступен ли номер мобильного телефона.",
  emailCodeSendErrorPrompt: "Не удалось отправить код подтверждения. Проверьте, доступен ли ваш адрес электронной почты.",
  codeSendToMuchPrompt: "Отправлено слишком много раз одновременно. Повторите попытку позже.",
  phoneAlreadySignupPrompt: "Этот номер мобильного телефона зарегистрирован, пожалуйста, войдите в систему напрямую.",
  emailAlreadySignupPrompt: "Этот адрес электронной почты зарегистрирован, пожалуйста, войдите напрямую",
  signUpErrorPrompt: "Регистрация не удалась, пожалуйста, проверьте правильность информации",
  enterErrorPrompt: "Ошибка ввода или неисправность сети",
  noAgreePrompt: "Для регистрации необходимо согласиться с Пользовательским соглашением и Политикой конфиденциальности.",
  regionAndLanguagePrompt: "Пожалуйста, выберите реальный региональный язык, иначе это повлияет на использование функций.",
  accountAndPassword: "счет",
  quickSignup: "Регистрация в один клик",
  accountEmptyPrompt: "Аккаунт не может быть пустым",
  accountRulePrompt: "Номер счета должен состоять из 8-16 букв и цифр.",
  accountAlreadySignupPrompt: "Эта учетная запись зарегистрирована, пожалуйста, войдите напрямую",
  tryAgain: "Отправить",
};

const PersonalCenter = {
  logAndSign: "Войти/Зарегистрироваться сейчас",
  logAndSignPrompt: "Разблокируйте эксклюзивные функции и эксклюзивные преимущества прямо сейчас",
  logOut: "выход",
  myFollow: "мое внимание",
  mySubscription: "моя подписка",
  accountSecurity: "Безопасность аккаунта",
  feedback: "Обратная связь",
  deviceInformation: "оборудование",
  aboutUs: "о нас",
  coin: "золото",
  moreServices: "Больше услуг",
  following: "Уже подписаны",
  subscribed: "Подписан",
  browser: "Браузер",
  user: "пользователь",
  profile: "персональная информация",
  nickname: "Никнейм",
  save: "сохранять",
  change: "Изменять",
  nicknamePrompt: "Пожалуйста, введите псевдоним",
  avatar: "аватар",
  changeSuccessPrompt: "Изменение успешно",
  ballAnimation: "Анимация цели",
  ballVoice: "Звуковые эффекты гола",
  setting: "настраивать",
};

const IntegralSystem = {
  Coin: "золото",
  goldCoinTask: "задание на золотую монету",
  PointsDetails: "Детали золотой монеты",
  myCoins: "мои золотые монеты",
  income: "доход",
  expenditure: "расход",
  signInPrompt: "Постоянно войдите в систему и получайте награды постоянно",
  toComplete: "завершить",
  toShare: "делиться",
  shareTasks: "делиться задачами",
  exclusiveLink: "эксклюзивная ссылка",
  copyLink: "копировать ссылку",
  highestWin: "самая высокая победа",
  day1: "1 день",
  day2: "День 2",
  day3: "3-й день",
  day4: "День 4",
  day5: "День 5",
  day6: "День 6",
  day7: "День 7",
  invitePrompt: "сюрприз! Зарегистрируйтесь сейчас и загрузите наше приложение, чтобы воспользоваться эксклюзивными преимуществами для новых пользователей! Бесплатные прямые трансляции событий, анализ данных в реальном времени и профессиональные прогнозы ведущих — все у вас под рукой. Не ждите больше, нажмите на ссылку, чтобы присоединиться!"
};

const Money = {
  activeCenter: "Центр деятельности",
  missionCenter: "миссионерский центр",
};

const ActivityCenter = {
  toParticipate: "участвовать",
  sportActivity: "спортивные занятия",
};

const ActivityDetails = {
  eventDetails: "Подробности мероприятия",
  dailyBetting: "Ежедневные ставки",
  matchTime: "Время матча",
  popularForEvent: "Популярные варианты этого мероприятия",
  clickToParticipate: "Нажмите, чтобы принять участие сейчас",
  activityRules: "Правила активности",
  clickToContactParticipate: "Нажмите, чтобы связаться со службой поддержки клиентов и принять участие",
  activityObj: "активный объект",
  activityPlatform: "Платформа для проведения мероприятий",
  activityDuration: "Продолжительность активности",
  activityDemand: "Требования к активности",
  activityTime: "Время активности",
};

const AboutUs = {
  aboutUs: "о нас",
  platformIntroduction: "Введение в платформу",
  privacyPolicy: "политика конфиденциальности",
  userAgreement: "Пользовательское Соглашение",
};

const DeviceInfo = {
  deviceInformation: "оборудование",
  currentTime: "Текущее время",
  port: "Порт входа",
  ip: "IP-адрес входа",
  version: "текущая версия",
  ipAndPort: "данные для входа",
};

const FeedBack = {
  feedback: "Обратная связь",
  feedbackType: "тип обратной связи",
  productSuggestion: "предложения продуктов",
  functionalFault: "Функциональный сбой",
  interfaceSuggestion: "Предложения по интерфейсу",
  accountIssue: "Проблема с аккаунтом",
  operationExperience: "Опыт эксплуатации",
  otherFeedback: "Другие отзывы",
  problemDescription: "Постановка задачи",
  submit: "представлять на рассмотрение",
  addPhoto: "добавить фото",
  uploadPrompt: "К сожалению, можно загрузить только 3 фотографии.",
  uploadRulePrompt: "Пожалуйста, загружайте изображения в формате jpg, jpeg, png.",
  uploadSizePrompt: "Размер изображения не может превышать 10 МБ.",
  chooseFeedback: "Пожалуйста, выберите тип отзыва",
  inputQuestionPrompt: "Пожалуйста, введите вопрос",
  submitSuccessPrompt: "Отправлено успешно",
};

const MySubscription = {
  myFollow: "мое внимание",
  mySubscription: "моя подписка",
  streamer: "Якорь",
  matches: "Конкурс",
};

const PrivacyAgreement = {
  privacyPolicy: "политика конфиденциальности",
};

const TermsofService = {
  termsOfService: "Условия использования",
};

const UserAgreement = {
  userAgreement: "Пользовательское Соглашение",
};

const AccountSecurity = {
  accountSecurity: "Безопасность аккаунта",
  phone: "Номер телефона",
  email: "Почта",
  bind: "обязательность",
  notBound: "Не связан",
  change: "Изменять",
  logInPassword: "логин Пароль",
  accountChangePassword: "Пожалуйста, сначала привяжите свой мобильный телефон/адрес электронной почты",
};

const BindEmail = {
  bindEmail: "Привязать электронную почту",
  email: "Почта",
  code: "Проверочный код",
  getCode: "получить код подтверждения",
  confirm: "подтверждать",
  emailRulePrompt: "Пожалуйста, введите правильный формат вашего адреса электронной почты",
  emailEmptyPrompt: "E-mail не может быть пустым",
  codeRulePrompt: "Код подтверждения состоит из 6 цифр.",
  codeEmptyPrompt: "код подтверждения должен быть заполнен",
  changeSuccessPrompt: "Изменение успешно",
  changeErrorPrompt: "Изменение не удалось",
  bindSuccessPrompt: "Привязка прошла успешно",
  bindErrorPrompt: "Привязка не удалась",
  emailCodeSendSuccessPrompt: "Код подтверждения успешно отправлен, проверьте свою электронную почту",
  emailCodeSendErrorPrompt: "Не удалось отправить код подтверждения. Проверьте, доступен ли ваш адрес электронной почты.",
  codeSendToMuchPrompt: "Отправлено слишком много раз одновременно. Повторите попытку позже.",
  emailAlreadySignupPrompt: "Этот адрес электронной почты зарегистрирован, пожалуйста, войдите напрямую",
  tryAgain: "Отправить",
};

const BindPhone = {
  bindPhone: "Привязать мобильный телефон",
  phone: "Номер телефона",
  code: "Проверочный код",
  getCode: "получить код подтверждения",
  confirm: "подтверждать",
  cancel: "Отмена",
  phoneRulePrompt: "Пожалуйста, введите номер вашего мобильного телефона в правильном формате",
  phoneEmptyPrompt: "Номер мобильного телефона не может быть пустым",
  codeRulePrompt: "Код подтверждения состоит из 6 цифр.",
  codeEmptyPrompt: "код подтверждения должен быть заполнен",
  changeSuccessPrompt: "Изменение успешно",
  changeErrorPrompt: "Изменение не удалось",
  bindSuccessPrompt: "Привязка прошла успешно",
  bindErrorPrompt: "Привязка не удалась",
  phoneNum8: "Пожалуйста, введите свой 8-значный номер мобильного телефона",
  phoneNum9To10: "Пожалуйста, введите номер мобильного телефона длиной от 9 до 10 цифр.",
  phoneNum9: "Пожалуйста, введите 9-значный номер мобильного телефона",
  phoneCodeSendSuccessPrompt: "Код подтверждения успешно отправлен. Проверьте текстовое сообщение на своем мобильном телефоне.",
  phoneCodeSendErrorPrompt: "Не удалось отправить код подтверждения. Проверьте, доступен ли номер мобильного телефона.",
  codeSendToMuchPrompt: "Отправлено слишком много раз одновременно. Повторите попытку позже.",
  phoneAlreadySignupPrompt: "Этот номер мобильного телефона зарегистрирован, пожалуйста, войдите в систему напрямую.",
  checkSignup: "Пожалуйста, проверьте, зарегистрирован ли номер",
  tryAgain: "Отправить",
};

const ChangePassword = {
  resetPassword: "Сброс пароля",
  phone: "Номер телефона",
  email: "Почта",
  code: "Проверочный код",
  getCode: "получить код подтверждения",
  logInPassword: "логин Пароль",
  confirm: "подтверждать",
  cancel: "Отмена",
  passwordRulePrompt: "Пароль должен состоять из прописных и строчных букв и цифр и содержать более 8 символов.",
  passwordEmptyPrompt: "пароль не может быть пустым",
  emailRulePrompt: "Пожалуйста, введите правильный формат вашего адреса электронной почты",
  emailEmptyPrompt: "E-mail не может быть пустым",
  phoneRulePrompt: "Пожалуйста, введите номер вашего мобильного телефона в правильном формате",
  phoneEmptyPrompt: "Номер мобильного телефона не может быть пустым",
  codeRulePrompt: "Код подтверждения состоит из 6 цифр.",
  codeEmptyPrompt: "код подтверждения должен быть заполнен",
  phoneNum8: "Пожалуйста, введите свой 8-значный номер мобильного телефона",
  phoneNum9To10: "Пожалуйста, введите номер мобильного телефона длиной от 9 до 10 цифр.",
  phoneNum9: "Пожалуйста, введите 9-значный номер мобильного телефона",
  resetSuccess: "Сброс пароля успешен",
  resetError: "Не удалось сбросить пароль. Проверьте правильность информации.",
  checkSignup: "Пожалуйста, проверьте, зарегистрирован ли номер",
  phoneCodeSendSuccessPrompt: "Код подтверждения успешно отправлен. Проверьте текстовое сообщение на своем мобильном телефоне.",
  codeSendToMuchPrompt: "Отправлено слишком много раз одновременно. Повторите попытку позже.",
  phoneAlreadySignupPrompt: "Этот номер мобильного телефона зарегистрирован, пожалуйста, войдите в систему напрямую.",
  phoneCodeSendErrorPrompt: "Не удалось отправить код подтверждения. Проверьте, доступен ли номер мобильного телефона.",
  emailCodeSendSuccessPrompt: "Код подтверждения успешно отправлен, проверьте свою электронную почту",
  emailCodeSendErrorPrompt: "Не удалось отправить код подтверждения. Проверьте, доступен ли ваш адрес электронной почты.",
  emailAlreadySignupPrompt: "Этот адрес электронной почты зарегистрирован, пожалуйста, войдите напрямую",
  bindPhoneOrEmail: "Пожалуйста, сначала привяжите свой номер мобильного телефона или адрес электронной почты",
  tryAgain: "Отправить",
};

const QuickRegister = {
  downloadScreenshot: "Скачать скриншот",
  saveAccountAndPassword: "Пожалуйста, сохраняйте свою учетную запись и пароль в безопасности",
  suggestSave: "Рекомендуется сделать снимок экрана и сохранить его или скопировать в заметку.",
  account: "счет",
  password: "пароль",
  copyAccountAndPassword: "Скопировать пароль учетной записи",
  prompt: "намекать",
  sureSave: "Вы сохранили пароль своей учетной записи?",
  confirm: "подтверждать",
  cancel: "Отмена",
  copySuccess: "Скопировано успешно",
  copyFailure: "Не удалось скопировать",
  manualRegister: "Количество регистраций достигло верхнего предела, пожалуйста, зарегистрируйтесь вручную",
};

const Recommend = {
  recommend: "рекомендовать",
  InternetErrorPrompt: "Ошибка сети",
  hitOfTheWeek: "Хит недели",
  victoryOrDefeat: "Победа или поражение",
  check: "Проверять",
  lastTenGames: "Последние 10 игр",
  recommendedByExperts: "Рекомендовано экспертами",
  changeBatch: "Изменить партию",
  follow: "сосредоточиться на",
  following: "Уже подписаны",
  unFollowing: "Разблокировать",
  all: "All",
  guaranteedCompensation: "Гарантированная компенсация",
  free: "бесплатно",
  toll: "ПОТЕРИ",
  HOT: "Продажа",
  HIT: "ударять",
  STREAK: "Даже в середине",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "Домашняя страница",
  chat: "чат",
  follow: "сосредоточиться на",
  following: "Уже подписаны",
  unFollowing: "Разблокировать",
  followersCount: "сосредоточиться на",
  planCount: "план",
  viewCount: "смотреть",
  statistics: "статистика",
  plan: "план",
};

const SchemeDetails = {
  planDetails: "Детали плана",
  release: "выпускать",
  chat: "чат",
  follow: "сосредоточиться на",
  following: "Уже подписаны",
  unFollowing: "Разблокировать",
  details: "Подробности",
  analyze: "анализировать",
  title: "заголовок",
  privateChatStreamerJoinsGroup: "Хозяин частного чата присоединяется к группе",
  upcoming: "не началось",
  othersPlan: "Другие варианты",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "Сортировка совпадений",
  byTime: "по времени",
  byLeague: "по лиге",
  confirm: "подтверждать",
  cancel: "Отмена",
};

const RecommendPublish = {
  enterTeam: "Введите команду",
  planRelease: "План выпуска",
  predictionTitle: "Название (видно всем пользователям, пожалуйста, не разглашайте результаты прогноза)",
  titleEmptyPrompt: "Название и содержание анализа не могут быть пустыми.",
  pleaseSelectAtLeastOneGameToPredictResults: "Пожалуйста, выберите хотя бы один способ прогнозирования результатов",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "футбол",
  basketball: "баскетбол",
  mixed: "всесторонний",
  setLogInPassword: "Установка пароля для входа",
  OCS: "Интернет Сервис",
  OCSPrompt: "к вашим услугам",
  phoneInCorrectPrompt: "Номер мобильного телефона введен неверно",
  codeInCorrectPrompt: "неправильный код подтверждения",
  emailInCorrectPrompt: "Ошибка ввода электронной почты",
  passwordPrompt: "Пожалуйста, введите комбинацию из 8-16 цифр английского языка и цифр.",
  newPassword: "Введите новый пароль",
  resetPasswordPrompt: "Вы уверены, что хотите сбросить пароль?",
  startChatPrompt: "Теперь вы можете начать общаться",
  send: "отправлять",
  noFollowPrompt: "Пока нет внимания",
  feedbackPrompt: "Пожалуйста, оставляйте свои ценные комментарии, и мы будем усердно работать над улучшением!",
  bindPhoneByOtherPrompt: "Этот номер мобильного телефона привязан другими пользователями",
  bindEmailByOtherPrompt: "Этот адрес электронной почты привязан другим пользователем",
  super_manager: "суперадминистратор",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "Конец первой четверти",
  endOfSecondQuarter: "Конец второй четверти",
  endOfThirdQuarter: "Конец третьего квартала",
  endOfFourthQuarter: "Конец четвёртого квартала",
  pullUpToLoadMore: "Потяните вверх, чтобы загрузить больше",
  pullDownToRefresh: "Потяните вниз, чтобы обновить",
  releaseToRefreshImmediately: "Отпустите, чтобы немедленно обновить",
  refreshPrompt: "Освежающий...",
  download: "скачать",
  appDownload: "Загрузка приложения",
  brandName: "OV88",
  saveToDesktop: "Добавить на домашний экран",
  downloadTitle: "Загрузка приложения",
  normalDownload: "Обычная загрузка",
  scanningPrompt: "Установите с помощью QR-кода и получите эксклюзивную скидку, а также другие роскошные привилегии!",
  downloadFailToWeb: "Если вы не можете загрузить приложение, нажмите здесь, чтобы использовать веб-версию.",
  scanQrCode: "Сканируйте QR-код с помощью мобильного телефона",
  usingMobileDevices: "Используйте мобильный",
  installTutorial: "Нажмите, чтобы просмотреть руководство по установке",
  whyNameDifferent: "Названия установленного и загруженного приложений не совпадают, пожалуйста, загружайте, устанавливайте и используйте!",
  clickDownload: "нажмите, чтобы скачать",
  installTutorialPrompt: "Руководство по установке",
  brandNativeName: "Родное приложение UFOScore",
  uploadingPrompt: "загружаю...",
  bHandicap: "Гандикап",
  different: "Разница",
  total: "общий",
  off: "не началось",
  video: "видео",
  animation: "анимация",
  install: "Установить",
  startDownloading: "начать загрузку",
  cancelGoal: "Цель отменена",
  appUpdatePrompt: "Вышла новая версия приложения, обновите.",
};
