<template>
  <el-skeleton :loading="dataLoading" animated>
    <template slot="template">
      <MatchInfoSkeleton />
    </template>
    <template>
      <!-- 比赛状况 -->
      <div class="matchInfo" v-if="match" :class="{ noLiveId: noLiveId }">
        <!-- 比赛时间 -->
        <div class="match-startTime">
          <div>{{ match?.league }}</div>
          <div class="time">{{ match?.formatTime }}</div>
        </div>
        <!-- 双方队伍 -->
        <div class="minTime" v-if="match?.competition_status.id === 2">
          {{ match?.match_real_time || "" }}
          <!-- 闪烁符号 -->
          <div
            class="flashingSymbol"
            v-if="
              match.competition_status.id === 2 &&
              match.match_real_time &&
              sportTypeId === 1 &&
              match.match_real_time !== 'HT'
            "
          >
            '
          </div>
        </div>

        <div class="teams">
          <div class="team">
            <div class="tag"></div>
            <van-image
              class="team-flaga"
              fit="cover"
              round
              :src="
                typeId === 1
                  ? placeholderLogo.home_team_logo
                  : placeholderLogo.away_team_logo
              "
              :alt="typeId === 1 ? match.home_team : match.away_team"
            />
            <div class="team-name">
              {{ typeId === 1 ? match.home_team : match.away_team }}
            </div>
          </div>
          <div class="center">
            <div class="scores" v-if="match?.competition_status.id !== 1">
              <div
                class="score"
                :class="{
                  yellowColor:
                    typeId === 1
                      ? match.home_scores[0] > match.away_scores[0]
                      : match.away_scores[0] > match.home_scores[0],
                }"
                v-if="
                  match.away_scores.length !== 0 &&
                  match.home_scores.length !== 0
                "
              >
                {{ typeId === 1 ? match.home_scores[0] : match.away_scores[0] }}
              </div>
              <div class="scores-icon">—</div>
              <div
                class="score"
                :class="{
                  yellowColor:
                    typeId === 1
                      ? match.home_scores[0] < match.away_scores[0]
                      : match.away_scores[0] < match.home_scores[0],
                }"
                v-if="
                  match.home_scores.length !== 0 &&
                  match.away_scores.length !== 0
                "
              >
                {{ typeId === 1 ? match.away_scores[0] : match.home_scores[0] }}
              </div>
            </div>
            <div class="scores" v-if="match?.competition_status.id === 1">
              <div class="vs">VS</div>
            </div>
            <!-- 倒计时 -->
            <div
              class="countdown"
              v-if="
                match.competition_status.id === 1 &&
                countDownTime.hours >= 0 &&
                countDownTime.hours < 24
              "
            >
              <div class="countdown-icon"></div>
              <div class="countdown-wrap">
                <van-count-down :time="countDownTime.ms" />
              </div>
            </div>
            <!-- 比赛中/已结束/距开始超24小时 -->
            <div class="status" v-else>
              <!-- 足球上半场之后显示半 -->
              <div
                class="status-text"
                v-if="
                  match.competition_status.id === 2 &&
                  match.competition_status.midfield
                "
              >
                {{
                  `${$t("FH")}:${match.home_scores?.[1] || 0}-${
                    match.away_scores?.[1] || 0
                  }`
                }}
              </div>
              <!-- 篮球显示总分和分差 -->
              <div
                class="status-text"
                style="margin-left: 5px"
                v-if="typeId === 2"
              >
                {{
                  `(${$t("different")}:${
                    match.home_scores?.[0] - match.away_scores[0] || 0
                  } ${$t("total")}:${
                    match.home_scores?.[0] + match.away_scores?.[0] || 0
                  })`
                }}
              </div>
            </div>
          </div>
          <div class="team">
            <!-- 篮球显示主队标志 -->
            <div class="tag">
              {{ typeId === 2 ? `(${$t("homeTag")})` : "" }}
            </div>
            <van-image
              class="team-flaga"
              fit="contain"
              round
              :src="
                typeId === 1
                  ? placeholderLogo.away_team_logo
                  : placeholderLogo.home_team_logo
              "
              :alt="typeId === 1 ? match.away_team : match.home_team"
            />
            <div class="team-name">
              {{ typeId === 1 ? match.away_team : match.home_team }}
            </div>
          </div>
        </div>
        <!-- <div class="homeTag">
      <div class="tag">({{ $t("homeTag") }})</div>
    </div> -->

        <div class="liveArea">
          <div v-if="currentVideo" class="item" @click="changeMode('video')">
            {{ $t("video") }}
          </div>
          <div class="division" v-if="currentVideo && match.coverage.mlive">
            {{ "|" }}
          </div>
          <div
            v-if="match.coverage.mlive && match.competition_status.id !== 1"
            class="item TASK_WatchGameAnim_BEACON"
            @click="changeMode('animation')"
          >
            {{ $t("animation") }}
          </div>

          <div
            v-else-if="!currentVideo && match.competition_status.id === 1"
            class="subItem"
          >
            <div
              class="item-content-schedule"
              @click.stop="
                clickSubscribe(
                  match.competition_id,
                  !subscribeCompetitionObj?.[match.competition_id]
                )
              "
            >
              <!-- 赛事订阅状态 -->
              <div
                class="item-content-subscribe"
                :style="{
                  backgroundImage:
                    'url(' +
                    (subscribeCompetitionObj?.[match.competition_id]
                      ? subscribedIcon.starRedIcon
                      : subscribedIcon.starGreyIcon) +
                    ')',
                }"
              ></div>
              {{
                subscribeCompetitionObj?.[match.competition_id]
                  ? $t("subscribed")
                  : $t("subscribe")
              }}
            </div>
          </div>
          <!-- <div class="item" @click="changeMode('animation')">{{ $t("animation") }}</div> -->
        </div>
      </div>
      <div class="matchInfoNoData" :class="{ noLiveId: noLiveId }" v-else>
        <NoData v-show="!dataLoading">{{ $t("noDataPrompt") }}</NoData>
        <WaitToastArea v-show="dataLoading"></WaitToastArea>
      </div>
    </template>
  </el-skeleton>
</template>

<script>
/* eslint-disable */
import { subscribedIcon } from "@/utils/commonIcons";
import {
  getFootballCompetitionDetails,
  getBasketballCompetitionDetails,
  getFootballLiveScore,
  getBasketballLiveScore,
} from "@/services/api/Competition";
import { mapActions, mapMutations, mapState } from "vuex";
import dayjs from "dayjs";
import toast from "@/utils/toast.js";
import NoData from "@/components/NoData/Index.vue";
import WaitToastArea from "@/components/WaitToastArea/Index";
import MatchInfoSkeleton from "@/components/MatchInfoSkeleton/Index";
import { checkClientTokenRole } from "@/utils/authCookie";

export default {
  name: "MatchInfo",
  props: {
    hasVideo: {
      type: Boolean,
      default: false,
    },
    competitionId: {
      type: String,
      // required: true,
    },
    sportTypeId: {
      type: Number || null,
    },
    // 没有liveID时调整头部板块的高度
    noLiveId: {
      type: Boolean,
    },
    // 没有直播线路时不显示切换直播的按钮
    currentVideo: Object,
  },
  components: {
    NoData,
    WaitToastArea,
    MatchInfoSkeleton,
  },
  computed: {
    ...mapState({
      subscribeCompetitionObj: (state) =>
        state.competitions.subscribeCompetitionObj,
    }),
    matchId() {
      return this.competitionId;
    },
    typeId() {
      return this.sportTypeId;
    },
    match() {
      return this.competition;
    },

    /**
     * 定义引入的subscribedIcon
     * @return {Object} 订阅状态图标
     */
    subscribedIcon() {
      return subscribedIcon;
    },
  },
  data() {
    return {
      competition: null, // 指定比赛的赛事数据
      countDownTime: {
        // 比赛开始时间倒计时时长
        ms: null, // 毫秒 用于倒计时组件
        hours: null, // 小时 决定倒计时组件的显
      },
      placeholderLogo: {
        home_team_logo: require("@/assets/client-logos/nodata.webp"),
        away_team_logo: require("@/assets/client-logos/nodata.webp"),
      }, // 球队logo的占位符
      competitionTimer: null,
      latestRequest: 0, // 最新的请求
      dataLoading: true,
      imageBaseUrl: "", // 图片基础路径
    };
  },

  async created() {
    // FIXME: 后端暂时没有跑域名的服务，使用提供logo图片路径 ""
    // const basePathStr = localStorage.getItem("basePathObj");
    // const basePathObj = JSON.parse(basePathStr) || {};
    // this.imageBaseUrl = basePathObj?.fileServer || "";
    await this.loadCompetitionDetails(this.matchId, this.typeId);
    this.replaceTeamsLogo();
  },
  watch: {
    matchId: {
      handler: async function (newVal, oldVal) {
        if (newVal !== oldVal) {
          await this.loadCompetitionDetails(this.matchId, this.typeId);
          this.replaceTeamsLogo();
        }
      },
    },
  },
  beforeDestroy() {
    this.clearCompetitionTimer();
  },
  methods: {
    // ...mapActions("globalWS", [
    //   "handleGlobalMessge",
    //   "removeHandleGlobalMessge",
    // ]),
    ...mapActions("competitions", [
      "changeCompetitionSubscribed",
      "setLiveCurrentMatch",
    ]),
    ...mapActions("lives", [
      "fetchVideoLine",
    ]),
    ...mapMutations("competitions", ["SET_COMPETITION_SUBSCRIBED"]),
    changeMode(value) {
      if (value === "video") {
        this.fetchVideoLine(this.competition.live_id)
      }
      this.$emit("changeLiveMode", value);
    },
    formatMatchDate(timestamp) {
      const matchDate = dayjs(timestamp * 1000); // 将时间戳转换为 Day.js 日期对象
      let formattedDate = matchDate.format("YYYY/MM/DD HH:mm"); //  "年/月/日 时:分"
      return formattedDate;
    },
    getCountDownTime(timestamp) {
      const matchDate = dayjs(timestamp * 1000); // 将时间戳转换为 Day.js 日期对象
      const now = dayjs(); // 获取当前时间
      const diffMs = matchDate.diff(now);
      const diffInHours = diffMs / (1000 * 60 * 60);
      return {
        ms: diffMs,
        hours: diffInHours,
      };
    },
    async loadCompetitionDetails(competition_id, typeId) {
      if (!competition_id) return;
      const language_id = localStorage.getItem("language_id");
      const country_id = localStorage.getItem("country_id");
      this.dataLoading = true;
      try {
        const getCompetitionDetails =
          Number(typeId) === 1
            ? getFootballCompetitionDetails
            : getBasketballCompetitionDetails;
        const res = await getCompetitionDetails(competition_id, {
          match_id: competition_id,
          // country_id,
          language_id,
        });
        if (res?.statusCode === 200) {
          let data = { ...res.data };
          // data["formatTime"] = this.formatMatchDate(data.match_time);
          this.competition = data;
          this.setLiveCurrentMatch({ ...data });

          this.countDownTime = this.getCountDownTime(data.match_time);
          // console.log("competition", this.competition, typeId);
          this.setCompetitionTimer(data.sport_type_id, data.competition_id);
          this.dataLoading = false;
        }
      } catch (error) {
        console.error(error);
        this.dataLoading = false;
      }
    },
    async updateScoreAndTime(sport_type_id, match_id) {
      const fetchFn =
        sport_type_id === 1 ? getFootballLiveScore : getBasketballLiveScore;
      try {
        const currentRequest = ++this.latestRequest;
        const res = await fetchFn(match_id);
        const { data, statusCode } = res || {};
        if (statusCode === 200) {
          if (currentRequest === this.latestRequest) {
            let preData = { ...this.competition };
            this.competition = { ...preData, ...data };
            this.setLiveCurrentMatch({ ...preData, ...data });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 定时请求比赛详情
     */
    setCompetitionTimer(sport_type_id, match_id) {
      this.clearCompetitionTimer();
      if (this.competition?.competition_status.id === 2) {
        this.competitionTimer = setInterval(() => {
          this.updateScoreAndTime(sport_type_id, match_id);
        }, 3 * 1000);
      }
    },
    /**
     * 清除定时请求赛事列表数据（即时和直播）
     */
    clearCompetitionTimer() {
      if (this.competitionTimer) {
        clearInterval(this.competitionTimer);
        this.competitionTimer = null;
      }
    },
    /**
     * 动态替换球队logo
     */
    replaceTeamsLogo() {
      (this.placeholderLogo = {
        home_team_logo: require("@/assets/client-logos/nodata.webp"),
        away_team_logo: require("@/assets/client-logos/nodata.webp"),
      }),
        ["home_team_logo", "away_team_logo"].forEach((team) => {
          const img = new Image();
          img.src = this.imageBaseUrl + this.competition?.[team];
          img.onload = () => {
            // 图像加载完成后，更新头像
            this.placeholderLogo[team] =
              this.imageBaseUrl + this.competition?.[team];
          };
        });
    },
    /**
     * 点击了赛事的订阅状态 触发弹框
     * @param {number} competition_id 比赛ID
     * @param {boolean} subscribed 用户是否关注该比赛
     */
    clickSubscribe(competition_id, value) {
      if (!value) {
        // 原状态已订阅 操作是取消订阅 弹框再次确认
        this.$dialog
          .confirm({
            title: this.$t("unSubscribePrompt"),
            theme: "round-button",
            confirmButtonText: this.$t("yes"),
            cancelButtonText: this.$t("no"),
            confirmButtonColor: "#2A283D", // 灰色
            cancelButtonColor: "#6418C3", // 紫色
            closeOnClickOverlay: true,
            className: "confirmLeft",
            getContainer: "#app",
          })
          .then(() => {
            // on confirm
            console.log("确定");
            this.changeSubscribed(competition_id, value);
          })
          .catch(() => {
            // on cancel
            // console.log("取消");
          });
      } else {
        // 原状态未订阅 操作是进行订阅 直接调用接口
        this.changeSubscribed(competition_id, value);
      }
      return;
    },

    /**
     * 通过vuex action
     * 更改服务端该比赛的订阅状态
     * @param {number} competition_id 比赛ID
     * @param {boolean} value 用户是否关注该比赛
     */
    async changeSubscribed(competition_id, value) {
      if (checkClientTokenRole() !== "user") {
        this.$dialog
          .confirm({
            title: this.$t("logAndSign"),
            theme: "round-button",
            confirmButtonText: this.$t("yes"),
            cancelButtonText: this.$t("no"),
            confirmButtonColor: "#6418C3", // 紫色
            cancelButtonColor: "#2A283D", // 灰色
            closeOnClickOverlay: true,
            getContainer: ".matchInfo",
          })
          .then(() => {
            sessionStorage.setItem("preLoginRoute", this.$route.fullPath);
            // on confirm
            this.$router.push("/LogInRegister");
          })
          .catch(() => {
            // on cancel
            // console.log("取消");
          });
        return;
      }
      const watiToast = toast.initWaitToast();
      const successCallback = () => {
        watiToast.clear(); // 清除加载toast
        if (this.category === "subscribed") {
          this.$emit("refreshSubscribedTab");
        }
      };
      const errorCallback = () => {
        // this.$vantToast({
        //   type: "fail",
        //   // FIXME:网络错误注释
        //   // message: this.$t("InternetErrorPrompt"),
        // });
        console.log("fail:网络错误！");
      };

      await this.changeCompetitionSubscribed({
        competition_id,
        subscribed: value,
        successCallback,
        errorCallback,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
</style>
