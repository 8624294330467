/* eslint-disable */
import dayjs from "dayjs";
import {
  subscribeCompetition,
  unsubscribeCompetition,
  getSportTypes,
  getFootballCompetitions,
  getBasketballCompetitions,
  getSubscribeCompetitionList,
} from "@/services/api/Competition";
const state = {
  // 更新足球时间的定时器
  timer: null,
  // 足球比赛进球提示动画内容
  footballScoreContent: null,
  // 进球球队样式对象定时器
  scoreObjTimer: null,
  // 进球球队样式对象
  scoreObj: {},
  // 比赛比分对象 competition_id: {homeScore, awayScore}
  competitionScoreObj: {},
  // 比赛订阅比赛
  subscribeCompetitionObj: {},
  // 体育类型
  sportTypes: [
    { id: 1, name: "football" },
    { id: 2, name: "basketball" },
    // FIXME： 注释掉的是后续需要添加的体育类型
    // { id: 3, name: "mixed" },
  ],

  // 赛事数据 需分组的已分组
  competitionData: {
    all: [],
    immediate: [],
    popular: [],
    streamer: [],
    in_schedule: [],
    in_result: [],
    subscribed: [],
  },

  latestRequest: 0, // 赛事数据最新请求

  // 页面状态
  competitionPageStatus: {
    sportTypeId: 1, // 体育id
    football: {
      categoryIndex: 0, // 分类tab索引 0-全部 1-即时 2-直播 3-赛程 4-赛果 5-已订阅
      category: "all", // 分类 immediate-即时 popular-热门 streamer-直播 in_schedule-赛程 in_result-赛果 subscribed-已订阅
      currentPage: {
        all: 1,
        immediate: 1,
        popular: 1,
        streamer: 1,
        in_schedule: 1,
        in_result: 1,
        subscribed: 1,
      }, // 已加载的页码
      maxPages: {
        all: null,
        immediate: null,
        popular: null,
        streamer: null,
        in_schedule: null,
        in_result: null,
        subscribed: null,
      }, // 最大页码
      scrollY: {
        all: null,
        immediate: null,
        popular: null,
        streamer: null,
        in_schedule: null,
        in_result: null,
        subscribed: null,
      }, // 滚动距离
      activeScheduleDate: null, // 赛程日期
      activeResultDate: null, // 赛果日期
      floatButtomStatus: "score", //赛事数据模式 默认为“比分”
      sortMode: "time", // 排序方式 time-按时间 league-按联赛
    },
    basketball: {
      categoryIndex: 0, // 分类tab索引 0-全部 1-即时 2-直播 3-赛程 4-赛果 5-已订阅
      category: "all", // 分类 immediate-即时 popular-热门 streamer-直播 in_schedule-赛程 in_result-赛果 subscribed-已订阅
      currentPage: {
        all: 1,
        immediate: 1,
        popular: 1,
        streamer: 1,
        in_schedule: 1,
        in_result: 1,
        subscribed: 1,
      }, // 已加载的页码
      maxPages: {
        all: null,
        immediate: null,
        popular: null,
        streamer: null,
        in_schedule: null,
        in_result: null,
        subscribed: null,
      }, // 最大页码
      scrollY: {
        all: null,
        immediate: null,
        popular: null,
        streamer: null,
        in_schedule: null,
        in_result: null,
        subscribed: null,
      }, // 滚动距离
      activeScheduleDate: null, // 赛程日期
      activeResultDate: null, // 赛果日期
      floatButtomStatus: null, // 赛事数据模式 默认为“比分” (篮球暂无数据模式)
      sortMode: "time", // 排序方式 time-按时间 league-按联赛
    },
  },

  // 指数板块相关的数据
  odds: {
    oddsData: {}, // 指数数据
    matchPeriod: 0, // 0-全场 1-半场
  },
  // 直播间正在打开的比赛，用于记录分数
  liveCurrentMatch: {},
};

const mutations = {
  /**
   * 设置定时器
   */
  SET_TIMER(state, timer) {
    state.timer = timer;
  },
  /**
   * 清除定时器
   */
  CLEAR_TIMER(state) {
    clearInterval(state.timer);
    state.timer = null;
  },
  /**
   * 设置足球比赛进球提示动画内容
   */
  SET_FOOTBALL_SCORE_CONTENT(state, footballScore) {
    state.footballScoreContent = footballScore;
  },
  /**
   * 清除足球比赛进球提示动画内容
   */
  CLEAR_FOOTBALL_SCORE_CONTENT(state) {
    state.footballScoreContent = null;
  },
  /**
   * 设置指定类别赛事的数据。
   * @param {String} payload.category - 赛事数据类别。
   * @param {Array} payload.groupedCompetition - 对应类别的赛事数据。
   * @param {Boolean} payload.isRefresh - 是否刷新行为
   */
  SET_COMPETITION_DATA(state, { category, groupedCompetition, isRefresh }) {
    // state.competitionData[category] = groupedCompetition;
    // FIXME:  检查内存泄漏
    if (isRefresh) {
      // 刷新行为直接替换
      state.competitionData[category] = groupedCompetition;
    } else {
      if (["popular", "streamer", "subscribed"].includes(category)) {
        // 经过日期分组的数据多嵌套了一层数组 需要注意新数据的首项与已有数据的末项日期是否相同
        const aLength = state.competitionData[category].length;
        const lastOfA = state.competitionData[category][aLength - 1]; // 已有数据的末项
        const firstOfB = groupedCompetition[0]; // 新数据的首项
        if (lastOfA[0].date === firstOfB[0].date) {
          // 新数据的首项与已有数据的末项日期相同
          lastOfA.push(...firstOfB);
          state.competitionData[category].push(...groupedCompetition.slice(1));
        } else {
          state.competitionData[category].push(...groupedCompetition);
        }
      } else {
        // 无需进行日期分组的数据
        state.competitionData[category].push(...groupedCompetition);
      }
    }
  },

  /**
   * 设置all类别赛事的数据。
   * @param {String} payload.category - all中的赛事数据子类别。
   * @param {Array} payload.data - 对应类别的赛事数据。
   * @param {Boolean} payload.isRefresh - 是否刷新行为
   */
  SET_COMPETITION_DATA_ALL(state, { category, data, isRefresh, isTimer }) {
    if (isRefresh) {
      if (isTimer) {
        // 定时器行为(赛程赛果没有请求新的数据)
        state.competitionData.all = [
          state.competitionData.all[0],
          data[1],
          state.competitionData.all[2],
        ];
      } else {
        // 刷新行为直接替换
        state.competitionData.all = data;
      }
    }
  },

  /**
   * 清空赛事的数据。
   */
  CLEAR_COMPETITION_DATA(state) {
    Object.keys(state.competitionData).forEach((key) => {
      state.competitionData[key] = [];
    });
  },

  /**
   * 设置指定id赛事的订阅状态。
   * @param {string} payload.category - 赛事数据类别。
   * @param {Number} payload.competition_id - 赛事ID。
   */
  SET_COMPETITION_SUBSCRIBED(state, { category, competition_id }) {},

  /**
   * 设置体育类型列表。
   * @param {string} payload.sportTypes - 体育类型列表
   */
  SET_SPORT_TYPES(state, { sportTypes }) {
    state.sportTypes = sportTypes;
  },

  /**
   * 设置页面状态。
   */
  SET_COMPETITIONPAGESTATUS(
    state,
    {
      sportTypeId,
      categoryIndex,
      category,
      currentPage,
      maxPages,
      scrollY,
      sortMode,
    }
  ) {
    if (!sportTypeId) {
      console.error("The 'sportTypeId' parameter must be passed");
      return;
    }
    state.competitionPageStatus.sportTypeId = Number(sportTypeId);
    const statusObj =
      Number(sportTypeId) === 1
        ? state.competitionPageStatus.football
        : state.competitionPageStatus.basketball;
    if (categoryIndex !== undefined) {
      statusObj.categoryIndex = categoryIndex;
    }
    if (category !== undefined) {
      statusObj.category = category;
    } else if (currentPage || maxPages || scrollY) {
      console.error("You need to pass the parameter 'category'");
      return;
    }
    if (currentPage !== undefined) {
      statusObj.currentPage[category] = currentPage;
    }
    if (maxPages !== undefined) {
      statusObj.maxPages[category] = maxPages;
    }
    if (scrollY !== undefined) {
      statusObj.scrollY[category] = scrollY;
    }
    if (sortMode !== undefined) {
      statusObj.sortMode = sortMode;
    }
  },

  /**
   * 设置页面状态-日期
   */
  SET_COMPETITIONPAGESTATUS_DATE(
    state,
    { sportTypeId, activeScheduleDate, activeResultDate }
  ) {
    if (!sportTypeId) {
      console.error("The 'sportTypeId' parameter must be passed");
      return;
    }
    const statusObj =
      Number(sportTypeId) === 1
        ? state.competitionPageStatus.football
        : state.competitionPageStatus.basketball;
    if (activeScheduleDate) {
      statusObj.activeScheduleDate = activeScheduleDate;
    }
    if (activeResultDate) {
      statusObj.activeResultDate = activeResultDate;
    }
  },

  /**
   * 清除页面状态
   * @param {String} key 被清除的值的键名
   */
  CLEAR_COMPETITIONPAGESTATUS(state, { sportTypeId, key }) {
    if (!sportTypeId) {
      console.error("The 'sportTypeId' parameter must be passed");
      return;
    }
    if (!key) {
      console.error("The 'key' parameter must be passed");
      return;
    }
    const isValid = [
      "categoryIndex",
      "category",
      "currentPage",
      "maxPages",
      "scrollY",
      "floatButtomStatus",
      "activeScheduleDate",
      "activeResultDate",
      "sortMode",
    ].includes(key);
    if (!isValid) {
      console.error(
        "The effective enumeration value is ['categoryIndex','category','currentPage','maxPages','scrollY','floatButtomStatus', 'activeScheduleDate', 'activeResultDate', 'sortMode']"
      );
      return;
    }
    const statusObj =
      Number(sportTypeId) === 1
        ? state.competitionPageStatus.football
        : state.competitionPageStatus.basketball;
    if (key === "categoryIndex") {
      statusObj[key] = 0;
    }
    if (key === "category") {
      statusObj[key] = "all";
    }
    if (key === "currentPage") {
      statusObj[key] = {
        all: 1,
        immediate: 1,
        popular: 1,
        streamer: 1,
        in_schedule: 1,
        in_result: 1,
        subscribed: 1,
      };
    }
    if (key === "maxPages" || key === "scrollY") {
      statusObj[key] = {
        all: null,
        immediate: null,
        popular: null,
        streamer: null,
        in_schedule: null,
        in_result: null,
        subscribed: null,
      };
    }
    if (key === "floatButtomStatus") {
      statusObj[key] = "score";
    }
    if (key === "activeScheduleDate") {
      statusObj[key] = null;
    }
    if (key === "activeResultDate") {
      statusObj[key] = null;
    }
    if (key === "sortMode") {
      statusObj[key] = "time";
    }
  },

  /**
   * 设置指数数据
   */
  SET_ODDSDATA(state, { oddsData }) {
    state.odds.oddsData = oddsData;
  },

  /**
   * 设置指数的全/半场之分
   */
  SET_MATCHPERIOD(state, { matchPeriod }) {
    state.odds.matchPeriod = matchPeriod;
  },

  SET_MATCH_MODEL(state, { sportTypeId, data }) {
    if (!sportTypeId) {
      console.error("The 'sportTypeId' parameter must be passed");
      return;
    }
    if (Number(sportTypeId) === 1) {
      state.competitionPageStatus.football.floatButtomStatus = data;
    } else {
      // 篮球暂无数据模式
      state.competitionPageStatus.basketball.floatButtomStatus = data;
    }
  },

  /**
   * 设置订阅赛事对象
   */
  SET_SUBSCRIBE_COMPETITION_OBJ(state, { subscribeCompetitionObj }) {
    state.subscribeCompetitionObj = subscribeCompetitionObj;
  },

  /**
   * 清除订阅赛事对象
   */
  CLEAR_SUBSCRIBE_COMPETITION_OBJ(state) {
    state.subscribeCompetitionObj = {};
  },
  /**
   * 设置直播间正在打开的比赛
   */
  SET_LIVE_CURRENT_MATCH(state, liveCurrentMatch) {
    state.liveCurrentMatch = liveCurrentMatch;
  },
};

const actions = {
  /**
   * 清除定时器
   */
  clearTimer({ commit }) {
    commit("CLEAR_TIMER");
  },
  /**
   * 异步获取指定类别的赛事数据，并提交到 state。
   * @param { Object } payload.fetchParams - 传递给 API 的参数对象。
   * @param { Boolean } payload.isRefresh - 是否刷新行为
   * @param { Boolean } payload.isTimer - 是否定时行为
   */
  async fetchCompetitionData({ commit }, { fetchParams, isRefresh, isTimer }) {
    const getCompetition =
      fetchParams.sport_type_id === 1
        ? getFootballCompetitions
        : getBasketballCompetitions;
    try {
      const currentRequest = ++state.latestRequest;
      const res = await getCompetition(fetchParams.category, {
        ...fetchParams,
        language_id: Number(fetchParams.language_id),
        // country_id: Number(fetchParams.country_id),
      });
      const { data, statusCode } = res;
      const { page, pages } = data;
      if (statusCode === 200 && data) {
        const category = fetchParams.category;
        let competitionData = data.items;
        if (category === "all") {
          const { in_result, immediate, in_schedule } = JSON.parse(data);
          competitionData = [
            in_result,
            immediate,
            [
              ...in_schedule[0],
              {
                competition_id: "isDivisionDate",
                date: dayjs(fetchParams.date)
                  .add(1, "day")
                  .format("YYYY-MM-DD"),
                weekDate: dayjs(fetchParams.date).add(1, "day").format("dddd"),
              },
              ...in_schedule[1],
            ],
          ];
        }
        const groupedCompetition = groupCompetitionData(
          competitionData,
          category
        ); // 日期分组处理
        if (category === "immediate") {
          computeScoreContent(state, competitionData);
        }
        // // 提交处理后的数据到 state
        if (currentRequest === state.latestRequest) {
          commit("SET_COMPETITION_DATA", {
            category,
            groupedCompetition,
            isRefresh,
          });
          return { page, pages };
        }
        return {};
      } else {
        return {};
      }
    } catch (error) {
      console.error(`请求${fetchParams.category}赛事数据出错:`, error);
    }
  },
  /**
   * 获取订阅赛事
   */
  async fetchSubscribeCompetitionList({ commit }) {
    try {
      const res = await getSubscribeCompetitionList();
      const { data, statusCode } = res || {};
      if (statusCode === 200) {
        const { items } = data || [];
        let obj = {};
        items.forEach((item) => {
          obj[item] = true;
        });
        commit("SET_SUBSCRIBE_COMPETITION_OBJ", {
          subscribeCompetitionObj: obj,
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  /**
   * 清除订阅赛事对象
   */
  clearSubscribeCompetitionObj({ commit }) {
    commit("CLEAR_SUBSCRIBE_COMPETITION_OBJ");
  },
  /**
   * 异步更改指定赛事的订阅状态
   * @param {string} payload.competition_id - 赛事id。
   * @param {Object} payload.subscribed - 赛事的订阅状态。
   */
  async changeCompetitionSubscribed(
    context,
    {
      competition_id,
      subscribed,
      successCallback = () => {},
      errorCallback = () => {},
    }
  ) {
    const fetchFn = subscribed ? subscribeCompetition : unsubscribeCompetition;

    try {
      const res = await fetchFn(competition_id);
      const { statusCode } = res || {};
      if (statusCode === 200) {
        // 调用 fetchSubscribeCompetitionList
        context.dispatch("fetchSubscribeCompetitionList");
        successCallback();
      } else {
        errorCallback();
      }
    } catch (error) {
      console.error(error);
      errorCallback();
    }
  },

  /**
   * 异步获取体育类型列表
   * @param {string} payload.language_id - 语言id。
   */
  async fetchSportTypes({ commit }, { language_id }) {
    try {
      const response = await getSportTypes(language_id);
      // 提交数据到 state
      // FIXME: 过滤掉了混合类型
      commit("SET_SPORT_TYPES", {
        sportTypes: [response.data.items?.[0], response.data.items?.[1]],
      });
      return true;
    } catch (error) {
      console.error(`获取体育类型列表出错 language_id: ${language_id}`, error);
      return false;
    }
  },

  /**
   * 清除足球比赛进球提示动画内容
   */
  clearFootballScoreContent({ commit }) {
    commit("CLEAR_FOOTBALL_SCORE_CONTENT");
  },
  /**
   * 设置直播间正在打开的比赛
   */
  setLiveCurrentMatch({ commit }, liveCurrentMatch) {
    commit("SET_LIVE_CURRENT_MATCH", liveCurrentMatch);
  },
};

function computeScoreContent(state, competitions) {
  competitions.forEach((item) => {
    const {
      home_scores,
      away_scores,
      competition_id,
      sport_type_id,
      home_team,
      away_team,
      competition_status,
    } = item;
    const target = state.competitionScoreObj[competition_id];
    if (!target || competition_status?.id !== 2) {
      state.competitionScoreObj[competition_id] = {
        home_scores: home_scores,
        away_scores: away_scores,
        sport_type_id: sport_type_id,
      };
      return;
    }
    let scoreContent = {
      time: item.match_real_time,
      // homeScore: home_scores[0],
      // awayScore: away_scores[0],
      goalTeam: home_team,
      concedeTeam: away_team,
      competition: item,
    };
    // home_scores 不为null且不是空数组
    if (home_scores && home_scores.length !== 0) {
      scoreContent.homeScore = home_scores[0];
      scoreContent.awayScore = away_scores[0];
      // 足球进球
      // 判断是否有进球
      if (home_scores[0] > target.home_scores[0]) {
        if (sport_type_id === 1) {
          //足球进球
          scoreContent.goalText = "goal";
          state.footballScoreContent = scoreContent;
        }
        state.scoreObj[competition_id] = "home";
        changeScoreObjTimer(competition_id);
      } else if (home_scores[0] < target.home_scores[0]) {
        if (sport_type_id === 1) {
          // 取消进球
          scoreContent.goalText = "cancelGoal";
          state.footballScoreContent = scoreContent;
        }
        state.scoreObj[competition_id] = "home";
        changeScoreObjTimer(competition_id);
      }
    }

    if (away_scores && away_scores.length !== 0) {
      // 进球
      scoreContent.homeScore = home_scores[0];
      scoreContent.awayScore = away_scores[0];

      // 判断是否有进球
      if (away_scores[0] > target.away_scores[0]) {
        if (sport_type_id === 1) {
          // 进球
          scoreContent.goalText = "goal";
          state.footballScoreContent = scoreContent;
        }
        state.scoreObj[competition_id] = "away";
        changeScoreObjTimer(competition_id);
      } else if (away_scores[0] < target.away_scores[0]) {
        if (sport_type_id === 1) {
          // 取消进球
          scoreContent.goalText = "cancelGoal";
          state.footballScoreContent = scoreContent;
        }
        state.scoreObj[competition_id] = "away";
        changeScoreObjTimer(competition_id);
      }
    }
    state.competitionScoreObj[competition_id] = {
      home_scores: home_scores,
      away_scores: away_scores,
      sport_type_id: sport_type_id,
    };
  });
}

/**
 * 将比赛数据按日期分组
 * @returns { Array } 返回一个数组。
 */
function groupCompetitionData(competitionData, category) {
  if (["popular", "streamer", "subscribed"].includes(category)) {
    const resData = {};
    competitionData.forEach((match) => {
      const date = formatMatchDate(match.match_time); // 转换时间戳为日期字符串
      const weekDate = formatWeekDate(match.match_time); // 转换时间戳为日期字符串
      if (!resData[date]) {
        // 如果该日期尚未在resData对象中创建，则初始化一个数组
        resData[date] = [];
      }
      resData[date].push({ ...match, date, weekDate });
    });
    let tempArray = Object.values(resData);
    return tempArray;
  } else {
    return competitionData;
  }
}

/**
 * 根据给定的时间戳格式化比赛日期。
 * @param {number} timestamp - 比赛的时间戳（单位：秒）
 * @returns {string} 格式化后的日期字符串 月/日
 */
function formatMatchDate(timestamp) {
  const matchDate = dayjs(timestamp * 1000); // 将时间戳转换为 Day.js 日期对象
  let formattedDate = matchDate.format("MM/DD"); //  "月/日"
  const now = dayjs(); // 获取当前日期的 Day.js 日期对象
  // 如果是今天，则在前面添加“今天”
  if (matchDate.isSame(now, "day")) {
    formattedDate = `today`;
  }
  return formattedDate;
}

/**
 * 根据给定的时间戳格式化比赛日期。
 * @param {number} timestamp - 比赛的时间戳（单位：秒）
 * @returns {string} 格式化后的日期字符串 星期几
 */
function formatWeekDate(timestamp) {
  const matchDate = dayjs(timestamp * 1000); // 将时间戳转换为 Day.js 日期对象
  let formattedDate = matchDate.format("dddd"); //  "星期几"
  const now = dayjs(); // 获取当前日期的 Day.js 日期对象
  // 如果是今天，则在前面添加“今天”
  if (matchDate.isSame(now, "day")) {
    formattedDate = null;
  }
  return formattedDate;
}
/**
 *  启动/暂停进球对象定时器
 */
function changeScoreObjTimer(competition_id) {
  // if (state.scoreObjTimer) {
  //   clearTimeout(state.scoreObjTimer);
  //   state.scoreObjTimer = null;
  // }
  setTimeout(() => {
    state.scoreObj[competition_id] = null;
  }, 500);
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
