/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "Accord de l'utilisateur",
  privacyPolicy: "politique de confidentialité",
  termsOfService: "Conditions d'utilisation",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "Pas encore commencé",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "Déjà diffusé",
  watch: "Allez voir",
  follow: "se concentrer sur",
  following: "Je suis déjà abonné",
  subscribe: "abonnement",
  streamerSchedule: "Calendrier d'ancrage",
  noDataPrompt: "Pas de données",
  unfollowPrompt: "Êtes-vous sûr de ne plus suivre ?",
  yes: "Oui",
  no: "Non",
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  hours: "Heure",
  mins: "minute",
};

const AnchorInformation = {
  recommendedLiveStreams: "Diffusion en direct recommandée",
  noDataPrompt: "Pas de données",
};

const BasketballScoreIndex = {
  details: "Détails",
  over: "sur",
  pointsDifference: "Différence de points",
  totalScore: "score total",
  live: "LIVE",
  unSubscribePrompt: "Etes-vous sûr d'annuler votre abonnement ?",
  yes: "Oui",
  no: "Non",
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  InternetErrorPrompt: "Erreur réseau",
};

const BasketballSmallBig = {
  details: "Détails",
  over: "sur",
  pointsDifference: "Différence de points",
  totalScore: "score total",
  live: "LIVE",
  unSubscribePrompt: "Etes-vous sûr d'annuler votre abonnement ?",
  yes: "Oui",
  no: "Non",
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  InternetErrorPrompt: "Erreur réseau",
  overIndex: "grand",
  underIndex: "Petit",
};

const BindPhoneDialog = {
  bindPhonePrompt: "Pour la sécurité de votre compte, veuillez lier votre numéro de téléphone mobile afin que nous puissions vous fournir de meilleurs services.",
  goBind: "Aller à la liaison",
};

const BottomNavigationBar = {
  home: "page de garde",
  matches: "Concours",
  recommend: "recommander",
  chat: "chat",
  personal: "personnel",
  discovery: "Découvrir",
  money: "faire de l'argent",
};

const ChatInterface = {
  noCsPrompt: "Impossible de discuter pour le moment",
  uploadRulePrompt: "Veuillez télécharger des photos au format jpg, jpeg, png",
  uploadSizePrompt: "La taille de l'image ne peut pas dépasser 10 M",
  sendError: "Échec de l'envoi",
  picSendError: "L'envoi de l'image a échoué",
  InternetErrorPrompt: "Erreur réseau",
  bannedSendPrompt: "Vous avez été banni",
};

const ChatList = {
  noNewPrompt: "Pas encore de nouvelles",
  logInPrompt: "Connectez-vous pour voir le contenu, veuillez d'abord vous connecter",
  logIn: "Se connecter",
  group: "groupe",
};

const ChatListItem = {
  withdrawMessage: "Retiré un message",
  bannedPrompt: "banni",
  unBanPrompt: "Non banni",
  joinGroupPrompt: "A rejoint le groupe",
  leftGroupPrompt: "A quitté le groupe",
  picture: "image",
  link: "Lien",
  emoji: "émoticônes animées",
  addedFriendPrompt: "Ajouté comme ami",
};

const CompetitionList = {
  logInPrompt: "Connectez-vous pour voir le contenu, veuillez d'abord vous connecter",
  logIn: "Se connecter",
  today: "aujourd'hui",
  Monday: "Lundi",
  Tuesday: "Mardi",
  Wednesday: "Mercredi",
  Thursday: "Jeudi",
  Friday: "Vendredi",
  Saturday: "Samedi",
  Sunday: "Dimanche",
  noGames: "Pas encore de jeux",
  toCollapse: "Cliquez pour réduire",
  toExpand: "Cliquez pour agrandir"
};

const CompetitionListItem = {
  details: "Détails",
  unSubscribePrompt: "Etes-vous sûr d'annuler votre abonnement ?",
  yes: "Oui",
  no: "Non",
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  InternetErrorPrompt: "Erreur réseau",
};

const CompetitionMode = {
  matchMode: "Mode compétition",
  confirm: "confirmer",
  cancel: "Annuler",
};

const EmojiCom = {
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  yes: "Oui",
  no: "Non",
};

const ExponentCom = {
  company: "entreprise",
  initial: "initial",
  ongoing: "immédiat",
  preMatch: "Avant le match",
  noDataPrompt: "Pas de données",
  handicap: "handicap",
  WinDrawLose: "Gagner, dessiner, perdre",
  totalGoals: "objectifs totaux",
  cornerKick: "corne",
  pleaseSelect: "Choisissez s'il vous plaît",
  confirm: "confirmer",
  cancel: "Annuler",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "Épinglé sur 'Carré'",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "se concentrer sur",
  following: "Je suis déjà abonné",
  chat: "chat",
  noDataPrompt: "Pas de données",
  unfollowPrompt: "Êtes-vous sûr de ne plus suivre ?",
  yes: "Oui",
  no: "Non",
  InternetErrorPrompt: "Erreur réseau",
};

const FootballCornerIndex = {
  details: "Détails",
  over: "sur",
  FH: "HT",
  cornerKick: "corne",
  unSubscribePrompt: "Etes-vous sûr d'annuler votre abonnement ?",
  yes: "Oui",
  no: "Non",
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  InternetErrorPrompt: "Erreur réseau",
};

const FootballScoreIndex = {
  details: "Détails",
  over: "sur",
  FH: "HT",
  cornerKick: "corne",
  live: "LIVE",
  unSubscribePrompt: "Etes-vous sûr d'annuler votre abonnement ?",
  yes: "Oui",
  no: "Non",
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  InternetErrorPrompt: "Erreur réseau",
};

const FootballScoreItem = {
  details: "Détails",
  over: "sur",
  FH: "HT",
  cornerKick: "corne",
  live: "LIVE",
  unSubscribePrompt: "Etes-vous sûr d'annuler votre abonnement ?",
  yes: "Oui",
  no: "Non",
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  InternetErrorPrompt: "Erreur réseau",
};

const GoalToast = {
  goal: "but",
};

const LiveRecommend = {
  noDataPrompt: "Pas de données",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "Victoire ou défaite",
};

const MatchCardList = {
  details: "Détails",
};

const MatchStatus = {
  noDataPrompt: "Pas de données",
};

const MoreStreams = {
  switchLines: "changer de ligne",
  cancel: "Annuler",
  currentStreamer: "Ancre actuelle",
  live: "LIVE",
  offLine: "Pas encore commencé",
  followersCount: "se concentrer sur",
  otherStreamersPrompt: "Il y a d'autres présentateurs qui diffusent actuellement le jeu",
  watch: "Allez voir",
};

const NewMatchStatus = {
  team: "équipe",
  halfTimeScore: "score à la mi-temps",
  shotOnTarget: "Tir au but",
  missedShot: "J'ai raté le but",
  attack: "attaque",
  dangerousAttack: "Attaque dangereuse",
  number3PointGoals: "Nombre de buts à 3 points marqués",
  number2PointGoals: "Nombre de buts à 2 points marqués",
  penaltyKickGoals: "Buts sur penalty",
  freeThrowPercentage: "Pourcentage de lancers francs",
  numberOfPausesRemaining: "Nombre de pauses restantes",
  numberOfFouls: "Nombre de fautes",
  totalNumberOfPauses: "Nombre total de pauses",
  threePointShot: "Tir à 3 points",
  twoPointShot: "Tir à 2 points",
  shoot: "Tirer",
  penalty: "peine",
  firstHalf: "première moitié",
  secondHalf: "Deuxième partie",
  redCard: "carte rouge",
  yellowCard: "carte jaune",
};

const NoData = {
  noDataPrompt: "Pas de données",
};

const NoticeMessage = {
  addedFriendPrompt: "Ajouté comme ami",
  withdrawMessage: "Retiré un message",
  withdrawMessageByAdmin: "Un message a été retiré par l'administrateur",
  joinGroupPrompt: "A rejoint le groupe",
  leftGroupPrompt: "A quitté le groupe",
  removeGroupPrompt: "Supprimé du groupe par l'administrateur",
  bannedPrompt: "banni",
  unBanPrompt: "Non banni",
};

const OddsStatisticsList = {
  initial: "initial",
  ongoing: "immédiat",
  preMatch: "Avant le match",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "valeur la plus élevée",
  min: "valeur la plus basse",
  average: "valeur moyenne",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "Pas de données",
};

const RecommendCompetitions = {
  today: "aujourd'hui",
  winsInARow: "Même au milieu",
  noLivePrompt: "Pas encore de diffusion en direct",
};

const RecommendStreamerList = {
  noDataPrompt: "Pas de données",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "Coup de la semaine",
  victoryOrDefeat: "Victoire ou défaite",
  check: "Vérifier",
  chat: "chat",
  quarterlyProfit: "bénéfice trimestriel",
};

const RecommendStreamers = {
  recommendedByExperts: "Recommandé par les experts",
  winsInARow: "Même au milieu",
  changeBatch: "Changer un lot",
  follow: "se concentrer sur",
  following: "Je suis déjà abonné",
  unFollowing: "Ouvrir",
  noDataPrompt: "Pas de données",
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  yes: "Oui",
  no: "Non",
  InternetErrorPrompt: "Erreur réseau",
  unfollowPrompt: "Êtes-vous sûr de ne plus suivre ?",
};

const RecordChart = {
  record: "enregistrer",
  allWaysToPlay: "Toutes les façons de jouer",
  fromFarToNear: "de loin vers près",
  lastTenGames: "10 derniers matchs",
  longestConsecutiveHit: "Le plus long succès consécutif",
  hitRate: "taux de succès",
  currentPropmt: "jeux récents",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "Échec de l'obtention des informations sur l'utilisateur",
  getAreaAndLanguageErrorPrompt: "Impossible d'obtenir la langue régionale",
};

const ScheduleCom = {
  startTime: "Heure de départ",
};

const SchemeCard = {
  planList: "Liste des forfaits",
  release: "libérer",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "Rémunération garantie",
  free: "gratuit",
  toll: "SONNER",
  victoryOrDefeat: "Victoire ou défaite",
};

const SquareCom = {
  watchPrompt: "les gens regardent",
  startedPrompt: "Commencé il y a quelques minutes",
  chat: "chat",
  sendMessageNeedLoginPrompt: "Veuillez vous connecter pour envoyer un message",
  assistant: "administrateur",
  audience: "utilisateur",
  newMessage: "Nouvelles informations",
  confirmSend: "Confirmer l'envoi",
  cancelSend: "Annuler",
};

const StatisticalChart = {
  hitRate: "taux de succès",
};

const SwitchLines = {
  switchLines: "changer de ligne",
  cancel: "Annuler",
  currentStreamer: "Ancre actuelle",
  live: "LIVE",
  offLine: "Pas encore commencé",
  followersCount: "se concentrer sur",
  otherStreamersPrompt: "Il y a d'autres présentateurs qui diffusent actuellement le jeu",
  watch: "Allez voir",
  LINE: "doubler",
  VIP: "VIP",
  OFFICIAL: "officiel",
};

const VideoCom = {
  videoErrorPrompt: "Le signal n'est pas très bon, veuillez réessayer plus tard.",
  clickRetryPrompt: "Cliquez pour réessayer",
};

const WelcomeCard = {
  join: "Rejoindre le groupe",
};

//utils
const tools = {
  yesterday: "hier",
  addedFriendPrompt: "Ajouté comme ami",
  withdrawMessage: "Retiré un message",
  joinGroupPrompt: "A rejoint le groupe",
  leftGroupPrompt: "A quitté le groupe",
  removeGroupPrompt: "Supprimé du groupe par l'administrateur",
  bannedPrompt: "banni",
  unBanPrompt: "Non banni",
};

//页面
const AddressBook = {
  search: "Trouver",
  addFriend: "Ajouter un ami",
  myGroupChat: "ma discussion de groupe",
  friends: "amis",
  delete: "supprimer",
  noFriendsPrompt: "Pas encore d'amis",
  nameAndIdSearch: "Entrez le nom, l'identifiant",
  searchResults: "Trouver des résultats",
  notFoundPrompt: "Utilisateur non trouvé",
  add: "Ajouter à",
  deleteFriendPrompt: "Êtes-vous sûr de supprimer l'ami ?",
  yes: "Oui",
  no: "Non",
  addFriendSuccessPrompt: "Ajouter un ami avec succès",
  deleteFriendSuccessPrompt: "Supprimer un ami avec succès",
  InputSearchPrompt: "Veuillez saisir le contenu",
};

const GroupChat = {
  groupChat: "discussion de groupe",
  groupSearch: "Entrez le nom de la discussion de groupe",
  noGroupPrompt: "Pas encore de discussion de groupe",
  chat: "chat",
  InputSearchPrompt: "Veuillez saisir le contenu",
};

const GroupAdministrator = {
  administrator: "administrateur",
  groupOwner: "Seigneur",
};

const GroupAnnouncement = {
  groupAnnouncement: "Annonce du groupe",
  groupOwner: "Seigneur",
  administrator: "administrateur",
};

const GroupDetail = {
  groupDetail: "Détails du groupe",
  groupMembers: "les membres du groupe",
  groupName: "Nom de groupe",
  groupDescription: "Description du groupe",
  groupOwner: "Seigneur",
  administrator: "administrateur",
  administratorList: "Liste des administrateurs",
  groupAnnouncement: "Annonce du groupe",
  groupFiles: "fichier de groupe",
  exitGroup: "Quitter le groupe",
  exitGroupPrompt: "Etes-vous sûr de quitter le groupe ?",
  yes: "Oui",
  no: "Non",
  leftGroupSuccessPrompt: "J'ai quitté le groupe avec succès",
};

const GroupFile = {
  groupFiles: "fichier de groupe",
  fileSearch: "Entrez le nom du fichier",
  today: "aujourd'hui",
  multimedia: "multimédia",
  file: "document",
  noFilesPrompt: "Aucun fichier pour l'instant",
};

const GroupMembers = {
  groupMembers: "les membres du groupe",
  nameAndIdSearch: "Entrez le nom, l'identifiant",
};

const Chat = {
  chat: "chat",
  nameAndIdSearch: "Entrez le nom, l'identifiant",
  contacts: "Carnet d'adresses",
  InputSearchPrompt: "Veuillez saisir le contenu",
  deleteContactPrompt: "Êtes-vous sûr de supprimer le chat ?",
};

const Competition = {
  all: "All",
  ongoing: "immédiat",
  popular: "Populaire",
  liveCompetition: "diffusion en direct",
  schedule: "calendrier",
  results: "résultat",
  subscribed: "Abonné",
  goal: "but",
  today: "aujourd'hui",
  Monday: "Lundi",
  Tuesday: "Mardi",
  Wednesday: "Mercredi",
  Thursday: "Jeudi",
  Friday: "Vendredi",
  Saturday: "Samedi",
  Sunday: "Dimanche",
};

const CompetitionDetails = {
  subscribe: "abonnement",
  subscribed: "Abonné",
  matchStreamerPrompt: "Ancre de jeu en direct",
  live: "LIVE",
  noDataPrompt: "Pas de données",
  noStreamersPrompt: "Il n'y a actuellement aucun hébergeur diffusant ce jeu",
  alreadyStreamerPrompt: "Un animateur a commencé à diffuser le match. Vous souhaitez aller le voir ?",
  yes: "Oui",
  no: "Non",
  liveStream: "diffusion en direct",
  matchStatus: "Statut de correspondance",
  odds: "Odds",
  unSubscribePrompt: "Etes-vous sûr d'annuler votre abonnement ?",
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  InternetErrorPrompt: "Erreur réseau",
};

const OddsDetails = {
  company: "entreprise",
  handicap: "handicap",
  WinDrawLose: "Gagner, dessiner, perdre",
  totalGoals: "objectifs totaux",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "beaucoup",
  Un: "peu",
};

const Home = {
  logIn: "Se connecter",
  signUp: "registre",
  popularMatches: "Compétitions populaires",
  more: "Plus",
  noDataPrompt: "Pas de données",
  currentlyLive: "Diffusion en direct",
  popularStreamers: "Ancre populaire",
  thinkYouLike: "Tu pourrais aussi aimer",
  loadMore: "charger plus",
  noMoreDataPrompt: "Pas encore de données",
  addDeskPrompt: "ajouter au bureau",
  clikToolPrompt: "Cliquez sur la barre d'outils ci-dessous",
  andSeletcPrompt: "et sélectionnez",
  addToScreenPrompt: "Nous souhaitons ajouter à votre écran d'accueil pour fournir un meilleur service",
  addToScreen: "Ajouter à l'écran d'accueil",
};

const MoreLives = {
  noDataPrompt: "Pas de données",
};

const MoreStreamers = {
  streamer: "Ancre",
  streamerSearch: "Entrez le nom et l'ID de l'ancre",
  all: "All",
  notFollowing: "Ne pas suivre",
  following: "Je suis déjà abonné",
  live: "LIVE",
  offLine: "Pas encore commencé",
  followersCount: "se concentrer sur",
  unfollowPrompt: "Êtes-vous sûr de ne plus suivre ?",
  yes: "Oui",
  no: "Non",
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  InternetErrorPrompt: "Erreur réseau",
  InputSearchPrompt: "Veuillez saisir le contenu",
  dataRequestErrorPrompt: "Échec de l'obtention des données",
};

const LiveRoom = {
  switchLines: "changer de ligne",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "Ancrer le chat privé",
  chat: "chat",
  odds: "Odds",
  streamerInformation: "Informations sur l'ancre",
  matchStatus: "Statut de correspondance",
};

const AnchorIntroduction = {
  streamerInformation: "Informations sur l'ancre",
  photosWall: "Mur de photos",
  seeMore: "voir plus",
  streamerPlan: "Plan d'ancrage",
};

const AnchorPhoto = {
  photosWall: "Mur de photos",
  noPhotosPrompt: "Pas encore de photos",
};

const LogInRegister = {
  logIn: "Se connecter",
  signUp: "registre",
  getAreaAndLanguageErrorPrompt: "Impossible d'obtenir la langue régionale",
  quickRegisterFrequentPrompt: "Demandes fréquentes, veuillez réessayer plus tard",
};

const LoginFn = {
  phone: "Numéro de téléphone",
  email: "Mail",
  logInPassword: "mot de passe",
  rememberPassword: "Se souvenir du mot de passe",
  forgotPassword: "oublier le mot de passe",
  logIn: "Se connecter",
  logInAgreement: "En vous connectant, vous acceptez",
  userAgreement: "Accord de l'utilisateur",
  and: "  et ",
  privacyPolicy: "politique de confidentialité",
  passwordRulePrompt: "Le mot de passe doit être composé de lettres majuscules et minuscules et de chiffres et doit comporter plus de 8 caractères.",
  passwordEmptyPrompt: "le mot de passe ne peut pas être vide",
  emailRulePrompt: "Veuillez saisir le format correct de votre adresse e-mail",
  emailEmptyPrompt: "L'e-mail ne peut pas être vide",
  phoneRulePrompt: "Veuillez saisir votre numéro de téléphone portable au format correct",
  phoneEmptyPrompt: "Le numéro de téléphone portable ne peut pas être vide",
  phoneNum8: "Veuillez saisir votre numéro de téléphone portable à 8 chiffres",
  phoneNum9To10: "Veuillez saisir un numéro de téléphone mobile de 9 à 10 chiffres",
  phoneNum9: "Veuillez saisir un numéro de téléphone portable à 9 chiffres",
  enterErrorPrompt: "Erreur d'entrée ou anomalie du réseau",
  loginNoAgreePrompt: "Vous devez accepter les conditions d'utilisation et la politique de confidentialité pour vous connecter",
  accountAndPassword: "compte",
  account: "compte",
  accountEmptyPrompt: "Le compte ne peut pas être vide",
  accountOrPasswordError: "identifiant ou mot de passe incorrect",
};

const Register = {
  phone: "Numéro de téléphone",
  email: "Mail",
  code: "Le code de vérification",
  getCode: "obtenir le code de vérification",
  logInPassword: "mot de passe",
  CS: "service client",
  signUp: "registre",
  signUpAgreement: "En vous inscrivant, vous acceptez",
  userAgreement: "Accord de l'utilisateur",
  and: "et",
  privacyPolicy: "politique de confidentialité",
  contactCSPrompt: "Vous rencontrez des difficultés pour vous inscrire ? Contactez le service client pour résoudre le problème pour vous",
  contactCS: "Contactez le service à la clientèle",
  passwordRulePrompt: "Le mot de passe doit être composé de lettres majuscules et minuscules et de chiffres et doit comporter plus de 8 caractères.",
  passwordEmptyPrompt: "le mot de passe ne peut pas être vide",
  emailRulePrompt: "Veuillez saisir le format correct de votre adresse e-mail",
  emailEmptyPrompt: "L'e-mail ne peut pas être vide",
  phoneRulePrompt: "Veuillez saisir votre numéro de téléphone portable au format correct",
  phoneEmptyPrompt: "Le numéro de téléphone portable ne peut pas être vide",
  codeRulePrompt: "Le code de vérification est composé de 6 chiffres",
  codeEmptyPrompt: "le code de vérification doit être rempli",
  phoneNum8: "Veuillez saisir votre numéro de téléphone portable à 8 chiffres",
  phoneNum9To10: "Veuillez saisir un numéro de téléphone mobile de 9 à 10 chiffres",
  phoneNum9: "Veuillez saisir un numéro de téléphone portable à 9 chiffres",
  phoneCodeSendSuccessPrompt: "Code de vérification envoyé avec succès, veuillez vérifier le message texte sur votre téléphone portable",
  emailCodeSendSuccessPrompt: "Code de vérification envoyé avec succès, veuillez vérifier votre email",
  phoneCodeSendErrorPrompt: "Le code de vérification n'a pas pu être envoyé, veuillez vérifier si le numéro de téléphone portable est disponible",
  emailCodeSendErrorPrompt: "Le code de vérification n'a pas pu être envoyé, veuillez vérifier si votre e-mail est disponible",
  codeSendToMuchPrompt: "Envoyé trop de fois en même temps, veuillez réessayer plus tard",
  phoneAlreadySignupPrompt: "Ce numéro de téléphone mobile a été enregistré, veuillez vous connecter directement",
  emailAlreadySignupPrompt: "Cette adresse email a été enregistrée, veuillez vous connecter directement",
  signUpErrorPrompt: "L'inscription a échoué, veuillez vérifier si les informations sont correctes",
  enterErrorPrompt: "Erreur d'entrée ou anomalie du réseau",
  noAgreePrompt: "Vous devez accepter les conditions d'utilisation et la politique de confidentialité pour vous inscrire",
  regionAndLanguagePrompt: "Veuillez sélectionner votre véritable langue régionale, sinon cela affectera l'utilisation des fonctions",
  accountAndPassword: "compte",
  quickSignup: "Inscription en un clic",
  accountEmptyPrompt: "Le compte ne peut pas être vide",
  accountRulePrompt: "Le numéro de compte doit être composé de 8 à 16 lettres et chiffres",
  accountAlreadySignupPrompt: "Ce compte a été enregistré, veuillez vous connecter directement",
  tryAgain: "Renvoyer",
};

const PersonalCenter = {
  logAndSign: "Connectez-vous/Inscrivez-vous maintenant",
  logAndSignPrompt: "Débloquez dès maintenant des fonctionnalités et des avantages exclusifs",
  logOut: "se déconnecter",
  myFollow: "ma concentration",
  mySubscription: "mon abonnement",
  accountSecurity: "Sécurité du compte",
  feedback: "Retour",
  deviceInformation: "équipement",
  aboutUs: "à propos de nous",
  coin: "or",
  moreServices: "Plus de services",
  following: "Je suis déjà abonné",
  subscribed: "Abonné",
  browser: "Navigateur",
  user: "utilisateur",
  profile: "informations personnelles",
  nickname: "Surnom",
  save: "sauvegarder",
  change: "Changement",
  nicknamePrompt: "Veuillez entrer un pseudo",
  avatar: "avatar",
  changeSuccessPrompt: "Changement réussi",
  ballAnimation: "Animation d'objectif",
  ballVoice: "Effets sonores d'objectif",
  setting: "installation",
};

const IntegralSystem = {
  Coin: "or",
  goldCoinTask: "tâche de pièce d'or",
  PointsDetails: "Détails de la pièce d'or",
  myCoins: "mes pièces d'or",
  income: "revenu",
  expenditure: "dépense",
  signInPrompt: "Connectez-vous en continu et recevez des récompenses en continu",
  toComplete: "compléter",
  toShare: "partager",
  shareTasks: "partager des tâches",
  exclusiveLink: "lien exclusif",
  copyLink: "Copier le lien",
  highestWin: "victoire la plus élevée",
  day1: "Jour 1",
  day2: "Jour 2",
  day3: "3ème jour",
  day4: "Jour 4",
  day5: "Jour 5",
  day6: "Jour 6",
  day7: "Jour 7",
  invitePrompt: "surprendre! Inscrivez-vous maintenant et téléchargez notre application pour profiter d'avantages exclusifs pour les nouveaux utilisateurs ! Diffusions d'événements en direct gratuites, analyse de données en temps réel et prédictions d'ancres professionnelles, le tout à portée de main. N'attendez plus, cliquez sur le lien pour vous inscrire !"
};

const Money = {
  activeCenter: "centre d'activités",
  missionCenter: "centre de mission",
};

const ActivityCenter = {
  toParticipate: "participer",
  sportActivity: "activités sportives",
};

const ActivityDetails = {
  eventDetails: "Détails de l'évènement",
  dailyBetting: "Paris quotidiens",
  matchTime: "Temps de match",
  popularForEvent: "Options populaires pour cet événement",
  clickToParticipate: "Cliquez pour participer maintenant",
  activityRules: "Règles d'activité",
  clickToContactParticipate: "Cliquez pour contacter le service client pour participer",
  activityObj: "objet actif",
  activityPlatform: "Plateforme événementielle",
  activityDuration: "Durée de l'activité",
  activityDemand: "Exigences d'activité",
  activityTime: "Temps d'activité",
};

const AboutUs = {
  aboutUs: "à propos de nous",
  platformIntroduction: "Présentation de la plateforme",
  privacyPolicy: "politique de confidentialité",
  userAgreement: "Accord de l'utilisateur",
};

const DeviceInfo = {
  deviceInformation: "équipement",
  currentTime: "heure actuelle",
  port: "Port de connexion",
  ip: "IP de connexion",
  version: "version actuelle",
  ipAndPort: "Informations de connexion",
};

const FeedBack = {
  feedback: "Retour",
  feedbackType: "type de commentaire",
  productSuggestion: "suggestions de produits",
  functionalFault: "Défaillance fonctionnelle",
  interfaceSuggestion: "Suggestions d'interfaces",
  accountIssue: "Problème de compte",
  operationExperience: "Expérience opérationnelle",
  otherFeedback: "Autres commentaires",
  problemDescription: "Énoncé du problème",
  submit: "soumettre",
  addPhoto: "ajouter une photo",
  uploadPrompt: "Désolé, seules 3 photos peuvent être téléchargées",
  uploadRulePrompt: "Veuillez télécharger des photos au format jpg, jpeg, png",
  uploadSizePrompt: "La taille de l'image ne peut pas dépasser 10 M",
  chooseFeedback: "Veuillez sélectionner le type de commentaire",
  inputQuestionPrompt: "Veuillez saisir une question",
  submitSuccessPrompt: "Soumis avec succès",
};

const MySubscription = {
  myFollow: "ma concentration",
  mySubscription: "mon abonnement",
  streamer: "Ancre",
  matches: "Concours",
};

const PrivacyAgreement = {
  privacyPolicy: "politique de confidentialité",
};

const TermsofService = {
  termsOfService: "Conditions d'utilisation",
};

const UserAgreement = {
  userAgreement: "Accord de l'utilisateur",
};

const AccountSecurity = {
  accountSecurity: "Sécurité du compte",
  phone: "Numéro de téléphone",
  email: "Mail",
  bind: "obligatoire",
  notBound: "Pas liée",
  change: "Changement",
  logInPassword: "mot de passe",
  accountChangePassword: "Veuillez d'abord lier votre téléphone portable/adresse e-mail",
};

const BindEmail = {
  bindEmail: "Lier un e-mail",
  email: "Mail",
  code: "Le code de vérification",
  getCode: "obtenir le code de vérification",
  confirm: "confirmer",
  emailRulePrompt: "Veuillez saisir le format correct de votre adresse e-mail",
  emailEmptyPrompt: "L'e-mail ne peut pas être vide",
  codeRulePrompt: "Le code de vérification est composé de 6 chiffres",
  codeEmptyPrompt: "le code de vérification doit être rempli",
  changeSuccessPrompt: "Changement réussi",
  changeErrorPrompt: "Échec du changement",
  bindSuccessPrompt: "Reliure réussie",
  bindErrorPrompt: "Échec de la liaison",
  emailCodeSendSuccessPrompt: "Code de vérification envoyé avec succès, veuillez vérifier votre email",
  emailCodeSendErrorPrompt: "Le code de vérification n'a pas pu être envoyé, veuillez vérifier si votre e-mail est disponible",
  codeSendToMuchPrompt: "Envoyé trop de fois en même temps, veuillez réessayer plus tard",
  emailAlreadySignupPrompt: "Cette adresse email a été enregistrée, veuillez vous connecter directement",
  tryAgain: "Renvoyer",
};

const BindPhone = {
  bindPhone: "Lier un téléphone portable",
  phone: "Numéro de téléphone",
  code: "Le code de vérification",
  getCode: "obtenir le code de vérification",
  confirm: "confirmer",
  cancel: "Annuler",
  phoneRulePrompt: "Veuillez saisir votre numéro de téléphone portable au format correct",
  phoneEmptyPrompt: "Le numéro de téléphone portable ne peut pas être vide",
  codeRulePrompt: "Le code de vérification est composé de 6 chiffres",
  codeEmptyPrompt: "le code de vérification doit être rempli",
  changeSuccessPrompt: "Changement réussi",
  changeErrorPrompt: "Échec du changement",
  bindSuccessPrompt: "Reliure réussie",
  bindErrorPrompt: "Échec de la liaison",
  phoneNum8: "Veuillez saisir votre numéro de téléphone portable à 8 chiffres",
  phoneNum9To10: "Veuillez saisir un numéro de téléphone mobile de 9 à 10 chiffres",
  phoneNum9: "Veuillez saisir un numéro de téléphone portable à 9 chiffres",
  phoneCodeSendSuccessPrompt: "Code de vérification envoyé avec succès, veuillez vérifier le message texte sur votre téléphone portable",
  phoneCodeSendErrorPrompt: "Le code de vérification n'a pas pu être envoyé, veuillez vérifier si le numéro de téléphone portable est disponible",
  codeSendToMuchPrompt: "Envoyé trop de fois en même temps, veuillez réessayer plus tard",
  phoneAlreadySignupPrompt: "Ce numéro de téléphone mobile a été enregistré, veuillez vous connecter directement",
  checkSignup: "Veuillez vérifier si le numéro est enregistré",
  tryAgain: "Renvoyer",
};

const ChangePassword = {
  resetPassword: "réinitialiser le mot de passe",
  phone: "Numéro de téléphone",
  email: "Mail",
  code: "Le code de vérification",
  getCode: "obtenir le code de vérification",
  logInPassword: "mot de passe",
  confirm: "confirmer",
  cancel: "Annuler",
  passwordRulePrompt: "Le mot de passe doit être composé de lettres majuscules et minuscules et de chiffres et doit comporter plus de 8 caractères.",
  passwordEmptyPrompt: "le mot de passe ne peut pas être vide",
  emailRulePrompt: "Veuillez saisir le format correct de votre adresse e-mail",
  emailEmptyPrompt: "L'e-mail ne peut pas être vide",
  phoneRulePrompt: "Veuillez saisir votre numéro de téléphone portable au format correct",
  phoneEmptyPrompt: "Le numéro de téléphone portable ne peut pas être vide",
  codeRulePrompt: "Le code de vérification est composé de 6 chiffres",
  codeEmptyPrompt: "le code de vérification doit être rempli",
  phoneNum8: "Veuillez saisir votre numéro de téléphone portable à 8 chiffres",
  phoneNum9To10: "Veuillez saisir un numéro de téléphone mobile de 9 à 10 chiffres",
  phoneNum9: "Veuillez saisir un numéro de téléphone portable à 9 chiffres",
  resetSuccess: "Réinitialisation du mot de passe réussie",
  resetError: "La réinitialisation du mot de passe a échoué, veuillez vérifier si les informations sont correctes",
  checkSignup: "Veuillez vérifier si le numéro est enregistré",
  phoneCodeSendSuccessPrompt: "Code de vérification envoyé avec succès, veuillez vérifier le message texte sur votre téléphone portable",
  codeSendToMuchPrompt: "Envoyé trop de fois en même temps, veuillez réessayer plus tard",
  phoneAlreadySignupPrompt: "Ce numéro de téléphone mobile a été enregistré, veuillez vous connecter directement",
  phoneCodeSendErrorPrompt: "Le code de vérification n'a pas pu être envoyé, veuillez vérifier si le numéro de téléphone portable est disponible",
  emailCodeSendSuccessPrompt: "Code de vérification envoyé avec succès, veuillez vérifier votre email",
  emailCodeSendErrorPrompt: "Le code de vérification n'a pas pu être envoyé, veuillez vérifier si votre e-mail est disponible",
  emailAlreadySignupPrompt: "Cette adresse email a été enregistrée, veuillez vous connecter directement",
  bindPhoneOrEmail: "Veuillez d'abord lier votre numéro de téléphone portable ou votre adresse e-mail",
  tryAgain: "Renvoyer",
};

const QuickRegister = {
  downloadScreenshot: "Télécharger la capture d'écran",
  saveAccountAndPassword: "Veuillez conserver votre compte et votre mot de passe en sécurité",
  suggestSave: "Il est recommandé de prendre une capture d'écran et de la sauvegarder ou de la copier dans un mémo.",
  account: "compte",
  password: "mot de passe",
  copyAccountAndPassword: "Copier le mot de passe du compte",
  prompt: "indice",
  sureSave: "Avez-vous enregistré le mot de passe de votre compte ?",
  confirm: "confirmer",
  cancel: "Annuler",
  copySuccess: "Copié avec succès",
  copyFailure: "Échec de la copie",
  manualRegister: "Le nombre d'inscriptions a atteint la limite supérieure, veuillez vous inscrire manuellement",
};

const Recommend = {
  recommend: "recommander",
  InternetErrorPrompt: "Erreur réseau",
  hitOfTheWeek: "Coup de la semaine",
  victoryOrDefeat: "Victoire ou défaite",
  check: "Vérifier",
  lastTenGames: "10 derniers matchs",
  recommendedByExperts: "Recommandé par les experts",
  changeBatch: "Changer un lot",
  follow: "se concentrer sur",
  following: "Je suis déjà abonné",
  unFollowing: "Ouvrir",
  all: "All",
  guaranteedCompensation: "Rémunération garantie",
  free: "gratuit",
  toll: "SONNER",
  HOT: "Vente",
  HIT: "frapper",
  STREAK: "Même au milieu",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "Page d'accueil",
  chat: "chat",
  follow: "se concentrer sur",
  following: "Je suis déjà abonné",
  unFollowing: "Ouvrir",
  followersCount: "se concentrer sur",
  planCount: "plan",
  viewCount: "montre",
  statistics: "statistiques",
  plan: "plan",
};

const SchemeDetails = {
  planDetails: "Détails du forfait",
  release: "libérer",
  chat: "chat",
  follow: "se concentrer sur",
  following: "Je suis déjà abonné",
  unFollowing: "Ouvrir",
  details: "Détails",
  analyze: "analyser",
  title: "titre",
  privateChatStreamerJoinsGroup: "L'hôte du chat privé rejoint le groupe",
  upcoming: "n'a pas commencé",
  othersPlan: "Autres options",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "Tri des correspondances",
  byTime: "par heure",
  byLeague: "par ligue",
  confirm: "confirmer",
  cancel: "Annuler",
};

const RecommendPublish = {
  enterTeam: "Entrer l'équipe",
  planRelease: "Publication du plan",
  predictionTitle: "Titre (visible par tous les utilisateurs, veuillez ne pas divulguer les résultats de la prédiction)",
  titleEmptyPrompt: "Le titre et le contenu de l'analyse ne peuvent pas être vides",
  pleaseSelectAtLeastOneGameToPredictResults: "Veuillez choisir au moins une façon de prédire les résultats",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "football",
  basketball: "basket-ball",
  mixed: "complet",
  setLogInPassword: "Définition du mot de passe de connexion",
  OCS: "un service en ligne",
  OCSPrompt: "à votre service",
  phoneInCorrectPrompt: "Numéro de téléphone portable mal saisi",
  codeInCorrectPrompt: "code de vérification incorrect",
  emailInCorrectPrompt: "Erreur de saisie d'e-mail",
  passwordPrompt: "Veuillez saisir une combinaison de 8 à 16 chiffres en anglais et en chiffres.",
  newPassword: "entrer un nouveau mot de passe",
  resetPasswordPrompt: "Etes-vous sûr d'avoir réinitialisé votre mot de passe ?",
  startChatPrompt: "Vous pouvez maintenant commencer à discuter",
  send: "envoyer",
  noFollowPrompt: "Aucune attention pour l'instant",
  feedbackPrompt: "S'il vous plaît laissez vos précieux commentaires et nous travaillerons dur pour nous améliorer !",
  bindPhoneByOtherPrompt: "Ce numéro de mobile a été lié par d'autres utilisateurs",
  bindEmailByOtherPrompt: "Cette adresse e-mail a été liée par un autre utilisateur",
  super_manager: "super administrateur",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "Fin du premier trimestre",
  endOfSecondQuarter: "Fin du deuxième trimestre",
  endOfThirdQuarter: "Fin du troisième trimestre",
  endOfFourthQuarter: "Fin du quatrième trimestre",
  pullUpToLoadMore: "Tirez vers le haut pour charger davantage",
  pullDownToRefresh: "Déroulez pour actualiser",
  releaseToRefreshImmediately: "Relâchez pour actualiser immédiatement",
  refreshPrompt: "Rafraîchissant...",
  download: "télécharger",
  appDownload: "Téléchargement de l'application",
  brandName: "OV88",
  saveToDesktop: "Ajouter à l'écran d'accueil",
  downloadTitle: "Téléchargement de l'application",
  normalDownload: "Téléchargement normal",
  scanningPrompt: "Installez avec le code QR et obtenez une grande réduction exclusive, ainsi que d'autres avantages luxueux à votre disposition!",
  downloadFailToWeb: "Si vous ne parvenez pas à télécharger l'application, cliquez ici pour utiliser la version Web",
  scanQrCode: "Scannez le code QR avec votre téléphone portable",
  usingMobileDevices: "Utiliser un mobile",
  installTutorial: "Cliquez pour voir le tutoriel d'installation",
  whyNameDifferent: "Les noms de l'application installée et de l'application téléchargée sont incohérents, n'hésitez pas à télécharger, installer et utiliser !",
  clickDownload: "cliquez pour télécharger",
  installTutorialPrompt: "Guide d'instructions d'installation",
  brandNativeName: "APPLICATION native UFOScore",
  uploadingPrompt: "Téléchargement...",
  bHandicap: "Handicap",
  different: "Différence",
  total: "total",
  off: "n'a pas commencé",
  video: "vidéo",
  animation: "animation",
  install: "Installer",
  startDownloading: "Commencer Le Téléchargement",
  cancelGoal: "But annulé",
  appUpdatePrompt: "Il existe une nouvelle version de l'APP, veuillez la mettre à jour",
};
