/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "Termo de Acordo do Usuário",
  privacyPolicy: "política de Privacidade",
  termsOfService: "Termos de serviço",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "Não iniciado ainda",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "Já transmitido",
  watch: "vai assistir",
  follow: "focar em",
  following: "Já estou seguindo",
  subscribe: "inscrição",
  streamerSchedule: "Cronograma âncora",
  noDataPrompt: "Sem dados",
  unfollowPrompt: "Tem certeza de parar de seguir?",
  yes: "sim",
  no: "não",
  logAndSign: "Faça login/Cadastre-se agora",
  hours: "Hora",
  mins: "minuto",
};

const AnchorInformation = {
  recommendedLiveStreams: "Transmissão ao vivo recomendada",
  noDataPrompt: "Sem dados",
};

const BasketballScoreIndex = {
  details: "Detalhes",
  over: "sobre",
  pointsDifference: "Diferença de pontos",
  totalScore: "pontuação total",
  live: "LIVE",
  unSubscribePrompt: "Tem certeza de que deseja cancelar sua assinatura?",
  yes: "sim",
  no: "não",
  logAndSign: "Faça login/Cadastre-se agora",
  InternetErrorPrompt: "Erro de rede",
};

const BasketballSmallBig = {
  details: "Detalhes",
  over: "sobre",
  pointsDifference: "Diferença de pontos",
  totalScore: "pontuação total",
  live: "LIVE",
  unSubscribePrompt: "Tem certeza de que deseja cancelar sua assinatura?",
  yes: "sim",
  no: "não",
  logAndSign: "Faça login/Cadastre-se agora",
  InternetErrorPrompt: "Erro de rede",
  overIndex: "grande",
  underIndex: "Pequeno",
};

const BindPhoneDialog = {
  bindPhonePrompt: "Para a segurança da sua conta, vincule o seu número de celular para que possamos lhe oferecer melhores serviços.",
  goBind: "Ir para vinculação",
};

const BottomNavigationBar = {
  home: "primeira página",
  matches: "Concurso",
  recommend: "recomendar",
  chat: "bater papo",
  personal: "pessoal",
  discovery: "Descobrir",
  money: "fazer dinheiro",
};

const ChatInterface = {
  noCsPrompt: "Não é possível conversar no momento",
  uploadRulePrompt: "Faça upload de fotos em formato jpg, jpeg, png",
  uploadSizePrompt: "O tamanho da imagem não pode exceder 10M",
  sendError: "Falha ao enviar",
  picSendError: "Falha no envio da imagem",
  InternetErrorPrompt: "Erro de rede",
  bannedSendPrompt: "Você foi banido",
};

const ChatList = {
  noNewPrompt: "Nenhuma notícia ainda",
  logInPrompt: "Faça login para visualizar o conteúdo, faça login primeiro",
  logIn: "Conecte-se",
  group: "grupo",
};

const ChatListItem = {
  withdrawMessage: "Retirou uma mensagem",
  bannedPrompt: "banido",
  unBanPrompt: "Não banido",
  joinGroupPrompt: "Entrou no grupo",
  leftGroupPrompt: "Deixou o grupo",
  picture: "foto",
  link: "Link",
  emoji: "emoticons animados",
  addedFriendPrompt: "Adicionado como amigo",
};

const CompetitionList = {
  logInPrompt: "Faça login para visualizar o conteúdo, faça login primeiro",
  logIn: "Conecte-se",
  today: "hoje",
  Monday: "Segunda-feira",
  Tuesday: "Terça-feira",
  Wednesday: "Quarta-feira",
  Thursday: "Quinta-feira",
  Friday: "Sexta-feira",
  Saturday: "Sábado",
  Sunday: "Domingo",
  noGames: "Ainda não há jogos",
  toCollapse: "Clique para recolher",
  toExpand: "Clique para expandir"
};

const CompetitionListItem = {
  details: "Detalhes",
  unSubscribePrompt: "Tem certeza de que deseja cancelar sua assinatura?",
  yes: "sim",
  no: "não",
  logAndSign: "Faça login/Cadastre-se agora",
  InternetErrorPrompt: "Erro de rede",
};

const CompetitionMode = {
  matchMode: "Modo de competição",
  confirm: "confirme",
  cancel: "Cancelar",
};

const EmojiCom = {
  logAndSign: "Faça login/Cadastre-se agora",
  yes: "sim",
  no: "não",
};

const ExponentCom = {
  company: "empresa",
  initial: "inicial",
  ongoing: "imediato",
  preMatch: "Antes do jogo",
  noDataPrompt: "Sem dados",
  handicap: "deficiência",
  WinDrawLose: "Ganhar, empatar, perder",
  totalGoals: "total de gols",
  cornerKick: "buzina",
  pleaseSelect: "por favor escolha",
  confirm: "confirme",
  cancel: "Cancelar",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "Fixado em 'Stream chat'",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "focar em",
  following: "Já estou seguindo",
  chat: "bater papo",
  noDataPrompt: "Sem dados",
  unfollowPrompt: "Tem certeza de parar de seguir?",
  yes: "sim",
  no: "não",
  InternetErrorPrompt: "Erro de rede",
};

const FootballCornerIndex = {
  details: "Detalhes",
  over: "sobre",
  FH: "HT",
  cornerKick: "buzina",
  unSubscribePrompt: "Tem certeza de que deseja cancelar sua assinatura?",
  yes: "sim",
  no: "não",
  logAndSign: "Faça login/Cadastre-se agora",
  InternetErrorPrompt: "Erro de rede",
};

const FootballScoreIndex = {
  details: "Detalhes",
  over: "sobre",
  FH: "HT",
  cornerKick: "buzina",
  live: "LIVE",
  unSubscribePrompt: "Tem certeza de que deseja cancelar sua assinatura?",
  yes: "sim",
  no: "não",
  logAndSign: "Faça login/Cadastre-se agora",
  InternetErrorPrompt: "Erro de rede",
};

const FootballScoreItem = {
  details: "Detalhes",
  over: "sobre",
  FH: "HT",
  cornerKick: "buzina",
  live: "LIVE",
  unSubscribePrompt: "Tem certeza de que deseja cancelar sua assinatura?",
  yes: "sim",
  no: "não",
  logAndSign: "Faça login/Cadastre-se agora",
  InternetErrorPrompt: "Erro de rede",
};

const GoalToast = {
  goal: "meta",
};

const LiveRecommend = {
  noDataPrompt: "Sem dados",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "Vitória ou derrota",
};

const MatchCardList = {
  details: "Detalhes",
};

const MatchStatus = {
  noDataPrompt: "Sem dados",
};

const MoreStreams = {
  switchLines: "mudar de linha",
  cancel: "Cancelar",
  currentStreamer: "Âncora atual",
  live: "LIVE",
  offLine: "Não iniciado ainda",
  followersCount: "focar em",
  otherStreamersPrompt: "Existem outros âncoras transmitindo o jogo atualmente",
  watch: "vai assistir",
};

const NewMatchStatus = {
  team: "equipe",
  halfTimeScore: "pontuação no intervalo",
  shotOnTarget: "Chute a gol",
  missedShot: "Perdeu o gol",
  attack: "ataque",
  dangerousAttack: "Ataque perigoso",
  number3PointGoals: "Número de gols de 3 pontos marcados",
  number2PointGoals: "Número de gols de 2 pontos marcados",
  penaltyKickGoals: "Gols de pênalti",
  freeThrowPercentage: "Porcentagem de lance livre",
  numberOfPausesRemaining: "Número de pausas restantes",
  numberOfFouls: "Número de faltas",
  totalNumberOfPauses: "Pausas totais",
  threePointShot: "Tiro de 3 pontos",
  twoPointShot: "Tiro de 2 pontos",
  shoot: "Atirar",
  penalty: "pena",
  firstHalf: "primeira metade",
  secondHalf: "segundo tempo",
  redCard: "cartão vermelho",
  yellowCard: "cartão amarelo",
};

const NoData = {
  noDataPrompt: "Sem dados",
};

const NoticeMessage = {
  addedFriendPrompt: "Adicionado como amigo",
  withdrawMessage: "Retirou uma mensagem",
  withdrawMessageByAdmin: "Uma mensagem foi retirada pelo administrador",
  joinGroupPrompt: "Entrou no grupo",
  leftGroupPrompt: "Deixou o grupo",
  removeGroupPrompt: "Removido do grupo pelo administrador",
  bannedPrompt: "banido",
  unBanPrompt: "Não banido",
};

const OddsStatisticsList = {
  initial: "inicial",
  ongoing: "imediato",
  preMatch: "Antes do jogo",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "valor mais alto",
  min: "valor mais baixo",
  average: "valor médio",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "Sem dados",
};

const RecommendCompetitions = {
  today: "hoje",
  winsInARow: "Mesmo no meio",
  noLivePrompt: "Ainda não há transmissão ao vivo",
};

const RecommendStreamerList = {
  noDataPrompt: "Sem dados",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "Sucesso da semana",
  victoryOrDefeat: "Vitória ou derrota",
  check: "Verificar",
  chat: "bater papo",
  quarterlyProfit: "lucro trimestral",
};

const RecommendStreamers = {
  recommendedByExperts: "Recomendado por especialistas",
  winsInARow: "Mesmo no meio",
  changeBatch: "Alterar um lote",
  follow: "focar em",
  following: "Já estou seguindo",
  unFollowing: "Desbloquear",
  noDataPrompt: "Sem dados",
  logAndSign: "Faça login/Cadastre-se agora",
  yes: "sim",
  no: "não",
  InternetErrorPrompt: "Erro de rede",
  unfollowPrompt: "Tem certeza de parar de seguir?",
};

const RecordChart = {
  record: "registro",
  allWaysToPlay: "Todas as formas de jogar",
  fromFarToNear: "de longe para perto",
  lastTenGames: "Últimos 10 jogos",
  longestConsecutiveHit: "O golpe consecutivo mais longo",
  hitRate: "taxa de acerto",
  currentPropmt: "jogos recentes",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "Falha ao obter informações do usuário",
  getAreaAndLanguageErrorPrompt: "Falha ao obter o idioma regional",
};

const ScheduleCom = {
  startTime: "Hora de início",
};

const SchemeCard = {
  planList: "Lista de planos",
  release: "liberar",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "Compensação garantida",
  free: "livre",
  toll: "PEDÁGIO",
  victoryOrDefeat: "Vitória ou derrota",
};

const SquareCom = {
  watchPrompt: "as pessoas estão assistindo",
  startedPrompt: "Começou há minutos",
  chat: "bater papo",
  sendMessageNeedLoginPrompt: "Faça login para enviar uma mensagem",
  assistant: "administrador",
  audience: "do utilizador",
  newMessage: "novas notícias",
  confirmSend: "Confirmar envio",
  cancelSend: "Cancelar",
};

const StatisticalChart = {
  hitRate: "taxa de acerto",
};

const SwitchLines = {
  switchLines: "mudar de linha",
  cancel: "Cancelar",
  currentStreamer: "Âncora atual",
  live: "LIVE",
  offLine: "Não iniciado ainda",
  followersCount: "focar em",
  otherStreamersPrompt: "Existem outros âncoras transmitindo o jogo atualmente",
  watch: "vai assistir",
  LINE: "linha",
  VIP: "VIP",
  OFFICIAL: "oficial",
};

const VideoCom = {
  videoErrorPrompt: "O sinal não está muito bom, tente novamente mais tarde.",
  clickRetryPrompt: "Clique para tentar novamente",
};

const WelcomeCard = {
  join: "Juntar-se ao grupo",
};

//utils
const tools = {
  yesterday: "ontem",
  addedFriendPrompt: "Adicionado como amigo",
  withdrawMessage: "Retirou uma mensagem",
  joinGroupPrompt: "Entrou no grupo",
  leftGroupPrompt: "Deixou o grupo",
  removeGroupPrompt: "Removido do grupo pelo administrador",
  bannedPrompt: "banido",
  unBanPrompt: "Não banido",
};

//页面
const AddressBook = {
  search: "Encontrar",
  addFriend: "adicionar amigo",
  myGroupChat: "meu bate-papo em grupo",
  friends: "amigos",
  delete: "excluir",
  noFriendsPrompt: "Ainda não há amigos",
  nameAndIdSearch: "Digite o nome, ID",
  searchResults: "Encontrar resultados",
  notFoundPrompt: "Usuário não encontrado",
  add: "adicionar à",
  deleteFriendPrompt: "Tem certeza de que deseja excluir o amigo?",
  yes: "sim",
  no: "não",
  addFriendSuccessPrompt: "Adicionar amigo com sucesso",
  deleteFriendSuccessPrompt: "Excluir amigo com sucesso",
  InputSearchPrompt: "Por favor insira o conteúdo",
};

const GroupChat = {
  groupChat: "conversa em grupo",
  groupSearch: "Digite o nome do bate-papo em grupo",
  noGroupPrompt: "Ainda não há bate-papo em grupo",
  chat: "bater papo",
  InputSearchPrompt: "Por favor insira o conteúdo",
};

const GroupAdministrator = {
  administrator: "administrador",
  groupOwner: "Senhor",
};

const GroupAnnouncement = {
  groupAnnouncement: "Anúncio do grupo",
  groupOwner: "Senhor",
  administrator: "administrador",
};

const GroupDetail = {
  groupDetail: "Detalhes do grupo",
  groupMembers: "membros do grupo",
  groupName: "Nome do grupo",
  groupDescription: "Descrição do grupo",
  groupOwner: "Senhor",
  administrator: "administrador",
  administratorList: "Lista de administradores",
  groupAnnouncement: "Anúncio do grupo",
  groupFiles: "arquivo de grupo",
  exitGroup: "Sair do grupo",
  exitGroupPrompt: "Tem certeza de que deseja sair do grupo?",
  yes: "sim",
  no: "não",
  leftGroupSuccessPrompt: "Saiu do grupo com sucesso",
};

const GroupFile = {
  groupFiles: "arquivo de grupo",
  fileSearch: "Digite o nome do arquivo",
  today: "hoje",
  multimedia: "multimídia",
  file: "documento",
  noFilesPrompt: "Nenhum arquivo ainda",
};

const GroupMembers = {
  groupMembers: "membros do grupo",
  nameAndIdSearch: "Digite o nome, ID",
};

const Chat = {
  chat: "bater papo",
  nameAndIdSearch: "Digite o nome, ID",
  contacts: "Livro de endereços",
  InputSearchPrompt: "Por favor insira o conteúdo",
  deleteContactPrompt: "Tem certeza de que deseja excluir o bate-papo?",
};

const Competition = {
  all: "All",
  ongoing: "imediato",
  popular: "Popular",
  liveCompetition: "transmissão ao vivo",
  schedule: "agendar",
  results: "resultado",
  subscribed: "Subscrito",
  goal: "meta",
  today: "hoje",
  Monday: "Segunda-feira",
  Tuesday: "Terça-feira",
  Wednesday: "Quarta-feira",
  Thursday: "Quinta-feira",
  Friday: "Sexta-feira",
  Saturday: "Sábado",
  Sunday: "Domingo",
};

const CompetitionDetails = {
  subscribe: "inscrição",
  subscribed: "Subscrito",
  matchStreamerPrompt: "Âncora do jogo ao vivo",
  live: "LIVE",
  noDataPrompt: "Sem dados",
  noStreamersPrompt: "Atualmente não há nenhum apresentador transmitindo este jogo",
  alreadyStreamerPrompt: "Um apresentador começou a transmitir a partida. Quer ir assistir?",
  yes: "sim",
  no: "não",
  liveStream: "transmissão ao vivo",
  matchStatus: "Status da partida",
  odds: "Odds",
  unSubscribePrompt: "Tem certeza de que deseja cancelar sua assinatura?",
  logAndSign: "Faça login/Cadastre-se agora",
  InternetErrorPrompt: "Erro de rede",
};

const OddsDetails = {
  company: "empresa",
  handicap: "deficiência",
  WinDrawLose: "Ganhar, empatar, perder",
  totalGoals: "total de gols",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "muitos",
  Un: "alguns",
};

const Home = {
  logIn: "Conecte-se",
  signUp: "registro",
  popularMatches: "Competições populares",
  more: "Mais",
  noDataPrompt: "Sem dados",
  currentlyLive: "Transmissão ao vivo",
  popularStreamers: "Âncora popular",
  thinkYouLike: "você pode gostar",
  loadMore: "Carregue mais",
  noMoreDataPrompt: "Ainda não há mais dados",
  addDeskPrompt: "adicionar à área de trabalho",
  clikToolPrompt: "Clique na barra de ferramentas abaixo",
  andSeletcPrompt: "e selecione",
  addToScreenPrompt: "Queremos adicionar à sua tela inicial para fornecer um melhor serviço",
  addToScreen: "Adicionar à tela inicial",
};

const MoreLives = {
  noDataPrompt: "Sem dados",
};

const MoreStreamers = {
  streamer: "Âncora",
  streamerSearch: "Insira o nome e ID da âncora",
  all: "All",
  notFollowing: "Não seguindo",
  following: "Já estou seguindo",
  live: "LIVE",
  offLine: "Não iniciado ainda",
  followersCount: "focar em",
  unfollowPrompt: "Tem certeza de parar de seguir?",
  yes: "sim",
  no: "não",
  logAndSign: "Faça login/Cadastre-se agora",
  InternetErrorPrompt: "Erro de rede",
  InputSearchPrompt: "Por favor insira o conteúdo",
  dataRequestErrorPrompt: "Falha ao obter dados",
};

const LiveRoom = {
  switchLines: "mudar de linha",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "Bate-papo privado âncora",
  chat: "bater papo",
  odds: "Odds",
  streamerInformation: "Informações da âncora",
  matchStatus: "Status da partida",
};

const AnchorIntroduction = {
  streamerInformation: "Informações da âncora",
  photosWall: "Mural de fotos",
  seeMore: "ver mais",
  streamerPlan: "Plano âncora",
};

const AnchorPhoto = {
  photosWall: "Mural de fotos",
  noPhotosPrompt: "Ainda não há mais fotos",
};

const LogInRegister = {
  logIn: "Conecte-se",
  signUp: "registro",
  getAreaAndLanguageErrorPrompt: "Falha ao obter o idioma regional",
  quickRegisterFrequentPrompt: "Solicitações frequentes, tente novamente mais tarde",
};

const LoginFn = {
  phone: "Número de telefone",
  email: "Correspondência",
  logInPassword: "senha de login",
  rememberPassword: "Lembrar senha",
  forgotPassword: "esqueça a senha",
  logIn: "Conecte-se",
  logInAgreement: "Ao fazer login você concorda",
  userAgreement: "Termo de Acordo do Usuário",
  and: "  e ",
  privacyPolicy: "política de Privacidade",
  passwordRulePrompt: "A senha deve consistir em letras maiúsculas e minúsculas e números e deve ter mais de 8 caracteres",
  passwordEmptyPrompt: "a senha não pode ficar em branco",
  emailRulePrompt: "Por favor insira o formato correto do seu endereço de e-mail",
  emailEmptyPrompt: "O e-mail não pode ficar vazio",
  phoneRulePrompt: "Por favor, insira o número do seu celular no formato correto",
  phoneEmptyPrompt: "O número do celular não pode ficar vazio",
  phoneNum8: "Por favor, insira seu número de celular de 8 dígitos",
  phoneNum9To10: "Insira um número de celular de 9 a 10 dígitos",
  phoneNum9: "Insira um número de celular de 9 dígitos",
  enterErrorPrompt: "Erro de entrada ou anormalidade na rede",
  loginNoAgreePrompt: "Você precisa concordar com o Contrato do Usuário e a Política de Privacidade para fazer login",
  accountAndPassword: "conta",
  account: "conta",
  accountEmptyPrompt: "A conta não pode ficar vazia",
  accountOrPasswordError: "Usuário ou senha incorretos",
};

const Register = {
  phone: "Número de telefone",
  email: "Correspondência",
  code: "Código de verificação",
  getCode: "obter código de verificação",
  logInPassword: "senha de login",
  CS: "atendimento ao Cliente",
  signUp: "registro",
  signUpAgreement: "Ao se cadastrar você concorda",
  userAgreement: "Termo de Acordo do Usuário",
  and: "e",
  privacyPolicy: "política de Privacidade",
  contactCSPrompt: "Você está tendo problemas para se registrar? Entre em contato com o atendimento ao cliente para resolver isso para você",
  contactCS: "Entre em contato com o atendimento ao cliente",
  passwordRulePrompt: "A senha deve consistir em letras maiúsculas e minúsculas e números e deve ter mais de 8 caracteres",
  passwordEmptyPrompt: "a senha não pode ficar em branco",
  emailRulePrompt: "Por favor insira o formato correto do seu endereço de e-mail",
  emailEmptyPrompt: "O e-mail não pode ficar vazio",
  phoneRulePrompt: "Por favor, insira o número do seu celular no formato correto",
  phoneEmptyPrompt: "O número do celular não pode ficar vazio",
  codeRulePrompt: "O código de verificação tem 6 dígitos",
  codeEmptyPrompt: "o código de verificação deve ser preenchido",
  phoneNum8: "Por favor, insira seu número de celular de 8 dígitos",
  phoneNum9To10: "Insira um número de celular de 9 a 10 dígitos",
  phoneNum9: "Insira um número de celular de 9 dígitos",
  phoneCodeSendSuccessPrompt: "Código de verificação enviado com sucesso, verifique a mensagem de texto no seu celular",
  emailCodeSendSuccessPrompt: "Código de verificação enviado com sucesso, verifique seu e-mail",
  phoneCodeSendErrorPrompt: "O código de verificação não foi enviado, verifique se o número do celular está disponível",
  emailCodeSendErrorPrompt: "O código de verificação não foi enviado, verifique se seu e-mail está disponível",
  codeSendToMuchPrompt: "Enviado muitas vezes ao mesmo tempo, tente novamente mais tarde",
  phoneAlreadySignupPrompt: "Este número de celular foi registrado, faça login diretamente",
  emailAlreadySignupPrompt: "Este endereço de e-mail foi registrado, faça login diretamente",
  signUpErrorPrompt: "Falha no registro, verifique se as informações estão corretas",
  enterErrorPrompt: "Erro de entrada ou anormalidade na rede",
  noAgreePrompt: "Você precisa concordar com o Contrato do Usuário e a Política de Privacidade para se registrar",
  regionAndLanguagePrompt: "Selecione seu idioma regional real, caso contrário, isso afetará o uso das funções",
  accountAndPassword: "conta",
  quickSignup: "Registro com um clique",
  accountEmptyPrompt: "A conta não pode ficar vazia",
  accountRulePrompt: "O número da conta deve consistir de 8 a 16 letras e números",
  accountAlreadySignupPrompt: "Esta conta foi registrada, faça login diretamente",
  tryAgain: "Reenviar",
};

const PersonalCenter = {
  logAndSign: "Faça login/Cadastre-se agora",
  logAndSignPrompt: "Desbloqueie recursos e benefícios exclusivos agora",
  logOut: "sair",
  myFollow: "meu foco",
  mySubscription: "minha assinatura",
  accountSecurity: "Segurança da conta",
  feedback: "Opinião",
  deviceInformation: "equipamento",
  aboutUs: "sobre nós",
  coin: "ouro",
  moreServices: "Mais serviços",
  following: "Já estou seguindo",
  subscribed: "Subscrito",
  browser: "Navegador",
  user: "do utilizador",
  profile: "informações pessoais",
  nickname: "Apelido",
  save: "salvar",
  change: "Mudar",
  nicknamePrompt: "Por favor insira um apelido",
  avatar: "avatar",
  changeSuccessPrompt: "Mudança bem-sucedida",
  ballAnimation: "Animação de gol",
  ballVoice: "Efeitos sonoros de gol",
  setting: "configurar",
};

const IntegralSystem = {
  Coin: "ouro",
  goldCoinTask: "tarefa de moeda de ouro",
  PointsDetails: "Detalhes da moeda de ouro",
  myCoins: "minhas moedas de ouro",
  income: "renda",
  expenditure: "despesa",
  signInPrompt: "Faça login continuamente e receba recompensas continuamente",
  toComplete: "completar",
  toShare: "compartilhar",
  shareTasks: "compartilhar tarefas",
  exclusiveLink: "link exclusivo",
  copyLink: "link de cópia",
  highestWin: "maior vitória",
  day1: "Dia 1",
  day2: "Dia 2",
  day3: "3º dia",
  day4: "Dia 4",
  day5: "Dia 5",
  day6: "Dia 6",
  day7: "Dia 7",
  invitePrompt: "surpresa! Cadastre-se agora e baixe nosso APP para aproveitar vantagens exclusivas para novos usuários! Transmissões gratuitas de eventos ao vivo, análise de dados em tempo real e previsões de âncoras profissionais, tudo ao seu alcance. Não espere mais, clique no link para participar!"
};

const Money = {
  activeCenter: "Centro de Atividades",
  missionCenter: "centro missionário",
};

const ActivityCenter = {
  toParticipate: "participar",
  sportActivity: "Atividades esportivas",
};

const ActivityDetails = {
  eventDetails: "detalhes do evento",
  dailyBetting: "Apostas Diárias",
  matchTime: "Hora da partida",
  popularForEvent: "Opções populares para este evento",
  clickToParticipate: "Clique para participar agora",
  activityRules: "Regras de atividade",
  clickToContactParticipate: "Clique para entrar em contato com o atendimento ao cliente para participar",
  activityObj: "objeto ativo",
  activityPlatform: "Plataforma de eventos",
  activityDuration: "Duração da atividade",
  activityDemand: "Requisitos de atividade",
  activityTime: "Tempo de atividade",
};

const AboutUs = {
  aboutUs: "sobre nós",
  platformIntroduction: "Introdução à plataforma",
  privacyPolicy: "política de Privacidade",
  userAgreement: "Termo de Acordo do Usuário",
};

const DeviceInfo = {
  deviceInformation: "equipamento",
  currentTime: "hora atual",
  port: "Porta de login",
  ip: "IP de login",
  version: "Versão Atual",
  ipAndPort: "informações de login",
};

const FeedBack = {
  feedback: "Opinião",
  feedbackType: "tipo de feedback",
  productSuggestion: "sugestões de produtos",
  functionalFault: "Falha funcional",
  interfaceSuggestion: "Sugestões de interface",
  accountIssue: "Problema na conta",
  operationExperience: "Experiência de operação",
  otherFeedback: "Outros comentários",
  problemDescription: "Declaração do problema",
  submit: "enviar",
  addPhoto: "adicionar foto",
  uploadPrompt: "Desculpe, apenas 3 fotos podem ser enviadas",
  uploadRulePrompt: "Faça upload de fotos em formato jpg, jpeg, png",
  uploadSizePrompt: "O tamanho da imagem não pode exceder 10M",
  chooseFeedback: "Selecione o tipo de feedback",
  inputQuestionPrompt: "Por favor insira uma pergunta",
  submitSuccessPrompt: "submetido com sucesso",
};

const MySubscription = {
  myFollow: "meu foco",
  mySubscription: "minha assinatura",
  streamer: "Âncora",
  matches: "Concurso",
};

const PrivacyAgreement = {
  privacyPolicy: "política de Privacidade",
};

const TermsofService = {
  termsOfService: "Termos de serviço",
};

const UserAgreement = {
  userAgreement: "Termo de Acordo do Usuário",
};

const AccountSecurity = {
  accountSecurity: "Segurança da conta",
  phone: "Número de telefone",
  email: "Correspondência",
  bind: "vinculativo",
  notBound: "Não vinculado",
  change: "Mudar",
  logInPassword: "senha de login",
  accountChangePassword: "Por favor, vincule seu telefone celular/endereço de e-mail primeiro",
};

const BindEmail = {
  bindEmail: "Vincular e-mail",
  email: "Correspondência",
  code: "Código de verificação",
  getCode: "obter código de verificação",
  confirm: "confirme",
  emailRulePrompt: "Por favor insira o formato correto do seu endereço de e-mail",
  emailEmptyPrompt: "O e-mail não pode ficar vazio",
  codeRulePrompt: "O código de verificação tem 6 dígitos",
  codeEmptyPrompt: "o código de verificação deve ser preenchido",
  changeSuccessPrompt: "Mudança bem-sucedida",
  changeErrorPrompt: "Falha na alteração",
  bindSuccessPrompt: "Vinculação bem-sucedida",
  bindErrorPrompt: "Falha na vinculação",
  emailCodeSendSuccessPrompt: "Código de verificação enviado com sucesso, verifique seu e-mail",
  emailCodeSendErrorPrompt: "O código de verificação não foi enviado, verifique se seu e-mail está disponível",
  codeSendToMuchPrompt: "Enviado muitas vezes ao mesmo tempo, tente novamente mais tarde",
  emailAlreadySignupPrompt: "Este endereço de e-mail foi registrado, faça login diretamente",
  tryAgain: "Reenviar",
};

const BindPhone = {
  bindPhone: "Vincular celular",
  phone: "Número de telefone",
  code: "Código de verificação",
  getCode: "obter código de verificação",
  confirm: "confirme",
  cancel: "Cancelar",
  phoneRulePrompt: "Por favor, insira o número do seu celular no formato correto",
  phoneEmptyPrompt: "O número do celular não pode ficar vazio",
  codeRulePrompt: "O código de verificação tem 6 dígitos",
  codeEmptyPrompt: "o código de verificação deve ser preenchido",
  changeSuccessPrompt: "Mudança bem-sucedida",
  changeErrorPrompt: "Falha na alteração",
  bindSuccessPrompt: "Vinculação bem-sucedida",
  bindErrorPrompt: "Falha na vinculação",
  phoneNum8: "Por favor, insira seu número de celular de 8 dígitos",
  phoneNum9To10: "Insira um número de celular de 9 a 10 dígitos",
  phoneNum9: "Insira um número de celular de 9 dígitos",
  phoneCodeSendSuccessPrompt: "Código de verificação enviado com sucesso, verifique a mensagem de texto no seu celular",
  phoneCodeSendErrorPrompt: "O código de verificação não foi enviado, verifique se o número do celular está disponível",
  codeSendToMuchPrompt: "Enviado muitas vezes ao mesmo tempo, tente novamente mais tarde",
  phoneAlreadySignupPrompt: "Este número de celular foi registrado, faça login diretamente",
  checkSignup: "Verifique se o número está cadastrado",
  tryAgain: "Reenviar",
};

const ChangePassword = {
  resetPassword: "redefinir senha",
  phone: "Número de telefone",
  email: "Correspondência",
  code: "Código de verificação",
  getCode: "obter código de verificação",
  logInPassword: "senha de login",
  confirm: "confirme",
  cancel: "Cancelar",
  passwordRulePrompt: "A senha deve consistir em letras maiúsculas e minúsculas e números e deve ter mais de 8 caracteres",
  passwordEmptyPrompt: "a senha não pode ficar em branco",
  emailRulePrompt: "Por favor insira o formato correto do seu endereço de e-mail",
  emailEmptyPrompt: "O e-mail não pode ficar vazio",
  phoneRulePrompt: "Por favor, insira o número do seu celular no formato correto",
  phoneEmptyPrompt: "O número do celular não pode ficar vazio",
  codeRulePrompt: "O código de verificação tem 6 dígitos",
  codeEmptyPrompt: "o código de verificação deve ser preenchido",
  phoneNum8: "Por favor, insira seu número de celular de 8 dígitos",
  phoneNum9To10: "Insira um número de celular de 9 a 10 dígitos",
  phoneNum9: "Insira um número de celular de 9 dígitos",
  resetSuccess: "Redefinição de senha bem-sucedida",
  resetError: "Falha na redefinição de senha, verifique se as informações estão corretas",
  checkSignup: "Verifique se o número está cadastrado",
  phoneCodeSendSuccessPrompt: "Código de verificação enviado com sucesso, verifique a mensagem de texto no seu celular",
  codeSendToMuchPrompt: "Enviado muitas vezes ao mesmo tempo, tente novamente mais tarde",
  phoneAlreadySignupPrompt: "Este número de celular foi registrado, faça login diretamente",
  phoneCodeSendErrorPrompt: "O código de verificação não foi enviado, verifique se o número do celular está disponível",
  emailCodeSendSuccessPrompt: "Código de verificação enviado com sucesso, verifique seu e-mail",
  emailCodeSendErrorPrompt: "O código de verificação não foi enviado, verifique se seu e-mail está disponível",
  emailAlreadySignupPrompt: "Este endereço de e-mail foi registrado, faça login diretamente",
  bindPhoneOrEmail: "Por favor, vincule seu número de celular ou endereço de e-mail primeiro",
  tryAgain: "Reenviar",
};

const QuickRegister = {
  downloadScreenshot: "Baixar captura de tela",
  saveAccountAndPassword: "Por favor, mantenha sua conta e senha seguras",
  suggestSave: "É recomendável que você faça uma captura de tela e salve-a ou copie-a para um memorando.",
  account: "conta",
  password: "senha",
  copyAccountAndPassword: "Copiar senha da conta",
  prompt: "dica",
  sureSave: "Você salvou a senha da sua conta?",
  confirm: "confirme",
  cancel: "Cancelar",
  copySuccess: "Copiado com sucesso",
  copyFailure: "Falha na cópia",
  manualRegister: "O número de inscrições atingiu o limite máximo, registre-se manualmente",
};

const Recommend = {
  recommend: "recomendar",
  InternetErrorPrompt: "Erro de rede",
  hitOfTheWeek: "Sucesso da semana",
  victoryOrDefeat: "Vitória ou derrota",
  check: "Verificar",
  lastTenGames: "Últimos 10 jogos",
  recommendedByExperts: "Recomendado por especialistas",
  changeBatch: "Alterar um lote",
  follow: "focar em",
  following: "Já estou seguindo",
  unFollowing: "Desbloquear",
  all: "All",
  guaranteedCompensation: "Compensação garantida",
  free: "livre",
  toll: "PEDÁGIO",
  HOT: "Vendendo",
  HIT: "bater",
  STREAK: "Mesmo no meio",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "Pagina inicial",
  chat: "bater papo",
  follow: "focar em",
  following: "Já estou seguindo",
  unFollowing: "Desbloquear",
  followersCount: "focar em",
  planCount: "plano",
  viewCount: "assistir",
  statistics: "Estatisticas",
  plan: "plano",
};

const SchemeDetails = {
  planDetails: "Detalhes do plano",
  release: "liberar",
  chat: "bater papo",
  follow: "focar em",
  following: "Já estou seguindo",
  unFollowing: "Desbloquear",
  details: "Detalhes",
  analyze: "analisar",
  title: "título",
  privateChatStreamerJoinsGroup: "O anfitrião do bate-papo privado entra no grupo",
  upcoming: "não começou",
  othersPlan: "Outras opções",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "Classificação de correspondência",
  byTime: "por tempo",
  byLeague: "por liga",
  confirm: "confirme",
  cancel: "Cancelar",
};

const RecommendPublish = {
  enterTeam: "Entre na equipe",
  planRelease: "Liberação do plano",
  predictionTitle: "Título (visível para todos os usuários, por favor não divulgue os resultados da previsão)",
  titleEmptyPrompt: "O título e o conteúdo da análise não podem ficar vazios",
  pleaseSelectAtLeastOneGameToPredictResults: "Escolha pelo menos uma maneira de prever os resultados",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "futebol",
  basketball: "basquetebol",
  mixed: "compreensivo",
  setLogInPassword: "Configurando senha de login",
  OCS: "serviço on-line",
  OCSPrompt: "a seu serviço",
  phoneInCorrectPrompt: "Número de celular digitado incorretamente",
  codeInCorrectPrompt: "código de verificação incorreto",
  emailInCorrectPrompt: "Erro de entrada de e-mail",
  passwordPrompt: "Insira uma combinação de 8 a 16 dígitos em inglês e números",
  newPassword: "Insira uma nova senha",
  resetPasswordPrompt: "Tem certeza de que deseja redefinir sua senha?",
  startChatPrompt: "Agora você pode começar a conversar",
  send: "enviar",
  noFollowPrompt: "Nenhuma atenção ainda",
  feedbackPrompt: "Por favor, deixe seus comentários valiosos e trabalharemos duro para melhorar!",
  bindPhoneByOtherPrompt: "Este número de celular foi vinculado por outros usuários",
  bindEmailByOtherPrompt: "Este endereço de e-mail foi vinculado por outro usuário",
  super_manager: "superadministrador",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "Fim do primeiro trimestre",
  endOfSecondQuarter: "Fim do segundo trimestre",
  endOfThirdQuarter: "Fim do terceiro trimestre",
  endOfFourthQuarter: "Fim do quarto trimestre",
  pullUpToLoadMore: "Suba para carregar mais",
  pullDownToRefresh: "Puxe para baixo para atualizar",
  releaseToRefreshImmediately: "Solte para atualizar imediatamente",
  refreshPrompt: "Refrescante...",
  download: "download",
  appDownload: "Baixar APLICATIVO",
  brandName: "OV88",
  saveToDesktop: "Adicionar à tela inicial",
  downloadTitle: "Baixar APLICATIVO",
  normalDownload: "Download normal",
  scanningPrompt: "Instale com o código QR e receba um desconto exclusivo e outros benefícios luxuosos à sua espera!",
  downloadFailToWeb: "Caso não consiga baixar o APP, clique aqui para usar a versão web",
  scanQrCode: "Digitalize o código QR com o celular",
  usingMobileDevices: "Usar celular",
  installTutorial: "Clique para ver o tutorial de instalação",
  whyNameDifferent: "Os nomes do APP instalado e do APP baixado são inconsistentes, sinta-se à vontade para baixar, instalar e usar!",
  clickDownload: "Clique para fazer o download",
  installTutorialPrompt: "Guia de instruções de instalação",
  brandNativeName: "APP nativo UFOScore",
  uploadingPrompt: "Enviando...",
  bHandicap: "Deficiente",
  different: "Diferença",
  total: "total",
  off: "não começou",
  video: "vídeo",
  animation: "animação",
  install: "Instalar",
  startDownloading: "iniciar o download",
  cancelGoal: "Gol cancelado",
  appUpdatePrompt: "Há uma nova versão do APP, atualize",
};
