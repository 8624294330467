/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "Acuerdo del Usuario",
  privacyPolicy: "política de privacidad",
  termsOfService: "Términos de servicio",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "No ha comenzado todavía",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "Ya transmitido",
  watch: "ve a ver",
  follow: "concentrarse en",
  following: "Ya estoy siguiendo",
  subscribe: "suscripción",
  streamerSchedule: "Horario de anclaje",
  noDataPrompt: "Sin datos",
  unfollowPrompt: "¿Estás seguro de dejar de seguir?",
  yes: "Sí",
  no: "No",
  logAndSign: "Iniciar sesión/Registrarse ahora",
  hours: "Hora",
  mins: "minuto",
};

const AnchorInformation = {
  recommendedLiveStreams: "Transmisión en vivo recomendada",
  noDataPrompt: "Sin datos",
};

const BasketballScoreIndex = {
  details: "Detalles",
  over: "encima",
  pointsDifference: "Diferencia de puntos",
  totalScore: "puntaje total",
  live: "LIVE",
  unSubscribePrompt: "¿Estás seguro de cancelar tu suscripción?",
  yes: "Sí",
  no: "No",
  logAndSign: "Iniciar sesión/Registrarse ahora",
  InternetErrorPrompt: "Error de red",
};

const BasketballSmallBig = {
  details: "Detalles",
  over: "encima",
  pointsDifference: "Diferencia de puntos",
  totalScore: "puntaje total",
  live: "LIVE",
  unSubscribePrompt: "¿Estás seguro de cancelar tu suscripción?",
  yes: "Sí",
  no: "No",
  logAndSign: "Iniciar sesión/Registrarse ahora",
  InternetErrorPrompt: "Error de red",
  overIndex: "grande",
  underIndex: "Pequeño",
};

const BindPhoneDialog = {
  bindPhonePrompt: "Para la seguridad de su cuenta, vincule su número de teléfono móvil para que podamos brindarle mejores servicios.",
  goBind: "Ir a encuadernación",
};

const BottomNavigationBar = {
  home: "página delantera",
  matches: "Concurso",
  recommend: "recomendar",
  chat: "charlar",
  personal: "personal",
  discovery: "Descubrir",
  money: "ganar dinero",
};

const ChatInterface = {
  noCsPrompt: "No se puede chatear en este momento",
  uploadRulePrompt: "Sube imágenes en formato jpg, jpeg, png.",
  uploadSizePrompt: "El tamaño de la imagen no puede exceder los 10M",
  sendError: "fallo al enviar",
  picSendError: "Error al enviar la imagen",
  InternetErrorPrompt: "Error de red",
  bannedSendPrompt: "has sido baneado",
};

const ChatList = {
  noNewPrompt: "Aún no hay noticias",
  logInPrompt: "Inicie sesión para ver el contenido, inicie sesión primero",
  logIn: "Acceso",
  group: "grupo",
};

const ChatListItem = {
  withdrawMessage: "Retirado un mensaje",
  bannedPrompt: "prohibido",
  unBanPrompt: "no prohibido",
  joinGroupPrompt: "Se unió al grupo",
  leftGroupPrompt: "Dejó el grupo",
  picture: "imagen",
  link: "Enlace",
  emoji: "emoticones animados",
  addedFriendPrompt: "Agregado como amigo",
};

const CompetitionList = {
  logInPrompt: "Inicie sesión para ver el contenido, inicie sesión primero",
  logIn: "Acceso",
  today: "hoy",
  Monday: "Lunes",
  Tuesday: "Martes",
  Wednesday: "Miércoles",
  Thursday: "Jueves",
  Friday: "Viernes",
  Saturday: "Sábado",
  Sunday: "Domingo",
  noGames: "Aún no hay juegos",
  toCollapse: "Haga clic para colapsar",
  toExpand: "Haga clic para ampliar"
};

const CompetitionListItem = {
  details: "Detalles",
  unSubscribePrompt: "¿Estás seguro de cancelar tu suscripción?",
  yes: "Sí",
  no: "No",
  logAndSign: "Iniciar sesión/Registrarse ahora",
  InternetErrorPrompt: "Error de red",
};

const CompetitionMode = {
  matchMode: "Modo competición",
  confirm: "confirmar",
  cancel: "Cancelar",
};

const EmojiCom = {
  logAndSign: "Iniciar sesión/Registrarse ahora",
  yes: "Sí",
  no: "No",
};

const ExponentCom = {
  company: "compañía",
  initial: "inicial",
  ongoing: "inmediato",
  preMatch: "Antes del juego",
  noDataPrompt: "Sin datos",
  handicap: "Desventaja",
  WinDrawLose: "Ganar, empatar, perder",
  totalGoals: "goles totales",
  cornerKick: "bocina",
  pleaseSelect: "por favor elige",
  confirm: "confirmar",
  cancel: "Cancelar",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "Fijado en 'Stream chat'",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "concentrarse en",
  following: "Ya estoy siguiendo",
  chat: "charlar",
  noDataPrompt: "Sin datos",
  unfollowPrompt: "¿Estás seguro de dejar de seguir?",
  yes: "Sí",
  no: "No",
  InternetErrorPrompt: "Error de red",
};

const FootballCornerIndex = {
  details: "Detalles",
  over: "encima",
  FH: "HT",
  cornerKick: "bocina",
  unSubscribePrompt: "¿Estás seguro de cancelar tu suscripción?",
  yes: "Sí",
  no: "No",
  logAndSign: "Iniciar sesión/Registrarse ahora",
  InternetErrorPrompt: "Error de red",
};

const FootballScoreIndex = {
  details: "Detalles",
  over: "encima",
  FH: "HT",
  cornerKick: "bocina",
  live: "LIVE",
  unSubscribePrompt: "¿Estás seguro de cancelar tu suscripción?",
  yes: "Sí",
  no: "No",
  logAndSign: "Iniciar sesión/Registrarse ahora",
  InternetErrorPrompt: "Error de red",
};

const FootballScoreItem = {
  details: "Detalles",
  over: "encima",
  FH: "HT",
  cornerKick: "bocina",
  live: "LIVE",
  unSubscribePrompt: "¿Estás seguro de cancelar tu suscripción?",
  yes: "Sí",
  no: "No",
  logAndSign: "Iniciar sesión/Registrarse ahora",
  InternetErrorPrompt: "Error de red",
};

const GoalToast = {
  goal: "meta",
};

const LiveRecommend = {
  noDataPrompt: "Sin datos",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "Victoria o derrota",
};

const MatchCardList = {
  details: "Detalles",
};

const MatchStatus = {
  noDataPrompt: "Sin datos",
};

const MoreStreams = {
  switchLines: "cambiar de linea",
  cancel: "Cancelar",
  currentStreamer: "Ancla actual",
  live: "LIVE",
  offLine: "No ha comenzado todavía",
  followersCount: "concentrarse en",
  otherStreamersPrompt: "Hay otros presentadores transmitiendo actualmente el juego.",
  watch: "ve a ver",
};

const NewMatchStatus = {
  team: "equipo",
  halfTimeScore: "puntuación del medio tiempo",
  shotOnTarget: "Disparo a portería",
  missedShot: "falló el gol",
  attack: "ataque",
  dangerousAttack: "Ataque peligroso",
  number3PointGoals: "Número de goles de 3 puntos marcados",
  number2PointGoals: "Número de goles de 2 puntos marcados",
  penaltyKickGoals: "Goles de penalti",
  freeThrowPercentage: "Porcentaje de tiros libres",
  numberOfPausesRemaining: "Número de pausas restantes",
  numberOfFouls: "Número de faltas",
  totalNumberOfPauses: "pausas totales",
  threePointShot: "tiro de 3 puntos",
  twoPointShot: "tiro de 2 puntos",
  shoot: "Disparar",
  penalty: "multa",
  firstHalf: "primera mitad",
  secondHalf: "segunda mitad",
  redCard: "tarjeta roja",
  yellowCard: "tarjeta amarilla",
};

const NoData = {
  noDataPrompt: "Sin datos",
};

const NoticeMessage = {
  addedFriendPrompt: "Agregado como amigo",
  withdrawMessage: "Retirado un mensaje",
  withdrawMessageByAdmin: "Un mensaje fue retirado por el administrador.",
  joinGroupPrompt: "Se unió al grupo",
  leftGroupPrompt: "Dejó el grupo",
  removeGroupPrompt: "Eliminado del grupo por el administrador",
  bannedPrompt: "prohibido",
  unBanPrompt: "no prohibido",
};

const OddsStatisticsList = {
  initial: "inicial",
  ongoing: "inmediato",
  preMatch: "Antes del juego",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "valor más alto",
  min: "valor más bajo",
  average: "valor promedio",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "Sin datos",
};

const RecommendCompetitions = {
  today: "hoy",
  winsInARow: "Incluso en el medio",
  noLivePrompt: "Aún no hay transmisión en vivo",
};

const RecommendStreamerList = {
  noDataPrompt: "Sin datos",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "Golpe de la semana",
  victoryOrDefeat: "Victoria o derrota",
  check: "Controlar",
  chat: "charlar",
  quarterlyProfit: "beneficio trimestral",
};

const RecommendStreamers = {
  recommendedByExperts: "Recomendado por expertos",
  winsInARow: "Incluso en el medio",
  changeBatch: "Cambiar un lote",
  follow: "concentrarse en",
  following: "Ya estoy siguiendo",
  unFollowing: "desbloquear",
  noDataPrompt: "Sin datos",
  logAndSign: "Iniciar sesión/Registrarse ahora",
  yes: "Sí",
  no: "No",
  InternetErrorPrompt: "Error de red",
  unfollowPrompt: "¿Estás seguro de dejar de seguir?",
};

const RecordChart = {
  record: "registro",
  allWaysToPlay: "Todas las formas de jugar",
  fromFarToNear: "de lejos a cerca",
  lastTenGames: "Últimos 10 juegos",
  longestConsecutiveHit: "El golpe consecutivo más largo",
  hitRate: "tasa de aciertos",
  currentPropmt: "juegos recientes",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "No se pudo obtener información del usuario",
  getAreaAndLanguageErrorPrompt: "No se pudo obtener el idioma regional",
};

const ScheduleCom = {
  startTime: "Tiempo de empezar",
};

const SchemeCard = {
  planList: "Lista de planes",
  release: "liberar",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "Compensación garantizada",
  free: "gratis",
  toll: "PEAJE",
  victoryOrDefeat: "Victoria o derrota",
};

const SquareCom = {
  watchPrompt: "la gente está mirando",
  startedPrompt: "Comenzó hace minutos",
  chat: "charlar",
  sendMessageNeedLoginPrompt: "Por favor inicia sesión para enviar un mensaje",
  assistant: "administrador",
  audience: "usuario",
  newMessage: "nuevas noticias",
  confirmSend: "Confirmar envío",
  cancelSend: "Cancelar",
};

const StatisticalChart = {
  hitRate: "tasa de aciertos",
};

const SwitchLines = {
  switchLines: "cambiar de linea",
  cancel: "Cancelar",
  currentStreamer: "Ancla actual",
  live: "LIVE",
  offLine: "No ha comenzado todavía",
  followersCount: "concentrarse en",
  otherStreamersPrompt: "Hay otros presentadores transmitiendo actualmente el juego.",
  watch: "ve a ver",
  LINE: "línea",
  VIP: "VIP",
  OFFICIAL: "oficial",
};

const VideoCom = {
  videoErrorPrompt: "La señal no es muy buena, inténtalo de nuevo más tarde.",
  clickRetryPrompt: "Haga clic para intentarlo de nuevo",
};

const WelcomeCard = {
  join: "Unirse al grupo",
};

//utils
const tools = {
  yesterday: "ayer",
  addedFriendPrompt: "Agregado como amigo",
  withdrawMessage: "Retirado un mensaje",
  joinGroupPrompt: "Se unió al grupo",
  leftGroupPrompt: "Dejó el grupo",
  removeGroupPrompt: "Eliminado del grupo por el administrador",
  bannedPrompt: "prohibido",
  unBanPrompt: "no prohibido",
};

//页面
const AddressBook = {
  search: "Encontrar",
  addFriend: "añadir amigo",
  myGroupChat: "mi chat grupal",
  friends: "amigos",
  delete: "borrar",
  noFriendsPrompt: "Aún no hay amigos",
  nameAndIdSearch: "Introduzca nombre, DNI",
  searchResults: "Buscar resultados",
  notFoundPrompt: "Usuario no encontrado",
  add: "añadir",
  deleteFriendPrompt: "¿Estás seguro de eliminar al amigo?",
  yes: "Sí",
  no: "No",
  addFriendSuccessPrompt: "Agregar amigo exitosamente",
  deleteFriendSuccessPrompt: "Eliminar amigo exitosamente",
  InputSearchPrompt: "Por favor ingresa el contenido",
};

const GroupChat = {
  groupChat: "grupo de chat",
  groupSearch: "Ingrese el nombre del chat grupal",
  noGroupPrompt: "Aún no hay chat grupal",
  chat: "charlar",
  InputSearchPrompt: "Por favor ingresa el contenido",
};

const GroupAdministrator = {
  administrator: "administrador",
  groupOwner: "Caballero",
};

const GroupAnnouncement = {
  groupAnnouncement: "Anuncio de grupo",
  groupOwner: "Caballero",
  administrator: "administrador",
};

const GroupDetail = {
  groupDetail: "Detalles del grupo",
  groupMembers: "miembros del grupo",
  groupName: "Nombre del grupo",
  groupDescription: "Descripción del Grupo",
  groupOwner: "Caballero",
  administrator: "administrador",
  administratorList: "Lista de administradores",
  groupAnnouncement: "Anuncio de grupo",
  groupFiles: "archivo de grupo",
  exitGroup: "salir del grupo",
  exitGroupPrompt: "¿Seguro que dejarás el grupo?",
  yes: "Sí",
  no: "No",
  leftGroupSuccessPrompt: "Salí del grupo exitosamente",
};

const GroupFile = {
  groupFiles: "archivo de grupo",
  fileSearch: "Introduzca el nombre del archivo",
  today: "hoy",
  multimedia: "multimedia",
  file: "documento",
  noFilesPrompt: "Aún no hay archivos",
};

const GroupMembers = {
  groupMembers: "miembros del grupo",
  nameAndIdSearch: "Introduzca nombre, DNI",
};

const Chat = {
  chat: "charlar",
  nameAndIdSearch: "Introduzca nombre, DNI",
  contacts: "Directorio",
  InputSearchPrompt: "Por favor ingresa el contenido",
  deleteContactPrompt: "¿Estás seguro de eliminar el chat?",
};

const Competition = {
  all: "All",
  ongoing: "inmediato",
  popular: "Popular",
  liveCompetition: "transmisión en vivo",
  schedule: "cronograma",
  results: "resultado",
  subscribed: "Suscrito",
  goal: "meta",
  today: "hoy",
  Monday: "Lunes",
  Tuesday: "Martes",
  Wednesday: "Miércoles",
  Thursday: "Jueves",
  Friday: "Viernes",
  Saturday: "Sábado",
  Sunday: "Domingo",
};

const CompetitionDetails = {
  subscribe: "suscripción",
  subscribed: "Suscrito",
  matchStreamerPrompt: "Ancla de juego en vivo",
  live: "LIVE",
  noDataPrompt: "Sin datos",
  noStreamersPrompt: "Actualmente no hay ningún anfitrión transmitiendo este juego.",
  alreadyStreamerPrompt: "Un presentador ha empezado a retransmitir el partido. ¿Quieres ir a verlo?",
  yes: "Sí",
  no: "No",
  liveStream: "transmisión en vivo",
  matchStatus: "Estado del partido",
  odds: "Odds",
  unSubscribePrompt: "¿Estás seguro de cancelar tu suscripción?",
  logAndSign: "Iniciar sesión/Registrarse ahora",
  InternetErrorPrompt: "Error de red",
};

const OddsDetails = {
  company: "compañía",
  handicap: "Desventaja",
  WinDrawLose: "Ganar, empatar, perder",
  totalGoals: "goles totales",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "muchos",
  Un: "pocos",
};

const Home = {
  logIn: "Acceso",
  signUp: "registro",
  popularMatches: "Competiciones populares",
  more: "Más",
  noDataPrompt: "Sin datos",
  currentlyLive: "Transmisión en vivo",
  popularStreamers: "Ancla popular",
  thinkYouLike: "También te puede interesar",
  loadMore: "carga más",
  noMoreDataPrompt: "Aún no hay más datos",
  addDeskPrompt: "agregar al escritorio",
  clikToolPrompt: "Haga clic en la barra de herramientas a continuación",
  andSeletcPrompt: "y seleccione",
  addToScreenPrompt: "Queremos agregar a su pantalla de inicio para brindar un mejor servicio.",
  addToScreen: "Añadir a la pantalla principal",
};

const MoreLives = {
  noDataPrompt: "Sin datos",
};

const MoreStreamers = {
  streamer: "Ancla",
  streamerSearch: "Ingrese el nombre del ancla y la identificación",
  all: "All",
  notFollowing: "No seguir",
  following: "Ya estoy siguiendo",
  live: "LIVE",
  offLine: "No ha comenzado todavía",
  followersCount: "concentrarse en",
  unfollowPrompt: "¿Estás seguro de dejar de seguir?",
  yes: "Sí",
  no: "No",
  logAndSign: "Iniciar sesión/Registrarse ahora",
  InternetErrorPrompt: "Error de red",
  InputSearchPrompt: "Por favor ingresa el contenido",
  dataRequestErrorPrompt: "No se pudieron obtener datos",
};

const LiveRoom = {
  switchLines: "cambiar de linea",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "Anclar chat privado",
  chat: "charlar",
  odds: "Odds",
  streamerInformation: "Información del ancla",
  matchStatus: "Estado del partido",
};

const AnchorIntroduction = {
  streamerInformation: "Información del ancla",
  photosWall: "Pared de fotos",
  seeMore: "ver más",
  streamerPlan: "Plano de anclaje",
};

const AnchorPhoto = {
  photosWall: "Pared de fotos",
  noPhotosPrompt: "No hay más fotos todavía",
};

const LogInRegister = {
  logIn: "Acceso",
  signUp: "registro",
  getAreaAndLanguageErrorPrompt: "No se pudo obtener el idioma regional",
  quickRegisterFrequentPrompt: "Solicitudes frecuentes, inténtalo de nuevo más tarde.",
};

const LoginFn = {
  phone: "Número de teléfono",
  email: "Correo",
  logInPassword: "contraseña de inicio de sesión",
  rememberPassword: "Recordar contraseña",
  forgotPassword: "olvida la contraseña",
  logIn: "Acceso",
  logInAgreement: "Al iniciar sesión estás de acuerdo",
  userAgreement: "Acuerdo del Usuario",
  and: "  y ",
  privacyPolicy: "política de privacidad",
  passwordRulePrompt: "La contraseña debe constar de letras mayúsculas, minúsculas y números y debe tener más de 8 caracteres.",
  passwordEmptyPrompt: "la contraseña no puede estar en blanco",
  emailRulePrompt: "Por favor ingrese el formato correcto de su dirección de correo electrónico",
  emailEmptyPrompt: "El correo electrónico no puede estar vacío.",
  phoneRulePrompt: "Por favor introduce tu número de teléfono móvil en el formato correcto",
  phoneEmptyPrompt: "El número de teléfono móvil no puede estar vacío",
  phoneNum8: "Por favor introduce tu número de teléfono móvil de 8 dígitos",
  phoneNum9To10: "Por favor ingrese un número de teléfono móvil de 9 a 10 dígitos",
  phoneNum9: "Por favor introduce un número de teléfono móvil de 9 dígitos",
  enterErrorPrompt: "Error de entrada o anomalía de la red",
  loginNoAgreePrompt: "Debe aceptar el Acuerdo de usuario y la Política de privacidad para iniciar sesión.",
  accountAndPassword: "cuenta",
  account: "cuenta",
  accountEmptyPrompt: "La cuenta no puede estar vacía",
  accountOrPasswordError: "Nombre de usuario o contraseña incorrecta",
};

const Register = {
  phone: "Número de teléfono",
  email: "Correo",
  code: "Código de verificación",
  getCode: "obtener código de verificación",
  logInPassword: "contraseña de inicio de sesión",
  CS: "Servicio al Cliente",
  signUp: "registro",
  signUpAgreement: "Al registrarte estás de acuerdo",
  userAgreement: "Acuerdo del Usuario",
  and: "y",
  privacyPolicy: "política de privacidad",
  contactCSPrompt: "¿Tienes problemas para registrarte? Póngase en contacto con el servicio de atención al cliente para resolverlo por usted.",
  contactCS: "Contactar Servicio al Cliente",
  passwordRulePrompt: "La contraseña debe constar de letras mayúsculas, minúsculas y números y debe tener más de 8 caracteres.",
  passwordEmptyPrompt: "la contraseña no puede estar en blanco",
  emailRulePrompt: "Por favor ingrese el formato correcto de su dirección de correo electrónico",
  emailEmptyPrompt: "El correo electrónico no puede estar vacío.",
  phoneRulePrompt: "Por favor introduce tu número de teléfono móvil en el formato correcto",
  phoneEmptyPrompt: "El número de teléfono móvil no puede estar vacío",
  codeRulePrompt: "El código de verificación es de 6 dígitos.",
  codeEmptyPrompt: "se debe completar el código de verificación",
  phoneNum8: "Por favor introduce tu número de teléfono móvil de 8 dígitos",
  phoneNum9To10: "Por favor ingrese un número de teléfono móvil de 9 a 10 dígitos",
  phoneNum9: "Por favor introduce un número de teléfono móvil de 9 dígitos",
  phoneCodeSendSuccessPrompt: "El código de verificación se envió correctamente, verifique el mensaje de texto en su teléfono móvil",
  emailCodeSendSuccessPrompt: "Código de verificación enviado exitosamente, por favor revisa tu correo electrónico",
  phoneCodeSendErrorPrompt: "No se pudo enviar el código de verificación. Verifique si el número de teléfono móvil está disponible.",
  emailCodeSendErrorPrompt: "No se pudo enviar el código de verificación, verifique si su correo electrónico está disponible",
  codeSendToMuchPrompt: "Enviado demasiadas veces al mismo tiempo, inténtelo de nuevo más tarde.",
  phoneAlreadySignupPrompt: "Este número de teléfono móvil ha sido registrado, inicie sesión directamente",
  emailAlreadySignupPrompt: "Esta dirección de correo electrónico ha sido registrada, inicie sesión directamente",
  signUpErrorPrompt: "El registro falló, por favor verifique si la información es correcta",
  enterErrorPrompt: "Error de entrada o anomalía de la red",
  noAgreePrompt: "Debe aceptar el Acuerdo de usuario y la Política de privacidad para registrarse.",
  regionAndLanguagePrompt: "Seleccione su idioma regional real; de lo contrario, afectará el uso de las funciones.",
  accountAndPassword: "cuenta",
  quickSignup: "Registro con un clic",
  accountEmptyPrompt: "La cuenta no puede estar vacía",
  accountRulePrompt: "El número de cuenta debe constar de 8 a 16 letras y números.",
  accountAlreadySignupPrompt: "Esta cuenta ha sido registrada, inicie sesión directamente",
  tryAgain: "Reenviar",
};

const PersonalCenter = {
  logAndSign: "Iniciar sesión/Registrarse ahora",
  logAndSignPrompt: "Desbloquee funciones exclusivas y beneficios exclusivos ahora",
  logOut: "desconectar",
  myFollow: "mi enfoque",
  mySubscription: "mi suscripción",
  accountSecurity: "Seguridad de la cuenta",
  feedback: "Comentario",
  deviceInformation: "equipo",
  aboutUs: "sobre nosotros",
  coin: "oro",
  moreServices: "Más servicios",
  following: "Ya estoy siguiendo",
  subscribed: "Suscrito",
  browser: "Navegador",
  user: "usuario",
  profile: "informacion personal",
  nickname: "Apodo",
  save: "ahorrar",
  change: "Cambiar",
  nicknamePrompt: "Por favor escribe un apodo",
  avatar: "avatar",
  changeSuccessPrompt: "Cambio exitoso",
  ballAnimation: "Animación de gol",
  ballVoice: "Efectos de sonido de gol.",
  setting: "configuración",
};

const IntegralSystem = {
  Coin: "oro",
  goldCoinTask: "tarea de moneda de oro",
  PointsDetails: "Detalles de la moneda de oro",
  myCoins: "mis monedas de oro",
  income: "ingreso",
  expenditure: "gasto",
  signInPrompt: "Inicie sesión continuamente y reciba recompensas continuamente",
  toComplete: "completar",
  toShare: "para compartir",
  shareTasks: "compartir tareas",
  exclusiveLink: "enlace exclusivo",
  copyLink: "copiar link",
  highestWin: "victoria más alta",
  day1: "Día 1",
  day2: "Dia 2",
  day3: "3er día",
  day4: "Día 4",
  day5: "Dia 5",
  day6: "Día 6",
  day7: "Día 7",
  invitePrompt: "¡sorpresa! ¡Regístrate ahora y descarga nuestra APP para disfrutar de beneficios exclusivos para nuevos usuarios! Transmisiones gratuitas de eventos en vivo, análisis de datos en tiempo real y predicciones de presentadores profesionales, todo al alcance de su mano. ¡No esperes más, haz clic en el enlace para unirte!"
};

const Money = {
  activeCenter: "centro de actividades",
  missionCenter: "centro de misión",
};

const ActivityCenter = {
  toParticipate: "para participar",
  sportActivity: "actividades deportivas",
};

const ActivityDetails = {
  eventDetails: "Detalles del evento",
  dailyBetting: "Apuestas Diarias",
  matchTime: "tiempo de partido",
  popularForEvent: "Opciones populares para este evento.",
  clickToParticipate: "Haga clic para participar ahora",
  activityRules: "Reglas de actividad",
  clickToContactParticipate: "Haga clic para contactar con el servicio de atención al cliente para participar.",
  activityObj: "objeto activo",
  activityPlatform: "Plataforma de eventos",
  activityDuration: "Duración de la actividad",
  activityDemand: "Requisitos de actividad",
  activityTime: "tiempo de actividad",
};

const AboutUs = {
  aboutUs: "sobre nosotros",
  platformIntroduction: "Introducción a la plataforma",
  privacyPolicy: "política de privacidad",
  userAgreement: "Acuerdo del Usuario",
};

const DeviceInfo = {
  deviceInformation: "equipo",
  currentTime: "tiempo actual",
  port: "Puerto de inicio de sesión",
  ip: "IP de inicio de sesión",
  version: "versión actual",
  ipAndPort: "Información Entrar",
};

const FeedBack = {
  feedback: "Comentario",
  feedbackType: "Tipo de Comentarios",
  productSuggestion: "sugerencias de productos",
  functionalFault: "Fallo funcional",
  interfaceSuggestion: "Sugerencias de interfaz",
  accountIssue: "problema de cuenta",
  operationExperience: "Experiencia operativa",
  otherFeedback: "Otros comentarios",
  problemDescription: "Planteamiento del problema",
  submit: "entregar",
  addPhoto: "añadir foto",
  uploadPrompt: "Lo sentimos, sólo se pueden subir 3 fotos.",
  uploadRulePrompt: "Sube imágenes en formato jpg, jpeg, png.",
  uploadSizePrompt: "El tamaño de la imagen no puede exceder los 10M",
  chooseFeedback: "Por favor seleccione el tipo de comentario",
  inputQuestionPrompt: "Por favor ingresa una pregunta",
  submitSuccessPrompt: "Enviado satisfactoriamente",
};

const MySubscription = {
  myFollow: "mi enfoque",
  mySubscription: "mi suscripción",
  streamer: "Ancla",
  matches: "Concurso",
};

const PrivacyAgreement = {
  privacyPolicy: "política de privacidad",
};

const TermsofService = {
  termsOfService: "Términos de servicio",
};

const UserAgreement = {
  userAgreement: "Acuerdo del Usuario",
};

const AccountSecurity = {
  accountSecurity: "Seguridad de la cuenta",
  phone: "Número de teléfono",
  email: "Correo",
  bind: "vinculante",
  notBound: "No unido",
  change: "Cambiar",
  logInPassword: "contraseña de inicio de sesión",
  accountChangePassword: "Primero vincule su teléfono móvil/dirección de correo electrónico",
};

const BindEmail = {
  bindEmail: "Vincular correo electrónico",
  email: "Correo",
  code: "Código de verificación",
  getCode: "obtener código de verificación",
  confirm: "confirmar",
  emailRulePrompt: "Por favor ingrese el formato correcto de su dirección de correo electrónico",
  emailEmptyPrompt: "El correo electrónico no puede estar vacío.",
  codeRulePrompt: "El código de verificación es de 6 dígitos.",
  codeEmptyPrompt: "se debe completar el código de verificación",
  changeSuccessPrompt: "Cambio exitoso",
  changeErrorPrompt: "El cambio falló",
  bindSuccessPrompt: "Vinculación exitosa",
  bindErrorPrompt: "Error al vincular",
  emailCodeSendSuccessPrompt: "Código de verificación enviado exitosamente, por favor revisa tu correo electrónico",
  emailCodeSendErrorPrompt: "No se pudo enviar el código de verificación, verifique si su correo electrónico está disponible",
  codeSendToMuchPrompt: "Enviado demasiadas veces al mismo tiempo, inténtelo de nuevo más tarde.",
  emailAlreadySignupPrompt: "Esta dirección de correo electrónico ha sido registrada, inicie sesión directamente",
  tryAgain: "Reenviar",
};

const BindPhone = {
  bindPhone: "vincular teléfono móvil",
  phone: "Número de teléfono",
  code: "Código de verificación",
  getCode: "obtener código de verificación",
  confirm: "confirmar",
  cancel: "Cancelar",
  phoneRulePrompt: "Por favor introduce tu número de teléfono móvil en el formato correcto",
  phoneEmptyPrompt: "El número de teléfono móvil no puede estar vacío",
  codeRulePrompt: "El código de verificación es de 6 dígitos.",
  codeEmptyPrompt: "se debe completar el código de verificación",
  changeSuccessPrompt: "Cambio exitoso",
  changeErrorPrompt: "El cambio falló",
  bindSuccessPrompt: "Vinculación exitosa",
  bindErrorPrompt: "Error al vincular",
  phoneNum8: "Por favor introduce tu número de teléfono móvil de 8 dígitos",
  phoneNum9To10: "Por favor ingrese un número de teléfono móvil de 9 a 10 dígitos",
  phoneNum9: "Por favor introduce un número de teléfono móvil de 9 dígitos",
  phoneCodeSendSuccessPrompt: "El código de verificación se envió correctamente, verifique el mensaje de texto en su teléfono móvil",
  phoneCodeSendErrorPrompt: "No se pudo enviar el código de verificación. Verifique si el número de teléfono móvil está disponible.",
  codeSendToMuchPrompt: "Enviado demasiadas veces al mismo tiempo, inténtelo de nuevo más tarde.",
  phoneAlreadySignupPrompt: "Este número de teléfono móvil ha sido registrado, inicie sesión directamente",
  checkSignup: "Por favor verifique si el número está registrado.",
  tryAgain: "Reenviar",
};

const ChangePassword = {
  resetPassword: "restablecer la contraseña",
  phone: "Número de teléfono",
  email: "Correo",
  code: "Código de verificación",
  getCode: "obtener código de verificación",
  logInPassword: "contraseña de inicio de sesión",
  confirm: "confirmar",
  cancel: "Cancelar",
  passwordRulePrompt: "La contraseña debe constar de letras mayúsculas, minúsculas y números y debe tener más de 8 caracteres.",
  passwordEmptyPrompt: "la contraseña no puede estar en blanco",
  emailRulePrompt: "Por favor ingrese el formato correcto de su dirección de correo electrónico",
  emailEmptyPrompt: "El correo electrónico no puede estar vacío.",
  phoneRulePrompt: "Por favor introduce tu número de teléfono móvil en el formato correcto",
  phoneEmptyPrompt: "El número de teléfono móvil no puede estar vacío",
  codeRulePrompt: "El código de verificación es de 6 dígitos.",
  codeEmptyPrompt: "se debe completar el código de verificación",
  phoneNum8: "Por favor introduce tu número de teléfono móvil de 8 dígitos",
  phoneNum9To10: "Por favor ingrese un número de teléfono móvil de 9 a 10 dígitos",
  phoneNum9: "Por favor introduce un número de teléfono móvil de 9 dígitos",
  resetSuccess: "Restablecimiento de contraseña exitoso",
  resetError: "Error al restablecer la contraseña, verifique si la información es correcta",
  checkSignup: "Por favor verifique si el número está registrado.",
  phoneCodeSendSuccessPrompt: "El código de verificación se envió correctamente, verifique el mensaje de texto en su teléfono móvil",
  codeSendToMuchPrompt: "Enviado demasiadas veces al mismo tiempo, inténtelo de nuevo más tarde.",
  phoneAlreadySignupPrompt: "Este número de teléfono móvil ha sido registrado, inicie sesión directamente",
  phoneCodeSendErrorPrompt: "No se pudo enviar el código de verificación. Verifique si el número de teléfono móvil está disponible.",
  emailCodeSendSuccessPrompt: "Código de verificación enviado exitosamente, por favor revisa tu correo electrónico",
  emailCodeSendErrorPrompt: "No se pudo enviar el código de verificación, verifique si su correo electrónico está disponible",
  emailAlreadySignupPrompt: "Esta dirección de correo electrónico ha sido registrada, inicie sesión directamente",
  bindPhoneOrEmail: "Primero vincule su número de teléfono móvil o dirección de correo electrónico",
  tryAgain: "Reenviar",
};

const QuickRegister = {
  downloadScreenshot: "Descargar captura de pantalla",
  saveAccountAndPassword: "Mantenga su cuenta y contraseña seguras",
  suggestSave: "Se recomienda tomar una captura de pantalla y guardarla o copiarla en una nota.",
  account: "cuenta",
  password: "contraseña",
  copyAccountAndPassword: "Copiar contraseña de cuenta",
  prompt: "pista",
  sureSave: "¿Has guardado la contraseña de tu cuenta?",
  confirm: "confirmar",
  cancel: "Cancelar",
  copySuccess: "Copiado exitosamente",
  copyFailure: "Copia fallida",
  manualRegister: "El número de registros ha alcanzado el límite superior, regístrese manualmente",
};

const Recommend = {
  recommend: "recomendar",
  InternetErrorPrompt: "Error de red",
  hitOfTheWeek: "Golpe de la semana",
  victoryOrDefeat: "Victoria o derrota",
  check: "Controlar",
  lastTenGames: "Últimos 10 juegos",
  recommendedByExperts: "Recomendado por expertos",
  changeBatch: "Cambiar un lote",
  follow: "concentrarse en",
  following: "Ya estoy siguiendo",
  unFollowing: "desbloquear",
  all: "All",
  guaranteedCompensation: "Compensación garantizada",
  free: "gratis",
  toll: "PEAJE",
  HOT: "Venta",
  HIT: "golpear",
  STREAK: "Incluso en el medio",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "pagina de inicio",
  chat: "charlar",
  follow: "concentrarse en",
  following: "Ya estoy siguiendo",
  unFollowing: "desbloquear",
  followersCount: "concentrarse en",
  planCount: "plan",
  viewCount: "mirar",
  statistics: "Estadísticas",
  plan: "plan",
};

const SchemeDetails = {
  planDetails: "Detalles del plano",
  release: "liberar",
  chat: "charlar",
  follow: "concentrarse en",
  following: "Ya estoy siguiendo",
  unFollowing: "desbloquear",
  details: "Detalles",
  analyze: "analizar",
  title: "título",
  privateChatStreamerJoinsGroup: "El anfitrión del chat privado se une al grupo",
  upcoming: "no ha comenzado",
  othersPlan: "Otras opciones",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "clasificación de coincidencias",
  byTime: "A tiempo",
  byLeague: "por liga",
  confirm: "confirmar",
  cancel: "Cancelar",
};

const RecommendPublish = {
  enterTeam: "Entrar equipo",
  planRelease: "Lanzamiento del plan",
  predictionTitle: "Título (visible para todos los usuarios, no revele los resultados de la predicción)",
  titleEmptyPrompt: "El título y contenido del análisis no pueden estar vacíos.",
  pleaseSelectAtLeastOneGameToPredictResults: "Elija al menos una forma de predecir los resultados.",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "fútbol americano",
  basketball: "baloncesto",
  mixed: "integral",
  setLogInPassword: "Configuración de contraseña de inicio de sesión",
  OCS: "Servicio en línea",
  OCSPrompt: "a su servicio",
  phoneInCorrectPrompt: "Número de teléfono móvil introducido incorrectamente",
  codeInCorrectPrompt: "código de verificación incorrecto",
  emailInCorrectPrompt: "Error de entrada de correo electrónico",
  passwordPrompt: "Ingrese una combinación de 8 a 16 dígitos de inglés y números",
  newPassword: "Introduzca una nueva contraseña",
  resetPasswordPrompt: "¿Estás seguro de restablecer tu contraseña?",
  startChatPrompt: "Ahora puedes empezar a chatear.",
  send: "enviar",
  noFollowPrompt: "Aún no hay atención",
  feedbackPrompt: "¡Deje sus valiosos comentarios y trabajaremos duro para mejorar!",
  bindPhoneByOtherPrompt: "Este número de móvil ha sido vinculado por otros usuarios.",
  bindEmailByOtherPrompt: "Esta dirección de correo electrónico ha sido vinculada por otro usuario.",
  super_manager: "superadministrador",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "Fin del primer trimestre",
  endOfSecondQuarter: "Fin del segundo trimestre",
  endOfThirdQuarter: "Fin del tercer trimestre",
  endOfFourthQuarter: "Fin del cuarto trimestre",
  pullUpToLoadMore: "Levántate para cargar más",
  pullDownToRefresh: "Tire hacia abajo para refrescar",
  releaseToRefreshImmediately: "Suelte para actualizar inmediatamente",
  refreshPrompt: "Refrescante...",
  download: "descargar",
  appDownload: "descarga de la aplicación",
  brandName: "OV88",
  saveToDesktop: "Añadir a la pantalla principal",
  downloadTitle: "descarga de la aplicación",
  normalDownload: "Descarga normal",
  scanningPrompt: "Instale con el código QR y obtenga un gran descuento exclusivo, ¡y disfrute de otros beneficios lujosos!",
  downloadFailToWeb: "Si no puedes descargar la APP, haz clic aquí para usar la versión web",
  scanQrCode: "Escanear código QR con teléfono móvil",
  usingMobileDevices: "usar movil",
  installTutorial: "Haga clic para ver el tutorial de instalación.",
  whyNameDifferent: "Los nombres de la APLICACIÓN instalada y la APLICACIÓN descargada son inconsistentes, ¡no dude en descargarlos, instalarlos y usarlos!",
  clickDownload: "haga clic para descargar",
  installTutorialPrompt: "Guía de instrucciones de instalación",
  brandNativeName: "APLICACIÓN nativa UFOScore",
  uploadingPrompt: "subiendo...",
  bHandicap: "Desventaja",
  different: "Diferencia",
  total: "total",
  off: "no ha comenzado",
  video: "video",
  animation: "animación",
  install: "Instalar",
  startDownloading: "Comienza a descargar",
  cancelGoal: "Gol anulado",
  appUpdatePrompt: "Hay una nueva versión de la aplicación, actualice",
};
