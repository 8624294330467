/**
 * @file 英语地区 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "User Agreement",
  privacyPolicy: "Privacy Policy",
  termsOfService: "Terms of Service",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "Offline",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "already broadcasting for",
  watch: "watch",
  follow: "Follow",
  following: "Following",
  subscribe: "Subscribe",
  streamerSchedule: "Streamer Schedule",
  noDataPrompt: "No data yet",
  unfollowPrompt: "Do you confirm to unfollow?",
  yes: "Yes",
  no: "No",
  logAndSign: "Log In/Sign Up now",
  hours: "hours",
  mins: "mins",
};

const AnchorInformation = {
  recommendedLiveStreams: "Recommended Live Streams",
  noDataPrompt: "No data yet",
};

const BasketballScoreIndex = {
  details: "Details",
  over: "Over",
  pointsDifference: "Score difference",
  totalScore: "Total Score",
  live: "LIVE",
  unSubscribePrompt: "Do you confirm to unsubscribe?",
  yes: "Yes",
  no: "No",
  logAndSign: "Log In/Sign Up now",
  InternetErrorPrompt: "Network Error",
};

const BasketballSmallBig = {
  details: "Details",
  over: "Over",
  pointsDifference: "Score difference",
  totalScore: "Total Score",
  live: "LIVE",
  unSubscribePrompt: "Do you confirm to unsubscribe?",
  yes: "Yes",
  no: "No",
  logAndSign: "Log In/Sign Up now",
  InternetErrorPrompt: "Network Error",
  overIndex: "O",
  underIndex: "U",
};

const BindPhoneDialog = {
  bindPhonePrompt:
    "For your account's security, please bind your phone number for better services.",
  goBind: "Go to binding",
};

const BottomNavigationBar = {
  home: "Home",
  matches: "Scores",
  recommend: "Tips",
  chat: "Chat",
  personal: "Personal",
  discovery: "Discover",
  money: "Money",
};

const ChatInterface = {
  noCsPrompt: "Unable to chat at the moment",
  uploadRulePrompt: "Please upload pictures in jpg, jpeg, png format",
  uploadSizePrompt: "Image size cannot exceed 10M",
  sendError: "Failed to send",
  picSendError: "Picture sending failed",
  InternetErrorPrompt: "Network Error",
  bannedSendPrompt: "You have been banned from speaking",
};

const ChatList = {
  noNewPrompt: "No news yet",
  logInPrompt: "Please log in to view content",
  logIn: "Login",
  group: "Group",
};

const ChatListItem = {
  withdrawMessage: "withdrawn a message",
  bannedPrompt: "is banned",
  unBanPrompt: "has been unbanned",
  joinGroupPrompt: "Joined the group",
  leftGroupPrompt: "Left the group",
  picture: "Picture",
  link: "Link",
  emoji: "Emoji",
  addedFriendPrompt: "has been added as your friend",
};

const CompetitionList = {
  logInPrompt: "Please log in to view content",
  logIn: "Login",
  today: "Today",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  noGames: "No games yet",
  toCollapse: "Click to collapse",
  toExpand: "Click to expand",
};

const CompetitionListItem = {
  details: "Details",
  unSubscribePrompt: "Do you confirm to unsubscribe?",
  yes: "Yes",
  no: "No",
  logAndSign: "Log In/Sign Up now",
  InternetErrorPrompt: "Network Error",
};

const CompetitionMode = {
  matchMode: "Match mode",
  confirm: "Confirm",
  cancel: "Cancel",
};

const EmojiCom = {
  logAndSign: "Log In/Sign Up now",
  yes: "Yes",
  no: "No",
};

const ExponentCom = {
  company: "Company",
  initial: "Initial",
  ongoing: "Now",
  preMatch: "Pre-match",
  noDataPrompt: "No data yet",
  handicap: "Handicap",
  WinDrawLose: "Win-Draw-Lose",
  totalGoals: "Total Goals",
  cornerKick: "Corner kick",
  pleaseSelect: "Please Select",
  confirm: "Confirm",
  cancel: "Cancel",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: 'Pinned to "Stream chat"',
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "Follow",
  following: "Following",
  chat: "Chat",
  noDataPrompt: "No data yet",
  unfollowPrompt: "Do you confirm to unfollow?",
  yes: "Yes",
  no: "No",
  InternetErrorPrompt: "Network Error",
};

const FootballCornerIndex = {
  details: "Details",
  over: "Over",
  FH: "HT",
  cornerKick: "CK",
  unSubscribePrompt: "Do you confirm to unsubscribe?",
  yes: "Yes",
  no: "No",
  logAndSign: "Log In/Sign Up now",
  InternetErrorPrompt: "Network Error",
};

const FootballScoreIndex = {
  details: "Details",
  over: "Over",
  FH: "HT",
  cornerKick: "CK",
  live: "LIVE",
  unSubscribePrompt: "Do you confirm to unsubscribe?",
  yes: "Yes",
  no: "No",
  logAndSign: "Log In/Sign Up now",
  InternetErrorPrompt: "Network Error",
};

const FootballScoreItem = {
  details: "Details",
  over: "Over",
  FH: "HT",
  cornerKick: "CK",
  live: "LIVE",
  unSubscribePrompt: "Do you confirm to unsubscribe?",
  yes: "Yes",
  no: "No",
  logAndSign: "Log In/Sign Up now",
  InternetErrorPrompt: "Network Error",
};

const GoalToast = {
  goal: "Goal",
};

const LiveRecommend = {
  noDataPrompt: "No data yet",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "Victory or Defeat",
};

const MatchCardList = {
  details: "Details",
};

const MatchStatus = {
  noDataPrompt: "No data yet",
};

const MoreStreams = {
  switchLines: "Switch lines",
  cancel: "Cancel",
  currentStreamer: "Current streamer",
  live: "LIVE",
  offLine: "Offline",
  followersCount: "Followers",
  otherStreamersPrompt: "Other streamers are currently broadcasting this match",
  watch: "watch",
};

const NewMatchStatus = {
  team: "Team",
  halfTimeScore: "Halftime score",
  shotOnTarget: "Shots on target",
  missedShot: "Shots off target",
  attack: "Attacks",
  dangerousAttack: "Dangerous attacks",
  number3PointGoals: "3-point field goals",
  number2PointGoals: "2-point field goals",
  penaltyKickGoals: "Free throws made",
  freeThrowPercentage: "Free throw percentage%",
  numberOfPausesRemaining: "Timeouts remaining",
  numberOfFouls: "Number of fouls",
  totalNumberOfPauses: "Total timeouts",
  threePointShot: "3-pointers",
  twoPointShot: "2-pointers",
  shoot: "Shots",
  penalty: "Free throws",
  firstHalf: "First half",
  secondHalf: "Second half",
  redCard: "Red card",
  yellowCard: "Yellow card",
};

const NoData = {
  noDataPrompt: "No data yet",
};

const NoticeMessage = {
  addedFriendPrompt: "has been added as your friend",
  withdrawMessage: "withdrawn a message",
  withdrawMessageByAdmin: "was withdrawn by the administrator",
  joinGroupPrompt: "Joined the group",
  leftGroupPrompt: "Left the group",
  removeGroupPrompt: "Removed from group by administrator",
  bannedPrompt: "is banned",
  unBanPrompt: "has been unbanned",
};

const OddsStatisticsList = {
  initial: "Initial",
  ongoing: "Now",
  preMatch: "Pre-match",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "Highest",
  min: "Lowest",
  average: "Average",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "No data yet",
};

const RecommendCompetitions = {
  today: "Today",
  winsInARow: "Wins in a Row",
  noLivePrompt: "No live yet",
};

const RecommendStreamerList = {
  noDataPrompt: "No data yet",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "Hit of the week",
  victoryOrDefeat: "Victory or Defeat",
  check: "Check",
  chat: "Chat",
  quarterlyProfit: "Quarterly Profit",
};

const RecommendStreamers = {
  recommendedByExperts: "Recommended by experts",
  winsInARow: "Wins in a Row",
  changeBatch: "Change a batch",
  follow: "Follow",
  following: "Following",
  unFollowing: "Unfollow",
  noDataPrompt: "No data yet",
  logAndSign: "Log In/Sign Up now",
  yes: "Yes",
  no: "No",
  InternetErrorPrompt: "Network Error",
  unfollowPrompt: "Do you confirm to unfollow?",
};

const RecordChart = {
  record: "Record",
  allWaysToPlay: "All ways to play",
  fromFarToNear: "From far to near",
  lastTenGames: "Last 10 games:",
  longestConsecutiveHit: "The longest consecutive hit",
  hitRate: "Hit Rate",
  currentPropmt: "recent matches",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "Please carefully select your country/region and language. This choice will affect the use of subsequent features and cannot be changed later",
  getUserInfoErrorPrompt: "Failed to obtain user information",
  getAreaAndLanguageErrorPrompt: "Failed to get regional language",
};

const ScheduleCom = {
  startTime: "Starting time",
};

const SchemeCard = {
  planList: "方案列表",
  release: "release",
  draw: "D",
  Win: "W",
  halfWin: "1/2W",
  lost: "L",
  halfLost: "1/2L",
  all: "All",
  guaranteedCompensation: "Guaranteed compensation",
  free: "Free",
  toll: "Toll",
  victoryOrDefeat: "Victory or Defeat",
};

const SquareCom = {
  watchPrompt: "people are watching",
  startedPrompt: "minutes ago,it started",
  chat: "Chat",
  sendMessageNeedLoginPrompt: "Please log in to send a message",
  assistant: "Admin",
  audience: "User",
  newMessage: "New message",
  confirmSend: "Confirm",
  cancelSend: "Cancel",
};

const StatisticalChart = {
  hitRate: "Hit Rate",
};

const SwitchLines = {
  switchLines: "Switch lines",
  cancel: "Cancel",
  currentStreamer: "Current streamer",
  live: "LIVE",
  offLine: "Offline",
  followersCount: "Followers",
  otherStreamersPrompt: "Other streamers are currently broadcasting this match",
  watch: "watch",
  LINE: "Line",
  VIP: "VIP",
  OFFICIAL: "Official",
};

const VideoCom = {
  videoErrorPrompt: "The signal is not very good. Please try again later",
  clickRetryPrompt: "Reload",
};

const WelcomeCard = {
  join: "join",
};

//utils
const tools = {
  yesterday: "Yesterday",
  addedFriendPrompt: "has been added as your friend",
  withdrawMessage: "withdrawn a message",
  joinGroupPrompt: "Joined the group",
  leftGroupPrompt: "Left the group",
  removeGroupPrompt: "Removed from group by administrator",
  bannedPrompt: "is banned",
  unBanPrompt: "has been unbanned",
};

//页面
const AddressBook = {
  search: "Search",
  addFriend: "Add friend",
  myGroupChat: "My group chat",
  friends: "Friends",
  delete: "Delete",
  noFriendsPrompt: "No friends yet",
  nameAndIdSearch: "Enter name or ID",
  searchResults: "Search results",
  notFoundPrompt: "User not found",
  add: "Add",
  deleteFriendPrompt: "Do you confirm to delete the friend?",
  yes: "Yes",
  no: "No",
  addFriendSuccessPrompt: "Add friend successfully",
  deleteFriendSuccessPrompt: "Delete friend successfully",
  InputSearchPrompt: "Please enter content",
};

const GroupChat = {
  groupChat: "Group chat",
  groupSearch: "Enter group name",
  noGroupPrompt: "No group chats yet",
  chat: "Chat",
  InputSearchPrompt: "Please enter content",
};

const GroupAdministrator = {
  administrator: "Administrator",
  groupOwner: "Group Owner",
};

const GroupAnnouncement = {
  groupAnnouncement: "Group Announcement",
  groupOwner: "Group Owner",
  administrator: "Administrator",
};

const GroupDetail = {
  groupDetail: "Group Detail",
  groupMembers: "Group Members",
  groupName: "Group Name",
  groupDescription: "Group Description",
  groupOwner: "Group Owner",
  administrator: "Administrator",
  administratorList: "Administrator List",
  groupAnnouncement: "Group Announcement",
  groupFiles: "Group Files",
  exitGroup: "Exit group",
  exitGroupPrompt: "Do you confirm to exit the group?",
  yes: "Yes",
  no: "No",
  leftGroupSuccessPrompt: "Exited the group successfully",
};

const GroupFile = {
  groupFiles: "Group Files",
  fileSearch: "Enter file name",
  today: "Today",
  multimedia: "Multimedia",
  file: "File",
  noFilesPrompt: "No files yet",
};

const GroupMembers = {
  groupMembers: "Group Members",
  nameAndIdSearch: "Enter name or ID",
};

const Chat = {
  chat: "Chat",
  nameAndIdSearch: "Enter name or ID",
  contacts: "Contacts",
  InputSearchPrompt: "Please enter content",
  deleteContactPrompt: "Do you confirm to delete the contact?",
};

const Competition = {
  all: "All",
  ongoing: "Now",
  popular: "Popular",
  liveCompetition: "Live",
  schedule: "Schedule",
  results: "Results",
  subscribed: "Subscribed",
  goal: "Goal",
  today: "Today",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
};

const CompetitionDetails = {
  subscribe: "Subscribe",
  subscribed: "Subscribed",
  matchStreamerPrompt: "Live match streamer",
  live: "LIVE",
  noDataPrompt: "No data yet",
  noStreamersPrompt: "No streamers broadcasting this match yet",
  alreadyStreamerPrompt:
    "A streamer is already broadcasting this match. Would you like to watch?",
  yes: "Yes",
  no: "No",
  liveStream: "Live streaming",
  matchStatus: "Match Status",
  odds: "Odds",
  unSubscribePrompt: "Do you confirm to unsubscribe?",
  logAndSign: "Log In/Sign Up now",
  InternetErrorPrompt: "Network Error",
};

const OddsDetails = {
  company: "Company",
  handicap: "Handicap",
  WinDrawLose: "Win-Draw-Lose",
  totalGoals: "Total Goals",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "Ov",
  Un: "Un",
};

const Home = {
  logIn: "Login",
  signUp: "Sign up",
  popularMatches: "Trending matches",
  more: "More",
  noDataPrompt: "No data yet",
  currentlyLive: "Currently Live",
  popularStreamers: "Popular streamers",
  thinkYouLike: "For you",
  loadMore: "Load More",
  noMoreDataPrompt: "No more data yet",
  addDeskPrompt: "Add to desktop",
  clikToolPrompt: "Click on the toolbar below",
  andSeletcPrompt: "And select",
  addToScreenPrompt:
    "We want to add to your home screen to provide better service",
  addToScreen: "Add to Home Screen",
};

const MoreLives = {
  noDataPrompt: "No data yet",
};

const MoreStreamers = {
  streamer: "Streamer",
  streamerSearch: "Enter the streamer name,ID",
  all: "All",
  notFollowing: "Not Following",
  following: "Following",
  live: "LIVE",
  offLine: "Offline",
  followersCount: "Followers",
  unfollowPrompt: "Do you confirm to unfollow?",
  yes: "Yes",
  no: "No",
  logAndSign: "Log In/Sign Up now",
  InternetErrorPrompt: "Network Error",
  InputSearchPrompt: "Please enter content",
  dataRequestErrorPrompt: "Failed to get data",
};

const LiveRoom = {
  switchLines: "Switch lines",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "Private chat",
  chat: "Chat",
  odds: "Odds",
  streamerInformation: "Profile",
  matchStatus: "Match Status",
};

const AnchorIntroduction = {
  streamerInformation: "Profile",
  photosWall: "Photos Wall",
  seeMore: "See More",
  streamerPlan: "Streamer Tips",
};

const AnchorPhoto = {
  photosWall: "Photos Wall",
  noPhotosPrompt: "No more photos yet",
};

const LogInRegister = {
  logIn: "Login",
  signUp: "Sign up",
  getAreaAndLanguageErrorPrompt: "Failed to get regional language",
  quickRegisterFrequentPrompt: "Frequent requests, please try again later",
};

const LoginFn = {
  phone: "Phone",
  email: "Email",
  logInPassword: "Password",
  rememberPassword: "Remember Password",
  forgotPassword: "Forgot Password",
  logIn: "Login",
  logInAgreement: "By logging in, you agree to the",
  userAgreement: "User Agreement",
  and: "and",
  privacyPolicy: "Privacy Policy",
  passwordRulePrompt:
    "Password must consist of uppercase and lowercase letters and numbers and must be greater than 8 characters",
  passwordEmptyPrompt: "The password cannot be empty",
  emailRulePrompt: "Please enter your email in the correct format",
  emailEmptyPrompt: "The email cannot be empty",
  phoneRulePrompt: "Please enter your phone in the correct format",
  phoneEmptyPrompt: "The phone cannot be empty",
  phoneNum8: "Please enter your 8-digit phone number",
  phoneNum9To10: "Please enter your 9 to 10 digit phone number",
  phoneNum9: "Please enter your 9-digit phone number",
  enterErrorPrompt: "Input error or network abnormality",
  loginNoAgreePrompt:
    "You need to agree to the User Agreement and Privacy Policy to log in",
  accountAndPassword: "Account",
  account: "Account",
  accountEmptyPrompt: "Account cannot be empty",
  accountOrPasswordError: "Incorrect account or password",
};

const Register = {
  phone: "Phone",
  email: "Email",
  code: "Code",
  getCode: "Get Code",
  logInPassword: "Password",
  CS: "CS",
  signUp: "Sign up",
  signUpAgreement: "By signing up, you agree to the",
  userAgreement: "User Agreement",
  and: "and",
  privacyPolicy: "Privacy Policy",
  contactCSPrompt: "Having trouble with signing up? Contact cs for assistance",
  contactCS: "Contact CS",
  passwordRulePrompt:
    "Password must consist of uppercase and lowercase letters and numbers and must be greater than 8 characters",
  passwordEmptyPrompt: "The password cannot be empty",
  emailRulePrompt: "Please enter your email in the correct format",
  emailEmptyPrompt: "The email cannot be empty",
  phoneRulePrompt: "Please enter your phone in the correct format",
  phoneEmptyPrompt: "The phone cannot be empty",
  codeRulePrompt: "The code is a 6-digit number",
  codeEmptyPrompt: "The code cannot be empty",
  phoneNum8: "Please enter your 8-digit phone number",
  phoneNum9To10: "Please enter your 9 to 10 digit phone number",
  phoneNum9: "Please enter your 9-digit phone number",
  phoneCodeSendSuccessPrompt:
    "Code sent successfully, please check the message on your phone",
  emailCodeSendSuccessPrompt:
    "Code sent successfully, please check the message on your email",
  phoneCodeSendErrorPrompt:
    "Code sending failed, please check if your phone is valid",
  emailCodeSendErrorPrompt:
    "Code sending failed, please check if your email is valid",
  codeSendToMuchPrompt:
    "Too many attempts made at the same time, please try again later",
  phoneAlreadySignupPrompt:
    "This phone is already signup, please log in directly",
  emailAlreadySignupPrompt:
    "This email is already signup, please log in directly",
  signUpErrorPrompt:
    "Signup failed, please check if the information is correct",
  enterErrorPrompt: "Input error or network abnormality",
  noAgreePrompt:
    "You need to agree to the User Agreement and Privacy Policy to sign up",
  regionAndLanguagePrompt:
    "Please select your actual region/language, otherwise it will affect the functionality",
  accountAndPassword: "Account",
  quickSignup: "One-click registration ",
  accountEmptyPrompt: "Account cannot be empty",
  accountRulePrompt: "Account number must consist of 8-16 letters and numbers",
  accountAlreadySignupPrompt:
    "This account has been registered, please log in directly",
  tryAgain: "Try again",
};

const PersonalCenter = {
  logAndSign: "Log In/Sign Up now",
  logAndSignPrompt:
    "Unlock exclusive features and special benefits immediately",
  logOut: "Log out",
  myFollow: "My subscriptions",
  mySubscription: "My subscriptions",
  accountSecurity: "Account security",
  feedback: "Feedback",
  deviceInformation: "Device",
  aboutUs: "About Us",
  coin: "Coin",
  moreServices: "More Services",
  following: "Following",
  subscribed: "Subscribed",
  browser: "Browser",
  user: "User",
  profile: "Profile",
  nickname: "nickname",
  save: "Save",
  change: "Change",
  nicknamePrompt: "Please enter your nickname",
  avatar: "Avatar",
  changeSuccessPrompt: "Change successful",
  ballAnimation: "Goal animation",
  ballVoice: "Goal sound effect",
  setting: "Setting",
};

const IntegralSystem = {
  Coin: "coin",
  goldCoinTask: "Coin Task",
  PointsDetails: "Points Details",
  myCoins: "My Coins",
  income: "income",
  expenditure: "expenditure",
  signInPrompt: "Sign in continuously and receive rewards continuously",
  toComplete: "to complete",
  toShare: "to share",
  shareTasks: "Share Tasks",
  exclusiveLink: "Exclusive link",
  copyLink: "Copy the link",
  highestWin: "highest win",
  day1: "Day 1",
  day2: "Day 2",
  day3: "Day 3",
  day4: "Day 4",
  day5: "Day 5",
  day6: "Day 6",
  day7: "Day 7",
  invitePrompt:
    "Surprise! Register and download our app now to enjoy exclusive benefits for new users! Free live streaming of matches, real-time data analysis, and professional commentator predictions—all at your fingertips. Don't wait any longer, click the link to join now!",
};

const Money = {
  activeCenter: "Activity Center",
  missionCenter: "Mission center",
};

const ActivityCenter = {
  toParticipate: "to participate",
  sportActivity: "Sports Activities",
};

const ActivityDetails = {
  eventDetails: "Event Details",
  dailyBetting: "Daily Betting",
  matchTime: "Match time",
  popularForEvent: "Popular options for this event",
  clickToParticipate: "Click to participate now",
  activityRules: "Activity Rules",
  clickToContactParticipate: "Click to contact customer service to participate",
  activityObj: "active object",
  activityPlatform: "Event platform",
  activityDuration: "Activity duration",
  activityDemand: "Activity requirements",
  activityTime: "Activity time",
};

const AboutUs = {
  aboutUs: "About Us",
  platformIntroduction: "Platform Introduction",
  privacyPolicy: "Privacy Policy",
  userAgreement: "User Agreement",
};

const DeviceInfo = {
  deviceInformation: "Device",
  currentTime: "Current Time",
  port: "Login Port",
  ip: "Login IP",
  version: "Current Version",
  ipAndPort: "Login Info",
};

const FeedBack = {
  feedback: "Feedback",
  feedbackType: "Feedback Type",
  productSuggestion: "Product Suggestion",
  functionalFault: "Functional Fault",
  interfaceSuggestion: "Interface Suggestion",
  accountIssue: "Account Issue",
  operationExperience: "Operation Experience",
  otherFeedback: "Other Feedback",
  problemDescription: "Problem Description",
  submit: "Submit",
  addPhoto: "Add Photo",
  uploadPrompt: "Sorry, only 3 photos can be uploaded",
  uploadRulePrompt: "Please upload pictures in jpg, jpeg, png format",
  uploadSizePrompt: "Image size cannot exceed 10M",
  chooseFeedback: "Please select feedback type",
  inputQuestionPrompt: "Please enter a question",
  submitSuccessPrompt: "Submitted successfully",
};

const MySubscription = {
  myFollow: "Followings",
  mySubscription: "Subscriptions",
  streamer: "Streamer",
  matches: "Matches",
};

const PrivacyAgreement = {
  privacyPolicy: "Privacy Policy",
};

const TermsofService = {
  termsOfService: "Terms of Service",
};

const UserAgreement = {
  userAgreement: "User Agreement",
};

const AccountSecurity = {
  accountSecurity: "Account security",
  phone: "Phone",
  email: "Email",
  bind: "Bind",
  notBound: "Not Bound",
  change: "Change",
  logInPassword: "Password",
  accountChangePassword: "Please bind your phone/email first",
};

const BindEmail = {
  bindEmail: "Bind Email",
  email: "Email",
  code: "Code",
  getCode: "Get Code",
  confirm: "Confirm",
  emailRulePrompt: "Please enter your email in the correct format",
  emailEmptyPrompt: "The email cannot be empty",
  codeRulePrompt: "The code is a 6-digit number",
  codeEmptyPrompt: "The code cannot be empty",
  changeSuccessPrompt: "Change successful",
  changeErrorPrompt: "Change failed",
  bindSuccessPrompt: "Binding successful",
  bindErrorPrompt: "Binding failed",
  emailCodeSendSuccessPrompt:
    "Code sent successfully, please check the message on your email",
  emailCodeSendErrorPrompt:
    "Code sending failed, please check if your email is valid",
  codeSendToMuchPrompt:
    "Too many attempts made at the same time, please try again later",
  emailAlreadySignupPrompt:
    "This email is already signup, please log in directly",
  tryAgain: "Try again",
};

const BindPhone = {
  bindPhone: "Bind Phone",
  phone: "Phone",
  code: "Code",
  getCode: "Get Code",
  confirm: "Confirm",
  cancel: "Cancel",
  phoneRulePrompt: "Please enter your phone in the correct format",
  phoneEmptyPrompt: "The phone cannot be empty",
  codeRulePrompt: "The code is a 6-digit number",
  codeEmptyPrompt: "The code cannot be empty",
  changeSuccessPrompt: "Change successful",
  changeErrorPrompt: "Change failed",
  bindSuccessPrompt: "Binding successful",
  bindErrorPrompt: "Binding failed",
  phoneNum8: "Please enter your 8-digit phone number",
  phoneNum9To10: "Please enter your 9 to 10 digit phone number",
  phoneNum9: "Please enter your 9-digit phone number",
  phoneCodeSendSuccessPrompt:
    "Code sent successfully, please check the message on your phone",
  phoneCodeSendErrorPrompt:
    "Code sending failed, please check if your phone is valid",
  codeSendToMuchPrompt:
    "Too many attempts made at the same time, please try again later",
  phoneAlreadySignupPrompt:
    "This phone is already signup, please log in directly",
  checkSignup: "Please check if the number is signed up",
  tryAgain: "Try again",
};

const ChangePassword = {
  resetPassword: "Reset Password",
  phone: "Phone",
  email: "Email",
  code: "Code",
  getCode: "Get Code",
  logInPassword: "Password",
  confirm: "Confirm",
  cancel: "Cancel",
  passwordRulePrompt:
    "Password must consist of uppercase and lowercase letters and numbers and must be greater than 8 characters",
  passwordEmptyPrompt: "The password cannot be empty",
  emailRulePrompt: "Please enter your email in the correct format",
  emailEmptyPrompt: "The email cannot be empty",
  phoneRulePrompt: "Please enter your phone in the correct format",
  phoneEmptyPrompt: "The phone cannot be empty",
  codeRulePrompt: "The code is a 6-digit number",
  codeEmptyPrompt: "The code cannot be empty",
  phoneNum8: "Please enter your 8-digit phone number",
  phoneNum9To10: "Please enter your 9 to 10 digit phone number",
  phoneNum9: "Please enter your 9-digit phone number",
  resetSuccess: "Password reset successful",
  resetError:
    "Password reset failed, please check if the information is correct",
  checkSignup: "Please check if the number is signed up",
  phoneCodeSendSuccessPrompt:
    "Code sent successfully, please check the message on your phone",
  codeSendToMuchPrompt:
    "Too many attempts made at the same time, please try again later",
  phoneAlreadySignupPrompt:
    "This phone is already signup, please log in directly",
  phoneCodeSendErrorPrompt:
    "Code sending failed, please check if your phone is valid",
  emailCodeSendSuccessPrompt:
    "Code sent successfully, please check the message on your email",
  emailCodeSendErrorPrompt:
    "Code sending failed, please check if your email is valid",
  emailAlreadySignupPrompt:
    "This email is already signup, please log in directly",
  bindPhoneOrEmail: "Please bind your mobile phone or email first",
  tryAgain: "Try again",
};

const QuickRegister = {
  downloadScreenshot: "Download screenshot",
  saveAccountAndPassword: "Please keep your account and password safe",
  suggestSave:
    "It is recommended that you take a screenshot and save it or copy it to a memo.",
  account: "Account",
  password: "Password",
  copyAccountAndPassword: "Copy account password",
  prompt: "Prompt",
  sureSave: "Have you saved your account password??",
  confirm: "Confirm",
  cancel: "Cancel",
  copySuccess: "Copy successfully",
  copyFailure: "Copy failed",
  confirmSave: "Have you already saved your account and password?",
  manualRegister:
    "Registration limit has been reached, please register manually",
};

const Recommend = {
  recommend: "Recommend",
  InternetErrorPrompt: "Network Error",
  hitOfTheWeek: "Hit of the week",
  victoryOrDefeat: "Victory or Defeat",
  check: "Check",
  lastTenGames: "Last 10 games:",
  recommendedByExperts: "Recommended by experts",
  changeBatch: "Change a batch",
  follow: "Follow",
  following: "Following",
  unFollowing: "Unfollow",
  all: "All",
  guaranteedCompensation: "Guaranteed compensation",
  free: "Free",
  toll: "Toll",
  HOT: "Hot",
  HIT: "Hit",
  STREAK: "Continuous Hit",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "Home Page",
  chat: "Chat",
  follow: "Follow",
  following: "Following",
  unFollowing: "Unfollow",
  followersCount: "Followers",
  planCount: "Plans",
  viewCount: "Viewers",
  statistics: "Statistics",
  plan: "Plan",
};

const SchemeDetails = {
  planDetails: "Plan Details",
  release: "release",
  chat: "Chat",
  follow: "Follow",
  following: "Following",
  unFollowing: "Unfollow",
  details: "Details",
  analyze: "Analyze",
  title: "Title",
  privateChatStreamerJoinsGroup: "Private chat Streamer joins group",
  upcoming: "Upcoming",
  othersPlan: "Others Tips",
};
const MatchInfo = {
  homeTag: "Home",
};
const LeagueSelection = {
  matchSorting: "Match sorting",
  byTime: "by time",
  byLeague: "by league",
  confirm: "Confirm",
  cancel: "Cancel",
};

const RecommendPublish = {
  enterTeam: "Enter team",
  planRelease: "Plan release",
  predictionTitle:
    "Title (visible to all users, please do not disclose prediction results)",
  titleEmptyPrompt: "The title and content of the analysis cannot be empty",
  pleaseSelectAtLeastOneGameToPredictResults:
    "Please select at least one game to predict results",
};

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "Football",
  basketball: "Basketball",
  mixed: "Mixed",
  setLogInPassword: "Setting login password",
  OCS: "OCS",
  OCSPrompt: "is at your service",
  phoneInCorrectPrompt: "Incorrect phone number",
  codeInCorrectPrompt: "Incorrect code",
  emailInCorrectPrompt: "Incorrect email",
  passwordPrompt:
    "Please enter a combination of 8-16 digits of English and numbers",
  newPassword: "Enter a new password",
  resetPasswordPrompt: "Do you confirm to reset the password?",
  startChatPrompt: "You can start chatting now",
  send: "Send",
  noFollowPrompt: "No followings yet",
  feedbackPrompt:
    "Please leave your valuable comments and we will work hard to improve!",
  bindPhoneByOtherPrompt: "This number has been bound by another user",
  bindEmailByOtherPrompt: "This email has been bound by another user",
  super_manager: "Super admin",
  score: "score",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "HT",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "Q1 End",
  endOfSecondQuarter: "Q2 End",
  endOfThirdQuarter: "Q3 End",
  endOfFourthQuarter: "Q4 End",
  pullUpToLoadMore: "Pull up to load more",
  pullDownToRefresh: "Pull Down To Refresh",
  releaseToRefreshImmediately: "Release to refresh immediately",
  refreshPrompt: "Refreshing...",
  download: "Download",
  appDownload: "APP download",
  brandName: "OV88",
  saveToDesktop: "Add To Home Screen",
  downloadTitle: "APP download",
  normalDownload: "Ordinary Download",
  scanningPrompt: "Scan to install and receive exclusive large discounts, with more luxurious benefits waiting for you!",
  downloadFailToWeb: "Click here to use the web version",
  scanQrCode: "Scan QR code on mobile phone",
  usingMobileDevices: "Using mobile devices",
  installTutorial: "Click to view installation tutorial",
  whyNameDifferent:
    "The names of the installed APP and downloaded APP are inconsistent, please feel free to download, install and use it!",
  clickDownload: "Click to Download",
  installTutorialPrompt: "Installation Guide",
  brandNativeName: "UFOScore Native APP",
  uploadingPrompt: "Uploading...",
  bHandicap: "Point Spread",
  different: "different",
  total: "total",
  off: "Upcoming",
  video: "Video",
  animation: "Animation",
  install: "Install",
  startDownloading: "Start Download",
  cancelGoal: "Goal disallowed",
  appUpdatePrompt:
    "The app has been updated, please download the latest version",
};
