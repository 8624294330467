<template>
  <!-- 让球和总进球 -->
  <div
    class="IndexSkeleton"
    v-if="mode === 'mode1'"
    :class="{ mode1: mode === 'mode1' }"
  >
    <!-- 公司 -->
    <div class="company"><el-skeleton-item variant="p" /></div>
    <!-- 初始 -->
    <div class="initial">
      <!-- 主队 -->
      <span class="homeTeam number"><el-skeleton-item variant="p" /></span>
      <!-- 走势 -->
      <span class="trend number"><el-skeleton-item variant="p" /></span>
      <!-- 客队 -->
      <span class="awayTeam number"><el-skeleton-item variant="p" /></span>
    </div>

    <div class="preMatch">
      <!-- 主队 -->
      <span class="homeTeam number"><el-skeleton-item variant="p" /></span>
      <!-- 走势 -->
      <span class="trend number"><el-skeleton-item variant="p" /></span>

      <!-- 客队 -->
      <span class="awayTeam number"><el-skeleton-item variant="p" /></span>
    </div>
    <div class="icon"><el-skeleton-item variant="p" /></div>
  </div>
  <!-- 足球的胜平负 -->
  <div
    class="IndexSkeleton"
    v-else-if="mode === 'mode2' || mode === 'mode3'"
    :class="{ mode2: mode === 'mode2', mode3: mode === 'mode3' }"
  >
    <div class="OddsStatisticsList">
      <!-- 侧边栏 -->
      <div class="sideBar">
        <div
          class="sideBarTitle"
          :class="{ 'bgc-1c1a32': index % 2 === 0 }"
          v-for="(title, index) in statisticsListSideBar"
          :key="index"
        >
          {{ $t(title) }}
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="content">
        <!-- 标题栏 -->
        <div class="contentTitleBar">
          <div
            class="title"
            v-for="(title, index) in winDrawLoseTitles"
            :key="index"
          >
            {{ $t(title) }}
          </div>
        </div>
        <!-- 指数内容 -->
        <div class="oddsContent">
          <!-- 统计项 -->
          <div
            class="oddsContentItem"
            :class="{ 'bgc-1c1a32': index % 2 === 0 }"
            v-for="(item, index) in statisticsListSideBar"
            :key="index"
          >
            <!-- 初始 -->
            <div class="initial moment">
              <span class="label spanItem">{{ $t("initial") }}</span>
              <span class="oddsNum spanItem"
                ><el-skeleton-item variant="p"
              /></span>
              <span class="oddsNum spanItem"
                ><el-skeleton-item variant="p"
              /></span>
              <span class="oddsNum spanItem" v-if="mode === 'mode2'"
                ><el-skeleton-item variant="p"
              /></span>
            </div>
            <!-- 赛前 -->
            <div class="immediately moment">
              <span class="label spanItem">{{ $t("preMatch") }}</span>
              <span class="oddsNum spanItem"
                ><el-skeleton-item variant="p"
              /></span>
              <span class="oddsNum spanItem"
                ><el-skeleton-item variant="p"
              /></span>
              <span class="oddsNum spanItem" v-if="mode === 'mode2'"
                ><el-skeleton-item variant="p"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公司列表 -->
    <div class="companyList">
      <!-- 侧边栏 -->
      <div class="sideBar">
        <div class="company">{{ $t("company") }}</div>
        <div class="sideBarTitle bgc-1c1a32">
          <el-skeleton-item variant="p" />
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="content">
        <!-- 标题栏 -->
        <div class="contentTitleBar">
          <div class="title picker">
            <div class="pickerTitle">
              {{ $t("preMatch") }}
            </div>
          </div>
          <div
            class="title"
            v-for="(title, index) in winDrawLoseTitles"
            :key="index"
          >
            {{ $t(title) }}
          </div>
        </div>
        <!-- 指数内容 -->
        <div class="oddsContent">
          <!-- 公司项 -->
          <div class="oddsContentItem bgc-1c1a32">
            <!-- 初始 -->
            <div class="initial moment">
              <span class="label spanItem">{{ $t("initial") }}</span>
              <!-- 主胜 -->
              <span class="homeTeam spanItem"
                ><el-skeleton-item variant="p"
              /></span>
              <!-- 平局 -->
              <span class="trend spanItem"
                ><el-skeleton-item variant="p"
              /></span>
              <!-- 客胜 -->
              <span class="awayTeam spanItem" v-if="mode === 'mode2'"
                ><el-skeleton-item variant="p"
              /></span>
            </div>

            <div class="preMatch moment">
              <span class="label spanItem">{{ $t("preMatch") }}</span>
              <!-- 主胜 -->
              <span class="homeTeam spanItem"
                ><el-skeleton-item variant="p"
              /></span>
              <!-- 平局 -->
              <span class="trend spanItem"
                ><el-skeleton-item variant="p"
              /></span>
              <!-- 客胜 -->
              <span class="awayTeam spanItem" v-if="mode === 'mode2'"
                ><el-skeleton-item variant="p"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  computed: {
    winDrawLoseTitles() {
      if (this.mode === "mode2") {
        // 足球的胜平负
        return ["HW", "Dr", "AW"];
      }
      if (this.mode === "mode3") {
        // 篮球的胜负
        return ["AW", "HW"];
      }
      return ["HW", "Dr", "AW"];
    },
  },
  data() {
    return {
      statisticsListSideBar: ["max", "min", "average"], // 统计数据列表的侧边栏: [最高值, 最低值, 平均值]
    };
  },
};
</script>

<style lang="scss" scoped>
.IndexSkeleton.mode1 {
  width: 100%;
  height: 41px;
  background: var(--theme-box-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;

  .company {
    width: 93px;
    padding-left: 10px;
    height: 16px;
  }

  .initial {
    width: 104px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 16px;

    .number {
      width: 26px;
    }
  }

  .preMatch {
    width: 118px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 16px;
  }

  .number {
    width: 30px;
    height: 16px;
  }

  .icon {
    width: 5px;
    height: 16px;

    .el-skeleton__p {
      height: 16px;
    }
  }
}

.IndexSkeleton.mode2,
.IndexSkeleton.mode3 {
  .OddsStatisticsList {
    display: flex;
    .bgc-1c1a32 {
      background-color: var(--theme-box-1);
    }
  }

  // 侧边栏
  .sideBar {
    flex: 0 0 87px;
    box-sizing: border-box;
    padding-top: 27px;

    .sideBarTitle {
      height: 56px;
      line-height: 56px;
      // 字体
      font-size: 9px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: var(--theme-main-word);
    }
  }

  // 内容区域
  .content {
    flex: 1;

    .contentTitleBar {
      height: 27px;
      box-sizing: border-box;
      padding-left: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        flex: 1;
        text-align: center;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: var(--theme-main-word);
      }
    }

    .oddsContent {
      .oddsContentItem {
        height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .moment {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // 字体
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: center;
          color: var(--theme-main-word);

          .label.spanItem {
            flex: 0 0 72px;
            flex-grow: 0;
          }

          .spanItem {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            .el-skeleton__p {
              width: 60%;
            }
          }
        }
      }
    }
  }

  .companyList {
    display: flex;
    .bgc-1c1a32 {
      background-color: var(--theme-box-1);
    }
    .sideBar {
      padding: 0;
      .company {
        height: 27px;
        line-height: 27px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: var(--theme-grey-word);
      }

      .sideBarTitle {
        display: flex;
        align-items: center;
        justify-content: center;

        .el-skeleton__p {
          width: 66%;
        }
      }
    }

    .content {
      flex: 1;
      .contentTitleBar {
        padding-left: 0;
        .title {
          flex: 1;
          text-align: center;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: var(--theme-main-word);
        }
        .picker {
          flex: 0 0 72px;
          display: flex;
          align-items: center;
          justify-content: center;
          .pickerTitle {
            display: flex;
          }
          .icon-arrow-down-grey {
            background-image: url("@/assets/icons/Common/icon-arrow-down-grey.svg");
            width: 9px;
            height: 5px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-left: 3px;
          }
        }
      }
    }
  }
}
</style>
