import request from "@/services/axios/commonRequest";
import requestV2 from "@/services/axios/commonRequestV2/index";
// import QS from "qs"; //数据序列化

// 重构迁移
// 查询直播
export function liveQuery(data) {
    return requestV2({
        url: '/lives/' + data.live_id,
        method: 'get',
        params: {
            live_id:data.live_id,
            country_id: data.country_id,
            language_id:data.language_id
        }
    })
}

//主播查询
export function anchorQuery(data) {
  return requestV2({
    url: "/streamers/" + data.streamer_username,
    method: "get",
    params: {
     ...data,
    },
  });
}

// 查询主播照片墙
export function getStreamerPhotos(streamer_username, params) {
  return requestV2({
    url: "/streamers/" + streamer_username + "/photos",
    method: "get",
    params: params
  });
}

/**
 * 直播间广场-置顶卡片信息
 */
export function getSquareTopCardInfo(params, live_id) {
  return requestV2({
    url: "/live-chat-top-promotion/" + live_id,
    method: "get",
    params,
  });
}
/**
 * 直播间私聊信息
 */
export function getPrivateChatInfo(live_id) {
  return request({
    url: `/lives/${live_id}/chat-assistant`,
    method: "get",
  });
}
/**
 * 获取方案私聊助手
 */
export function getStreamerAssistant(streamer_username) {
  return request({
    url: `/streamers/${streamer_username}/chat-assistant`,
    method: "get",
    params:{
      streamer_username
    }
  });
}
// 获取直播间直播线路
export function getLiveLine(live_id) {
  return requestV2({
    url: `/lives/${live_id}/live-lines`,
    method: "get",
    params: {live_id}
  });
}