/* eslint-disable */
import {
  getTasks,
  triggerTask,
  myShareCode,
  myAssets,
  getActivityList,
} from "@/services/api/Tasks";
import { checkClientTokenRole } from "@/utils/authCookie";
import {
  addClickListeners,
  watchRoute,
  removeClickListeners,
} from "@/utils/tools";
import { EventBus } from "@/utils/event-bus";
import { getTimeZoneOffset } from "@/utils/timezoneOffset";

const state = {
  tasks: [], // 任务列表
  share: {}, // 分享列表
  share_code: null, // 专属分享链接的身份码
  myGold: null, // 我的资产-金币
  myScore: null, // 我的资产-积分
  activityList: {
    0: [], // 全部
    1: [], // 体育活动
  },
  clickTaskArray: [],
  pathTaskObj: {},
};

const mutations = {
  // 设置任务列表
  SET_TASKS(state, { tasks, share }) {
    state.tasks = tasks;
    state.share = share;
  },

  // 设置分享码
  SET_SHARE_CODE(state, { share_code }) {
    state.share_code = share_code;
  },

  // 设置资产-金币
  SET_GOLD(state, { myGold }) {
    state.myGold = myGold;
  },

  // 设置资产-积分
  SET_SCORE(state, { myScore }) {
    state.myScore = myScore;
  },

  // 设置活动列表
  SET_ACTIVITYLIST(state, { key, value }) {
    state.activityList[key] = value;
  },

  // 设置点击类活动
  SET_CLICKTASKARRAY(state, { clickTaskArray }) {
    state.clickTaskArray = clickTaskArray;
  },

  // 设置页面类活动
  SET_PATHTASKOBJ(state, { pathTaskObj }) {
    state.pathTaskObj = pathTaskObj;
  },
};

const actions = {
  //  获取任务列表
  async getTaskList(
    { commit },
    { vm, language_id, country_id, utc_offset, monitor = true }
  ) {
    try {
      const res = await getTasks({ language_id, country_id, utc_offset });
      const { statusCode, data } = res;
      let pathTaskObj = {};
      let clickTaskArray = [];
      if (statusCode === 200) {
        console.log(data);
        const { gold, share } = data;
        commit("SET_TASKS", {
          tasks: gold.toReversed(),
          share: share,
        });
        gold.forEach((item) => {
          if (item.type === "surf") {
            // 页面(路由访问)类任务
            pathTaskObj[item.route] = {
              ...item,
              callback: async (data = {}) => {
                await triggerTask({
                  type: item.type,
                  code: item.code,
                  data,
                  utc_offset: getTimeZoneOffset(), // 时区偏移（单位：分钟）。-720(-12:00) ~ +840(+14:00)
                });
              },
            };
            commit("SET_PATHTASKOBJ", { pathTaskObj });
          } else if (item.type === "click") {
            // 按钮点击类任务
            clickTaskArray.push({
              ...item,
              callback: async (data = {}) => {
                await triggerTask({
                  type: item.type,
                  code: item.code,
                  data,
                  utc_offset: getTimeZoneOffset(), // 时区偏移（单位：分钟）。-720(-12:00) ~ +840(+14:00)
                });
              },
            });
            commit("SET_CLICKTASKARRAY", { clickTaskArray });
          }
        });
        console.log({ gold, pathTaskObj, clickTaskArray });
        if (checkClientTokenRole() === "user" && monitor) {
          console.log("监听任务");
          removeClickListeners();
          addClickListeners();
          // watchRoute(vm, pathTaskObj);
          EventBus.$emit("updatePathObj", pathTaskObj);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  },

  /**
   * 触发埋点 上报任务完成情况
   */
  async triggerTask({ commit }, { params }) {
    try {
      const res = await triggerTask(params);
      const { statusCode, data } = res;
      if (statusCode === 200) {
        console.log("data", data);
      }
    } catch (error) {
      console.error(`触发任务出错:`, error);
    }
  },

  /**
   * 获取专属分享链接的身份码
   */
  async getMyShareCode({ commit }) {
    try {
      const res = await myShareCode();
      const { statusCode, data } = res;
      if (statusCode === 200) {
        const shareCodeObj = {};
        data.share_code.forEach((obj) => {
          shareCodeObj[obj.channel] = obj.share_code;
        });
        commit("SET_SHARE_CODE", {
          share_code: shareCodeObj,
        });
      }
    } catch (error) {
      console.error(`触发任务出错:`, error);
    }
  },

  /**
   * 获取个人总资产数据
   */
  async getMyAsset({ commit }) {
    try {
      const res = await myAssets();
      if (res.statusCode === 200) {
        console.log("加载个人总资产数据", res);
        const { gold, score } = res.data;
        commit("SET_GOLD", {
          myGold: gold,
        });
        commit("SET_SCORE", {
          myScore: score,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * 请求活动列表数据
   */
  async fetchActivityList(
    { commit },
    { country_id, language_id, category_id }
  ) {
    try {
      const res = await getActivityList({
        country_id,
        language_id,
        category_id: category_id === 0 ? undefined : category_id,
      });
      const { statusCode, data } = res;
      if (statusCode === 200) {
        console.log("请求活动列表数据", data);
        commit("SET_ACTIVITYLIST", {
          key: category_id,
          value: data.items,
        });
      }
    } catch (error) {
      console.error(`请求活动列表数据:`, error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
