import request from "@/services/axios/commonRequest";
import requestV2 from "@/services/axios/commonRequestV2/index";
/* eslint-disable */
// 获取比赛列表
export function getCompetition(params, isTimer) {
  const resquestdata = {
    url: "/competitions",
    method: "get",
    params: params,
  };
  //FIXME: 即时和直播比赛列表请求超时时间
  // if(isTimer) resquestdata.timeout = 1000;
  return request(resquestdata);
}

// // 订阅比赛
// export function subscribeCompetition(competition_id) {
//   return request({
//     url: "/subscribe-competition",
//     method: "post",
//     data: { competition_id },
//   });
// }

// // 取消订阅比赛
// export function unsubscribeCompetition(competition_id) {
//   return request({
//     url: "/unsubscribe-competition",
//     method: "post",
//     data: { competition_id },
//   });
// }

// 体育类型列表
export function getSportTypes(language_id) {
  return requestV2({
    url: "/matches/sport-types",
    method: "get",
    params: { language_id },
  });
}

// 获取指定比赛的赛事详情数据
export function getCompetitionDetails(
  competition_id,
  country_id,
  language_id,
  isTimer
) {
  const resquestdata = {
    url: "/competitions/" + competition_id,
    method: "get",
    params: { country_id, language_id },
  };
  // if(isTimer) resquestdata.timeout = 3000;
  return request(resquestdata);
}

// 获取指定比赛的赛况数据
export function getCompetitionStatus(competition_id, language_id) {
  const resquestdata = {
    url: "/competition-status/" + competition_id,
    method: "get",
    params: { language_id },
  };
  // if(isTimer) resquestdata.timeout = 3000;
  return request(resquestdata);
}

// 获取指数二级菜单数据（足球）
export function getFootballOddsHistory(competition_id, isTimer) {
  const resquestdata = {
    url: "/football/odds/history",
    method: "get",
    params: { competition_id },
  };
  // if(isTimer) resquestdata.timeout = 3000;
  return request(resquestdata);
}

// 获取指数二级菜单数据（足球）
export function getBasketballOddsHistory(competition_id, isTimer) {
  const resquestdata = {
    url: "/basketball/odds/history",
    method: "get",
    params: { competition_id },
  };
  // if(isTimer) resquestdata.timeout = 3000;
  return request(resquestdata);
}

// 获取足球公司
export function getFootballOddsCompanies(params, cancelToken) {
  const resquestdata = {
    url: "/football/odds/odds_companies",
    method: "get",
    params,
    cancelToken
  };
  // if(isTimer) resquestdata.timeout = 3000;
  return request(resquestdata);
}
// 获取足球指数
export function getFootballOdds(params) {
  const resquestdata = {
    url: "/football/odds",
    method: "get",
    params,
  };
  // if(isTimer) resquestdata.timeout = 3000;
  return request(resquestdata);
}

// 获取篮球公司
export function getBasketballOddsCompanies(params, cancelToken) {
  const resquestdata = {
    url: "/basketball/odds/odds_companies",
    method: "get",
    params,
    cancelToken
  };
  // if(isTimer) resquestdata.timeout = 3000;
  return request(resquestdata);
}
// 获取篮球指数
export function getBasketballOdds(params) {
  const resquestdata = {
    url: "/basketball/odds",
    method: "get",
    params,
  };
  // if(isTimer) resquestdata.timeout = 3000;
  return request(resquestdata);
}

// 获取足球比赛
export function getFootballCompetitions(type, params) {
  return requestV2({
    url: `/matches/football-matches/${type}`,
    method: "get",
    params,
  });
}
// 获取足球比赛详情
export function getFootballCompetitionDetails(match_id, params) {
  return requestV2({
    url: `/matches/football-matches/${match_id}`,
    method: "get",
    params,
  });
}

// 获取篮球比赛
export function getBasketballCompetitions(type, params) {
  return requestV2({
    url: `/matches/basketball-matches/${type}`,
    method: "get",
    params,
  });
}
// 获取篮球比赛详情
export function getBasketballCompetitionDetails(match_id, params) {
  return requestV2({
    url: `/matches/basketball-matches/${match_id}`,
    method: "get",
    params,
  });
}

// 获取热门比赛
export function getPopularCompetitions(params) {
  return requestV2({
    url: `/matches/popular`,
    method: "get",
    params,
  });
}
// 订阅比赛
export function subscribeCompetition(competition_id) {
  return requestV2({
    url: "/matches/subscribe-match",
    method: "post",
    data: { match_id: competition_id },
  });
}
// 取消订阅比赛
export function unsubscribeCompetition(competition_id) {
  return requestV2({
    url: "/matches/unsubscribe-match",
    method: "post",
    data: { match_id: competition_id },
  });
}
// 获取足球实时分数和时间
export function getFootballLiveScore(match_id) {
  return requestV2({
    url: `/matches/football-matches/live/${match_id}`,
    method: "get",
    params: { match_id },
  });
}
// 获取篮球实时分数和时间
export function getBasketballLiveScore(match_id) {
  return requestV2({
    url: `/matches/basketball-matches/live/${match_id}`,
    method: "get",
    params: { match_id },
  });
}
// 获取足球实时stats
export function getFootballLiveStats({ match_id, language_id }) {
  return requestV2({
    url: `/matches/football-matches/stats/${match_id}`,
    method: "get",
    params: { match_id, language_id },
  });
}
// 获取篮球实时stats
export function getBasketballLiveStats({ match_id, language_id }) {
  return requestV2({
    url: `/matches/basketball-matches/stats/${match_id}`,
    method: "get",
    params: { match_id, language_id },
  });
}
// 订阅比赛列表
export function getSubscribeCompetitionList() {
  return requestV2({
    url: "/matches/subscribe-matches",
    method: "get",
  });
}