import { getAboutUs } from '@/services/api/PersonalCenter';
const state = {
  userAgreement:'',
  privacyAgreement: '',
  termsofService:''
};

const mutations = {
 /**设置 userAgreement*/
 SET_INFORMATION(state, data) {
    state.userAgreement = data.user_agreement;
    state.privacyAgreement = data.privacy_agreement;
    state.termsofService = data.terms_of_service
  },

};

const actions = {
  /**
   * 获取关于我们的信息，并提交到 state。
   * @param {Object} payload - 传递给 API 的参数对象。
   */
  getInformation({ commit }, payload) {
    const {language_id, callback} = payload || {};
    getAboutUs({language_id}).then((res) => {
      const {statusCode, statusCodeText, data} = res;
      if (statusCode === 200) {
        commit('SET_INFORMATION', data);
        callback?.(data);
      } else {
        console.log(statusCodeText);
      }
    }).catch((err) => {
      console.log(err);
  });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
