import dayjs from "dayjs";
import taskBeacon from "@/store/modules/taskBeacon";
import { debounce } from "lodash";
import store from "@/store";

// 国际化
// import Vue from 'vue';

// 将 UTF-8 编码的 JavaScript 转义序列转换为 UTF-16 编码的 JavaScript 转义序列
export function utf8EscapesToUtf16Escapes(utf8Escapes) {
  const hexes = utf8Escapes.split("\\x").slice(1); // 去掉第一个空字符串
  const utf8Bytes = hexes.map((hex) => parseInt(hex, 16));
  const charCode =
    ((utf8Bytes[0] & 0x07) << 18) |
    ((utf8Bytes[1] & 0x3f) << 12) |
    ((utf8Bytes[2] & 0x3f) << 6) |
    (utf8Bytes[3] & 0x3f);
  const highSurrogate = Math.floor((charCode - 0x10000) / 0x400) + 0xd800;
  const lowSurrogate = ((charCode - 0x10000) % 0x400) + 0xdc00;
  return String.fromCodePoint(highSurrogate, lowSurrogate);
}

// 将 UTF-16 编码的 JavaScript 转义序列转换为 UTF-8 编码的 JavaScript 转义序列
export function utf16EscapesToUtf8Escapes(utf16Escapes) {
  const hexes = utf16Escapes.split("\\u").slice(1); // 去掉第一个空字符串
  const charCode =
    (parseInt(hexes[0], 16) - 0xd800) * 0x400 +
    parseInt(hexes[1], 16) -
    0xdc00 +
    0x10000;
  return (
    "\\x" +
    ((charCode >> 18) | 0xf0).toString(16) +
    "\\x" +
    (((charCode >> 12) & 0x3f) | 0x80).toString(16) +
    "\\x" +
    (((charCode >> 6) & 0x3f) | 0x80).toString(16) +
    "\\x" +
    ((charCode & 0x3f) | 0x80).toString(16)
  );
}

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
//daj.js格式化消息时间显示格式,传入时间戳,返回格式化后的字符串，本周内显示星期几和时间，超过本周显示日期和时间，超过一年显示年月日
export function formatDateText(time) {
  if (!time) return "";
  // day.js 计算时间差
  const now = dayjs();
  const diff = Math.abs(dayjs(time).diff(now, "day"));
  let dateText = "";
  if (diff <= 1 && dayjs(time).day() !== dayjs().day()) {
    dateText = "yesterday";
  } else if (diff > 1 && diff < 7) {
    // dayjs().day() 获取当前星期几
    const dayOfWeek = dayjs(time).day();
    const weekdayText = daysOfWeek[dayOfWeek];
    dateText = weekdayText;
  } else if (diff >= 7 && diff <= 365) {
    // 是否是今年
    if (dayjs(time).year() === dayjs().year()) {
      dateText = dayjs(time).format("MM/DD");
    } else {
      dateText = dayjs(time).format("YYYY/MM/DD");
    }
  } else if (diff > 365) {
    dateText = dayjs(time).format("YYYY/MM/DD");
  }
  return dateText;
}
// 格式化消息时间显示格式,传入时间戳,返回格式化后的字符串，本周内显示星期几和时间，超过本周显示日期和时间，超过一年显示年月日
export function formatTimeText(time) {
  if (!time) return "";
  // day.js 计算时间差
  const now = dayjs();
  const diff = Math.abs(dayjs(time).diff(now, "day"));
  let timeText = "";
  if (diff < 365) {
    timeText = " " + dayjs(time).format("HH:mm");
  }
  return timeText;
}

// 消息内容渲染
export function renderMessage(content) {
  if (content?.length === 0) return "";
  let text = "";
  content?.map((item) => {
    const { type, data } = item;
    switch (type) {
      case "text":
        text += data?.text || "";
        break;
      case "face":
        text += data?.code;
        break;
      case "friend_add":
        text = data?.target_id + " 已经是你的好友了";
        break;
      case "friend_recall":
        text += data?.user_id + " 撤回了一条消息";
        break;
      case "group_recall":
        text += data?.user_id + " 撤回了一条消息";
        break;
      case "group_increase":
        text += data?.user_id + " 加入了群组";
        break;
      case "group_decrease":
        text += data?.operator_id
          ? `${data.user_id} 被 ${data.operator_id} 移出了群组`
          : data?.user_id + " 退出了群组";
        break;
      case "group_ban":
        text +=
          data?.sub_type === "ban"
            ? `${data.user_id} 被禁言了`
            : `${data.user_id} 被解除禁言了`;
        break;
      default:
        break;
    }
  });
  return text;
}
// 检查系统版本
export function checkSystemVersion() {
  fetch(`/version.json?time=${new Date()}`)
    .then((response) => response.json())
    .then((data) => {
      if (
        localStorage.getItem("webClientDevice") === "Android" &&
        data.APKVersion !== process.env.VUE_APP_APK_VERSION
      ) {
        return;
      }
      if (process.env.VUE_APP_VERSION !== data.version && data.refresh) {
        window.location.reload();
      }
    })
    .catch((error) => console.error("Error:", error));
}
// 检查APK版本
export function checkAPKVersion(resolve) {
  fetch(`/version.json?time=${new Date()}`)
    .then((response) => response.json())
    .then((data) => {
      resolve(data);
    })
    .catch((error) => console.error("Error:", error));
}
// 获取公钥
export function getPublicKey(resolve) {
  return fetch(`/public_key.pem?time=${new Date()}`)
    .then((response) => response.text())
    .then((data) => {
      resolve(data);
    })
    .catch((error) => console.error("Error:", error));
}
// 格式化数据
function parseData(data) {
  const lines = data.split("\n");
  const result = {};
  lines.forEach((line) => {
    const [key, value] = line.split("=");
    result[key] = value;
  });
  return result;
}
// 获取ip地区是否开放
export function judegeArea(resolve) {
  return fetch(`https://cloudflare.com/cdn-cgi/trace`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    })
    .then((data) => {
      const result = parseData(data);
      resolve && resolve(result);
      return result;
    })
    .catch((error) => console.error("Error:", error));
}
// 限制访问
export async function forbidden() {
  if (process.env.VUE_APP_ACCESSS_LINK === "true") {
    try {
      const response = await fetch("https://cloudflare.com/cdn-cgi/trace");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.text();
      const result = parseData(data);
      if (result.loc === "CN") {
        const VUE_APP_BRAND = process.env.VUE_APP_BRAND;
        let domain = "";
        if (VUE_APP_BRAND === "OV88") {
          domain = "ov88";
        }
        if (VUE_APP_BRAND === "UFOScore") {
          domain = "ufoscore";
        }
        // console.log("https://" + domain + ".com/forbidden.html");
        window.location.replace("https://" + domain + ".com/forbidden.html");
        return true;
      }
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  }
}
// 生成随机字符串
export function generateRandomString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
//  获取系统信息

export function getSystemInfo() {
  let systemInfo = "";
  let browserInfo = "";
  // 获取移动设备信息，判断是否为h5
  const userAgent = navigator.userAgent;
  const matches = userAgent.match(/(Android|iPhone|iPod|iPad|Windows Phone)/);
  const deviceModel = matches ? matches[0] : "PC";

  const osVersion = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/) || [0, 0, 0];
  const osVersionString = `${osVersion[1] || "0"}.${osVersion[2] || "0"}.${
    osVersion[3] || "0"
  }`;
  // 获取浏览器信息
  const browserInfoObj = (() => {
    const ua = navigator.userAgent;
    let tem;
    let result = {};
    // 匹配浏览器类型和版本
    if ((tem = ua.match(/Edge\/([\d.]+)/))) {
      result.browser = "Edge";
      result.version = tem[1];
    } else if ((tem = ua.match(/(Chrome|CriOS)\/([\d.]+)/))) {
      result.browser = "Chrome";
      result.version = tem[2];
    } else if ((tem = ua.match(/Firefox\/([\d.]+)/))) {
      result.browser = "Firefox";
      result.version = tem[1];
    } else if ((tem = ua.match(/Safari\/([\d.]+)/))) {
      result.browser = "Safari";
      result.version = tem[1];
    } else if (
      (tem = ua.match(/MSIE ([\d.]+)/)) ||
      (tem = ua.match(/Trident.*rv:([\d.]+)/))
    ) {
      result.browser = "IE";
      result.version = tem[1];
    } else {
      result.browser = "Unknown";
      result.version = "Unknown";
    }

    return result;
  })();
  const device = localStorage.getItem("webClientDevice");
  if (device) {
    // 路由判断
    systemInfo = deviceModel + " " + osVersionString;
    browserInfo =
      browserInfoObj.browser + " " + browserInfoObj.version + "/" + device;
  } else if (window.navigator.standalone === true) {
    // webClip判断
    const webClip = "webClip";
    systemInfo = deviceModel + " " + osVersionString;
    browserInfo =
      browserInfoObj.browser + " " + browserInfoObj.version + "/" + webClip;
  } else {
    // 默认H5
    systemInfo = deviceModel + " " + osVersionString;
    browserInfo =
      browserInfoObj.browser + " " + browserInfoObj.version + "/" + "H5";
  }
  return {
    systemInfo,
    browserInfo,
  };
}

export function chunkArray(array, chunkSize) {
  let result = [];
  for (let i = 0; i < array?.length; i += chunkSize) {
    let chunk = array.slice(i, i + chunkSize); // 创建从当前索引开始的特定大小的子数组
    result.push({
      id: i + 1,
      data: chunk,
    }); // 将子数组添加到最终结果中
  }
  return result;
}
// 埋点上报
// sendBeacon 上报
export async function sendBeacon({ url = "", params }) {
  if (navigator?.sendBeacon && url) {
    const isSuccess = await navigator?.sendBeacon(url, JSON.stringify(params));
    if (isSuccess) return true;
  }
  return false;
}

// 请求接口的埋点上报
export function sendAjax({ req = "", params, postAction }) {
  return new Promise((resolve, reject) => {
    if (req) {
      postAction(req, params)
        .then(() => resolve(true))
        .catch(() => reject(false));
    } else {
      reject(false);
    }
  });
}
//  上报函数
// 基础上报函数  BEACON, AJAX
// export async function reportEvent(params, reportType) {
//   let finalType = false;
//   for (const key in reportType) {
//     if (!finalType) {
//       try {
//         await EVENT_REPORT_FUNCTION_MAP[key](params).then(() => {
//           finalType = true;
//         });
//       } catch (error) {
//         console.error(error);
//       }
//     }
//   }
//   return finalType;
// }

const handleClick = debounce(function (event) {
  // 获取触发事件的目标元素
  const target = event.target;
  console.log("触发事件的目标元素", event, target);
  // 检查目标元素的 ID 是否在 clickTaskObj 中
  const findTask = store.state.tasks.clickTaskArray.find((item) => {
    const regex = new RegExp(`\\b${item.btn}\\b`);
    const result = regex.test(target.className);
    return result;
  });
  console.log("findTask", findTask);
  // 如果找到对应的 ID
  // FIXME: 需要满足算子
  if (findTask && satisfyPipeline(findTask.config)) {
    // 上报埋点
    try {
      findTask.callback();
    } catch (error) {
      console.error(error);
    }
  }
}, 300);

/**
 * 监听在任务列表中的按钮的点击事件并绑定上报接口的逻辑
 * @param {Object} clickTaskObj 点击类任务的列表对象，数据结构详见store/modules/task
 */
export function addClickListeners() {
  // for (const key in clickTaskObj) {
  //   // key值为按钮元素的id
  //   const element = document.getElementById(key);
  //   console.log("按钮元素", element);
  //   if (element) {
  //     element.addEventListener("click", async () => {
  //      await clickTaskObj[key].callback();
  //     });
  //   }
  // }
  const app = document.getElementById("app");
  app.addEventListener("click", handleClick);
  console.log("addClickListeners");
}

export function removeClickListeners() {
  // for (const key in clickTaskObj) {
  //   // key值为按钮元素的id
  //   const element = document.getElementById(key);
  //   console.log("按钮元素", element);
  //   if (element) {
  //     element.addEventListener("click", async () => {
  //      await clickTaskObj[key].callback();
  //     });
  //   }
  // }
  const app = document.getElementById("app");
  app.removeEventListener("click", handleClick);
  console.log("removeClickListeners");
}

// 保存取消监听器的函数
let unwatchRoute = null;

/**
 * 监听在任务列表中的路由的状态 达到条件则上报接口
 * @param {*} vm vue实例（全局）
 * @param {*} pathObj 页面类(路由类)任务的列表对象，数据结构详见store/modules/task
 */
export function watchRoute(vm, pathObj) {
  function handleRouteChange(to, from) {
    let fromPath = from.path;
    fromPath = fromPath?.split("/")?.[1];
    fromPath = `/${fromPath}`;
    console.log("fromPath", fromPath);
    if (pathObj[fromPath]) {
      if (pathObj[fromPath].need_stay === 1) {
        const lastEnterTime = sessionStorage.getItem(
          `TASK_${pathObj[fromPath].code}_enterTime`
        );
        if (lastEnterTime) {
          const leaveTime = new Date();
          const lengthOfStay = leaveTime - new Date(lastEnterTime);
          console.log("leaveTime", leaveTime);
          console.log("lengthOfStay", lengthOfStay);
          sessionStorage.setItem(
            `TASK_${pathObj[fromPath].code}_durationViewede`,
            lengthOfStay
          );
        }
      }
    }

    let toPath = to.path;
    toPath = toPath?.split("/")?.[1];
    toPath = `/${toPath}`;
    console.log("toPath", toPath);
    const pathTimer = sessionStorage.getItem("pathTimer");
    if (pathTimer) {
      clearTimeout(pathTimer);
      sessionStorage.removeItem("pathTimer");
    }
    console.log("pathObj", pathObj);
    console.log("pathObj[toPath]", pathObj[toPath]);
    if (pathObj[toPath]) {
      if (pathObj[toPath].need_stay === 1) {
        console.log("pathObj[toPath].need_stay", pathObj[toPath].need_stay);
        // 获取当前时间
        const enterTime = new Date();
        console.log("enterTime", enterTime);
        sessionStorage.setItem(
          `TASK_${pathObj[toPath].code}_enterTime`,
          enterTime
        );
        const durationViewede = sessionStorage.getItem(
          `TASK_${pathObj[toPath].code}_durationViewede`
        );
        let timingDuration = null;
        if (durationViewede) {
          timingDuration = pathObj[toPath].stay_second * 1000 - Number(durationViewede)
        } else {
          timingDuration = pathObj[toPath].stay_second * 1000
        }
        const timer = setTimeout(async () => {
          // 获取定时器结束的时间
          const endTime = new Date();
          // 计算时间差
          const diff = endTime - enterTime;
          console.log("diff", diff);
          // FIXME: 需要满足算子
          if (satisfyPipeline(pathObj[toPath].config)) {
            await pathObj[toPath].callback({ time: diff });
          }
        }, timingDuration);
        sessionStorage.setItem("pathTimer", timer);
      } else {
        console.log("pathObj[toPath].need_stay", pathObj[toPath].need_stay);
        // FIXME: 需要满足算子
        if (satisfyPipeline(pathObj[toPath].config)) {
          pathObj[toPath].callback();
        }
      }
    }
  }

  // 在添加新的监听器之前取消旧的监听器
  if (unwatchRoute) {
    unwatchRoute();
  }

  // 添加新的监听器并保存取消函数
  unwatchRoute = vm.$watch("$route", handleRouteChange);
}

/**
 * 算子
 */
function satisfyPipeline(configs) {
  if (!configs || !configs.length || !Object.keys(configs).length) {
    return true;
  }
  let AND = [];
  configs.forEach((config) => {
    if (config.storeType === "vuex") {
      console.log(
        "config.key:",
        config.key,
        "config.value:",
        config.value,
        "taskBeacon.state[config.key]",
        taskBeacon.state[config.key],
        "taskBeacon.state[config.key] === config.value",
        taskBeacon.state[config.key] === config.value
      );
      AND.push(taskBeacon.state[config.key] === config.value);
    }

    if (config.storeType === "localStorage") {
      AND.push(localStorage.getItem(config.key) == config.value);
    }

    if (config.storeType === "sessionStorage") {
      AND.push(sessionStorage.getItem(config.key) == config.value);
    }
  });
  const hasFalse = AND.some((element) => element === false);
  return !hasFalse;
}
