<!-- 指数组件：篮球指数（主右客左） -->
<template>
  <div
    class="ExponentCom"
    :class="{
      parentCompetitionDetails: parentComponent === 'CompetitionDetails',
      parentLiveRoom: parentComponent === 'LiveRoom',
    }"
  >
    <div class="ExponentComContainer">
      <!-- 分类菜单 -->
      <div class="classifyScrollWrapper" ref="exponentClassifyScroll">
        <div class="paddingWrapper">
          <div
            class="classify"
            :class="{ lengthThree: oddsType?.length === 3 }"
          >
            <div
              class="classification"
              v-for="(item, key) in oddsType"
              :key="key"
              @click="selectClassification(item.id)"
              :class="{ actives: selectedClassify === item.id }"
            >
              <div class="classifyName">{{ $t(item.name) }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 让球 总进球 角球 -->
      <div
        class="ExponentData"
        v-show="
          selectedClassify === 'asian' ||
          selectedClassify === 'ball' ||
          selectedClassify === 'cr'
        "
      >
        <div class="top">
          <div class="topClassify company">{{ $t("company") }}</div>
          <div class="topClassify initial">{{ $t("initial") }}</div>
          <div class="topClassify options" v-if="sport_type_id === 1">
            <div class="title">
              {{ columnsed }}
            </div>
          </div>
          <div class="topClassify options" v-else>
            <div class="title" @click.stop="show = true">
              {{ columnsed }}
              <div class="iconfont icon-jiantou_liebiaozhankai_o"></div>
            </div>
          </div>
        </div>

        <el-skeleton
          style="flex: 1;overflow: hidden;"
          :loading="isLoading"
          animated
          :count="3"
        >
          <template slot="template">
            <IndexSkeleton mode="mode1" />
          </template>
          <template>
            <div
              class="dataContent"
              :class="{ noDataContent: dataSource?.length == 0 }"
            >
              <div
                class="DataScrollWrapper"
                v-show="dataSource?.length > 0"
                ref="DataScrollWrapper"
                @touchmove.stop="handleScroll"
                @touchstart.stop="handleTouchStart"
                @touchend.stop="handleTouchEnd"
                @scroll.stop="handleScroll"
              >
                <van-image class="pullDownIcon" :src="logoUrl"></van-image>

                <!-- <div style="height: 1500px;  background-color: red"></div> -->

                <!-- asian 让球-->

                <div class="Data" v-if="selectedClassify === 'asian'">
                  <div
                    class="listData"
                    v-for="(item, index) in dataSource"
                    :key="item.companyId"
                    :class="{ actives: index % 2 === 1 }"
                  >
                    <!-- 公司 -->
                    <div class="company">
                      <!-- <van-checkbox
                    v-if="parentComponent === 'LiveRoom'"
                    icon-size="14px"
                    v-model="item.checked"
                    @change="handleCheckboxChange(item)"
                    >{{ key }}</van-checkbox
                  > -->
                      <!-- <span v-if="parentComponent === 'CompetitionDetails'">{{ key }}</span> -->
                      <span>{{ item.companyName }}</span>
                    </div>

                    <!-- 初始 -->
                    <div class="initial" @click="toOddsDetails(item.companyId)">
                      <!-- 主队 -->
                      <span class="homeTeam number">{{
                        item.orignGuest || "-"
                      }}</span>
                      <!-- 走势 -->
                      <span class="trend number">{{ item?.orign || "" }}</span>
                      <!-- 客队 -->
                      <span class="awayTeam number">{{
                        item.orignMaster || "-"
                      }}</span>
                    </div>

                    <!-- 即时 -->
                    <div
                      class="immediately"
                      v-if="columnsed === $t('ongoing')"
                      @click="toOddsDetails(item.companyId)"
                    >
                      <!-- 主队 -->
                      <span
                        class="homeTeam number"
                        :class="item?.immediately?.homeTeamClass"
                        >{{ item.timelyGuest || "-" }}</span
                      >
                      <!-- 走势 -->
                      <span
                        class="trend number"
                        :class="item?.immediately?.trendClass"
                      >
                        {{ item?.timely || "-" }}
                      </span>
                      <!-- 客队 -->
                      <span
                        class="awayTeam number"
                        :class="item?.immediately?.awayTeamClass"
                      >
                        {{ item?.timelyMaster || "-" }}
                      </span>
                    </div>

                    <!-- 赛前 -->
                    <div
                      class="preMatch"
                      v-if="columnsed === $t('preMatch')"
                      @click="toOddsDetails(item.companyId)"
                    >
                      <!-- 主队 -->
                      <span
                        class="homeTeam number"
                        :class="item?.pregameMaster"
                        >{{ item.pregameGuest }}</span
                      >
                      <!-- 走势 -->
                      <span class="trend number" :class="item?.pregame">
                        {{ item?.pregame }}
                      </span>

                      <!-- 客队 -->
                      <span class="awayTeam number" :class="item?.pregameGuest">
                        {{ item.pregameMaster }}
                      </span>
                    </div>
                    <div
                      class="icon"
                      @click="toOddsDetails(item.companyId)"
                    ></div>
                  </div>
                </div>

                <!-- 总进球 ball -->
                <div class="Data" v-if="selectedClassify === 'ball'">
                  <div
                    class="listData"
                    v-for="(item, index) in dataSource"
                    :key="item.companyId"
                    :class="{ actives: index % 2 === 1 }"
                  >
                    <!-- 公司 -->
                    <div class="company">
                      <span>{{ item.companyName }}</span>
                    </div>

                    <!-- 初始 -->
                    <div class="initial" @click="toOddsDetails(item.companyId)">
                      <!-- 主队 -->
                      <span class="homeTeam number">{{
                        item?.orignBig || "-"
                      }}</span>
                      <!-- 走势 -->
                      <span class="trend number">{{ item?.orign || "-" }}</span>
                      <!-- 客队 -->
                      <span class="awayTeam number">{{
                        item?.orignSmall || "-"
                      }}</span>
                    </div>

                    <!-- 赛前 -->
                    <div
                      class="immediately"
                      v-if="columnsed === $t('ongoing')"
                      @click="toOddsDetails(item.companyId)"
                    >
                      <!-- 主队 -->
                      <span
                        class="homeTeam number"
                        :class="item?.immediately?.homeTeamClass"
                        >{{ item?.timelyBig || "-" }}</span
                      >
                      <!-- 走势 -->
                      <span
                        class="trend number"
                        :class="item?.immediately?.trendClass"
                      >
                        {{ item?.timely || "-" }}
                      </span>
                      <!-- 客队 -->
                      <span
                        class="awayTeam number"
                        :class="item?.immediately?.awayTeamClass"
                      >
                        {{ item?.timelySmall || "-" }}
                      </span>
                    </div>

                    <!-- 赛前 -->
                    <div
                      class="preMatch"
                      v-if="columnsed === $t('preMatch')"
                      @click="toOddsDetails(item.companyId)"
                    >
                      <!-- 主队 -->
                      <span class="homeTeam number" :class="item?.pregameBig">{{
                        item?.pregameBig
                      }}</span>
                      <!-- 走势 -->
                      <span class="trend number" :class="item?.pregame">
                        {{ item?.pregame }}
                      </span>

                      <!-- 客队 -->
                      <span class="awayTeam number" :class="item?.pregameSmall">
                        {{ item?.pregameSmall }}
                      </span>
                    </div>
                    <div
                      class="icon"
                      @click="toOddsDetails(item.companyId)"
                    ></div>
                  </div>
                </div>
              </div>
              <!-- 无数据 -->
              <div
                class="noScheduleData DataScrollWrapper"
                v-show="dataSource?.length == 0"
              >
                <WaitToastArea v-show="isLoading"></WaitToastArea>
                <NoData v-show="!isLoading">{{ $t("noDataPrompt") }}</NoData>
              </div>
            </div>
          </template>
        </el-skeleton>
      </div>

      <!-- 胜负 -->
      <el-skeleton
        class="winDrawLoseWrapper"
        v-if="selectedClassify === 'europe'"
        style="flex: 1;overflow: hidden; width: 100%"
        :loading="isLoading"
        animated
        :count="1"
      >
        <template slot="template">
          <IndexSkeleton mode="mode3" />
        </template>
        <template>
          <div
            class="winDrawLose"
            v-show="selectedClassify === 'europe' && dataSource?.length"
            ref="winDrawLose"
            @touchmove.stop="handleScroll"
            @touchstart.stop="handleTouchStart"
            @touchend.stop="handleTouchEnd"
            @scroll.stop="handleScroll"
          >
            <van-image class="pullDownIcon" :src="logoUrl"></van-image>
            <div class="container">
              <!--FIXME:统计列表 最高值/最低值/平均值 -->
              <OddsStatisticsList
                :columnsed="columnsed"
                :winDrawLoseTitles="winDrawLoseTitles"
                :sport_type_id="sport_type_id"
                @toOddsDetails="toOddsDetails"
                :statisticsList="statisticsList"
                :companyList="dataSource"
              ></OddsStatisticsList>

              <!-- 公司列表 -->
              <div class="companyList">
                <!-- 侧边栏 -->
                <div class="sideBar">
                  <div class="company">{{ $t("company") }}</div>
                  <div
                    class="sideBarTitle"
                    :class="{ 'bgc-1c1a32': index % 2 === 0 }"
                    v-for="(item, index) in dataSource"
                    :key="item.companyId"
                  >
                    {{ item.companyName }}
                  </div>
                </div>
                <!-- 内容区域 -->
                <div class="content">
                  <!-- 标题栏 -->
                  <div class="contentTitleBar">
                    <div class="title picker" @click.stop="show = true">
                      <div class="pickerTitle">
                        {{ columnsed }}
                        <div
                          class="iconfont icon-jiantou_liebiaozhankai_o"
                        ></div>
                      </div>
                    </div>
                    <div
                      class="title"
                      v-for="(title, index) in winDrawLoseTitles"
                      :key="index"
                    >
                      {{ $t(title) }}
                    </div>
                  </div>
                  <!-- 指数内容 -->
                  <div class="oddsContent">
                    <!-- 公司项 -->
                    <div
                      class="oddsContentItem"
                      :class="{ 'bgc-1c1a32': index % 2 === 0 }"
                      v-for="(item, index) in dataSource"
                      :key="item.companyId"
                      @click="toOddsDetails(item.companyId)"
                    >
                      <!-- 初始 -->
                      <div class="initial moment">
                        <span class="label spanItem">{{ $t("initial") }}</span>
                        <!-- 主胜 -->
                        <span class="homeTeam spanItem">{{
                          item?.orignGuest || "-"
                        }}</span>

                        <!-- 客胜 -->
                        <span class="awayTeam spanItem">{{
                          item?.orignMaster || "-"
                        }}</span>
                      </div>

                      <!-- 赛前 -->
                      <div
                        class="immediately moment"
                        v-show="columnsed === $t('ongoing')"
                      >
                        <span class="label spanItem">{{ $t(columnsed) }}</span>

                        <!-- 主 -->
                        <span
                          class="homeTeam spanItem"
                          :class="item?.immediately?.homeTeamClass"
                          >{{ item?.timelyGuest || "-" }}</span
                        >
                        <!-- 平 -->
                        <span
                          class="trend spanItem"
                          :class="item?.immediately?.trendClass"
                          v-show="sport_type_id === 1"
                        >
                          {{ item?.timelyEquivalent || "-" }}
                        </span>
                        <!-- 客 -->
                        <span
                          class="awayTeam spanItem"
                          :class="item?.immediately?.awayTeamClass"
                        >
                          {{ item?.timelyMaster || "-" }}
                        </span>
                      </div>

                      <!-- FIXME:  赛前 -->
                      <div
                        class="preMatch moment"
                        v-show="columnsed === $t('preMatch')"
                      >
                        <span class="label spanItem">{{ $t(columnsed) }}</span>
                        <!-- 主 -->
                        <span
                          class="homeTeam spanItem"
                          :class="item?.pregameMaster"
                          >{{ item?.pregameGuest }}</span
                        >
                        <!-- 平 -->
                        <!-- <span
                      class="trend spanItem"
                      :class="item?.pregame"
                    >
                      {{ item?.pregame }}
                    </span> -->

                        <!-- 客 -->
                        <span
                          class="awayTeam spanItem"
                          :class="item?.pregameGuest"
                        >
                          {{ item?.pregameMaster }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 无数据 -->
            <!-- <div
          class="noScheduleData winDrawLose"
          v-show=" dataSource.length === 0"
        >
        <WaitToastArea v-if="isLoading"></WaitToastArea>
          <NoData v-else>{{ $t("noDataPrompt") }}</NoData>
        </div> -->
          </div>
          <div
            class="winDrawLose noWinDrawLose"
            v-show="selectedClassify === 'europe' && !dataSource.length"
          >
            <WaitToastArea v-show="isLoading"></WaitToastArea>
            <NoData v-show="!isLoading">{{ $t("noDataPrompt") }}</NoData>
          </div>
        </template>
      </el-skeleton>
    </div>

    <!-- FIXME: 暂时没有赛前，注释 选择器弹出层 -->
    <van-popup v-model="show" position="bottom" get-container="#app">
      <van-picker
        :title="$t('pleaseSelect')"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        :default-index="0"
        :lock-scroll="false"
      >
        <template #confirm>
          <van-icon name="success" size="20px" />
        </template>
        <template #cancel>
          <van-icon name="cross" size="18px" />
        </template>
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import {
  getFootballCompetitionDetails,
  getBasketballCompetitionDetails,
  getFootballOddsCompanies,
  getBasketballOddsCompanies,
} from "@/services/api/Competition";
import { Checkbox, CheckboxGroup } from "vant";
import { Popup } from "vant";
import { Picker } from "vant";
import NoData from "@/components/NoData/Index.vue";
import OddsStatisticsList from "@/components/OddsStatisticsList/Index";
import WaitToastArea from "@/components/WaitToastArea/Index";
import IndexSkeleton from "@/components/ExponentCom/IndexSkeleton.vue";
import { debounce } from "lodash";
import axios from "axios";
import toast from "@/utils/toast.js";
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
export default {
  name: "ExponentCom",
  props: {
    /**
     * 父组件
     * - 赛事详情页: CompetitionDetails
     * - 直播间: LiveRoom
     */
    parentComponent: {
      type: String,
      require: true,
    },

    // 赛事id
    competition_id: {
      type: String,
      require: true,
    },

    // 体育id
    sport_type_id: {
      type: Number,
      require: true,
      default: 1,
    },
    // 双方赛事队伍名称
    teamInfo: {
      type: Object,
    },
    // 直播间当前tab选择
    TMoptionActivity: {
      type: String,
    },
    // 源id
    source_id: {
      type: String,
    },
  },
  components: {
    NoData,
    OddsStatisticsList,
    WaitToastArea,
    IndexSkeleton,
  },
  data() {
    return {
      // 全场的指数类型
      oddsTypeFH: [
        {
          id: "asian",
          name: "handicap",
        },
        {
          id: "europe",
          name: "WinDrawLose",
        },
        {
          id: "ball",
          name: "totalGoals",
        },
        // {
        //   id: "cr",
        //   name: "cornerKick",
        // },
      ],
      // 半场的指数类型
      oddsTypeFT: [
        {
          id: "asian",
          name: "handicap",
        },
        {
          id: "europe",
          name: "WinDrawLose",
        },
        {
          id: "ball",
          name: "totalGoals",
        },
      ],
      // 篮球的指数类型
      oddsTypeBasketball: [
        {
          // 让分
          id: "asian",
          name: "bHandicap",
        },
        {
          // 胜负
          id: "europe",
          name: "victoryOrDefeat",
        },
        {
          // 总分
          id: "ball",
          name: "totalScore",
        },
      ],
      // winDrawLoseTitles: ["HW", "Dr", "AW"], // 胜平负的标签栏 HW: '主胜', Dr: '平局', AW: '客胜',
      Mcircle: [this.$t("FH"), this.$t("FT")], // 切换全场/半场的控件 FH全场 FT半场
      McircleActive: 0, // 全/半场 选中的索引 0-全场 1-半场
      selectedClassify: "asian", // 指数类型是否被选中  asian: 让球 europe: 胜平负 ball: 总进球
      checked: true,
      isPopped: false,
      show: false,
      columns: [this.$t("preMatch"), this.$t("ongoing")], // [即时/赛前]选择器-选项列表
      columnsed: this.$t("preMatch"), // [即时/赛前]选择器-选中的选项

      oddsTimer: null, // 定时器
      // 重构代码的数据
      dataSource: [], // 指数列表
      statisticsList: [], // europe平均值/最高值/最低值
      isLoading: true,
      // latestRequest: 0,
      // 是否请求caifu接口拿指数
      oddsFetch: true,
      source: null,
      logoUrl: require("@/assets/client-logos/com-logo@3x.webp"),
      isTop: false,
      startScrollTop: 0,
      startY: 0,
    };
  },
  computed: {
    winDrawLoseTitles() {
      if (this.sport_type_id === 1) {
        return ["HW", "Dr", "AW"];
      }
      if (this.sport_type_id === 2) {
        return ["AW", "HW"];
      }
      return ["HW", "Dr", "AW"];
      // return null
    },

    oddsType() {
      if (this.sport_type_id === 1) {
        return this.McircleActive === 0 ? this.oddsTypeFH : this.oddsTypeFT;
      }
      if (this.sport_type_id === 2) {
        return this.oddsTypeBasketball;
      }
      return this.McircleActive === 0 ? this.oddsTypeFH : this.oddsTypeFT;
      // return null;
    },
  },
  watch: {
    TMoptionActivity: {
      handler: async function (newValue) {
        // this.clearOddsTimer();
        if (newValue === "odds") {
          await this.getCompanies(true);
        }
      },
    },
  },
  async created() {
    this.isloading = true;
    await this.getCompanies(true);
  },
  methods: {
    //  获取公司列表
    async getCompanies(isloading = false) {
      // const currentRequest = ++this.latestRequest;
      this.source = axios.CancelToken.source();
      if (this.sport_type_id === 1) {
        if (isloading) this.isLoading = true;
        try {
          const res = await getFootballOddsCompanies(
            {
              source_id: this.source_id,
              type: this.selectedClassify,
            },
            this.source.token
          );
          const { data, statusCode } = res;
          const { results, odds_fetch, code } = data;
          if (statusCode === 200 && code === 200) {
            this.isLoading = false;
            const { data: dataItems, statistics } = results || [];
            this.dataSource = dataItems;
            this.statisticsList = statistics;
            this.oddsFetch = odds_fetch;
            sessionStorage.setItem(
              "footballOddsCompanies",
              JSON.stringify(dataItems)
            );
            // if(isloading) {
            //   this.setOddsTimer();
            // }
            // }
          } else {
            this.isLoading = false;
          }
        } catch (e) {
          if (e.code !== "ERR_CANCELED") this.isLoading = false;
          console.log(e);
        }
      } else if (this.sport_type_id === 2) {
        if (isloading) this.isLoading = true;
        try {
          const res = await getBasketballOddsCompanies(
            {
              source_id: this.source_id,
              type: this.selectedClassify,
            },
            this.source.token
          );
          const { data, statusCode } = res;
          const { results, odds_fetch, code } = data;
          if (statusCode === 200 && code === 200) {
            this.isLoading = false;
            const { data: dataItems, statistics } = results || [];
            this.dataSource = dataItems;
            this.statisticsList = statistics;
            this.oddsFetch = odds_fetch;
            sessionStorage.setItem(
              "basketballOddsCompanies",
              JSON.stringify(dataItems)
            );
            // if(isloading) {
            //   this.setOddsTimer();
            // }
            // }
          } else {
            this.isLoading = false;
          }
        } catch (e) {
          if (e.code !== "ERR_CANCELED") this.isLoading = false;
          console.log(e);
        }
      }
    },
    /**
     * 下拉事件触发刷新
     */
    async pullingDownHandler() {
      const watiToast = toast.initWaitToast();
      await this.getCompanies(true);
      toast.clearWaitToast(watiToast);
    },
    // 滚动事件
    handleScroll: debounce(function () {
      if (!this.$refs.DataScrollWrapper || this.$refs.winDrawLose) {
        return;
      }
      // 判断手势是上拉还是下拉
      if (
        this.$refs.DataScrollWrapper.scrollTop === 0 ||
        this.$refs.winDrawLose.scrollTop == 0
      ) {
        this.isTop = true;
        return;
      }
    }, 200),

    handleTouchStart(event) {
      if (!this.$refs.DataScrollWrapper || !this.$refs.winDrawLose) {
        return;
      }

      this.startY = event.touches[0].clientY;
      this.startScrollTop =
        this.selectedClassify === "europe"
          ? this.$refs.winDrawLose.scrollTop
          : this.$refs.DataScrollWrapper.scrollTop;
      if (this.startScrollTop === 0) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    },

    async handleTouchEnd(event) {
      const endY = event.changedTouches[0].clientY;
      const distanceY = endY - this.startY;
      if (this.isTop && distanceY > 88) {
        this.isTop = false;
        await this.pullingDownHandler();
      }
    },

    cancelRequest() {
      this.source.cancel();
    },
    /**
     * 定时器请求指数数据
     */
    // setOddsTimer() {
    //   if (this.oddsTimer) {
    //     this.clearOddsTimer();
    //   }
    //   this.oddsTimer = setInterval(async () => {
    //     await this.getCompanies();
    //   }, 3 * 1000);
    // },

    /**
     * 清除定时器
     */
    // clearOddsTimer() {
    //   if (this.oddsTimer) {
    //     clearInterval(this.oddsTimer);
    //     this.oddsTimer = null;
    //   }
    // },

    //  防抖获取公司列表
    getCompaniesDebounce: debounce(function () {
      this.getCompanies(true);
    }, 300),
    // 切换指数类型
    selectClassification(key) {
      this.isLoading = true;
      this.selectedClassify = key;
      this.dataSource = [];
      this.cancelRequest();
      this.getCompaniesDebounce();
    },

    /**
     * [即时/赛前]选择器-确认
     */
    onConfirm(value, index) {
      this.columnsed = this.columns[index];
      this.show = false;
      // const picker = document.querySelector(".companyList .picker");
      const picker = document.querySelector(".companyList");
      if (index === 1) {
        picker.style.fontSize = "2.93333vw";
      }
      if (index === 0) {
        picker.style.fontSize = "3.2vw";
      }
    },

    /**
     * [即时/赛前]选择器-取消
     */
    onCancel() {
      this.show = false;
    },

    /**
     * 获取赛事数据
     */
    async getCompetition() {
      const getCompetitionDetails =
        Number(this.sport_type_id) === 1
          ? getFootballCompetitionDetails
          : getBasketballCompetitionDetails;
      try {
        // FIXME: 比赛详情接口
        const res = await getCompetitionDetails(this.competition_id, {
          match_id: this.competition_id,
          // country_id: Number(localStorage.getItem("country_id")),
          language_id: Number(localStorage.getItem("language_id")),
        });
        if (res?.statusCode === 200) {
          return {
            homeTeam: res.data.home_team,
            awayTeam: res.data.away_team,
          };
        }
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * 跳转[指数详情页面]
     */
    async toOddsDetails(companyId) {
      let teamInfo = this.teamInfo
        ? this.teamInfo
        : await this.getCompetition();
      sessionStorage.setItem("liveRoomTabActivity", this.TMoptionActivity);
      this.$router.push({
        name: "OddsDetails",
        query: {
          id: this.competition_id, // 赛事id
          sport_type_id: this.sport_type_id, // 体育id
          homeTeamName: teamInfo?.homeTeam, // 主队名称
          awayTeamName: teamInfo?.awayTeam, // 客队名称
          source_id: this.source_id,
          oddsFetch: this.oddsFetch,
        },
        params: {
          selectedClassify: this.selectedClassify,
          companyId: companyId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./BasketballIndex.scss";
</style>
<style lang="scss" scoped>
@import "./BasketballIndex.vant.scss";
</style>
