/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "Nutzungsbedingungen",
  privacyPolicy: "Datenschutzrichtlinie",
  termsOfService: "Nutzungsbedingungen",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "Noch nicht angefangen",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "Bereits ausgestrahlt",
  watch: "geh schauen",
  follow: "konzentrieren Sie sich auf",
  following: "Folgt bereits",
  subscribe: "Abonnement",
  streamerSchedule: "Ankerplan",
  noDataPrompt: "Keine Daten",
  unfollowPrompt: "Sind Sie sicher, dass Sie nicht mehr folgen?",
  yes: "Ja",
  no: "NEIN",
  logAndSign: "Jetzt anmelden/registrieren",
  hours: "Stunde",
  mins: "Minute",
};

const AnchorInformation = {
  recommendedLiveStreams: "Empfohlene Live-Übertragung",
  noDataPrompt: "Keine Daten",
};

const BasketballScoreIndex = {
  details: "Einzelheiten",
  over: "über",
  pointsDifference: "Punkteunterschied",
  totalScore: "Gesamtpunktzahl",
  live: "LIVE",
  unSubscribePrompt: "Möchten Sie Ihr Abonnement wirklich kündigen?",
  yes: "Ja",
  no: "NEIN",
  logAndSign: "Jetzt anmelden/registrieren",
  InternetErrorPrompt: "Netzwerkfehler",
};

const BasketballSmallBig = {
  details: "Einzelheiten",
  over: "über",
  pointsDifference: "Punkteunterschied",
  totalScore: "Gesamtpunktzahl",
  live: "LIVE",
  unSubscribePrompt: "Möchten Sie Ihr Abonnement wirklich kündigen?",
  yes: "Ja",
  no: "NEIN",
  logAndSign: "Jetzt anmelden/registrieren",
  InternetErrorPrompt: "Netzwerkfehler",
  overIndex: "groß",
  underIndex: "Klein",
};

const BindPhoneDialog = {
  bindPhonePrompt: "Um die Sicherheit Ihres Kontos zu gewährleisten, verknüpfen Sie bitte Ihre Mobiltelefonnummer, damit wir Ihnen bessere Dienste bieten können.",
  goBind: "Gehen Sie zum Binden",
};

const BottomNavigationBar = {
  home: "Titelseite",
  matches: "Wettbewerb",
  recommend: "empfehlen",
  chat: "Plaudern",
  personal: "persönlich",
  discovery: "Entdecken",
  money: "Geld verdienen",
};

const ChatInterface = {
  noCsPrompt: "Im Moment ist kein Chat möglich",
  uploadRulePrompt: "Bitte laden Sie Bilder im JPG-, JPEG- oder PNG-Format hoch",
  uploadSizePrompt: "Die Bildgröße darf 10 MB nicht überschreiten",
  sendError: "Senden fehlgeschlagen",
  picSendError: "Das Senden des Bildes ist fehlgeschlagen",
  InternetErrorPrompt: "Netzwerkfehler",
  bannedSendPrompt: "Du wurdest gesperrt",
};

const ChatList = {
  noNewPrompt: "Noch keine Nachrichten",
  logInPrompt: "Melden Sie sich an, um Inhalte anzuzeigen. Bitte melden Sie sich zuerst an",
  logIn: "Anmeldung",
  group: "Gruppe",
};

const ChatListItem = {
  withdrawMessage: "Eine Nachricht zurückgezogen",
  bannedPrompt: "verboten",
  unBanPrompt: "Nicht gesperrt",
  joinGroupPrompt: "Der Gruppe beigetreten",
  leftGroupPrompt: "Hat die Gruppe verlassen",
  picture: "Bild",
  link: "Verknüpfung",
  emoji: "animierte Emoticons",
  addedFriendPrompt: "Als Freund hinzugefügt",
};

const CompetitionList = {
  logInPrompt: "Melden Sie sich an, um Inhalte anzuzeigen. Bitte melden Sie sich zuerst an",
  logIn: "Anmeldung",
  today: "Heute",
  Monday: "Montag",
  Tuesday: "Dienstag",
  Wednesday: "Mittwoch",
  Thursday: "Donnerstag",
  Friday: "Freitag",
  Saturday: "Samstag",
  Sunday: "Sonntag",
  noGames: "Noch keine Spiele",
  toCollapse: "Zum Ausblenden klicken",
  toExpand: "Zum erweitern klicken"
};

const CompetitionListItem = {
  details: "Einzelheiten",
  unSubscribePrompt: "Möchten Sie Ihr Abonnement wirklich kündigen?",
  yes: "Ja",
  no: "NEIN",
  logAndSign: "Jetzt anmelden/registrieren",
  InternetErrorPrompt: "Netzwerkfehler",
};

const CompetitionMode = {
  matchMode: "Wettbewerbsmodus",
  confirm: "bestätigen",
  cancel: "Stornieren",
};

const EmojiCom = {
  logAndSign: "Jetzt anmelden/registrieren",
  yes: "Ja",
  no: "NEIN",
};

const ExponentCom = {
  company: "Unternehmen",
  initial: "anfänglich",
  ongoing: "sofort",
  preMatch: "Vor dem Spiel",
  noDataPrompt: "Keine Daten",
  handicap: "Behinderung",
  WinDrawLose: "Gewinnen, unentschieden spielen, verlieren",
  totalGoals: "Gesamtziele",
  cornerKick: "Horn",
  pleaseSelect: "bitte auswählen",
  confirm: "bestätigen",
  cancel: "Stornieren",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "Angepinnt auf „Stream chat“",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "konzentrieren Sie sich auf",
  following: "Folgt bereits",
  chat: "Plaudern",
  noDataPrompt: "Keine Daten",
  unfollowPrompt: "Sind Sie sicher, dass Sie nicht mehr folgen?",
  yes: "Ja",
  no: "NEIN",
  InternetErrorPrompt: "Netzwerkfehler",
};

const FootballCornerIndex = {
  details: "Einzelheiten",
  over: "über",
  FH: "HT",
  cornerKick: "Horn",
  unSubscribePrompt: "Möchten Sie Ihr Abonnement wirklich kündigen?",
  yes: "Ja",
  no: "NEIN",
  logAndSign: "Jetzt anmelden/registrieren",
  InternetErrorPrompt: "Netzwerkfehler",
};

const FootballScoreIndex = {
  details: "Einzelheiten",
  over: "über",
  FH: "HT",
  cornerKick: "Horn",
  live: "LIVE",
  unSubscribePrompt: "Möchten Sie Ihr Abonnement wirklich kündigen?",
  yes: "Ja",
  no: "NEIN",
  logAndSign: "Jetzt anmelden/registrieren",
  InternetErrorPrompt: "Netzwerkfehler",
};

const FootballScoreItem = {
  details: "Einzelheiten",
  over: "über",
  FH: "HT",
  cornerKick: "Horn",
  live: "LIVE",
  unSubscribePrompt: "Möchten Sie Ihr Abonnement wirklich kündigen?",
  yes: "Ja",
  no: "NEIN",
  logAndSign: "Jetzt anmelden/registrieren",
  InternetErrorPrompt: "Netzwerkfehler",
};

const GoalToast = {
  goal: "Ziel",
};

const LiveRecommend = {
  noDataPrompt: "Keine Daten",
  recoverLosses: "Recover Losses",
  winningBetSlip: "Winning Bet Slip",
};

const LiveRecommendList = {
  victoryOrDefeat: "Sieg oder Niederlage",
};

const MatchCardList = {
  details: "Einzelheiten",
};

const MatchStatus = {
  noDataPrompt: "Keine Daten",
};

const MoreStreams = {
  switchLines: "Leitungen wechseln",
  cancel: "Stornieren",
  currentStreamer: "Aktueller Anker",
  live: "LIVE",
  offLine: "Noch nicht angefangen",
  followersCount: "konzentrieren Sie sich auf",
  otherStreamersPrompt: "Derzeit übertragen andere Moderatoren das Spiel",
  watch: "geh schauen",
};

const NewMatchStatus = {
  team: "Team",
  halfTimeScore: "Halbzeitstand",
  shotOnTarget: "Schuss aufs Tor",
  missedShot: "Das Ziel verfehlt",
  attack: "Attacke",
  dangerousAttack: "Gefährlicher Angriff",
  number3PointGoals: "Anzahl der erzielten 3-Punkte-Tore",
  number2PointGoals: "Anzahl der erzielten 2-Punkte-Tore",
  penaltyKickGoals: "Elfmetertore",
  freeThrowPercentage: "Freiwurfprozentsatz",
  numberOfPausesRemaining: "Anzahl der verbleibenden Pausen",
  numberOfFouls: "Anzahl der Fouls",
  totalNumberOfPauses: "Totale Pausen",
  threePointShot: "3-Punkte-Schuss",
  twoPointShot: "2-Punkte-Schuss",
  shoot: "Schießen",
  penalty: "Strafe",
  firstHalf: "erste Hälfte",
  secondHalf: "zweite Hälfte",
  redCard: "rote Karte",
  yellowCard: "gelbe Karte",
};

const NoData = {
  noDataPrompt: "Keine Daten",
};

const NoticeMessage = {
  addedFriendPrompt: "Als Freund hinzugefügt",
  withdrawMessage: "Eine Nachricht zurückgezogen",
  withdrawMessageByAdmin: "Eine Nachricht wurde vom Administrator zurückgezogen",
  joinGroupPrompt: "Der Gruppe beigetreten",
  leftGroupPrompt: "Hat die Gruppe verlassen",
  removeGroupPrompt: "Vom Administrator aus der Gruppe entfernt",
  bannedPrompt: "verboten",
  unBanPrompt: "Nicht gesperrt",
};

const OddsStatisticsList = {
  initial: "anfänglich",
  ongoing: "sofort",
  preMatch: "Vor dem Spiel",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  max: "Höchster Wert",
  min: "niedrigster Wert",
  average: "Durchschnittswert",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "Keine Daten",
};

const RecommendCompetitions = {
  today: "Heute",
  winsInARow: "Sogar in der Mitte",
  noLivePrompt: "Noch keine Live-Übertragung",
};

const RecommendStreamerList = {
  noDataPrompt: "Keine Daten",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "Hit der Woche",
  victoryOrDefeat: "Sieg oder Niederlage",
  check: "Überprüfen",
  chat: "Plaudern",
  quarterlyProfit: "Quartalsgewinn",
};

const RecommendStreamers = {
  recommendedByExperts: "Von Experten empfohlen",
  winsInARow: "Sogar in der Mitte",
  changeBatch: "Ändern Sie eine Charge",
  follow: "konzentrieren Sie sich auf",
  following: "Folgt bereits",
  unFollowing: "Freischalten",
  noDataPrompt: "Keine Daten",
  logAndSign: "Jetzt anmelden/registrieren",
  yes: "Ja",
  no: "NEIN",
  InternetErrorPrompt: "Netzwerkfehler",
  unfollowPrompt: "Sind Sie sicher, dass Sie nicht mehr folgen?",
};

const RecordChart = {
  record: "aufzeichnen",
  allWaysToPlay: "Alle Arten zu spielen",
  fromFarToNear: "von fern nach nah",
  lastTenGames: "Letzte 10 Spiele",
  longestConsecutiveHit: "Der längste Hit in Folge",
  hitRate: "Trefferquote",
  currentPropmt: "aktuelle Spiele",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "Benutzerinformationen konnten nicht abgerufen werden",
  getAreaAndLanguageErrorPrompt: "Die regionale Sprache konnte nicht abgerufen werden",
};

const ScheduleCom = {
  startTime: "Anfangszeit",
};

const SchemeCard = {
  planList: "Planliste",
  release: "freigeben",
  draw: "D",
  win: "W",
  halfWin: "HT",
  lost: "L",
  halfLost: "HT",
  all: "All",
  guaranteedCompensation: "Garantierte Entschädigung",
  free: "frei",
  toll: "MAUT",
  victoryOrDefeat: "Sieg oder Niederlage",
};

const SquareCom = {
  watchPrompt: "Leute schauen zu",
  startedPrompt: "Vor Minuten angefangen",
  chat: "Plaudern",
  sendMessageNeedLoginPrompt: "Bitte melden Sie sich an, um eine Nachricht zu senden",
  assistant: "Administrator",
  audience: "Benutzer",
  newMessage: "neue Nachrichten",
  confirmSend: "Bestätigen Sie den Versand",
  cancelSend: "Stornieren",
};

const StatisticalChart = {
  hitRate: "Trefferquote",
};

const SwitchLines = {
  switchLines: "Leitungen wechseln",
  cancel: "Stornieren",
  currentStreamer: "Aktueller Anker",
  live: "LIVE",
  offLine: "Noch nicht angefangen",
  followersCount: "konzentrieren Sie sich auf",
  otherStreamersPrompt: "Derzeit übertragen andere Moderatoren das Spiel",
  watch: "geh schauen",
  LINE: "Linie",
  VIP: "VIP",
  OFFICIAL: "offiziell",
};

const VideoCom = {
  videoErrorPrompt: "Das Signal ist nicht sehr gut, bitte versuchen Sie es später noch einmal.",
  clickRetryPrompt: "Klicken Sie, um es erneut zu versuchen",
};

const WelcomeCard = {
  join: "Gruppe beitreten",
};

//utils
const tools = {
  yesterday: "gestern",
  addedFriendPrompt: "Als Freund hinzugefügt",
  withdrawMessage: "Eine Nachricht zurückgezogen",
  joinGroupPrompt: "Der Gruppe beigetreten",
  leftGroupPrompt: "Hat die Gruppe verlassen",
  removeGroupPrompt: "Vom Administrator aus der Gruppe entfernt",
  bannedPrompt: "verboten",
  unBanPrompt: "Nicht gesperrt",
};

//页面
const AddressBook = {
  search: "Finden",
  addFriend: "Freund hinzufügen",
  myGroupChat: "mein Gruppenchat",
  friends: "Freunde",
  delete: "löschen",
  noFriendsPrompt: "Noch keine Freunde",
  nameAndIdSearch: "Geben Sie Name und ID ein",
  searchResults: "Finden Sie Ergebnisse",
  notFoundPrompt: "Benutzer nicht gefunden",
  add: "Hinzufügen zu",
  deleteFriendPrompt: "Möchten Sie den Freund wirklich löschen?",
  yes: "Ja",
  no: "NEIN",
  addFriendSuccessPrompt: "Freund erfolgreich hinzugefügt",
  deleteFriendSuccessPrompt: "Freund erfolgreich löschen",
  InputSearchPrompt: "Bitte geben Sie Inhalte ein",
};

const GroupChat = {
  groupChat: "Gruppenchat",
  groupSearch: "Geben Sie den Namen des Gruppenchats ein",
  noGroupPrompt: "Noch kein Gruppenchat",
  chat: "Plaudern",
  InputSearchPrompt: "Bitte geben Sie Inhalte ein",
};

const GroupAdministrator = {
  administrator: "Administrator",
  groupOwner: "Herr",
};

const GroupAnnouncement = {
  groupAnnouncement: "Gruppenankündigung",
  groupOwner: "Herr",
  administrator: "Administrator",
};

const GroupDetail = {
  groupDetail: "Gruppendetails",
  groupMembers: "Gruppenmitglieder",
  groupName: "Gruppenname",
  groupDescription: "Gruppenbeschreibung",
  groupOwner: "Herr",
  administrator: "Administrator",
  administratorList: "Administratorliste",
  groupAnnouncement: "Gruppenankündigung",
  groupFiles: "Gruppendatei",
  exitGroup: "Verlasse die Gruppe",
  exitGroupPrompt: "Sind Sie sicher, dass Sie die Gruppe verlassen?",
  yes: "Ja",
  no: "NEIN",
  leftGroupSuccessPrompt: "Die Gruppe wurde erfolgreich verlassen",
};

const GroupFile = {
  groupFiles: "Gruppendatei",
  fileSearch: "Geben Sie den Dateinamen ein",
  today: "Heute",
  multimedia: "Multimedia",
  file: "dokumentieren",
  noFilesPrompt: "Noch keine Dateien",
};

const GroupMembers = {
  groupMembers: "Gruppenmitglieder",
  nameAndIdSearch: "Geben Sie Name und ID ein",
};

const Chat = {
  chat: "Plaudern",
  nameAndIdSearch: "Geben Sie Name und ID ein",
  contacts: "Adressbuch",
  InputSearchPrompt: "Bitte geben Sie Inhalte ein",
  deleteContactPrompt: "Möchten Sie den Chat wirklich löschen?",
};

const Competition = {
  all: "All",
  ongoing: "sofort",
  popular: "Beliebt",
  liveCompetition: "Live-Streaming",
  schedule: "Zeitplan",
  results: "Ergebnis",
  subscribed: "Gezeichnet",
  goal: "Ziel",
  today: "Heute",
  Monday: "Montag",
  Tuesday: "Dienstag",
  Wednesday: "Mittwoch",
  Thursday: "Donnerstag",
  Friday: "Freitag",
  Saturday: "Samstag",
  Sunday: "Sonntag",
};

const CompetitionDetails = {
  subscribe: "Abonnement",
  subscribed: "Gezeichnet",
  matchStreamerPrompt: "Moderator des Live-Spiels",
  live: "LIVE",
  noDataPrompt: "Keine Daten",
  noStreamersPrompt: "Derzeit gibt es keinen Host, der dieses Spiel überträgt",
  alreadyStreamerPrompt: "Ein Moderator hat mit der Übertragung des Spiels begonnen. Möchten Sie es sich ansehen?",
  yes: "Ja",
  no: "NEIN",
  liveStream: "Live-Streaming",
  matchStatus: "Übereinstimmungsstatus",
  odds: "Odds",
  unSubscribePrompt: "Möchten Sie Ihr Abonnement wirklich kündigen?",
  logAndSign: "Jetzt anmelden/registrieren",
  InternetErrorPrompt: "Netzwerkfehler",
};

const OddsDetails = {
  company: "Unternehmen",
  handicap: "Behinderung",
  WinDrawLose: "Gewinnen, unentschieden spielen, verlieren",
  totalGoals: "Gesamtziele",
  cornerKick: "CK",
  HT: "HT",
  Trd: "Trd",
  AT: "AT",
  Tm: "Tm",
  Sc: "Sc",
  HW: "HW",
  Dr: "Dr",
  AW: "AW",
  RR: "RR",
  Ov: "viele",
  Un: "wenige",
};

const Home = {
  logIn: "Anmeldung",
  signUp: "registrieren",
  popularMatches: "Beliebte Wettbewerbe",
  more: "Mehr",
  noDataPrompt: "Keine Daten",
  currentlyLive: "Live-Übertragung",
  popularStreamers: "Beliebter Anker",
  thinkYouLike: "Sie können auch mögen",
  loadMore: "Mehr laden",
  noMoreDataPrompt: "Noch keine weiteren Daten",
  addDeskPrompt: "Zum Desktop hinzufügen",
  clikToolPrompt: "Klicken Sie auf die Symbolleiste unten",
  andSeletcPrompt: "und auswählen",
  addToScreenPrompt: "Wir möchten Ihren Startbildschirm erweitern, um einen besseren Service zu bieten",
  addToScreen: "Zum Startbildschirm hinzufügen",
};

const MoreLives = {
  noDataPrompt: "Keine Daten",
};

const MoreStreamers = {
  streamer: "Anker",
  streamerSearch: "Geben Sie den Ankernamen und die ID ein",
  all: "All",
  notFollowing: "Nicht folgend",
  following: "Folgt bereits",
  live: "LIVE",
  offLine: "Noch nicht angefangen",
  followersCount: "konzentrieren Sie sich auf",
  unfollowPrompt: "Sind Sie sicher, dass Sie nicht mehr folgen?",
  yes: "Ja",
  no: "NEIN",
  logAndSign: "Jetzt anmelden/registrieren",
  InternetErrorPrompt: "Netzwerkfehler",
  InputSearchPrompt: "Bitte geben Sie Inhalte ein",
  dataRequestErrorPrompt: "Daten konnten nicht abgerufen werden",
};

const LiveRoom = {
  switchLines: "Leitungen wechseln",
  streamChat: "Chat Lobby",
  streamerPrivateChat: "Ankern Sie den privaten Chat",
  chat: "Plaudern",
  odds: "Odds",
  streamerInformation: "Ankerinformationen",
  matchStatus: "Übereinstimmungsstatus",
};

const AnchorIntroduction = {
  streamerInformation: "Ankerinformationen",
  photosWall: "Foto Wand",
  seeMore: "Mehr sehen",
  streamerPlan: "Ankerplan",
};

const AnchorPhoto = {
  photosWall: "Foto Wand",
  noPhotosPrompt: "Noch keine Fotos mehr",
};

const LogInRegister = {
  logIn: "Anmeldung",
  signUp: "registrieren",
  getAreaAndLanguageErrorPrompt: "Die regionale Sprache konnte nicht abgerufen werden",
  quickRegisterFrequentPrompt: "Häufige Anfragen, bitte versuchen Sie es später noch einmal",
};

const LoginFn = {
  phone: "Telefonnummer",
  email: "Post",
  logInPassword: "Passwort",
  rememberPassword: "Passwort merken",
  forgotPassword: "vergessen Sie das Passwort",
  logIn: "Anmeldung",
  logInAgreement: "Mit der Anmeldung stimmen Sie zu",
  userAgreement: "Nutzungsbedingungen",
  and: "  Und ",
  privacyPolicy: "Datenschutzrichtlinie",
  passwordRulePrompt: "Das Passwort muss aus Groß- und Kleinbuchstaben sowie Zahlen bestehen und länger als 8 Zeichen sein",
  passwordEmptyPrompt: "Das Passwort darf nicht leer sein",
  emailRulePrompt: "Bitte geben Sie das korrekte Format Ihrer E-Mail-Adresse ein",
  emailEmptyPrompt: "E-Mail darf nicht leer sein",
  phoneRulePrompt: "Bitte geben Sie Ihre Mobiltelefonnummer im richtigen Format ein",
  phoneEmptyPrompt: "Die Mobiltelefonnummer darf nicht leer sein",
  phoneNum8: "Bitte geben Sie Ihre 8-stellige Mobiltelefonnummer ein",
  phoneNum9To10: "Bitte geben Sie eine 9- bis 10-stellige Mobiltelefonnummer ein",
  phoneNum9: "Bitte geben Sie eine 9-stellige Mobiltelefonnummer ein",
  enterErrorPrompt: "Eingabefehler oder Netzwerkanomalie",
  loginNoAgreePrompt: "Um sich anzumelden, müssen Sie der Benutzervereinbarung und der Datenschutzrichtlinie zustimmen",
  accountAndPassword: "Konto",
  account: "Konto",
  accountEmptyPrompt: "Das Konto darf nicht leer sein",
  accountOrPasswordError: "Falscher Benutzername oder Passwort",
};

const Register = {
  phone: "Telefonnummer",
  email: "Post",
  code: "Bestätigungscode",
  getCode: "Holen Sie sich den Bestätigungscode",
  logInPassword: "Passwort",
  CS: "Kundendienst",
  signUp: "registrieren",
  signUpAgreement: "Mit der Anmeldung stimmen Sie zu",
  userAgreement: "Nutzungsbedingungen",
  and: "Und",
  privacyPolicy: "Datenschutzrichtlinie",
  contactCSPrompt: "Haben Sie Probleme bei der Registrierung? Wenden Sie sich an den Kundendienst, um das Problem für Sie zu lösen",
  contactCS: "Kontaktieren Sie den Kundendienst",
  passwordRulePrompt: "Das Passwort muss aus Groß- und Kleinbuchstaben sowie Zahlen bestehen und länger als 8 Zeichen sein",
  passwordEmptyPrompt: "Das Passwort darf nicht leer sein",
  emailRulePrompt: "Bitte geben Sie das korrekte Format Ihrer E-Mail-Adresse ein",
  emailEmptyPrompt: "E-Mail darf nicht leer sein",
  phoneRulePrompt: "Bitte geben Sie Ihre Mobiltelefonnummer im richtigen Format ein",
  phoneEmptyPrompt: "Die Mobiltelefonnummer darf nicht leer sein",
  codeRulePrompt: "Der Bestätigungscode besteht aus 6 Ziffern",
  codeEmptyPrompt: "Der Verifizierungscode muss ausgefüllt werden",
  phoneNum8: "Bitte geben Sie Ihre 8-stellige Mobiltelefonnummer ein",
  phoneNum9To10: "Bitte geben Sie eine 9- bis 10-stellige Mobiltelefonnummer ein",
  phoneNum9: "Bitte geben Sie eine 9-stellige Mobiltelefonnummer ein",
  phoneCodeSendSuccessPrompt: "Der Bestätigungscode wurde erfolgreich gesendet. Bitte überprüfen Sie die SMS auf Ihrem Mobiltelefon",
  emailCodeSendSuccessPrompt: "Der Bestätigungscode wurde erfolgreich gesendet. Bitte überprüfen Sie Ihre E-Mails",
  phoneCodeSendErrorPrompt: "Der Bestätigungscode konnte nicht gesendet werden. Bitte prüfen Sie, ob die Mobiltelefonnummer verfügbar ist",
  emailCodeSendErrorPrompt: "Der Bestätigungscode konnte nicht gesendet werden. Bitte überprüfen Sie, ob Ihre E-Mail-Adresse verfügbar ist",
  codeSendToMuchPrompt: "Zu oft auf einmal gesendet. Bitte versuchen Sie es später noch einmal",
  phoneAlreadySignupPrompt: "Diese Mobiltelefonnummer wurde registriert, bitte loggen Sie sich direkt ein",
  emailAlreadySignupPrompt: "Diese E-Mail-Adresse wurde registriert, bitte loggen Sie sich direkt ein",
  signUpErrorPrompt: "Die Registrierung ist fehlgeschlagen. Bitte überprüfen Sie, ob die Informationen korrekt sind",
  enterErrorPrompt: "Eingabefehler oder Netzwerkanomalie",
  noAgreePrompt: "Um sich zu registrieren, müssen Sie der Benutzervereinbarung und der Datenschutzrichtlinie zustimmen",
  regionAndLanguagePrompt: "Bitte wählen Sie Ihre echte Regionalsprache aus, da dies sonst Auswirkungen auf die Nutzung der Funktionen hat",
  accountAndPassword: "Konto",
  quickSignup: "Registrierung mit einem Klick",
  accountEmptyPrompt: "Das Konto darf nicht leer sein",
  accountRulePrompt: "Die Kontonummer muss aus 8–16 Buchstaben und Zahlen bestehen",
  accountAlreadySignupPrompt: "Dieses Konto wurde registriert, bitte melden Sie sich direkt an",
  tryAgain: "Erneut senden",
};

const PersonalCenter = {
  logAndSign: "Jetzt anmelden/registrieren",
  logAndSignPrompt: "Schalten Sie jetzt exklusive Funktionen und exklusive Vorteile frei",
  logOut: "Abmelden",
  myFollow: "Mein Fokus",
  mySubscription: "Mein Abonnement",
  accountSecurity: "Konto Sicherheit",
  feedback: "Rückmeldung",
  deviceInformation: "Ausrüstung",
  aboutUs: "über uns",
  coin: "Gold",
  moreServices: "Mehr Dienstleistungen",
  following: "Folgt bereits",
  subscribed: "Gezeichnet",
  browser: "Browser",
  user: "Benutzer",
  profile: "Persönliche Angaben",
  nickname: "Spitzname",
  save: "speichern",
  change: "Ändern",
  nicknamePrompt: "Bitte geben Sie einen Spitznamen ein",
  avatar: "Benutzerbild",
  changeSuccessPrompt: "Veränderung erfolgreich",
  ballAnimation: "Zielanimation",
  ballVoice: "Tor-Soundeffekte",
  setting: "aufstellen",
};

const IntegralSystem = {
  Coin: "Gold",
  goldCoinTask: "Goldmünzenaufgabe",
  PointsDetails: "Details zur Goldmünze",
  myCoins: "meine Goldmünzen",
  income: "Einkommen",
  expenditure: "Ausgaben",
  signInPrompt: "Melden Sie sich kontinuierlich an und erhalten Sie kontinuierlich Belohnungen",
  toComplete: "fertigstellen",
  toShare: "Teilen",
  shareTasks: "Aufgaben teilen",
  exclusiveLink: "Exklusiver Link",
  copyLink: "Link kopieren",
  highestWin: "höchster Sieg",
  day1: "Tag 1",
  day2: "Tag 2",
  day3: "3. Tag",
  day4: "Tag 4",
  day5: "Tag 5",
  day6: "Tag 6",
  day7: "Tag 7",
  invitePrompt: "Überraschung! Registrieren Sie sich jetzt und laden Sie unsere APP herunter, um exklusive Vorteile für neue Benutzer zu genießen! Kostenlose Live-Event-Übertragungen, Echtzeit-Datenanalyse und professionelle Ankervorhersagen – alles auf Knopfdruck. Warten Sie nicht länger, klicken Sie auf den Link, um beizutreten!"
};

const Money = {
  activeCenter: "Aktivitätszentrum",
  missionCenter: "Missionszentrum",
};

const ActivityCenter = {
  toParticipate: "teilnehmen",
  sportActivity: "sportliche Aktivitäten",
};

const ActivityDetails = {
  eventDetails: "Veranstaltungsdetails",
  dailyBetting: "Tägliche Wetten",
  matchTime: "Spielzeit",
  popularForEvent: "Beliebte Optionen für diese Veranstaltung",
  clickToParticipate: "Klicken Sie hier, um jetzt teilzunehmen",
  activityRules: "Aktivitätsregeln",
  clickToContactParticipate: "Klicken Sie hier, um den Kundendienst zu kontaktieren und teilzunehmen",
  activityObj: "aktives Objekt",
  activityPlatform: "Eventplattform",
  activityDuration: "Aktivitätsdauer",
  activityDemand: "Aktivitätsanforderungen",
  activityTime: "Aktivitätszeit",
};

const AboutUs = {
  aboutUs: "über uns",
  platformIntroduction: "Einführung in die Plattform",
  privacyPolicy: "Datenschutzrichtlinie",
  userAgreement: "Nutzungsbedingungen",
};

const DeviceInfo = {
  deviceInformation: "Ausrüstung",
  currentTime: "aktuelle Uhrzeit",
  port: "Anmeldeport",
  ip: "Login-IP",
  version: "aktuelle Version",
  ipAndPort: "Anmeldeinformationen",
};

const FeedBack = {
  feedback: "Rückmeldung",
  feedbackType: "Feedback-Art",
  productSuggestion: "Produktvorschläge",
  functionalFault: "Funktionsstörung",
  interfaceSuggestion: "Schnittstellenvorschläge",
  accountIssue: "Kontoproblem",
  operationExperience: "Betriebserfahrung",
  otherFeedback: "Anderes Feedback",
  problemDescription: "Problemstellung",
  submit: "einreichen",
  addPhoto: "Foto hinzufügen",
  uploadPrompt: "Leider können nur 3 Fotos hochgeladen werden",
  uploadRulePrompt: "Bitte laden Sie Bilder im JPG-, JPEG- oder PNG-Format hoch",
  uploadSizePrompt: "Die Bildgröße darf 10 MB nicht überschreiten",
  chooseFeedback: "Bitte wählen Sie den Feedbacktyp aus",
  inputQuestionPrompt: "Bitte geben Sie eine Frage ein",
  submitSuccessPrompt: "erfolgreich eingereicht",
};

const MySubscription = {
  myFollow: "Mein Fokus",
  mySubscription: "Mein Abonnement",
  streamer: "Anker",
  matches: "Wettbewerb",
};

const PrivacyAgreement = {
  privacyPolicy: "Datenschutzrichtlinie",
};

const TermsofService = {
  termsOfService: "Nutzungsbedingungen",
};

const UserAgreement = {
  userAgreement: "Nutzungsbedingungen",
};

const AccountSecurity = {
  accountSecurity: "Konto Sicherheit",
  phone: "Telefonnummer",
  email: "Post",
  bind: "Bindung",
  notBound: "Nicht gebunden",
  change: "Ändern",
  logInPassword: "Passwort",
  accountChangePassword: "Bitte binden Sie zunächst Ihre Mobiltelefon-/E-Mail-Adresse ein",
};

const BindEmail = {
  bindEmail: "E-Mail binden",
  email: "Post",
  code: "Bestätigungscode",
  getCode: "Holen Sie sich den Bestätigungscode",
  confirm: "bestätigen",
  emailRulePrompt: "Bitte geben Sie das korrekte Format Ihrer E-Mail-Adresse ein",
  emailEmptyPrompt: "E-Mail darf nicht leer sein",
  codeRulePrompt: "Der Bestätigungscode besteht aus 6 Ziffern",
  codeEmptyPrompt: "Der Verifizierungscode muss ausgefüllt werden",
  changeSuccessPrompt: "Veränderung erfolgreich",
  changeErrorPrompt: "Die Änderung ist fehlgeschlagen",
  bindSuccessPrompt: "Bindung erfolgreich",
  bindErrorPrompt: "Die Bindung ist fehlgeschlagen",
  emailCodeSendSuccessPrompt: "Der Bestätigungscode wurde erfolgreich gesendet. Bitte überprüfen Sie Ihre E-Mails",
  emailCodeSendErrorPrompt: "Der Bestätigungscode konnte nicht gesendet werden. Bitte überprüfen Sie, ob Ihre E-Mail-Adresse verfügbar ist",
  codeSendToMuchPrompt: "Zu oft auf einmal gesendet. Bitte versuchen Sie es später noch einmal",
  emailAlreadySignupPrompt: "Diese E-Mail-Adresse wurde registriert, bitte loggen Sie sich direkt ein",
  tryAgain: "Erneut senden",
};

const BindPhone = {
  bindPhone: "Handy binden",
  phone: "Telefonnummer",
  code: "Bestätigungscode",
  getCode: "Holen Sie sich den Bestätigungscode",
  confirm: "bestätigen",
  cancel: "Stornieren",
  phoneRulePrompt: "Bitte geben Sie Ihre Mobiltelefonnummer im richtigen Format ein",
  phoneEmptyPrompt: "Die Mobiltelefonnummer darf nicht leer sein",
  codeRulePrompt: "Der Bestätigungscode besteht aus 6 Ziffern",
  codeEmptyPrompt: "Der Verifizierungscode muss ausgefüllt werden",
  changeSuccessPrompt: "Veränderung erfolgreich",
  changeErrorPrompt: "Die Änderung ist fehlgeschlagen",
  bindSuccessPrompt: "Bindung erfolgreich",
  bindErrorPrompt: "Die Bindung ist fehlgeschlagen",
  phoneNum8: "Bitte geben Sie Ihre 8-stellige Mobiltelefonnummer ein",
  phoneNum9To10: "Bitte geben Sie eine 9- bis 10-stellige Mobiltelefonnummer ein",
  phoneNum9: "Bitte geben Sie eine 9-stellige Mobiltelefonnummer ein",
  phoneCodeSendSuccessPrompt: "Der Bestätigungscode wurde erfolgreich gesendet. Bitte überprüfen Sie die SMS auf Ihrem Mobiltelefon",
  phoneCodeSendErrorPrompt: "Der Bestätigungscode konnte nicht gesendet werden. Bitte prüfen Sie, ob die Mobiltelefonnummer verfügbar ist",
  codeSendToMuchPrompt: "Zu oft auf einmal gesendet. Bitte versuchen Sie es später noch einmal",
  phoneAlreadySignupPrompt: "Diese Mobiltelefonnummer wurde registriert, bitte loggen Sie sich direkt ein",
  checkSignup: "Bitte prüfen Sie, ob die Nummer registriert ist",
  tryAgain: "Erneut senden",
};

const ChangePassword = {
  resetPassword: "Passwort zurücksetzen",
  phone: "Telefonnummer",
  email: "Post",
  code: "Bestätigungscode",
  getCode: "Holen Sie sich den Bestätigungscode",
  logInPassword: "Passwort",
  confirm: "bestätigen",
  cancel: "Stornieren",
  passwordRulePrompt: "Das Passwort muss aus Groß- und Kleinbuchstaben sowie Zahlen bestehen und länger als 8 Zeichen sein",
  passwordEmptyPrompt: "Das Passwort darf nicht leer sein",
  emailRulePrompt: "Bitte geben Sie das korrekte Format Ihrer E-Mail-Adresse ein",
  emailEmptyPrompt: "E-Mail darf nicht leer sein",
  phoneRulePrompt: "Bitte geben Sie Ihre Mobiltelefonnummer im richtigen Format ein",
  phoneEmptyPrompt: "Die Mobiltelefonnummer darf nicht leer sein",
  codeRulePrompt: "Der Bestätigungscode besteht aus 6 Ziffern",
  codeEmptyPrompt: "Der Verifizierungscode muss ausgefüllt werden",
  phoneNum8: "Bitte geben Sie Ihre 8-stellige Mobiltelefonnummer ein",
  phoneNum9To10: "Bitte geben Sie eine 9- bis 10-stellige Mobiltelefonnummer ein",
  phoneNum9: "Bitte geben Sie eine 9-stellige Mobiltelefonnummer ein",
  resetSuccess: "Passwort-Reset erfolgreich",
  resetError: "Das Zurücksetzen des Passworts ist fehlgeschlagen. Bitte überprüfen Sie, ob die Informationen korrekt sind",
  checkSignup: "Bitte prüfen Sie, ob die Nummer registriert ist",
  phoneCodeSendSuccessPrompt: "Der Bestätigungscode wurde erfolgreich gesendet. Bitte überprüfen Sie die SMS auf Ihrem Mobiltelefon",
  codeSendToMuchPrompt: "Zu oft auf einmal gesendet. Bitte versuchen Sie es später noch einmal",
  phoneAlreadySignupPrompt: "Diese Mobiltelefonnummer wurde registriert, bitte loggen Sie sich direkt ein",
  phoneCodeSendErrorPrompt: "Der Bestätigungscode konnte nicht gesendet werden. Bitte prüfen Sie, ob die Mobiltelefonnummer verfügbar ist",
  emailCodeSendSuccessPrompt: "Der Bestätigungscode wurde erfolgreich gesendet. Bitte überprüfen Sie Ihre E-Mails",
  emailCodeSendErrorPrompt: "Der Bestätigungscode konnte nicht gesendet werden. Bitte überprüfen Sie, ob Ihre E-Mail-Adresse verfügbar ist",
  emailAlreadySignupPrompt: "Diese E-Mail-Adresse wurde registriert, bitte loggen Sie sich direkt ein",
  bindPhoneOrEmail: "Bitte binden Sie zunächst Ihre Mobiltelefonnummer oder E-Mail-Adresse ein",
  tryAgain: "Erneut senden",
};

const QuickRegister = {
  downloadScreenshot: "Screenshot herunterladen",
  saveAccountAndPassword: "Bitte bewahren Sie Ihr Konto und Ihr Passwort sicher auf",
  suggestSave: "Es empfiehlt sich, einen Screenshot zu machen und diesen zu speichern oder in ein Memo zu kopieren.",
  account: "Konto",
  password: "Passwort",
  copyAccountAndPassword: "Kontopasswort kopieren",
  prompt: "Hinweis",
  sureSave: "Haben Sie Ihr Kontopasswort gespeichert?",
  confirm: "bestätigen",
  cancel: "Stornieren",
  copySuccess: "Erfolgreich kopiert",
  copyFailure: "Das Kopieren ist fehlgeschlagen",
  manualRegister: "Die Anzahl der Anmeldungen hat die Obergrenze erreicht, bitte registrieren Sie sich manuell",
};

const Recommend = {
  recommend: "empfehlen",
  InternetErrorPrompt: "Netzwerkfehler",
  hitOfTheWeek: "Hit der Woche",
  victoryOrDefeat: "Sieg oder Niederlage",
  check: "Überprüfen",
  lastTenGames: "Letzte 10 Spiele",
  recommendedByExperts: "Von Experten empfohlen",
  changeBatch: "Ändern Sie eine Charge",
  follow: "konzentrieren Sie sich auf",
  following: "Folgt bereits",
  unFollowing: "Freischalten",
  all: "All",
  guaranteedCompensation: "Garantierte Entschädigung",
  free: "frei",
  toll: "MAUT",
  HOT: "Verkauf",
  HIT: "Schlag",
  STREAK: "Sogar in der Mitte",
  WEEK_HIT: "Weekly hit rate",
  MONTH_HIT: "Monthly hit rate",
  QUARTER_HIT: "Seasonal hit rate",
  RECENT_10_HIT: "Nearly ten hit rates",
};

const RecommendDetail = {
  homePage: "Startseite",
  chat: "Plaudern",
  follow: "konzentrieren Sie sich auf",
  following: "Folgt bereits",
  unFollowing: "Freischalten",
  followersCount: "konzentrieren Sie sich auf",
  planCount: "planen",
  viewCount: "betrachten",
  statistics: "Statistiken",
  plan: "planen",
};

const SchemeDetails = {
  planDetails: "Plandetails",
  release: "freigeben",
  chat: "Plaudern",
  follow: "konzentrieren Sie sich auf",
  following: "Folgt bereits",
  unFollowing: "Freischalten",
  details: "Einzelheiten",
  analyze: "analysieren",
  title: "Titel",
  privateChatStreamerJoinsGroup: "Der Moderator eines privaten Chats tritt der Gruppe bei",
  upcoming: "hat nicht begonnen",
  othersPlan: "Andere Optionen",
};

const MatchInfo = {
  homeTag: "Home",
};

const LeagueSelection = {
  matchSorting: "Übereinstimmungssortierung",
  byTime: "zum Zeitpunkt",
  byLeague: "nach Liga",
  confirm: "bestätigen",
  cancel: "Stornieren",
};

const RecommendPublish = {
  enterTeam: "Team betreten",
  planRelease: "Planfreigabe",
  predictionTitle: "Titel (für alle Benutzer sichtbar, bitte keine Vorhersageergebnisse offenlegen)",
  titleEmptyPrompt: "Titel und Inhalt der Analyse dürfen nicht leer sein",
  pleaseSelectAtLeastOneGameToPredictResults: "Bitte wählen Sie mindestens eine Möglichkeit, die Ergebnisse vorherzusagen",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "Fußball",
  basketball: "Basketball",
  mixed: "umfassend",
  setLogInPassword: "Login-Passwort festlegen",
  OCS: "Onlineservice",
  OCSPrompt: "zu Ihren Diensten",
  phoneInCorrectPrompt: "Mobiltelefonnummer falsch eingegeben",
  codeInCorrectPrompt: "Falscher verifikationscode",
  emailInCorrectPrompt: "Fehler bei der E-Mail-Eingabe",
  passwordPrompt: "Bitte geben Sie eine Kombination aus 8–16 englischen Ziffern und Zahlen ein",
  newPassword: "Geben Sie ein neues Kennwort ein",
  resetPasswordPrompt: "Möchten Sie Ihr Passwort wirklich zurücksetzen?",
  startChatPrompt: "Jetzt können Sie mit dem Chatten beginnen",
  send: "schicken",
  noFollowPrompt: "Noch keine Aufmerksamkeit",
  feedbackPrompt: "Bitte hinterlassen Sie Ihre wertvollen Kommentare und wir werden hart daran arbeiten, uns zu verbessern!",
  bindPhoneByOtherPrompt: "Diese Handynummer wurde von anderen Benutzern gebunden",
  bindEmailByOtherPrompt: "Diese E-Mail-Adresse wurde von einem anderen Benutzer gebunden",
  super_manager: "Superadministrator",
  score: "Sc",
  scoreIndex: "Score-Index",
  cornerKickIndex: "Corner kick-Index",
  close: "Close",
  midfield: "W",
  overtime: "Overtime",
  penaltyKick: "Penalty Kick",
  endOfFirstQuarter: "Ende des ersten Quartals",
  endOfSecondQuarter: "Ende des zweiten Quartals",
  endOfThirdQuarter: "Ende des dritten Quartals",
  endOfFourthQuarter: "Ende des vierten Quartals",
  pullUpToLoadMore: "Ziehen Sie nach oben, um mehr zu laden",
  pullDownToRefresh: "Zum Aktualisieren nach unten ziehen",
  releaseToRefreshImmediately: "Zum sofortigen Aktualisieren loslassen",
  refreshPrompt: "Erfrischend...",
  download: "herunterladen",
  appDownload: "APP-Download",
  brandName: "OV88",
  saveToDesktop: "Zum Startbildschirm hinzufügen",
  downloadTitle: "APP-Download",
  normalDownload: "Normaler Download",
  scanningPrompt: "Installieren Sie mit dem QR-Code und erhalten Sie einen exklusiven großen Rabatt und andere luxuriöse Vorteile!",
  downloadFailToWeb: "Wenn Sie die APP nicht herunterladen können, klicken Sie hier, um die Webversion zu verwenden",
  scanQrCode: "QR-Code mit Mobiltelefon scannen",
  usingMobileDevices: "Mobil nutzen",
  installTutorial: "Klicken Sie hier, um das Installations-Tutorial anzuzeigen",
  whyNameDifferent: "Die Namen der installierten APP und der heruntergeladenen APP stimmen nicht überein. Sie können sie gerne herunterladen, installieren und verwenden!",
  clickDownload: "Klicken Sie hier zum Herunterladen",
  installTutorialPrompt: "Installationsanleitung",
  brandNativeName: "UFOScore native APP",
  uploadingPrompt: "Hochladen...",
  bHandicap: "Behinderung",
  different: "Unterschied",
  total: "gesamt",
  off: "hat nicht begonnen",
  video: "Video",
  animation: "Animation",
  install: "Installieren",
  startDownloading: "Starte Download",
  cancelGoal: "Tor abgesagt",
  appUpdatePrompt: "Es gibt eine neue Version der APP, bitte aktualisieren Sie sie",
};
