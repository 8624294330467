export const languageObj = {
    "1": "zh-cn",
    "2": "zh-hk",
    "3": "en",
    "4": "vi",
    "5": "ms",
    "6": "pt", // 葡萄牙语
    "7": "km", // 高棉语
    "8": "es", // 西班牙语
    "9": "ru", // 俄罗斯语
    "10": "ja", // 日语
    "11": "ko", // 韩语
    "12": "hi", // 印地语
    "13": "fr", // 法语
    "14": "de", // 德语
    "15": "th", // 泰语
    "16": "lo", // 老挝语
}
